import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import VueLazyload from "vue-lazyload";
const loadImage = require("./assets/gallery/lazy/lazy.jpg");
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: loadImage,
  attempt: 1,
});

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import { languages } from "./i18n/index.js";
import { defaultLocale } from "./i18n/index.js";
const messages = Object.assign(languages);

import VueGtag from "vue-gtag";

import VueMeta from "vue-meta";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: `${process.env.VUE_APP_GA}` },
});

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

var i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: "en",
  messages,
});

Sentry.init({
  Vue,
  dsn: `${process.env.VUE_APP_SENTRY}`,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "leap-for-mankind", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
