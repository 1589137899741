<template>
  <section class="dashboard-left">
    <dashboard-altitude
      v-if="isAltimeterVisible"
      :vehicule-altitude="vehiculePosition.y"
      :position-adjustment="this.positionAdjustment"
      :floor-position-adjustment="floorPositionAdjustment"
      :progress="
        calculateProgress(
          vehiculePosition.y * this.positionAdjustment -
            floorPositionAdjustment,
          totalAltitude
        )
      "
      :is-landing="isLanding"
      :is-animated-started="isAnimationStarted"
    />
  </section>
</template>

<script>
import DashboardAltitude from "@/components/Dashboard/DashboardAltitude/DashboardAltitude";
export default {
  components: {
    DashboardAltitude,
  },
  props: {
    vehiculePosition: {
      type: Object,
      required: true,
    },
    totalAltitude: {
      type: Number,
      required: true,
    },
    floorPositionAdjustment: {
      type: Number,
      required: true,
    },
    positionAdjustment: {
      type: Number,
      required: true,
    },
    isLanding: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAltimeterVisible: {
      type: Boolean,
      required: false,
      default: false,
    },

    // isVisible: {
    //   type: Boolean,
    //   required: false,
    //   default: false,
    // },
  },

  methods: {
    calculateProgress(vehiculeAltitude, totalAltitude) {
      const progress = (vehiculeAltitude / totalAltitude) * 100;
      return this.isLanding ? -progress : progress;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/config/vars.scss";
@import "@/assets/scss/config/mixins.scss";
@import "@/assets/scss/config/responsive.scss";
.dashboard-left {
  grid-column: 1 / 2;
  grid-row: 1/1;
  display: flex;
  align-items: center;
}
</style>
