<template>
  <ul class="dashboard-altitude">
    <!-- lines -->
    <shared-altimeter-lines
      :is-animation-started="isAltimeterVisible"
      :progress="progress"
      :is-landing="isLanding"
    />
    <!-- square -->
    <li class="dashboard-altitude__square">
      <shared-button-squares
        v-if="isAltimeterVisible"
        :is-white="false"
        :is-black="false"
        icon-name="copy"
        :copy="
          roundNumber(
            vehiculeAltitude * positionAdjustment - floorPositionAdjustment
          )
        "
        :rotation="90"
      />
    </li>
    <!-- lines -->
    <shared-altimeter-lines
      :is-animation-started="isAltimeterVisible"
      :progress="progress"
      :is-landing="isLanding"
    />
  </ul>
</template>

<script>
import { mapState } from "vuex";
import SharedAltimeterLines from "@/components/Shared/SharedAltimeter/SharedAltimeterLines/SharedAltimeterLines";
import SharedButtonSquares from "@/components/Shared/SharedButton/SharedButtonSquares/SharedButtonSquares";
export default {
  components: {
    SharedAltimeterLines,
    SharedButtonSquares,
  },
  props: {
    vehiculeAltitude: {
      type: Number,
      required: true,
    },
    positionAdjustment: {
      type: Number,
      required: true,
    },
    progress: {
      type: Number,
      required: false,
      default: 4000,
    },
    floorPositionAdjustment: {
      type: Number,
      required: false,
      default: 23.5,
    },
    isLanding: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      isAltimeterVisible: (state) => state.dashboardBottom.isAltimeterVisible,
    }),
  },
  methods: {
    roundNumber(number) {
      return String(number.toFixed(0));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/config/vars.scss";
@import "@/assets/scss/config/mixins.scss";
@import "@/assets/scss/config/responsive.scss";

.dashboard-altitude {
  display: flex;
  flex-direction: column;
  // total height - 2 btn + padding scene
  height: calc(100vh - (6.6rem * 2 + 8rem));
  padding: $xl-margin 0;
  justify-content: center;
}
</style>
