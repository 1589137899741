<template>
  <div
    class="shared-button-video-progress"
    :style="`--dataoffset:${dataOffsetProgress}`"
  >
    <svg
      width="79"
      height="79"
      viewBox="0 0 79 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <clipPath id="shared-btn-video-progress">
        <circle cx="39.5" cy="39.5" r="37.5" />
      </clipPath>
      <circle
        stroke="#bc85ff"
        clip-path="url(#shared-btn-video-progress)"
        class="shared-button-video-progress__circle"
        fill="none"
        stroke-miterlimit="4"
        stroke-width="4"
        cx="39.5"
        cy="39.5"
        r="37.5"
      />
    </svg>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dataOffset: 0,
    };
  },
  computed: {
    ...mapState({
      progress: (state) => state.intro.progress,
    }),
    dataOffsetProgress() {
      //   return this.progress * 4.5 - 450;
      return 450 - this.progress * 235;
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-video-progress {
  --dataoffset: 450;
  @include transform(rotate(-90deg));
  position: absolute;
  top: -3.6%;
  left: -1.5%;

  &__circle {
    @include transition(0.3s ease all);
    stroke: $gallery;

    stroke-dasharray: 450;
    stroke-dashoffset: 450;
    stroke-dashoffset: var(--dataoffset);
  }
}
</style>
