<template>
  <div class="shared-modal" :class="{ 'shared-modal--wide': isWide }">
    <div class="shared-modal__inner">
      <div class="shared-modal__inner__modal">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAnimationStarted: false,
      modalTimeout: null,
    };
  },
  props: {
    isWide: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-modal {
  --shared-modal-column: 2 / 3;
  @include fullScreenDom(fixed);
  @include dashboardLayout;
  pointer-events: auto;
  z-index: 90;
  background: rgb(34, 40, 49);
  background: radial-gradient(
    circle,
    rgba(34, 40, 49, 1) 0%,
    rgba(34, 40, 49, 0.76) 100%
  );

  &--wide {
    --shared-modal-column: 1 / 4;
    @include teq-breakpoint($medium) {
      --shared-modal-column: 2 / 3;
    }
  }

  //   padding: 4rem; //padding left and right === padding
  &__inner {
    &__modal {
      grid-column: var(--shared-modal-column);
      grid-row: 1/1;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      pointer-events: inherit;
      // cursor: pointer;
    }
  }
}
</style>
