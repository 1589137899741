<template>
  <div class="shared-btn-svg-square">
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- square -->
      <clipPath id="shared-btn-svg-square__square-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41 1H1V41H41V1ZM0 0V42H42V0H0Z"
          fill="#E8E8F2"
        />
      </clipPath>
      <path
        clip-path="url(#shared-btn-svg-square__square-path)"
        class="shared-btn-svg-square__square-path"
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
        d="M41 1H1V41H41V1ZM0 0V42H42V0H0Z"
      />

      <!-- circle -->
      <clipPath id="shared-btn-svg-square__circle-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41ZM21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
          fill="#E8E8F2"
        />
      </clipPath>
      <path
        clip-path="url(#shared-btn-svg-square__circle-path)"
        class="shared-btn-svg-square__circle-path"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
        d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41ZM21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
      />

      <!-- line -->
      <clipPath id="shared-btn-svg-square__line-2-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41 42L-1.44839e-05 0.999953L0.707092 0.292847L41.7071 41.2928L41 42Z"
          fill="#E8E8F2"
        />
      </clipPath>
      <path
        clip-path="url(#shared-btn-svg-square__line-2-path)"
        class="shared-btn-svg-square__line-2-path"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
        d="M41 42L-1.44839e-05 0.999953L0.707092 0.292847L41.7071 41.2928L41 42Z"
      />
      <!-- line -->
      <clipPath id="shared-btn-svg-square__line-1-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M42 0.999953L1.00001 42L0.292908 41.2928L41.2929 0.292846L42 0.999953Z"
        />
      </clipPath>
      <path
        clip-path="url(#shared-btn-svg-square__line-1-path)"
        class="shared-btn-svg-square__line-1-path"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
        d="M42 0.999953L1.00001 42L0.292908 41.2928L41.2929 0.292846L42 0.999953Z"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scope>
.shared-btn-svg-square {
  @include buttonAnimation;
}
</style>
