<template>
  <div class="dashboard-bottom">
    <ul class="dashboard-bottom__list">
      <shared-bottom-control-left v-if="isResetVisible" />

      <shared-bottom-control-center
        :is-lift-off-visible="isLiftOffVisible"
        :is-visible="isJoystickVisible"
        :scene-name="sceneName"
      />
      <shared-bottom-control-right
        v-if="isLiftOffVisible && isArrowUpVisible"
        :scene-name="sceneName"
        @keyPressed="keyPressed"
        @keyUnPressed="keyUnPressed"
      />
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SharedBottomControlCenter from "@/components/Shared/SharedBottomControl/SharedBottomControlCenter/SharedBottomControlCenter";
import SharedBottomControlLeft from "@/components/Shared/SharedBottomControl/SharedBottomControlLeft";
import SharedBottomControlRight from "@/components/Shared/SharedBottomControl/SharedBottomControlRight/SharedBottomControlRight";
export default {
  components: {
    SharedBottomControlCenter,
    SharedBottomControlLeft,
    SharedBottomControlRight,
  },
  computed: {
    ...mapState({
      isResetVisible: (state) => state.dashboardBottom.isResetVisible,
      isJoystickVisible: (state) => state.dashboardBottom.isJoystickVisible,
      isArrowUpVisible: (state) => state.dashboardBottom.isArrowUpVisible,
    }),
  },
  props: {
    isLiftOffVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    sceneName: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    keyPressed(val) {
      this.$emit("keyPressed", val);
    },
    keyUnPressed(val) {
      this.$emit("keyUnPressed", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 $small-margin $small-margin $small-margin;
  z-index: 9;
  @include teq-breakpoint($medium) {
    padding: 0 $xl-margin $xl-margin $xl-margin;
  }
  &__list {
    display: grid;
    gap: $small-margin;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-columns: 6.6rem, 1fr, 6.6rem;
    grid-template-rows: repeat(1, 1fr);
  }
}
</style>
