<template>
  <li class="credits-list">
    <p v-if="item.url">
      {{ $t(`pages.credits.copy.${item.key}.title`) }}:
      <a :href="item.url" target="_blank" rel="noopener noreferrer">
        &nbsp; {{ $t(`pages.credits.copy.${item.key}.label`) }}</a
      >
    </p>
    <h2 v-else>{{ $t(`pages.credits.copy.${item.key}.title`) }}</h2>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.credits-list {
  --credit-list-aligment: flex-start;
  --credit-aligment: left;
  display: flex;
  flex-direction: row;
  text-align: var(--credit-aligment);
  justify-content: var(--credit-list-aligment);
  margin-bottom: $x-small-margin;
  @include teq-breakpoint($medium) {
    // --credit-list-aligment: center;
    // --credit-aligment: center;
  }
  h2 {
    margin-top: $x-small-margin;
    @include h4;
  }
  a {
    @include yellowLink;
    position: relative;

    &:after {
      content: " ";
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: -2px;
      left: 0px;
      background-color: var(--color-golden);
    }
  }
}
</style>
