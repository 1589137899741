<template>
  <section
    class="scene"
    :class="{
      'scene--exit': gsapAnimation.exitScene.isRunning,
      'scene--debug': isDevEnv(),
    }"
  >
    <dashboard
      v-if="physics.objectsToUpdate.length"
      :vehicule-position="physics.objectsToUpdate[0].body.position"
      :perimeters="perimeters.alert"
      :is-space-key-pressed="this.isAlertPressKeyTriggered"
      :is-paused="this.isGamePaused"
      :total-altitude="4000"
      :floor-position-adjustment="23.5"
      scene-name="liftOff"
      :position-adjustment="2"
    />

    <dashboard-bottom
      scene-name="liftOff"
      @keyPressed="keyPressed"
      @keyUnPressed="keyUnPressed"
    />
    <canvas ref="scene__container" class="scene__container"></canvas>
    <!-- @click="physics.isPausedphysics.isPaused = true" -->
  </section>
</template>

<script>
import { mapState } from "vuex";

import gtagMixin from "@/mixins/gtagMixin.js";
import isDevMixin from "@/mixins/isDevMixin";
import resetGamePlayStoreMixin from "@/mixins/resetGamePlayStoreMixin";
import sharedFXAudioMixin from "@/mixins/sharedFXAudioMixin";
import sharedGamePlayMixin from "@/mixins/sharedGamePlayMixin";
import toggleDashboardBottomMixin from "@/mixins/toggleDashboardBottomMixin";
import transitionMixin from "@/mixins/transitionMixin.js";
import tutorialMixin from "@/mixins/tutorialMixin";

// GSAP
import { gsap } from "gsap";

// Three
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

// Post Processing
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { RGBShiftShader } from "three/examples/jsm/shaders/RGBShiftShader.js";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass.js";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";

import { Water } from "three/examples/jsm/objects/Water.js";
import { Sky } from "three/examples/jsm/objects/Sky.js";

// vendors
import * as CANNON from "cannon-es";
import CannonDebugRenderer from "@/vendors/CannonDebugRenderer.js";
import LinearSpline from "@/vendors/LinearSpline.js";

// STATS
import Stats from "stats.js";
import { GUI } from "@/vendors/dat.gui.module.js";

// shaders
import fireVertexShader from "@/shaders/fire/vertex.glsl";
import fireFragmentShader from "@/shaders/fire/fragment.glsl";

// components
import Dashboard from "@/components/Dashboard/Dashboard";
import DashboardBottom from "@/components/Dashboard/DashboardBottom/DashboardBottom";

export default {
  mixins: [
    gtagMixin,
    isDevMixin,
    resetGamePlayStoreMixin,
    sharedFXAudioMixin,
    toggleDashboardBottomMixin,
    transitionMixin,
    tutorialMixin,
    sharedGamePlayMixin,
  ],
  components: {
    Dashboard,
    DashboardBottom,
  },
  data() {
    return {
      // base
      container: null,
      scene: null,
      camera: null,
      controls: null,
      animation: undefined,

      isGamePausedOnce: false,

      lights: {
        ambientLight: null,
      },

      gameIsResetTimeout: null,

      initiate: {
        isInitStarted: false,
        isInitiating: false,
        timeout: null,
        timeoutGameloop: null,
        timeoutMounted: null,
        exitSceneTimeout: null,
        existSceneProgressTimeout: null,
      },

      softParticles: {
        renderTarget: null,
        ratio: 0,
        uDepthFade: 0.005,
      },

      effectComposer: {
        effectComposer: null,
        renderTarget: null,
        unrealBloomPass: {
          unrealBloomPass: null,
          parameters: {
            strength: 1.7,
            radius: 1.25,
            threshold: 0.6,
          },
        },
        glitchPass: {
          GlitchPass: null,
          isGlitchPassVisible: false,
        },
        RGBShiftPass: null,
        filmPass: {
          filmPass: null,
        },
        glitchResetTimeOut: null,
      },

      skyBox: {
        sky: null,
        sun: null,
        parameters: { elevation: 0, azimuth: 50 },
      },

      //  helper
      helpers: {
        stats: null,
        clock: null,
      },
      clock: {
        clock: null,
        oldElapsedTime: 0,
        then: 0,
        then60FPS: 0,
        then20FPS: 0,
        then10FPS: 0,
      },
      cameras: {
        defaultCameraPosition: {
          camX: 25,
          camY: -5,
          camZ: 0,
        },

        windowUser: {
          width: 0,
          height: 0,
        },
      },
      // perimeters for alert and reset
      perimeters: {
        alert: {
          x: {
            min: -50,
            max: 50,
          },
          // left + right keys
          z: {
            min: -50,
            max: 50,
          },
          // top + bottom keys
        },
        reset: {
          x: {
            min: -85,
            max: 85,
          },
          // left + right keys
          z: {
            min: -85,
            max: 85,
          },
          // top + bottom keys
        },
      },
      // loader GLTF
      gltf: {
        GLTFLoader: null,
        GLFTList: [
          {
            key: "saturnV",
            material: {
              large: {
                texture: "saturn-v/baked.jpg",
                normal: "saturn-v/normal.jpg",
              },
              small: {
                texture: "saturn-v/baked__1024.jpg",
                normal: "saturn-v/normal__1024.jpg",
              },
            },
            path: "saturn-v/saturn",
            position: [0, 0, 0],
            progress: 0,
            loaded: false,
            isMobile: true,
          },
          {
            key: "base",
            material: {
              large: {
                texture: "launchpad/road_and_details/baked__support_bunker.jpg",
              },
              small: {
                texture:
                  "launchpad/road_and_details/baked__support_bunker__512.jpg",
              },
            },
            path: "road_and_details/launchpad__support",
            position: [0, 0, 0],
            progress: 0,
            loaded: false,
            isMobile: true,
          },
          {
            key: "bunker",
            material: {
              large: {
                texture: "launchpad/launchpad__bunker__large.jpg",
              },
              small: {
                texture: "launchpad/launchpad__bunker__large__512.jpg",
              },
            },
            path: "launchpad__bunker__large",
            position: [0, 0, 0],
            progress: 0,
            loaded: false,
            isMobile: true,
          },
          {
            key: "mainTerrain",
            material: {
              large: {
                texture: "launchpad/grass/baked__painted__terrain.jpg",
              },
              small: {
                texture: "launchpad/grass/baked__painted__terrain.jpg",
              },
            },
            path: "grass/launchpad__terrain",
            position: [0, 0, 0],
            progress: 0,
            loaded: false,
            isMobile: false,
          },
          {
            key: "details",
            material: {
              large: {
                texture: "launchpad/road_and_details/baked__road.jpg",
              },
              small: {
                texture: "launchpad/road_and_details/baked__road__1024.jpg",
              },
            },
            path: "road_and_details/launchpad__road",
            position: [0, 0, 0],
            progress: 0,
            loaded: false,
            isMobile: true,
          },
          {
            key: "tower",
            material: {
              large: {
                texture: "launchpad/launchPad__tower--with-connectors.jpg",
              },
              small: {
                texture:
                  "launchpad/launchPad__tower--with-connectors__1024.jpg",
              },
            },
            path: "launch-pad__tower",
            position: [0, 0, 0],
            progress: 0,
            loaded: false,
            isMobile: true,
          },
          {
            key: "surroundingTerrain",
            material: {
              large: {
                texture:
                  "launchpad/grass/baked__painted__terrain__surrounding.jpg",
              },
            },
            path: "grass/launch-pad__terrain__surrounding",
            position: [200, 0, 0],
            progress: 0,
            loaded: false,
            isMobile: false,
            clones: [
              {
                position: [-199, 0, 0],
                rotate: 0,
                size: [0.1, 0.1, 0.1],
                progress: 0,
              },
              {
                position: [46 - 200, 0, 90],
                rotate: Math.PI,
                size: [0.1, 0.1, 0.1],
                progress: 0,
              },
              {
                position: [46, 0, 90],
                rotate: Math.PI,
                size: [0.1, 0.1, 0.1],
                progress: 0,
              },
              {
                position: [46 + 200, 0, 90],
                rotate: Math.PI,
                size: [0.1, 0.1, 0.1],
                progress: 0,
              },
            ],
          },
          {
            key: "surroundingTerrainCorner",
            material: {
              large: {
                texture:
                  "launchpad/grass/baked__painted__terrain__surrounding__corner.jpg",
              },
            },
            path: "grass/launch-pad__terrain__surrounding__corner",
            position: [398, 0, 0],
            progress: 0,
            loaded: false,
            isMobile: false,
            clones: [
              {
                position: [368, 0, 122],
                rotate: Math.PI * 1.5,
                size: [0.1, 0.1, 0.1],
                progress: 0,
              },
            ],
          },

          {
            key: "birds",
            texture: null,
            path: "birds/launchpad__birds",
            position: [-10, 0, 0],
            progress: 0,
            loaded: false,
          },
        ],
      },
      loader: {
        textureLoader: null,
      },
      birds: undefined,
      //   meshes
      meshes: {
        birds: undefined,

        water: {
          mesh: null,
        },
        clouds: {
          meshes: [
            {
              key: "cloud1",
              texture: {
                large: {
                  texture: "clouds/cloud1.png",
                },
                small: {
                  texture: "clouds/small/cloud1.png",
                },
              },
              mesh: null,
              geometry: null,
              material: null,
              position: [1000, 800, -8000],
              scale: 800,
              rotate: 0,
              color: 0xd6b198,
              opacity: 0.4,
              clones: [],
            },
            {
              key: "cloud2",
              texture: {
                large: {
                  texture: "clouds/cloud5.png",
                },
                small: {
                  texture: "clouds/small/cloud5.png",
                },
              },
              mesh: null,
              geometry: null,
              material: null,
              position: [0, 1200, -8000],
              scale: 800,
              rotate: 0,
              color: 0xd6b198,
              opacity: 0.4,
              clones: [
                {
                  position: [5000, 5000, -3000],
                  scale: 500,
                  rotate: Math.PI * 0.75,
                  color: 0xd6b198,
                  opacity: 0.4,
                },
                {
                  position: [4000, 4200, -5000],
                  scale: 700,
                  rotate: Math.PI * 0.75,
                  color: 0xd6b198,
                  opacity: 1.0,
                },
              ],
            },
            {
              key: "cloud3",
              texture: {
                large: {
                  texture: "clouds/cloud3.png",
                },
                small: {
                  texture: "clouds/small/cloud3.png",
                },
              },
              mesh: null,
              geometry: null,
              material: null,
              position: [5000, 2000, -3000],
              scale: 800,
              rotate: Math.PI * 0.75,
              color: 0xd6b198,
              opacity: 0.35,
              clones: [],
            },
            {
              key: "cloud5",
              texture: {
                large: {
                  texture: "clouds/cloud11.png",
                },
                small: {
                  texture: "clouds/small/cloud11.png",
                },
              },
              mesh: null,
              geometry: null,
              material: null,
              position: [20, 315, 20],
              scale: 50,
              rotate: Math.PI * 0.75,
              color: 0x5c5550,
              opacity: 1.0,
              clones: [
                {
                  mesh: null,
                  position: [-20, 80, 20],
                  scale: 30,
                  color: 0x5c5550,
                  opacity: 1.0,
                },
                {
                  mesh: null,
                  position: [-0, 80, 30],
                  scale: 40,
                  color: 0x5c5550,
                  opacity: 1.0,
                },
                {
                  mesh: null,
                  position: [-5, 85, 22],
                  scale: 20,
                  color: 0x5c5550,
                  opacity: 1.0,
                },

                {
                  mesh: null,
                  position: [-20, 410, 20],
                  scale: 40,
                  color: 0x5c5550,
                  opacity: 1.0,
                },

                {
                  mesh: null,
                  position: [-5, 420, 22],
                  scale: 50,
                  color: 0x5c5550,
                  opacity: 1.0,
                },

                {
                  mesh: null,
                  position: [-20, 500, 10],
                  scale: 50,
                  color: 0x5c5550,
                  opacity: 1.0,
                },

                {
                  mesh: null,
                  position: [-10, 600, 10],
                  scale: 100,
                  color: 0x5c5550,
                  opacity: 1.0,
                },

                {
                  mesh: null,
                  position: [-15, 650, 10],
                  scale: 150,
                  color: 0x5c5550,
                  opacity: 1.0,
                },

                {
                  mesh: null,
                  position: [-15, 1100 - 200, 10],
                  scale: 200,
                  color: 0x5c5550,
                  opacity: 1.0,
                },
                {
                  mesh: null,
                  position: [-1, 1150 - 200, 10],
                  scale: 200,
                  color: 0x5c5550,
                  opacity: 1.0,
                },

                {
                  mesh: null,
                  position: [-10, 1200 - 200, 10],
                  scale: 300,
                  color: 0x5c5550,
                  opacity: 1.0,
                },

                {
                  mesh: null,
                  position: [-15, 1200 - 200, 10],
                  scale: 400,
                  color: 0x5c5550,
                  opacity: 1.0,
                },
                {
                  mesh: null,
                  position: [-1, 1250 - 200, 10],
                  scale: 500,
                  color: 0x5c5550,
                  opacity: 1.0,
                },
                {
                  mesh: null,
                  position: [-15, 1300 - 200, 11],
                  scale: 500,
                  color: 0x5c5550,
                  opacity: 1.0,
                },
              ],
            },
          ],
        },
      },

      // particles
      particles: {
        maxPoints: {
          fire: 100,
          smoke: 80,
        },
        fire: {
          particles: [],
          points: undefined,
          geometry: undefined,
          alphaSpline: null,
          colourSpline: null,
          sizeSpline: null,
          alphaSplineS: null,
          colourSplineS: null,
          sizeSplineS: null,
          alphaSplineF: null,
          colourSplineF: null,
          sizeSplineF: null,
        },
        smoke: {
          particles: [],
          points: undefined,
          geometry: undefined,
          material: undefined,
          alphaSplineS: null,
          colourSplineS: null,
          sizeSplineS: null,
        },
      },

      //   physics
      physics: {
        world: null,
        helper: null,
        cannonDebugRenderer: null,
        materials: {
          defaultMaterial: null,
        },
        objectsToUpdate: [],
        boostPower: {
          space: [0, 10, 0], // 10
          isSpaceKeyPressed: false,
          isUserPressedSpaceOnce: false,
        },

        altitude: {
          flight: [
            {
              alt: 13, // start detect collision after this height
              hasFlightAbove: false,
            },
            {
              alt: 50, // run radio lift off
              hasFlightAbove: false,
            },
            {
              alt: 200, // display radio sound (roll over program)
              hasFlightAbove: false,
            },
            {
              alt: 980, // display exit scene
              hasFlightAbove: false,
            },
          ],

          isRocketCrashed: false,
        },
      },

      alerts: {
        countDown: {
          secondsLeft: 5,
          countDownInterval: null,
        },
        isRocketCrashModalVisible: false,
        timeoutModal: null,
        timeoutRocketCrash: null,
      },
      tutorials: {
        timeOutTutorials: null,

        resetDisplaySpaceBarTimeout: null,
        isResetStarted: false,
      },

      // gsap animation
      gsapAnimation: {
        cameraShake: {
          explosionTimeLine: {
            timeLine: null,
            isRunning: false,
            isActive: false,
          },
          fastTimeLine: {
            timeLine: null,
            isRunning: false,
            isActive: false,
          },
          slowTimeLine: {
            timeLine: null,
            isRunning: false,
            isActive: false,
          },
          timeLine: null,
          timeLineSoft: null,
          isRunning: false,
          isActive: false,
          duration: 0.05,
          durationSoft: 0.1,
        },
        exitScene: {
          isRunning: false,
        },
        intro: {
          timeline: null,
          isStarted: false,
        },
      },

      birdMixer: undefined,
      gltfAnimation: {
        birds: {
          animationMixer: null,
        },

        launchPad: {
          launchPadConnector: null,

          animation: {
            // launch pad animation
            launchPadAnimationArray: [],
            isLaunchPadAnimationStarted: false,
            launchPadAnimation: null,
          },
        },
      },
      audio: {
        isRollProgramRun: false,
        isLiftOffRun: false,
      },

      loaderManager: {
        intervalProgress: null,
        timeOutStartGame: null,
        totalProgress: 0,
        totalProgressClones: 0,
        totalProgressCombined: 0,
        totalProgressPhysics: 0,
      },

      defaultValues: {
        rocket: {
          position: {
            x: 0,
            y: 0,
            z: 0,
          },
          rotation: {
            x: 0,
            y: 0,
            z: 0,
          },
        },
      },
    };
  },

  computed: {
    ...mapState({
      isMobile: (state) => state.userDevice.isMobile,
      isGamePaused: (state) => state.sharedGamePlay.isGamePaused,
      isGamePlayDebug: (state) => state.sharedGamePlay.isGamePlayDebug,
      isGameControlDisabled: (state) =>
        state.sharedGamePlay.isGameControlDisabled,
      isGameToReset: (state) => state.sharedGamePlay.isGameToReset,
      isIntroAnimationVisible: (state) =>
        state.sharedGamePlay.isIntroAnimationVisible,
      isTransitionLongEnough: (state) =>
        state.sharedTransition.isTransitionLongEnough,
      isUserDeviceReady: (state) => state.userDevice.isUserDeviceReady,
      userBrowser: (state) => state.userDevice.browser,
    }),
    isAlertPressKeyTriggered() {
      // avoid the alter booster to be trigger when the user didn't even pressed space OR when user is in pause
      return this.physics.boostPower.isUserPressedSpaceOnce &&
        !this.isGamePaused &&
        !this.physics.altitude.isRocketCrashed
        ? this.physics.boostPower.isSpaceKeyPressed
        : true;
    },
    isGamePlayDebugging() {
      return this.isDevEnv() && this.isGamePlayDebug;
    },
    isSafari() {
      return this.userBrowser.includes("safari") || this.isMobile;
    },
  },
  watch: {
    isGameToReset(bool) {
      bool ? this.resetGame() : this.spaceBarTutorialToDisplayAfterReset();
    },
    isGamePaused(bool) {
      bool
        ? (this.stopCameraShake(),
          this.userStoppedBooster(),
          this.toggleIntroTimeline(!bool),
          this.toggleIsGamePausedOnce(true))
        : this.toggleIntroTimeline(!bool),
        this.isGamePausedOnce && !this.initiate.isInitiating
          ? this.nextAnimationFrame()
          : null;
    },
    isUserDeviceReady(bool) {
      bool && !this.initiate.isInitiating ? this.delayInit() : null;
    },
    isTransitionLongEnough(bool) {
      bool ? this.triggerPlayIntroTimeline() : null;
    },
  },

  mounted() {
    this.delayMountedMethods();
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animation);
    this.animation = undefined;

    this.destroyInterval(this.alerts.countDown.countDownInterval);

    // remove all timeouts
    this.destroyTimeout(this.alerts.timeoutModal);

    this.destroyTimeout(this.initiate.timeout);
    this.destroyTimeout(this.effectComposer.glitchResetTimeOut);
    this.destroyTimeout(this.alerts.timeoutRocketCrash);
    this.destroyTimeout(this.loaderManager.timeOutStartGame);
    this.destroyTimeout(this.initiate.exitSceneTimeout);
    this.destroyTimeout(this.initiate.existSceneProgressTimeout);
    this.destroyTimeout(this.gameIsResetTimeout);

    // remove event listener
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("keydown", this.keyPressed);
    window.removeEventListener("keyup", this.keyUnPressed);
    this.physics.objectsToUpdate[0].body.removeEventListener(
      "collide",
      this.rocketCrash
    );

    // reset tutorials (which have timeout)
    this.resetTutorial();
    this.destroyTimeout(this.loaderManager.timeOutStartGame);
    this.destroyTimeout(this.initiate.timeoutMounted);
    this.resetLoaderManagerInterval();

    // reset global gameplay
    this.resetGamePlayStore();
    this.clearDisplayAndHideTimeOut();

    this.destroyTimelines();
  },
  methods: {
    ////////////////////////////////
    //       START ON START METHODS
    ////////////////////////////////

    delayMountedMethods() {
      this.initiate.timeoutMounted = setTimeout(() => {
        this.methodsOnMount();
        this.destroyTimeout(this.initiate.timeoutMounted);
      }, 300);
    },
    methodsOnMount() {
      this.toggleIsGamePausedOnce(false);

      this.toggleResetLocally(false);
      this.setWindowSize();
      this.isUserDeviceReady && !this.isPageFullyLoaded()
        ? this.delayInit()
        : null;
      // Register an event listener when the Vue component is ready
      window.addEventListener("resize", this.onResize);
      window.addEventListener("keydown", this.keyPressed);
      window.addEventListener("keyup", this.keyUnPressed);
    },

    /*------------------------------
      Start Reset on mount
    ------------------------------*/

    // gameLoop is pause when game pause, so ensure that gameLoop don't run twince on mount
    toggleIsGamePausedOnce(bool) {
      this.isGamePausedOnce = bool;
    },

    /*------------------------------
      End Reset on mount
    ------------------------------*/
    //   set window width
    setWindowSize() {
      this.cameras.windowUser.width = window.innerWidth;
      this.cameras.windowUser.height = window.innerHeight;
    },

    onResize() {
      // Update sizes
      this.setWindowSize();

      // Update camera
      this.camera ? this.updateCameraOnResize() : null;

      this.renderer ? this.setRenderSize() : null;

      this.effectComposer.composer
        ? (this.setSizePostProcessing(), this.setEffectComposerRenderTarget())
        : null;
    },

    updateCameraOnResize() {
      this.camera.aspect =
        this.cameras.windowUser.width / this.cameras.windowUser.height;
      this.camera.updateProjectionMatrix();
    },

    setRenderSize() {
      // Update renderer
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );
      this.softParticles.ratio = Math.min(window.devicePixelRatio, 2);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },

    setRenderTargetMethod() {
      this.softParticles.renderTarget = new THREE.WebGLRenderTarget(1, 1);
      this.softParticles.renderTarget.texture.format = THREE.RGBAFormat;
      this.softParticles.renderTarget.texture.minFilter = THREE.NearestFilter;
      this.softParticles.renderTarget.texture.magFilter = THREE.NearestFilter;
      this.softParticles.renderTarget.texture.generateMipmaps = false;
      this.softParticles.renderTarget.stencilBuffer = false;
      this.softParticles.renderTarget.depthBuffer = true;
      this.softParticles.renderTarget.depthTexture = new THREE.DepthTexture();
      this.softParticles.renderTarget.depthTexture.type =
        THREE.UnsignedShortType;
      this.softParticles.renderTarget.depthTexture.format = THREE.DepthFormat;

      this.softParticles.renderTarget.setSize(
        Math.floor(window.innerWidth * this.softParticles.ratio),
        Math.floor(window.innerHeight * this.softParticles.ratio)
      );
    },

    setEffectComposerRenderTarget() {
      this.effectComposer.renderTarget = new THREE.WebGLRenderTarget(
        window.innerWidth,
        window.innerHeight,
        {
          minFilter: THREE.LinearFilter,
          magFilter: THREE.LinearFilter,
          format: THREE.RGBAFormat,
          encoding: THREE.sRGBEncoding,
        }
      );
    },

    ////////////////////////////////
    //       END ON START METHODS
    ////////////////////////////////

    ////////////////////////////////
    //       START INIT SCENE AND RENDER
    ////////////////////////////////

    delayInit() {
      this.initiate.isInitiating = true;
      this.initiate.timeout = setTimeout(
        () => {
          this.init();
          this.destroyTimeout(this.initiate.timeout);
        },
        this.isMobile ? 2000 : 300
      );
    },

    init() {
      if (this.initiate.isInitStarted) return;
      this.initiate.isInitStarted = true;

      // ensure that user have enough time to read the tutorial
      this.startTimerTransitionIsLongEnough();

      this.setBaseScene();

      this.setLight();

      /*------------------------------
      Start add meshes to the scenes
      ------------------------------*/
      this.addPhysics();
      this.loadModels();
      this.addMeshesToScene();
      /*------------------------------
      End add meshes to the scenes
      ------------------------------*/

      this.setRenderer();

      this.setCamera();
      this.setControl();

      // sky box need this.render to be set
      this.addSkybox();

      this.setPostProcessing();
      this.setRenderTargetMethod();

      this.isDevEnv() ? this.setHelpers() : "";

      /*------------------------------
      Start Particles
      ------------------------------*/

      this.setParticleSystem();
      /*------------------------------
      End Particles
      ------------------------------*/

      // set animations related to the scene
      !this.isMobile ? this.setCameraShake() : null;

      this.runProgressLoadingPage();

      // skip animation in debug mode
      this.isGamePlayDebugging ? this.runDebuggedGameMode() : null;
      this.initiate.isInitiating = false;
      this.gameLoop();
    },

    runDebuggedGameMode() {
      this.displaySpaceBarTutorial(); // mock
      this.$store.commit("sharedGamePlay/TOGGLE_INTRO_ANIMATION", false);
    },

    //======= START GAME LOOP =======//

    gameLoop() {
      // don't display the stats if on prod. Maybe this dev stuff should be handle differently
      this.isDevEnv() ? this.helpers.stats.update() : null;
      this.isDevEnv() ? this.physics.cannonDebugRenderer.update() : null;

      const elapsedTime = this.clock.clock.getElapsedTime();
      const deltaTime = elapsedTime - this.clock.oldElapsedTime;
      this.clock.oldElapsedTime = elapsedTime;

      this.animatePhysics(deltaTime);

      for (const object of this.physics.objectsToUpdate) {
        object.mesh.position.copy(object.body.position);
        object.mesh.quaternion.copy(object.body.quaternion);
      }

      this.physics.objectsToUpdate.length
        ? this.groupedMethodsWhichNeedsEverythingReady(elapsedTime)
        : null;

      this.effectComposer.composer.render();

      // Call gameLoop again on the next frame
      this.isGamePaused ? null : this.nextAnimationFrame();
    },
    nextAnimationFrame() {
      this.animation = requestAnimationFrame(this.gameLoop);
    },

    animatePhysics(deltaTime) {
      this.physics.world.step(1 / 60, deltaTime, 3);
    },

    groupedMethodsWhichNeedsEverythingReady(elapsedTime) {
      // camera follow the rocket
      this.isIntroAnimationVisible && !this.isGamePlayDebugging
        ? null
        : this.followVehicule();

      // Render NEED TO BE AT THE BERY END
      this.groupedRecudedFPSMethods(elapsedTime);
    },

    groupedRecudedFPSMethods(elapsedTime) {
      this.reducedFPSMethods(elapsedTime);
      this.reduced60FPS(elapsedTime);
      this.reduced20FPS(elapsedTime);
      this.reduced10FPS(elapsedTime);
    },

    reduced60FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 60;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then60FPS;

      if (delta > interval) {
        // it's too hard to pilot with 3 axis to manage
        this.forceRocketToNotRotate();

        // move fire point below the rocket
        this.particles.fire.points && this.physics.objectsToUpdate.length
          ? this.updatePositionFirePoint()
          : null;

        this.particleSteps(0.1); // animate smokes and dust
        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then60FPS = now - (delta % interval);
      }
    },

    reducedFPSMethods(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 30;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then;

      if (delta > interval) {
        this.togglePoints(false);

        this.renderer.setRenderTarget(this.softParticles.renderTarget);
        this.renderer.render(this.scene, this.camera);

        this.renderer.setRenderTarget(null);

        this.togglePoints(true);
        this.updatePointsUniforms("fire");
        this.updatePointsUniforms("smoke");

        // animate birds
        !this.isMobile && this.meshes.birds ? this.animateBirds() : null;

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then = now - (delta % interval);
      }
    },

    reduced20FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 20;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then20FPS;

      if (delta > interval) {
        this.addExplosion();
        this.physics.boostPower.isSpaceKeyPressed
          ? (this.addParticles(), this.isFloorSmokeVisible())
          : null;

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then20FPS = now - (delta % interval);
      }
    },
    reduced10FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 10;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then10FPS;

      if (delta > interval) {
        !this.isMobile ? this.animateWater() : null;

        this.gltfAnimation.launchPad.animation.isLaunchPadAnimationStarted
          ? this.animateLaunchPadConnector()
          : null;

        this.physics.boostPower.isSpaceKeyPressed ||
        this.gsapAnimation.exitScene.isRunning
          ? // apply force here instead of of keyup because when user keyUp other key it stop the booster
            this.applyForce(
              this.physics.boostPower.space[0],
              this.isSpaceKeyPressed(),
              this.physics.boostPower.space[2]
            )
          : null;

        this.heightBasedMethods();

        // if rover is outside the perimeter reset it
        this.checkPositionRover();

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then10FPS = now - (delta % interval);
      }
    },
    animateWater() {
      this.meshes.water.mesh.material.uniforms["time"].value -= 1.0 / 60.0;
    },

    /*------------------------------
    Start HEIGHT BASED METHODS
    ------------------------------*/
    heightBasedMethods() {
      // calculate if rocket flight above a certain point
      this.isRocketHightEnough(); // run audio its a lift off

      // increase power
      this.increasePowerRocketProgressively();

      // is arrow tutorial visible
      this.isArrowTutorialVisible();

      // audio roll program
      this.startAudioRollProgress();

      // exit
      this.exitScene();
    },

    //--- start rocket flight above a certain point ---//
    isRocketHightEnough() {
      // could be a loop or not
      this.isRocketFlightAbove(0);
      this.isRocketFlightAbove(1);
      this.isRocketFlightAbove(2);
      this.isRocketFlightAbove(3);
    },
    isRocketFlightAbove(indexAlt) {
      this.physics.objectsToUpdate[0].body.position.y >=
      this.physics.altitude.flight[indexAlt].alt
        ? this.toggleAltitude(indexAlt)
        : null;
    },
    toggleAltitude(alt) {
      this.physics.altitude.flight[alt].hasFlightAbove = true;
    },
    // snippet
    hasFlightAboveOnce(indexAlt) {
      return this.physics.altitude.flight[indexAlt].hasFlightAbove;
    },
    //--- end rocket flight above a certain point ---//

    //--- start Add rocket crash particules ---//
    addExplosion() {
      this.physics.altitude.isRocketCrashed
        ? (this.addExplosionParticles(),
          this.crashCameraShake(),
          // this.playPauseFX("explosion", "fx", true))
          this.stopAndPlaySoundEffect("explosion", "fx"))
        : null;
    },
    //--- end Add rocket crash particules ---//t

    //--- start increase power ---//
    increasePowerRocketProgressively() {
      this.physics.boostPower.space[1] < 75 &&
      this.physics.boostPower.isUserPressedSpaceOnce
        ? this.increasePowerRocket()
        : null;
    },
    increasePowerRocket() {
      this.physics.boostPower.space[1] = this.physics.boostPower.space[1] + 5;
    },
    //--- end increase power ---//

    //--- start display arrow tutorials ---//
    isArrowTutorialVisible() {
      this.hasFlightAboveOnce(1) && !this.audio.isLiftOffRun
        ? ((this.audio.isLiftOffRun = true),
          this.stopAndPlaySoundEffect("weHaveALiftOff", "fx"))
        : null;
    },
    //--- end display arrow tutorials ---//

    startAudioRollProgress() {
      this.hasFlightAboveOnce(2) && !this.audio.isRollProgramRun
        ? ((this.audio.isRollProgramRun = true),
          this.stopAndPlaySoundEffect("liftOffRollProgram", "fx"))
        : null;
    },

    // Exit Scene
    /*=========================================
    =            EXIT
    =========================================*/

    exitScene() {
      this.isRocketHighEnough() ? this.startExitScene() : null;
    },
    isRocketHighEnough() {
      return (
        this.hasFlightAboveOnce(3) && !this.gsapAnimation.exitScene.isRunning
      );
    },
    startExitScene() {
      // fade out FX
      this.setExitScene();

      // ensure that the methods isn't run twice
      // + fadein background
      this.gsapAnimation.exitScene.isRunning = true;
      // disable alters

      // after timeout pause game for performance purpose
      this.initiate.exitSceneTimeout = setTimeout(() => {
        this.toggleGamePauseGlobally(true);
        this.stopCameraShake();
        this.destroyTimeout(this.initiate.exitSceneTimeout);
      }, 2000);
      // display transition page
      this.initiate.existSceneProgressTimeout = setTimeout(() => {
        this.updateProgressInformation();
        this.destroyTimeout(this.initiate.existSceneProgressTimeout);
      }, 2100);
    },
    toggleGamePauseGlobally(bool) {
      this.$store.commit("sharedGamePlay/TOGGLE_GAME_PAUSED", bool);
    },
    updateProgressInformation() {
      this.$store.commit("sharedTransition/SET_SUCCESS_MISSION", "space");
    },
    /*=========================================
    =            EXIT
    =========================================*/

    /*------------------------------
    End HEIGHT BASED METHODS
    ------------------------------*/

    /*------------------------------
   Start Rotation Control
   ------------------------------*/
    forceRocketToNotRotate() {
      this.disableRotationFrontBack();
      this.disableRotationLeftRight();
    },
    disableRotationFrontBack() {
      // rocket move by itself so make sure the rocket is actually flying
      this.physics.objectsToUpdate[0].body.position.y > 12
        ? ((this.physics.objectsToUpdate[0].body.quaternion.y = 0),
          (this.physics.objectsToUpdate[0].body.quaternion.x = 0))
        : null;
    },
    disableRotationLeftRight() {
      // ensure that the rocket don't rotate too much on the left or on the right
      this.physics.objectsToUpdate[0].body.quaternion.z >= 0.05
        ? ((this.physics.objectsToUpdate[0].body.quaternion.z = 0.05),
          this.applyForce(
            this.physics.boostPower.ArrowRight[0] * 2,
            0,
            this.physics.boostPower.ArrowRight[2] * 2
          ))
        : null;
      this.physics.objectsToUpdate[0].body.quaternion.z <= -0.05
        ? ((this.physics.objectsToUpdate[0].body.quaternion.z = -0.05),
          this.applyForce(
            this.physics.boostPower.ArrowLeft[0] * 2,
            0,
            this.physics.boostPower.ArrowLeft[2] * 2
          ))
        : null;
    },

    /*------------------------------
   End Rotation Control
   ------------------------------*/

    /*------------------------------
   Start Soft Particles Uniforms updates
   ------------------------------*/
    togglePoints(bool) {
      this.particles.fire.points.material.visible = bool;
      this.particles.smoke.points.material.visible = bool;
    },

    updatePointsUniforms(key) {
      this.particles[
        key
      ].points.material.uniforms.uDepthTexture.value = this.softParticles.renderTarget.depthTexture;
      this.particles[
        key
      ].points.material.uniforms.uCameraNear.value = this.camera.near;
      this.particles[
        key
      ].points.material.uniforms.uCameraFar.value = this.camera.far;
      this.particles[
        key
      ].points.material.uniforms.uResolution.value = new THREE.Vector2(
        window.innerWidth * this.softParticles.ratio,
        window.innerHeight * this.softParticles.ratio
      );
    },
    /*------------------------------
   End Soft Particles Uniforms updates
   ------------------------------*/

    //======= END GAME LOOP =======//

    //======= START BASE THREEJS =======//

    setBaseScene() {
      // set container
      this.container = this.$refs.scene__container;

      // create scene
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0x5091fa);

      this.setClock();
    },

    setHelpers() {
      // stats
      this.helpers.stats = new Stats();
      document.body.appendChild(this.helpers.stats.dom);

      // axes helpers
      const axesHelper = new THREE.AxesHelper(5);
      // x y z
      this.scene.add(axesHelper);

      // set gui globaly
      // this.setGUI();
    },
    setGUI() {
      this.helpers.gui = new GUI();
      const debugObject = {};

      debugObject.pushUp = () => {
        this.applyForce(
          this.physics.boostPower.space[0],
          this.physics.boostPower.space[1],
          this.physics.boostPower.space[2]
        );
      };
      debugObject.pushRight = () => {
        this.applyForce(
          this.physics.boostPower.ArrowRight[0],
          this.physics.boostPower.ArrowRight[1],
          this.physics.boostPower.ArrowRight[2]
        );
      };
      debugObject.pushLeft = () => {
        this.applyForce(
          this.physics.boostPower.ArrowLeft[0],
          this.physics.boostPower.ArrowLeft[1],
          this.physics.boostPower.ArrowLeft[2]
        );
      };
      debugObject.pushDown = () => {
        this.applyForce(
          this.physics.boostPower.ArrowRight[0],
          this.physics.boostPower.ArrowRight[1],
          this.physics.boostPower.ArrowRight[2]
        );
      };
      debugObject.pushTop = () => {
        this.applyForce(
          this.physics.boostPower.ArrowRight[0],
          this.physics.boostPower.ArrowRight[1],
          this.physics.boostPower.ArrowRight[2]
        );
      };

      debugObject.reset = () => {
        this.resetVehicule();
      };

      this.helpers.gui.add(debugObject, "pushUp");
      this.helpers.gui.add(debugObject, "pushLeft");
      this.helpers.gui.add(debugObject, "pushRight");
      this.helpers.gui.add(debugObject, "pushDown");
      this.helpers.gui.add(debugObject, "pushTop");
      this.helpers.gui.add(debugObject, "reset");

      this.helpers.gui.add(
        this.effectComposer.unrealBloomPass.unrealBloomPass,
        "enabled"
      );
      this.helpers.gui
        .add(this.effectComposer.unrealBloomPass.unrealBloomPass, "strength")
        .min(0)
        .max(2)
        .step(0.001);
      this.helpers.gui
        .add(this.effectComposer.unrealBloomPass.unrealBloomPass, "radius")
        .min(0)
        .max(2)
        .step(0.001);
      this.helpers.gui
        .add(this.effectComposer.unrealBloomPass.unrealBloomPass, "threshold")
        .min(0)
        .max(1)
        .step(0.001);

      // const parameters = {
      //   elevation: 2,
      //   azimuth: 180,
      // };

      // const folderSky = this.helpers.gui.addFolder("Sky");
      // folderSky
      //   .add(this.skyBox.parameters, "elevation", 0, 90, 0.1)
      //   .onChange(this.updateSun("test"));
      // folderSky
      //   .add(this.skyBox.parameters, "azimuth", -180, 180, 0.1)
      //   .onChange(this.updateSun("test"));
      // folderSky.open();

      // this.helpers.gui.add(debugObject, "focus");
      // this.helpers.gui.add(debugObject, "focus");
      // https://threejs.org/examples/#webgl_postprocessing_dof

      // this.helpers.gui
      //   .add(this.effectComposer.bokeh.uniforms.focus, "value")
      //   .min(0.0)
      //   .max(100.0)
      //   .step(0.001)
      //   .name("focus");

      // this.helpers.gui
      //   .add(this.effectComposer.bokeh.uniforms.aperture, "value")
      //   .min(0.0)
      //   .max(0.01)
      //   .step(0.00000001)
      //   .name("aperture");

      // this.helpers.gui
      //   .add(this.effectComposer.bokeh.uniforms.maxblur, "value")
      //   .min(-0.00000001)
      //   .max(-0.0000001)
      //   .step(0.00000001)
      //   .name("maxblur");
    },
    setClock() {
      this.clock.clock = new THREE.Clock();
    },

    //======= END BASE THREEJS =======//

    //======= START LIGHTS =======//

    setLight() {
      this.lights.ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
      this.scene.add(this.lights.ambientLight);
      // const directionalLight = new THREE.DirectionalLight(0xffffff, 0.2);
      // directionalLight.castShadow = true;
      // directionalLight.shadow.mapSize.x = 256 * 2;
      // directionalLight.shadow.mapSize.y = 256 * 2;
      // // directionalLight.shadow.camera.far = 15;
      // directionalLight.shadow.camera.left = -50;
      // directionalLight.shadow.camera.top = 50;
      // directionalLight.shadow.camera.right = 50;
      // directionalLight.shadow.camera.bottom = -50;
      // directionalLight.position.set(0, 200, 20);
      // this.scene.add(directionalLight);
      // this.isDevEnv() ? this.directioLightHelper() : null;
    },
    directioLightHelper() {
      const directionalLightHelper = new THREE.DirectionalLightHelper(
        this.lights.directionalLight,
        0.1
      );
      this.scene.add(directionalLightHelper);
      const directionalLightCameraHelper = new THREE.CameraHelper(
        this.lights.directionalLight.shadow.camera
      );
      directionalLightCameraHelper.visible = true;
      this.scene.add(directionalLightCameraHelper);
    },

    //======= END LIGHTS =======//

    //======= START CAMERA AND CONTROL =======//

    setCamera() {
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.cameras.windowUser.width / this.cameras.windowUser.height,
        1.5,
        10000
      );
      this.camera.position.set(
        this.cameras.defaultCameraPosition.camX,
        this.cameras.defaultCameraPosition.camY,
        this.cameras.defaultCameraPosition.camZ
      );

      this.scene.add(this.camera);
    },

    setControl() {
      this.controls = new OrbitControls(this.camera, this.container);
      // this.controls.enableDamping = true;
    },

    //======= END CAMERA AND CONTROL =======//

    //======= START RENDERER  =======//

    setRenderer() {
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.container,
        powerPreference: "high-performance",
        antialias: !this.isSafari,
      });
      this.setRenderSize();

      this.renderer.outputEncoding = THREE.sRGBEncoding;

      // shadow
      // this.renderer.shadowMap.enabled = true;
      // this.renderer.shadowMapSoft = true;
      // https://stackoverflow.com/questions/15140832/what-are-the-possible-shadowmaptypes
      // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    },

    /*------------------------------
    Start Post Processing
    ------------------------------*/

    setPostProcessing() {
      this.setEffectComposerRenderTarget();

      this.effectComposer.composer = new EffectComposer(
        this.renderer,
        this.effectComposer.renderTarget
      );
      this.setSizePostProcessing();

      this.effectComposer.composer.addPass(
        new RenderPass(this.scene, this.camera)
      );

      this.setBloom();
      this.setGlitchPass();
      this.setFilmPass();
    },

    setSizePostProcessing() {
      this.effectComposer.composer.setPixelRatio(
        Math.min(window.devicePixelRatio, 2)
      );
      this.effectComposer.composer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );
    },

    // set Glitch Pass
    setFilmPass() {
      this.effectComposer.RGBShiftPass = new ShaderPass(RGBShiftShader);
      this.effectComposer.composer.addPass(this.effectComposer.RGBShiftPass);
      this.effectComposer.RGBShiftPass.enabled = false;

      this.effectComposer.filmPass.filmPass = new FilmPass(
        0.2,
        0.6,
        256,
        false
      );

      this.effectComposer.filmPass.filmPass.renderToScreen = true;
      this.effectComposer.composer.addPass(
        this.effectComposer.filmPass.filmPass
      );

      this.effectComposer.filmPass.filmPass.enabled = false;
    },
    setGlitchPass() {
      this.effectComposer.glitchPass.glitchPass = new GlitchPass(64);
      this.effectComposer.glitchPass.glitchPass.renderToScreen = true;
      this.effectComposer.composer.addPass(
        this.effectComposer.glitchPass.glitchPass
      );
      this.effectComposer.glitchPass.glitchPass.enabled = false;
    },

    setBloom() {
      this.effectComposer.unrealBloomPass.unrealBloomPass = new UnrealBloomPass();
      this.effectComposer.composer.addPass(
        this.effectComposer.unrealBloomPass.unrealBloomPass
      );
      this.effectComposer.unrealBloomPass.unrealBloomPass.strength = this.effectComposer.unrealBloomPass.parameters.strength;
      this.effectComposer.unrealBloomPass.unrealBloomPass.radius = this.effectComposer.unrealBloomPass.parameters.radius;
      this.effectComposer.unrealBloomPass.unrealBloomPass.threshold = this.effectComposer.unrealBloomPass.parameters.threshold;
    },

    /*------------------------------
    End Post Processing
    ------------------------------*/

    //======= END RENDERER  =======//

    ////////////////////////////////
    //       END INIT SCENE AND RENDER
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD PHYSICS
    ////////////////////////////////
    addPhysics() {
      this.setPhysicsWorld();
      this.setPhysicFLoor();
    },

    applyForce(x, y, z) {
      this.physics.objectsToUpdate[0].body.applyForce(new CANNON.Vec3(x, y, z));
    },

    //======= START SET PHYSICS WORLD =======//

    setPhysicsWorld() {
      // world
      this.physics.world = new CANNON.World();
      this.physics.world.broadphase = new CANNON.SAPBroadphase(
        this.physics.world
      );
      this.physics.world.allowSleep = true;
      this.physics.world.gravity.set(0, -9.82, 0); // earth

      // material
      this.physics.materials.defaultMaterial = new CANNON.Material("ground");

      const defaultContactMaterial = new CANNON.ContactMaterial(
        this.physics.materials.defaultMaterial,
        this.physics.materials.defaultMaterial,
        {
          friction: 0.0,
          restitution: 0.0,
        }
      );

      this.physics.world.addContactMaterial(defaultContactMaterial);
      this.physics.world.defaultContactMaterial = defaultContactMaterial;

      this.physics.cannonDebugRenderer = new CannonDebugRenderer(
        this.scene,
        this.physics.world
      );
    },

    //======= END SET PHYSICS WORLD =======//

    //======= START PHYSIC FLOOR =======//

    setPhysicFLoor() {
      this.setDemoPhysicFLoor();
    },

    setDemoPhysicFLoor() {
      const floorShape = new CANNON.Plane();
      const floorBody = new CANNON.Body();
      floorBody.material = this.physics.materials.defaultMaterial;
      floorBody.mass = 0;
      floorBody.addShape(floorShape);
      floorBody.quaternion.setFromAxisAngle(
        new CANNON.Vec3(-1, 0, 0),
        Math.PI * 0.5
      );
      this.physics.world.addBody(floorBody);

      const towerPhysics = new CANNON.Box(new CANNON.Vec3(2, 2, 12.5));
      floorBody.addShape(
        towerPhysics,
        new CANNON.Vec3(4.5, 0, 12.5),
        new CANNON.Quaternion()
      );
    },

    // To get the pixels, draw the image onto a canvas. From the canvas get the Pixel (R,G,B,A)

    //======= END PHYSIC FLOOR =======//

    //======= START PHYSICS GENERATOR =======//

    physicGenerator(position, width, height, depth, mesh) {
      // Cannon.js body
      const shape = new CANNON.Box(
        new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5)
      );

      const body = new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(position),
        shape: shape,
        material: this.physics.materials.defaultMaterial,
      });
      body.position.copy(position);

      this.physics.world.addBody(body);

      // // Save in objects
      this.physics.objectsToUpdate.push({
        mesh: mesh,
        body: body,
      });
      body.addEventListener("collide", this.rocketCrash);
      this.updateLoaderProgressPhysics(1);

      this.initAnimatedCamera();
    },
    updateLoaderProgressPhysics(progress) {
      this.loaderManager.totalProgressPhysics = progress;
    },

    //======= END PHYSICS GENERATOR =======//

    rocketCrash() {
      // I can also add an explosion later

      // is rocket already crashed once
      !this.physics.altitude.isRocketCrashed && this.hasFlightAboveOnce(0)
        ? this.runRocketCrash()
        : null;
    },

    runRocketCrash() {
      // toggle the rocket crash to avoid the value to repeat
      this.toggleRocketCrash(true);

      // add explosion audio

      // run explosion

      // display end of game modal after a small delay to display explosion
      this.alerts.timeoutRocketCrash = setTimeout(() => {
        this.toggleRocketCrashModal(true);
        this.destroyTimeout(this.alerts.timeoutRocketCrash);
      }, 500);
    },

    toggleRocketCrash(bool) {
      this.physics.altitude.isRocketCrashed = bool;
    },
    toggleRocketCrashModal(bool) {
      this.alerts.isRocketCrashModalVisible = bool;
    },

    ////////////////////////////////
    //       END ADD PHYSICS
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MESHES TO SCENE
    ////////////////////////////////

    addMeshesToScene() {
      this.addWater();
      this.addClouds();
      this.isMobile ? this.addMobileTerrain() : null;
    },
    ////////////////////////////////
    //       END ADD MESHES TO SCENE
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MESHES
    ////////////////////////////////

    addWater() {
      // Water

      const waterGeometry = new THREE.PlaneGeometry(20000, 20000);

      const waterNormals = this.loadResponsiveTexture(
        "launchpad/water/waternormals__128.jpg",
        "launchpad/water/waternormals.jpg"
      );
      waterNormals.wrapS = THREE.RepeatWrapping;
      waterNormals.wrapT = THREE.RepeatWrapping;

      this.meshes.water.mesh = new Water(waterGeometry, {
        textureWidth: 512,
        textureHeight: 512,
        waterNormals: waterNormals,
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0x001e0f,
        distortionScale: 3.7,
        fog: this.scene.fog !== undefined,
      });

      this.meshes.water.mesh.rotation.x = -Math.PI / 2;

      this.meshes.water.mesh.position.set(0, -30, -1000); // should be -10, but safari leads to issues. I would like to not add another ternary for something that most people won't notice.

      this.scene.add(this.meshes.water.mesh);
    },

    addClouds() {
      for (let i = 0; i < this.meshes.clouds.meshes.length; i++) {
        this.generateCloud(this.meshes.clouds.meshes[i]);
      }
    },
    generateCloud(cloud) {
      const cloudTexture = this.loadResponsiveTexture(
        cloud.texture.small.texture,
        cloud.texture.large.texture
      );
      const cloudMaterial = new THREE.SpriteMaterial({
        map: cloudTexture,
        transparent: true,
        color: cloud.color,
        opacity: cloud.opacity,
      });

      // const cloudMesh = new THREE.Mesh(cloudGeometry, cloudMaterial);
      const cloudMesh = new THREE.Sprite(cloudMaterial);

      this.scene.add(cloudMesh);
      cloudMesh.scale.set(cloud.scale, cloud.scale, cloud.scale);
      cloudMesh.position.set(
        cloud.position[0],
        cloud.position[1],
        cloud.position[2]
      );

      cloud.clones.forEach((clonedCloud) => {
        this.addClonedClouds(clonedCloud, cloudMesh);
      });
    },
    addClonedClouds(clonedCloudInfo, cloudToClone) {
      const clonedCloud = cloudToClone.clone();

      this.scene.add(clonedCloud);
      clonedCloud.scale.set(
        clonedCloudInfo.scale,
        clonedCloudInfo.scale,
        clonedCloudInfo.scale
      );

      clonedCloud.position.set(
        clonedCloudInfo.position[0],
        clonedCloudInfo.position[1],
        clonedCloudInfo.position[2]
      );

      clonedCloud.color = clonedCloudInfo.color;
      this.updateProgressClones();
    },

    addMobileTerrain() {
      const textureGrass = this.requiredLoad(
        "launchpad/grass/baked__grass_texture__512.jpg"
      );

      textureGrass.wrapS = THREE.MirroredRepeatWrapping;
      textureGrass.wrapT = THREE.MirroredRepeatWrapping;
      textureGrass.repeat.set(40, 40);

      const floor = new THREE.Mesh(
        new THREE.PlaneGeometry(800, 800),
        new THREE.MeshPhongMaterial({
          color: "#777E6A",
          map: textureGrass,
        })
      );
      floor.castShadow = false;
      floor.receiveShadow = false;
      floor.rotation.x = -Math.PI * 0.5;
      floor.position.set(200, -4.5, 300);
      this.scene.add(floor);

      const clonedFloor = floor.clone();

      // clonedFloor.position.set(400, -4.5, -100);
      // this.scene.add(clonedFloor);
    },

    ////////////////////////////////
    //       END ADD MESHES
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD SKY BOX
    ////////////////////////////////
    addSkybox() {
      this.skyBox.sun = new THREE.Vector3();
      // Skybox

      this.skyBox.sky = new Sky();
      this.skyBox.sky.scale.setScalar(10000);
      this.scene.add(this.skyBox.sky);

      const skyUniforms = this.skyBox.sky.material.uniforms;

      skyUniforms["turbidity"].value = 10;
      skyUniforms["rayleigh"].value = 2;
      skyUniforms["mieCoefficient"].value = 0.005;
      skyUniforms["mieDirectionalG"].value = 0.8;

      this.updateSun();
    },
    updateSun() {
      const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
      const phi = THREE.MathUtils.degToRad(
        90 - this.skyBox.parameters.elevation
      );
      const theta = THREE.MathUtils.degToRad(this.skyBox.parameters.azimuth);

      this.skyBox.sun.setFromSphericalCoords(1, phi, theta);

      this.skyBox.sky.material.uniforms["sunPosition"].value.copy(
        this.skyBox.sun
      );
      this.meshes.water.mesh.material.uniforms["sunDirection"].value
        .copy(this.skyBox.sun)
        .normalize();

      this.scene.environment = pmremGenerator.fromScene(
        this.skyBox.sky
      ).texture;
    },
    ////////////////////////////////
    //       END ADD SKY BOX
    ////////////////////////////////

    ////////////////////////////////
    //       START MOVE CAMERA
    ////////////////////////////////
    followVehicule() {
      this.camera.position.set(
        this.physics.objectsToUpdate[0].body.position.x - (4 + 10),
        this.physics.objectsToUpdate[0].body.position.y + 5,
        this.physics.objectsToUpdate[0].body.position.z + (15 + 15)
      );

      this.camera.lookAt(
        new THREE.Vector3(
          this.physics.objectsToUpdate[0].body.position.x,
          this.physics.objectsToUpdate[0].body.position.y,
          this.physics.objectsToUpdate[0].body.position.z
        )
      );
    },
    cameraPositionY(positionCamera) {
      return positionCamera <= 50 ? positionCamera : 50;
    },

    //======= START SHAKE CAMERA =======//

    setCameraShake() {
      this.initCameraShakeLoop(
        "explosionTimeLine",
        8,
        this.gsapAnimation.cameraShake.duration
      );
      this.initCameraShakeLoop(
        "fastTimeLine",
        1,
        this.gsapAnimation.cameraShake.duration
      );
      this.initCameraShakeLoop(
        "slowTimeLine",
        0.25,
        this.gsapAnimation.cameraShake.durationSoft
      );
    },

    initCameraShakeLoop(name, pointMultiplier, duration) {
      this.gsapAnimation.cameraShake[name].timeLine = gsap.timeline({
        repeat: -1,
        yoyo: true,
        repeatDelay: 0,
        ease: "elastic",
      });

      for (let i = 0; i < 3; i++) {
        this.gsapAnimation.cameraShake[name].timeLine.to(this.scene.position, {
          x: 0.2 * pointMultiplier,
          y: -0.3 * pointMultiplier,
          z: -0.2 * pointMultiplier,
          duration: duration,
        });
        this.gsapAnimation.cameraShake[name].timeLine.to(this.scene.position, {
          x: -0.2 * pointMultiplier,
          y: 0.3 * pointMultiplier,
          z: 0.2 * pointMultiplier,
          duration: duration,
        });
      }

      this.gsapAnimation.cameraShake[name].timeLine.pause();
    },

    /*------------------------------
    Start Run Animation
    ------------------------------*/

    startCameraShake() {
      !this.isMobile ? this.inFlightCameraShake() : null;
    },
    inFlightCameraShake() {
      this.physics.objectsToUpdate[0].body.position.y <= 80
        ? this.runACameraShake("fastTimeLine", [
            "slowTimeLine",
            "explosionTimeLine",
          ])
        : this.runACameraShake("slowTimeLine", [
            "fastTimeLine",
            "explosionTimeLine",
          ]);
    },

    crashCameraShake() {
      !this.isMobile
        ? this.runACameraShake("explosionTimeLine", [
            "slowTimeLine",
            "fastTimeLine",
          ])
        : null;
    },

    runACameraShake(cameraToRun, camerasToHide) {
      this.pauseMultipleCameraTimeline(camerasToHide);

      this.gsapAnimation.cameraShake[cameraToRun].isRunning
        ? null
        : this.runTimeLine(cameraToRun);
    },
    pauseMultipleCameraTimeline(camerasToHide) {
      camerasToHide.forEach((camera) => {
        this.gsapAnimation.cameraShake[camera].isActive
          ? this.pauseTimeLine(camera)
          : null;
      });
    },

    pauseTimeLine(timeLineName) {
      this.gsapAnimation.cameraShake[timeLineName].timeLine.pause();
      this.gsapAnimation.cameraShake[timeLineName].isActive = false;
      this.gsapAnimation.cameraShake[timeLineName].isRunning = false;
    },
    runTimeLine(timeLineName) {
      this.gsapAnimation.cameraShake[timeLineName].isRunning = true;
      this.gsapAnimation.cameraShake[timeLineName].isActive = true;
      this.gsapAnimation.cameraShake[timeLineName].timeLine.play();
    },
    /*------------------------------
    End Run Animation
    ------------------------------*/

    stopCameraShake() {
      this.gsapAnimation.cameraShake.explosionTimeLine.isActive ||
      this.gsapAnimation.cameraShake.fastTimeLine.isActive ||
      this.gsapAnimation.cameraShake.slowTimeLine.isActive
        ? this.stopAllCameraShake()
        : null;
    },
    stopAllCameraShake() {
      this.pauseTimeLine("explosionTimeLine");
      this.pauseTimeLine("fastTimeLine");
      this.pauseTimeLine("slowTimeLine");

      gsap.to(this.scene.position, {
        x: 0,
        y: 0,
        z: 0,
        duration: 0.1,
        ease: "power2.inOut",
      });
    },

    //======= END SHAKE CAMERA =======//

    ////////////////////////////////
    //       END MOVE CAMERA
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MODELS
    ////////////////////////////////
    loadModels() {
      this.setBaseLoader();
      this.textureLoader();
      this.loopGLTFLoader();
    },
    setBaseLoader() {
      // draco always before GLTF Loader
      this.setDraco();
      this.setLoader();
    },

    setDraco() {
      this.gltf.dracoLoader = new DRACOLoader();
      //  path to a folder containing WASM/JS decoding libraries.
      this.gltf.dracoLoader.setDecoderPath("/vendors/draco/"); // path needs be public
      this.gltf.dracoLoader.preload();
    },
    setLoader() {
      // set loader
      this.gltf.GLTFLoader = new GLTFLoader();
      this.gltf.GLTFLoader.setDRACOLoader(this.gltf.dracoLoader);
    },

    ////////////////////////////////
    //       START LOAD TEXTURE
    ////////////////////////////////

    textureLoader() {
      // Texture loader
      this.loader.textureLoader = new THREE.TextureLoader();
    },

    loadResponsiveTexture(mobileTexture, desktopTexture) {
      return this.isMobile
        ? this.requiredLoad(mobileTexture)
        : this.requiredLoad(desktopTexture);
    },
    requiredLoad(path) {
      return this.loader.textureLoader.load(this.requireFile(path));
    },
    requireFile(path) {
      return require(`@/assets/textures/${path}`);
    },
    ////////////////////////////////
    //       END LOAD TEXTURE
    ////////////////////////////////

    loopGLTFLoader() {
      // loop each model to load
      this.gltf.GLFTList.forEach((model, index) => {
        model.key !== "birds"
          ? this.gltfLoader(model, index)
          : this.gltfLoaderNoMaterial(model, index);
      });
    },

    gltfLoaderNoMaterial(model, index) {
      if (this.isMobile) return; // don't run the bird on mobile
      this.gltf.GLTFLoader.load(
        `/three-assets/launchpad/${model.path}.glb`,
        (gltf) => {
          this.meshes.birds = gltf;
          gltf.scene.traverse((child) => {
            if (child.isMesh) {
              child.castShadow = false;
              child.receiveShadow = false;
            }
          });

          this.scene.add(gltf.scene);

          gltf.scene.scale.set(0.1, 0.1, 0.1);
          gltf.scene.position.set(
            model.position[0],
            model.position[1],
            model.position[2]
          );
          gltf.scene.rotation.y = 1;

          this.setABirdsAnimation(gltf);
        },
        (xhr) => {
          // console.log("xhr", xhr);

          // progress
          // this.setProgress(glfName, xhr.loaded, xhr.total);
          this.GLTFProgressLoader(index, xhr.loaded / xhr.total);

          if ((xhr.loaded / xhr.total) * 100 === 100) {
            // console.log("gltf", gltf);
            // console.log("this.meshes.birds", this.birds);
            // console.log("xhr.loaded", xhr.loaded, xhr.total, this.birds);
          }
        },
        undefined
      );
    },

    //======= START BIRD ANIMATION =======//

    setABirdsAnimation(gltf) {
      this.gltfAnimation.birds.animationMixer = new THREE.AnimationMixer(
        gltf.scene
      );
      gltf.animations.forEach((clip) => {
        this.gltfAnimation.birds.animationMixer.clipAction(clip).play();
      });
    },
    animateBirds() {
      var fakeDelta = 0.1;
      this.gltfAnimation.birds.animationMixer
        ? this.gltfAnimation.birds.animationMixer.update((fakeDelta += 0.1))
        : null;

      this.meshes.birds.scene.rotation.y += 0.005;
    },

    //======= END BIRD ANIMATION =======//

    gltfLoader(model, index) {
      if (this.isMobile && !model.isMobile) return;

      const childMaterial = this.setMaterial(model.material);

      this.gltf.GLTFLoader.load(
        `/three-assets/launchpad/${model.path}.glb`,
        (gltf) => {
          gltf.scene.traverse((child) => {
            child.material = childMaterial;
            if (child.isMesh) {
              child.castShadow = false;
              child.receiveShadow = false;

              model.key === "tower"
                ? (child.material.side = THREE.DoubleSide)
                : null;
            }
          });

          this.scene.add(gltf.scene);

          // Apply materials

          gltf.scene.scale.set(0.1, 0.1, 0.1);
          gltf.scene.position.set(
            model.position[0],
            model.position[1],
            model.position[2]
          );

          // only the rocket needs CannonJS
          model.key === "saturnV"
            ? this.physicGenerator(
                { x: 0, y: 23.8 / 2, z: 0 },
                1.5,
                23.8,
                1.5,
                gltf.scene
              ) // position, with, heith, depth
            : null;

          // clone surrounding terrain to save
          (!this.isMobile && model.key === "surroundingTerrain") ||
          model.key === "surroundingTerrainCorner"
            ? this.loopSurroundingTerrainToClone(gltf, model.key)
            : null;

          // only some models have an animation
          model.key === "tower" ? this.setLaunchPadAnimation(gltf) : null;
        },
        (xhr) => {
          // console.log("xhr", xhr);

          // progress
          this.GLTFProgressLoader(index, xhr.loaded / xhr.total);
          // this.setProgress(glfName, xhr.loaded, xhr.total);
          if ((xhr.loaded / xhr.total) * 100 === 100) {
            // console.log("gltf", gltf);
          }
        },
        (e) => {
          console.log("model.key", model.key);
          console.log("error", e);
        }
      );
    },
    setMaterial(material) {
      const bakedTexture = this.loadResponsiveTexture(
        this.isItemHasResponsiveTexture(material, "texture"),
        material.large.texture
      );

      bakedTexture.flipY = false;
      bakedTexture.encoding = THREE.sRGBEncoding;

      const bakedMaterial = new THREE.MeshStandardMaterial();

      bakedMaterial.map = bakedTexture;

      // can be refacotred or not"
      if (material.normal) {
        const bakedNormal = this.loadResponsiveTexture(
          this.isItemHasResponsiveTexture(material, "normal"),
          material.large.normal
        );
        bakedNormal.flipY = false;
        bakedNormal.encoding = THREE.sRGBEncoding;
        bakedMaterial.normalMap = bakedNormal;
        bakedMaterial.normalScale = new THREE.Vector2(3, 3);
        return bakedMaterial;
      } else {
        return bakedMaterial;
      }
    },
    isItemHasResponsiveTexture(material, textureOrNormal) {
      return material.small
        ? material.small[textureOrNormal]
        : material.large[textureOrNormal];
    },

    GLTFProgressLoader(index, progress) {
      // update this gltf progress
      this.gltf.GLFTList[index].progress = progress;
    },

    //======= START CLONE SURROUNDING GLTF TERRAIN =======//

    loopSurroundingTerrainToClone(gltf, keyTofind) {
      const indexSurroundingTerrain = this.findIndexSurroundingTerrain(
        keyTofind
      );

      this.gltf.GLFTList[indexSurroundingTerrain].clones.forEach(
        (clone, index) => {
          this.cloneSurroundingTerrain(gltf, clone);
        }
      );
    },

    findIndexSurroundingTerrain(keyTofind) {
      return this.gltf.GLFTList.findIndex(({ key }) => key === keyTofind);
    },

    cloneSurroundingTerrain(gltf, clone) {
      const clonedTerrain = gltf.scene.clone();
      this.scene.add(clonedTerrain);
      clonedTerrain.position.set(
        clone.position[0],
        clone.position[1],
        clone.position[2]
      );
      clonedTerrain.rotation.y = clone.rotate;
      clonedTerrain.scale.set(clone.size[0], clone.size[0], clone.size[0]);

      // this.GLTFProgressLoaderClone(indexSurroundingTerrain, index); // I think I will make it easier
      this.updateProgressClones();
    },

    updateProgressClones() {
      // update this gltf progress
      // this.gltf.GLFTList[indexSurroundingTerrain].clones[index].progress = 1;
      this.loaderManager.totalProgressClones =
        this.loaderManager.totalProgressClones + 1;
    },

    //======= END CLONE SURROUNDING GLTF TERRAIN =======//

    //======= START LAUNCHPAD ANIMATION =======//

    setLaunchPadAnimation(gltf) {
      this.gltfAnimation.launchPad.animation.launchPadAnimationArray = new THREE.AnimationMixer(
        gltf.scene
      );

      this.gltfAnimation.launchPad.animation.launchPadAnimation = this.gltfAnimation.launchPad.animation.launchPadAnimationArray.clipAction(
        gltf.animations[0]
      );

      this.gltfAnimation.launchPad.animation.launchPadAnimation.setLoop(
        THREE.LoopOnce
      );
      this.gltfAnimation.launchPad.animation.launchPadAnimation.clampWhenFinished = true;
      this.gltfAnimation.launchPad.animation.launchPadAnimation.enable = true;
    },

    runLaunchPadAnimation() {
      !this.gltfAnimation.launchPad.animation.isLaunchPadAnimationStarted
        ? this.startLaunchPadAnimation()
        : null;
    },

    startLaunchPadAnimation() {
      this.gltfAnimation.launchPad.animation.isLaunchPadAnimationStarted = true;
      this.gltfAnimation.launchPad.animation.launchPadAnimation.play().reset();
    },

    animateLaunchPadConnector() {
      var fakeDelta = 0.0;
      this.gltfAnimation.launchPad.animation.launchPadAnimationArray.update(
        (fakeDelta += 0.05)
      );
    },

    //======= END LAUNCHPAD ANIMATION =======//

    ////////////////////////////////
    //       END ADD MODELS
    ////////////////////////////////

    ////////////////////////////////
    //       START PARTICLES
    ////////////////////////////////

    //======= START CREATE PARTICLES =======//

    updateDepthFade(value) {
      this.softParticles.uDepthFade = value;
    },

    setParticleSystem() {
      this.isMobile ? this.updateDepthFade(0.0) : null;
      /*------------------------------
      Start materials
      ------------------------------*/
      const pointMultiplier =
        (window.innerHeight /
          (2.0 * Math.tan((0.5 * 60.0 * Math.PI) / 180.0))) *
        this.renderer.getPixelRatio();
      const uniforms = {
        diffuseTexture: {
          value: this.requiredLoad("fire/fire.png"),
        },
        pointMultiplier: {
          value: pointMultiplier,
        },
        uCameraNear: {
          value: 0,
        },
        uCameraFar: {
          value: 0,
        },
        uDepthFade: {
          value: this.softParticles.uDepthFade,
        },
        uResolution: {
          value: new THREE.Vector2(),
        },
        uDepthTexture: {
          value: null,
        },
        uEnableFade: {
          value: 0.01,
        },
        uSpriteGrid: {
          value: new THREE.Vector2(4, 4),
        },
      };
      const fireMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: fireVertexShader,
        fragmentShader: fireFragmentShader,
        blending: THREE.CustomBlending,
        blendEquation: THREE.AddEquation,
        blendSrc: THREE.OneFactor,
        blendDst: THREE.OneMinusSrcAlphaFactor,
        depthTest: true,
        depthWrite: false,
        transparent: true,
        vertexColors: true,
        side: THREE.DoubleSide,
      });

      const uniformsSmoke = {
        diffuseTexture: {
          value: this.requiredLoad("smoke/smoke.png"),
        },
        pointMultiplier: {
          value: pointMultiplier,
        },
        uCameraNear: {
          value: 0,
        },
        uCameraFar: {
          value: 0,
        },
        uDepthFade: {
          value: this.softParticles.uDepthFade,
        },
        uResolution: {
          value: new THREE.Vector2(),
        },
        uDepthTexture: {
          value: null,
        },
        uEnableFade: {
          value: 1.0,
        },
        uSpriteGrid: {
          value: new THREE.Vector2(4, 4),
        },
      };

      this.particles.smoke.material = new THREE.ShaderMaterial({
        uniforms: uniformsSmoke,
        vertexShader: fireVertexShader,
        fragmentShader: fireFragmentShader,
        blending: THREE.CustomBlending,
        blendEquation: THREE.AddEquation,
        blendSrc: THREE.OneFactor,
        blendDst: THREE.OneMinusSrcAlphaFactor,
        depthTest: true,
        depthWrite: false,
        // transparent: true,
        vertexColors: true,
        side: THREE.DoubleSide,
      });

      this.particles.fire.particles = [];
      this.particles.smoke.particles = [];

      /*------------------------------
      End materials
      ------------------------------*/

      /*------------------------------
      Start particule geometry
      ------------------------------*/

      this.particles.fire.geometry = new THREE.BufferGeometry();

      this.particles.smoke.geometry = new THREE.BufferGeometry();

      const defaultBufferGeometryValuesFire = this.setDefaultStartingParticles(
        this.particles.maxPoints.fire
      );
      const defaultBufferGeometryValuesSmoke = this.setDefaultStartingParticles(
        this.particles.maxPoints.smoke
      );

      this.setParticlesAttributes(
        defaultBufferGeometryValuesFire.position,
        defaultBufferGeometryValuesFire.particlePosition,
        defaultBufferGeometryValuesFire.size,
        defaultBufferGeometryValuesFire.colour,
        defaultBufferGeometryValuesFire.angle,
        defaultBufferGeometryValuesFire.blend,
        "fire",
        defaultBufferGeometryValuesFire.uv,
        defaultBufferGeometryValuesFire.normal,
        false
      );
      this.setParticlesAttributes(
        defaultBufferGeometryValuesSmoke.position,
        defaultBufferGeometryValuesSmoke.particlePosition,
        defaultBufferGeometryValuesSmoke.size,
        defaultBufferGeometryValuesSmoke.colour,
        defaultBufferGeometryValuesSmoke.angle,
        defaultBufferGeometryValuesSmoke.blend,
        "smoke",
        defaultBufferGeometryValuesSmoke.uv,
        defaultBufferGeometryValuesSmoke.normal,
        false
      );
      /*------------------------------
      End particule geometry
      ------------------------------*/

      this.particles.fire.points = new THREE.Mesh(
        this.particles.fire.geometry,
        fireMaterial
      );
      this.particles.smoke.points = new THREE.Mesh(
        this.particles.smoke.geometry,
        this.particles.smoke.material
      );

      this.scene.add(this.particles.fire.points);
      this.scene.add(this.particles.smoke.points);

      this.particles.smoke.points.frustumCulled = false;

      // this.addDemoGeomtry()

      const splinesArray = [
        // START FIRE
        {
          particleName: "fire",
          splineName: "alphaSpline",
          pointsArray: [
            [0.0, 0.0],
            [0.1, 1.0],
            [0.6, 1.0],
            [1.0, 0.0],
          ],
          isColour: false,
        },
        {
          particleName: "fire",
          splineName: "colourSpline",
          pointsArray: [
            [0.0, new THREE.Color(0xffff80)],
            [1.0, new THREE.Color(0xff8080)],
          ],
          isColour: true,
        },
        {
          particleName: "fire",
          splineName: "sizeSpline",
          pointsArray: [
            [0.0, 0.5],
            [0.1, 1.0],
            [0.5, 2.0],
            [1.0, 1.0],
          ],
          isColour: false,
        },
        // END FIRE
        // START SMOKE
        {
          particleName: "fire",
          splineName: "alphaSplineS",
          pointsArray: [
            [0.0, 0.0],
            [0.1, 1.0],
            [0.5, 1.0],
            [1.0, 0.0],
          ],
          isColour: false,
        },
        {
          particleName: "fire",
          splineName: "colourSplineS",
          pointsArray: [
            [0.0, new THREE.Color(0x202020)],
            [1.0, new THREE.Color(0x000000)],
          ],
          isColour: true,
        },
        {
          particleName: "fire",
          splineName: "sizeSplineS",
          pointsArray: [
            [0.0, 1.0],
            [0.5, 2.0],
            [1.0, 1.0],
          ],
          isColour: false,
        },
        // END SMOKE

        // START PARTICULES
        {
          particleName: "fire",
          splineName: "alphaSplineX",
          pointsArray: [
            [0.0, 0.0],
            [0.1, 1.0],
            [0.9, 1.0],
            [1.0, 0.0],
          ],
          isColour: false,
        },
        {
          particleName: "fire",
          splineName: "colourSplineX",
          pointsArray: [
            [0.0, new THREE.Color(0xffff80)],
            [1.0, new THREE.Color(0xff8080)],
          ],
          isColour: true,
        },
        {
          particleName: "fire",
          splineName: "sizeSplineX",
          pointsArray: [
            [0.0, 0.1],
            [1.0, 0.1],
          ],
          isColour: false,
        },
        // END PARTICULES

        {
          particleName: "smoke",
          splineName: "alphaSplineS",
          pointsArray: [
            [0.0, 1.0],
            [0.1, 1.0],
            [0.5, 1.0],
            [1.0, 0.0],
          ],
          isColour: false,
        },
        {
          particleName: "smoke",
          splineName: "colourSplineS",
          pointsArray: [
            // [0.0, new THREE.Color(0xfaebd7)],
            // [1.0, new THREE.Color(0xffffff)],
            [0.0, new THREE.Color(0x000000)],
            [1.0, new THREE.Color(0xfaebd7)],
          ],
          isColour: true,
        },
        {
          particleName: "smoke",
          splineName: "sizeSplineS",
          pointsArray: [
            [0.0, 5.0],
            [0.5, 8.0],
            [1.0, 9.0],
          ],
          isColour: false,
        },
      ];

      splinesArray.forEach((element) => {
        this.addParticulePointForSpline(
          element.particleName,
          element.splineName,
          element.pointsArray,
          element.isColour
        );
      });

      this.updateGeometry("fire");
      this.updateGeometry("smoke");
    },

    // addDemoGeomtry() {
    //   this.geometry = new THREE.BufferGeometry();
    //   // create a simple square shape. We duplicate the top left and bottom right
    //   // vertices because each vertex needs to appear once per triangle.
    //   const vertices = new Float32Array([
    //     -1.0,
    //     -1.0,
    //     1.0,
    //     1.0,
    //     -1.0,
    //     1.0,
    //     1.0,
    //     1.0,
    //     1.0,

    //     1.0,
    //     1.0,
    //     1.0,
    //     -1.0,
    //     1.0,
    //     1.0,
    //     -1.0,
    //     -1.0,
    //     1.0,
    //   ]);

    //   // itemSize = 3 because there are 3 values (components) per vertex
    //   this.geometry.setAttribute(
    //     "position",
    //     new THREE.BufferAttribute(vertices, 3)
    //   );
    //   const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    //   this.mesh = new THREE.Mesh(this.geometry, material);

    //   this.scene.add(this.mesh);
    // },
    setDefaultStartingParticles(maxPoints) {
      return {
        position: new Float32Array(maxPoints * 6),
        particlePosition: new Float32Array(maxPoints * 3 * 6),
        size: new Float32Array(maxPoints * 6),
        colour: new Float32Array(maxPoints * 4 * 6),
        angle: new Float32Array(maxPoints * 6),
        blend: new Float32Array(maxPoints * 6),
        uv: new Float32Array(maxPoints * 6 * 6),
        normal: new Float32Array(maxPoints * 6),
      };
    },

    /*------------------------------
    Start Add points
    ------------------------------*/
    addParticulePointForSpline(
      particuleName,
      splineName,
      arrayOfPoints,
      isColour
    ) {
      isColour
        ? (this.particles[particuleName][splineName] = new LinearSpline(
            (t, a, b) => {
              const c = a.clone();
              return c.lerp(b, t);
            }
          ))
        : (this.particles[particuleName][splineName] = new LinearSpline(
            (t, a, b) => {
              return a + t * (b - a);
            }
          ));

      arrayOfPoints.forEach((points) => {
        this.particles[particuleName][splineName].AddPoint(
          points[0],
          points[1]
        );
      });
    },

    /*------------------------------
    End Add points
    ------------------------------*/

    /*------------------------------
    Start create or udpate particules attributes
    ------------------------------*/
    // https://pretagteam.com/question/threejs-buffergeometry-vs-geometry-for-particles
    // https://stackoverflow.com/questions/19504337/three-js-buffergeometry-with-texture-coordinates
    // https://github.com/YaleDHLab/pix-plot/commit/c7c48dd61eea8637b4fb7c41a49b0b2297130e39
    // https://www.khronos.org/registry/webgl/sdk/demos/google/particles/
    //    https://webglfundamentals.org/webgl/lessons/webgl-qna-working-around-gl_pointsize-limitations-webgl.html

    setParticlesAttributes(
      worldPosition,
      particlePosition,
      sizes,
      colours,
      angles,
      blends,
      particuleName,
      uvs,
      normals,
      isNeedUpdate
    ) {
      const arrayAttributes = [
        { key: "position", vecLength: 3, att: worldPosition },
        { key: "particlePosition", vecLength: 3, att: particlePosition },
        {
          key: "size",
          vecLength: 1,
          att: sizes,
        },
        { key: "colour", vecLength: 4, att: colours },
        { key: "angle", vecLength: 1, att: angles },
        { key: "blend", vecLength: 1, att: blends },
        { key: "uv", vecLength: 3, att: uvs },
        { key: "normal", vecLength: 3, att: normals },
      ];

      arrayAttributes.forEach((element) => {
        isNeedUpdate
          ? (this.particles[particuleName].geometry.setAttribute(
              `${element.key}`,
              new THREE.Float32BufferAttribute(element.att, element.vecLength)
            ),
            (this.particles[particuleName].geometry.attributes[
              element.key
            ].needsUpdate = true),
            this.particles[particuleName].geometry.computeBoundingSphere())
          : this.particles[particuleName].geometry.setAttribute(
              `${element.key}`,
              new THREE.BufferAttribute(element.att, element.vecLength)
            );
      });
    },

    /*------------------------------
    End create or udpate particules attributes
    ------------------------------*/

    //======= END SET PARTICLES ATTRIBUTES =======//

    //======= START ADD PARTICLES =======//

    addParticles() {
      if (this.particles.fire.particles.length > this.particles.maxPoints.fire)
        return;

      const n = 2;
      const adjustedX = 0;
      const adjustedZ = 0;
      ////  fire
      for (let i = 0; i < n; i++) {
        this.particles.fire.particles.push(
          this.sharedParticuleGenerator(
            [
              (Math.random() * 1.0 - 0) * 0.5 - adjustedX,
              (Math.random() * 1.0 - 0) * 0.5 - 12,
              (Math.random() * 1.0 - 0) * 0.5 - adjustedZ,
            ],
            (Math.random() * 0.5 + 0.5) * 6.0,
            (Math.random() * 0.75 + 0.25) * 3.0,
            Math.random() * 2.0 * Math.PI,
            [0, -5, 0],
            0.25,
            "",
            1.0
          )
        );
      }
      ////smoke;
      for (let i = 0; i < n * 1; i++) {
        this.particles.fire.particles.push(
          this.sharedParticuleGenerator(
            [
              (Math.random() * 1.0 - 0) * 0.5 - adjustedX,
              (Math.random() * 1.0 - 0) * 0.5 - (14.5 + 2),
              (Math.random() * 1.0 - 0) * 0.5 - adjustedZ,
            ],
            (Math.random() * 0.5 + 0.5) * 4.0,
            (Math.random() * 0.75 + 0.25) * 3.0,
            Math.random() * 2.0 * Math.PI,
            [0, -5, 0],
            1.0,
            "S",
            1.0
          )
        );
      }
      for (let i = 0; i < n * 1; i++) {
        this.particles.fire.particles.push(
          this.sharedParticuleGenerator(
            [
              (Math.random() * 2 - 1) * 3.0,
              (Math.random() * 2 - 1) * 4.0 - (14 + 5),
              (Math.random() * 2 - 1) * 3.0,
            ],
            (Math.random() * 0.5 + 0.5) * 0.5,
            (Math.random() * 0.75 + 0.25) * 2.0,
            Math.random() * 2.0 * Math.PI,
            [
              (Math.random() * 2.0 - 1.0) * 2.0,
              -5,
              (Math.random() * 2.0 - 1.0) * 2.0,
            ],
            1.0,
            "X",
            0.25
          )
        );
      }
    },

    addExplosionParticles() {
      if (this.particles.fire.particles.length > this.particles.maxPoints.fire)
        return;

      const n = 10;

      ////  fire
      for (let i = 0; i < n; i++) {
        this.particles.fire.particles.push(
          this.sharedParticuleGenerator(
            [
              this.physics.objectsToUpdate[0].body.position.x - 1,
              (Math.random() * 2.0 - 0) * 0.5 - 5,
              this.physics.objectsToUpdate[0].body.position.z + 3,
            ],
            (Math.random() * 0.5 + 0.5) * (10.0 * 0.8),
            (Math.random() * 0.75 + 0.25) * (3.0 * 1),
            Math.random() * 2.0 * Math.PI,
            [
              (Math.random() * 2.0 - 1.0) * 2.5,
              (Math.random() * 2.0 - 1.0) * 3.5,
              (Math.random() * 2.0 - 1.0) * 2.5,
            ],
            0.25,
            "",
            1.0
          )
        );
      }
      ////smoke;
      for (let i = 0; i < n * 1; i++) {
        this.particles.fire.particles.push(
          this.sharedParticuleGenerator(
            [
              this.physics.objectsToUpdate[0].body.position.x - 1,
              (Math.random() * 2.0 - 0) * 0.5 - 5,
              this.physics.objectsToUpdate[0].body.position.z + 2,
            ],
            (Math.random() * 0.5 + 0.5) * (12.0 * 0.8),
            (Math.random() * 0.75 + 0.25) * (3.0 * 1),
            Math.random() * 2.0 * Math.PI,
            [
              (Math.random() * 2.0 - 1.0) * 2.5,
              (Math.random() * 2.0 - 1.0) * 3.5,
              (Math.random() * 2.0 - 1.0) * 2.5,
            ],
            1.0,
            "S",
            1.0
          )
        );
      }
    },

    addParticlesSmokeLaunch() {
      if (
        this.particles.smoke.particles.length > this.particles.maxPoints.smoke
      )
        return;

      var n = 4;
      for (let i = 0; i < n; i++) {
        this.particles.smoke.particles.push(
          this.sharedParticuleGenerator(
            [
              this.physics.objectsToUpdate[0].body.position.x - 1,

              4,
              this.physics.objectsToUpdate[0].body.position.z + 3,
            ],
            (Math.random() * 0.5 + 0.5) * 2,
            (Math.random() * 0.75 + 0.25) * 4.0,
            Math.random() * 2.0 * Math.PI,
            [
              (Math.random() * 2.0 - 1.0) * 3.5,
              0,
              (Math.random() * 2.0 - 1.0) * 3.5,
            ],
            1.0,
            "S",
            1.0
          )
        );
      }
    },

    /*------------------------------
    Start particule generator
    ------------------------------*/
    // generate dynamicly the partcile to add
    sharedParticuleGenerator(
      position,
      size,
      life,
      rotation,
      velocity,
      blend,
      splineName,
      originalSize
    ) {
      // https://stackoverflow.com/questions/34215682/wrong-location-of-texture-on-the-second-triangle-of-buffergeometry-square
      return {
        // position: new THREE.Vector3(position[0], position[1], position[2]),
        position: new THREE.Vector3(position[0], position[1], position[2]),
        worldPosition: new Float32Array([
          -originalSize,
          -originalSize,
          0.0,

          originalSize,
          -originalSize,
          0.0,

          originalSize,
          originalSize,
          0.0,

          originalSize,
          originalSize,
          0.0,

          -originalSize,
          originalSize,
          0.0,

          -originalSize,
          -originalSize,
          0.0,
        ]),
        size: size,
        colour: new THREE.Color(),
        alpha: 1.0,
        life: life,
        maxLife: life,
        rotation: rotation,
        velocity: new THREE.Vector3(velocity[0], velocity[1], velocity[2]),
        blend: blend,
        splineName: splineName,
        uvs: new Float32Array(),
      };
    },
    /*------------------------------
    End particule generator
    ------------------------------*/

    //======= END ADD PARTICLES =======//

    updateGeometry(particleName) {
      const worldPosition = [];
      const particlesPosition = [];
      const sizes = [];
      const colours = [];
      const angles = [];
      const blends = [];
      const uvs = [];
      const normals = [];

      for (let p of this.particles[particleName].particles) {
        worldPosition.push(
          p.worldPosition[0],
          p.worldPosition[1],
          p.worldPosition[2],

          p.worldPosition[3],
          p.worldPosition[4],
          p.worldPosition[5],

          p.worldPosition[6],
          p.worldPosition[7],
          p.worldPosition[8],

          p.worldPosition[9],
          p.worldPosition[10],
          p.worldPosition[11],

          p.worldPosition[12],
          p.worldPosition[13],
          p.worldPosition[14],

          p.worldPosition[15],
          p.worldPosition[16],
          p.worldPosition[17]
        );

        for (let i = 0; i < 6; i++) {
          particlesPosition.push(p.position.x, p.position.y, p.position.z);
          blends.push(p.blend);
          colours.push(p.colour.r, p.colour.g, p.colour.b, p.alpha);
          sizes.push(p.currentSize);
          angles.push(p.rotation);

          uvs.push(
            -1.0,
            -1.0,
            0.0,

            1.0,
            -1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            -1.0,
            1.0,
            0.0,

            -1.0,
            -1.0,
            0.0
          );

          normals.push(
            -1.0,
            -1.0,
            0.0,

            1.0,
            -1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            -1.0,
            1.0,
            0.0,

            -1.0,
            -1.0,
            0.0
          );
        }
      }
      this.setParticlesAttributes(
        worldPosition,
        particlesPosition,
        sizes,
        colours,
        angles,
        blends,
        particleName,
        uvs,
        normals,
        true
      );
    },

    //======= START UPDATE PARTICULES EACH FRAMES =======//

    particleSteps(timeElapsed) {
      this.updateParticuleManager("fire", timeElapsed);
      this.updateParticuleManager("smoke", timeElapsed);
    },
    updateParticuleManager(name, timeElapsed) {
      this.updateParticles(timeElapsed, name);
      this.updateGeometry(name);
    },
    updateParticles(timeElapsed, particleName) {
      for (let p of this.particles[particleName].particles) {
        p.life -= timeElapsed;
      }

      this.particles[particleName].particles = this.particles[
        particleName
      ].particles.filter((p) => {
        return p.life > 0.0;
      });

      for (let p of this.particles[particleName].particles) {
        const t = 1.0 - p.life / p.maxLife;
        p.rotation += timeElapsed * 0.5;

        p.position.add(p.velocity.clone().multiplyScalar(timeElapsed));

        this.splineSwitcher(p, t, particleName, p.splineName);

        const drag = p.velocity.clone();
        drag.multiplyScalar(timeElapsed * 0.1);
        drag.x =
          Math.sign(p.velocity.x) *
          Math.min(Math.abs(drag.x), Math.abs(p.velocity.x));

        drag.y =
          Math.sign(p.velocity.y) *
          Math.min(Math.abs(drag.y), Math.abs(p.velocity.y));
        drag.z =
          Math.sign(p.velocity.z) *
          Math.min(Math.abs(drag.z), Math.abs(p.velocity.z));
        p.velocity.sub(drag);
      }

      this.particles[particleName].particles.sort((a, b) => {
        const d1 = this.camera.position.distanceTo(a.position);
        const d2 = this.camera.position.distanceTo(b.position);
        if (d1 > d2) {
          return -1;
        }
        if (d1 < d2) {
          return 1;
        }
        return 0;
      });
    },

    /*------------------------------
    Start START UPDATE SPLINE
    ------------------------------*/
    splineSwitcher(p, t, particleName, alphaSpline) {
      return (
        (p.alpha = this.particles[particleName][
          `alphaSpline${alphaSpline}`
        ].Get(t)),
        (p.currentSize =
          p.size *
          this.particles[particleName][`sizeSpline${alphaSpline}`].Get(t)),
        p.colour.copy(
          this.particles[particleName][`colourSpline${alphaSpline}`].Get(t)
        )
      );
    },
    /*------------------------------
    End START UPDATE SPLINE
    ------------------------------*/

    //======= END UPDATE PARTICULES EACH FRAMES =======//

    ////////////////////////////////
    //       END PARTICLES
    ////////////////////////////////

    ////////////////////////////////
    //       START MOVE FIRE WITH THE ROCKET
    ////////////////////////////////
    updatePositionFirePoint() {
      this.particles.fire.points.position.copy(
        this.physics.objectsToUpdate[0].body.position
      );

      this.particles.fire.points.quaternion.copy(
        this.physics.objectsToUpdate[0].body.quaternion
      );
    },
    ////////////////////////////////
    //       END MOVE FIRE WITH THE ROCKET
    ////////////////////////////////

    ////////////////////////////////
    //       START MOVE ROCKET
    ////////////////////////////////
    keyPressed(keyPressed) {
      // rocket still want to go up if user press space and hit pause at the same time
      if (this.isGamePaused || this.isGameControlDisabled) return;

      keyPressed.code === "Space" ? this.userPressSpaceKey() : null;
    },

    userPressSpaceKey() {
      this.runLaunchPadAnimation();
      this.toggleBoostUp(true);
      this.playPauseFX("rocket", "engine", true);
      this.toggleIsUserPressedSpaceOnce();
      this.destroyBoosterWarningInterval(); // booster warning when user don't hold space bar
      this.startCameraShake();
    },

    toggleBoostUp(bool) {
      return (this.physics.boostPower.isSpaceKeyPressed = bool);
    },

    isSpaceKeyPressed() {
      // allowing user to go up while pressing other key
      return this.physics.boostPower.isSpaceKeyPressed
        ? this.physics.boostPower.space[1]
        : 0;
    },
    toggleIsUserPressedSpaceOnce() {
      this.physics.boostPower.isUserPressedSpaceOnce
        ? null
        : this.runMethodsOnFirstKeyPressed();
    },
    runMethodsOnFirstKeyPressed() {
      this.physics.boostPower.isUserPressedSpaceOnce = true;
      this.hideTutorials();

      // run audio count down
      this.stopAndPlaySoundEffect("liftOffCountDown", "fx");
    },

    //======= START MANAGE LAUNCH PAD SMOKE DISPLAY =======//

    isFloorSmokeVisible() {
      // if the rocket is above a certain altitute point and that didn't already passed this altitute (like rocket goes up and down)
      this.particles.fire.points.position.y <= 60
        ? this.addParticlesSmokeLaunch()
        : null;
    },

    //======= END MANAGE LAUNCH PAD SMOKE DISPLAY =======//

    //======= START USER HIT SPACE + OTHER KEY AT THE SAME TIME =======//

    keyUnPressed(keyUnPressed) {
      if (this.isGameControlDisabled || this.gsapAnimation.exitScene.isRunning)
        return;

      keyUnPressed.code === "Space"
        ? (this.userStoppedBooster(false), this.stopCameraShake())
        : null;
    },
    //======= END USER HIT SPACE + OTHER KEY AT THE SAME TIME =======//

    ////////////////////////////////
    //       END MOVE ROCKET
    ////////////////////////////////

    ////////////////////////////////
    //       START USER STOP HITTING SPACE
    ////////////////////////////////
    userStoppedBooster() {
      // toggle the booster down
      this.toggleBoostUp(false);
      //stop audio
      this.playPauseFX("rocket", "engine", false);

      // start a counter
      !this.isGamePaused ? this.startCountDownBooster() : null; // only run the countdown booster warning if user is not in pause
    },
    startCountDownBooster() {
      // reset timer as fall back
      this.alerts.countDown.secondsLeft = 5;
      // update globaly
      this.updateCountDownGlobally(this.alerts.countDown.secondsLeft);
      // start interval
      this.initCountDownBooster();
    },
    initCountDownBooster() {
      this.alerts.countDown.countDownInterval = setInterval(() => {
        this.isGamePaused ? null : this.countBoosterGroupedMethods();
      }, 1000);
    },

    countBoosterGroupedMethods() {
      this.isCountDownEnded()
        ? this.boosterTimeoutRunOutOfTime()
        : this.removeOneSecond(),
        this.updateCountDownGlobally(this.alerts.countDown.secondsLeft);
    },
    isCountDownEnded() {
      return this.alerts.countDown.secondsLeft <= 0;
    },
    removeOneSecond() {
      this.alerts.countDown.secondsLeft = this.alerts.countDown.secondsLeft - 1;
    },

    boosterTimeoutRunOutOfTime() {
      // stop game
      this.toggleGamePauseGlobally(true);
      // destroy interval
      this.destroyBoosterWarningInterval();
    },
    destroyBoosterWarningInterval() {
      this.alerts.countDown.secondsLeft = 5;
      this.alerts.countDown.countDownInterval
        ? (this.destroyInterval(this.alerts.countDown.countDownInterval),
          this.updateCountDownGlobally(5))
        : null;
    },
    updateCountDownGlobally(val) {
      this.$store.commit("dashboardAlerts/UPDATE_COUNTDOWN", val);
    },

    ////////////////////////////////
    //       END USER STOP HITTING SPACE
    ////////////////////////////////

    ////////////////////////////////
    //       START RESET POSITION ROVER
    ////////////////////////////////

    checkPositionRover() {
      // set const that I need info later
      // const isRocketOutside = this.isRocketOutsideDrivingArea();
      const isCountdownRunOut = this.isCountDownEnded();

      // isRocketOutside ||
      isCountdownRunOut || this.alerts.isRocketCrashModalVisible
        ? (this.startResetGlitch(),
          this.displayModalWithDelay(
            false,
            isCountdownRunOut,
            this.physics.altitude.isRocketCrashed
          ),
          this.stopCameraShake())
        : null;
      this.isGamePaused || !this.isCountDownEnded() ? null : this.startGlitch();
    },

    // alertManagement() {
    //   this.isRocketOutsideMainArea() ? this.startGlitch() : this.removeGlitch();
    //   this.removeGlitch();
    // },

    // isRocketOutsideDrivingArea() {
    //   return (
    //     this.physics.objectsToUpdate[0].mesh.position.x <=
    //       this.perimeters.reset.x.min ||
    //     this.physics.objectsToUpdate[0].mesh.position.x >=
    //       this.perimeters.reset.x.max ||
    //     this.physics.objectsToUpdate[0].mesh.position.z <=
    //       this.perimeters.reset.z.min ||
    //     this.physics.objectsToUpdate[0].mesh.position.z >=
    //       this.perimeters.reset.z.max
    //   );
    // },
    // isRocketOutsideMainArea() {
    //   return (
    //     this.physics.objectsToUpdate[0].mesh.position.x <=
    //       this.perimeters.alert.x.min ||
    //     this.physics.objectsToUpdate[0].mesh.position.x >=
    //       this.perimeters.alert.x.max ||
    //     this.physics.objectsToUpdate[0].mesh.position.z <=
    //       this.perimeters.alert.z.min ||
    //     this.physics.objectsToUpdate[0].mesh.position.z >=
    //       this.perimeters.alert.z.max
    //   );
    // },

    ////////////////////////////////
    //       END RESET POSITION ROVER
    ////////////////////////////////

    ////////////////////////////////
    //       START DISPLAY TUTORIALS
    ////////////////////////////////
    displaySpaceBarTutorial() {
      // mock the end of the animation
      this.toggleDashboardBottom("arrow", true);
      this.toggleDashboardBottom("reset", true);
      // this.tutorials.timeOutTutorials = setTimeout(() => {
      this.toggleDashboardBottom("alti", true);
      const spaceBarTuto = {
        isCombinedTutorial: false,
        copy: "space",
      };
      this.displayTutorial(spaceBarTuto);
      // }, 2000);
    },

    resetTutorial() {
      this.clearDisplayAndHideTimeOut();
      this.resetTimeOutTutorial();
      this.hideTutorials();
    },
    resetTimeOutTutorial() {
      this.tutorials.timeOutTutorials
        ? (clearInterval(this.tutorials.timeOutTutorials),
          (this.tutorials.timeOutTutorials = null))
        : null;
    },

    ////////////////////////////////
    //       END DISPLAY TUTORIALS
    ////////////////////////////////

    ////////////////////////////////
    //       START RESET
    ////////////////////////////////

    //======= START DISPLAY RESTART/PAUSE MODAL =======//
    displayModalWithDelay(isRocketOustide, isCountdownRunOut, hasCrashed) {
      if (!this.alerts.timeoutModal) {
        this.alerts.timeoutModal = setTimeout(() => {
          this.displayDashboardModal(
            isRocketOustide,
            isCountdownRunOut,
            hasCrashed
          );
          this.destroyTimeout(this.alerts.timeoutModal);
        }, 1000);
      }
    },

    displayDashboardModal(isRocketOustide, isCountdownRunOut, hasCrashed) {
      // make sure to detroy the timout for the booster since the user wont hit space
      this.destroyBoosterWarningInterval();
      if (isRocketOustide) {
        this.displayDashboardModalGlobally("outside");
      } else if (isCountdownRunOut) {
        this.displayDashboardModalGlobally("boost");
      } else if (hasCrashed) {
        this.displayDashboardModalGlobally("crash");
      }
    },
    displayDashboardModalGlobally(val) {
      this.$store.commit("dashboardModal/OPEN_MODAL", val);
    },

    //======= END DISPLAY RESTART/PAUSE MODAL =======//

    resetGame() {
      this.gameIsResetTimeout = setTimeout(() => {
        // reset locally (used only to reset the tutorial);
        this.toggleResetLocally(true);
        // reset adudio
        this.resetAudio();
        // reset tutorial (reset)
        this.resetTutorial();

        this.destroyBoosterWarningInterval();

        // reset hit space once
        // reset is spaceKey Pressed
        this.resetUserSpaceKeyPressed();

        // reset timer hold space
        this.destroyBoosterWarningInterval();

        // reset altitude reached
        this.resetIsAltitudeReached();

        // reset composer
        this.resetEffectComposer();

        // reset dust and smoke
        this.resetDust();
        this.alerts.timeoutModal = null;
        this.resetRocketCrashed();

        // reset vehicule position rotation, euler
        this.resetVehicule();

        // reset tower animation
        this.resetAnimationTowerConnector();

        this.toggleGamePauseGlobally(false);
        this.destroyTimeout(this.gameIsResetTimeout);

        this.emitGtag(`Reset_Lift-Off`, "Reset", "Click");
      }, 500);
    },

    /*------------------------------
    Start Reset tutorial after reset
    ------------------------------*/
    spaceBarTutorialToDisplayAfterReset() {
      this.tutorials.isResetStarted
        ? (this.displaySpaceBarTutorial(), this.toggleResetLocally(true))
        : null;
    },
    toggleResetLocally(bool) {
      this.tutorials.isResetStarted = bool;
    },
    /*------------------------------
    End Reset tutorial after reset
    ------------------------------*/

    resetAudio() {
      // reset audio
      this.audio.isRollProgramRun = false;
      this.audio.isLiftOffRun = false;
    },

    resetUserSpaceKeyPressed() {
      // reset hit space once
      this.physics.boostPower.isUserPressedSpaceOnce = false;
      // reset is spaceKey Pressed
      this.physics.boostPower.isSpaceKeyPressed = false;
    },

    resetIsAltitudeReached() {
      for (let i = 0; i < this.physics.altitude.flight.length; i++) {
        this.physics.altitude.flight[i].hasFlightAbove = false;
      }
    },

    resetEffectComposer() {
      this.effectComposer.glitchPass.isGlitchPassVisible = false;
      this.updateGlitch(false);
      this.effectComposer.filmPass.filmPass.uniforms.grayscale.value = false;
    },
    resetAnimationTowerConnector() {
      this.gltfAnimation.launchPad.animation.isLaunchPadAnimationStarted = false;
      this.gltfAnimation.launchPad.animation.launchPadAnimation.reset().stop();
    },

    resetRocketCrashed() {
      this.toggleRocketCrash(false);
      this.toggleRocketCrashModal(false);
    },

    resetDust() {
      this.particles.fire.particles = [];
      this.particles.smoke.particles = [];
    },

    resetVehicule() {
      // resetPosition
      // https://github.com/schteppe/cannon.js/issues/215

      this.physics.objectsToUpdate[0].body.position.x = 0;
      this.physics.objectsToUpdate[0].body.position.y = 11.900003243251314;
      this.physics.objectsToUpdate[0].body.position.z = 1.6954313756767826e-17;

      // orientation
      this.physics.objectsToUpdate[0].body.quaternion.set(0, 0, 0, 1);
      this.physics.objectsToUpdate[0].body.initQuaternion.set(0, 0, 0, 1);
      this.physics.objectsToUpdate[0].body.previousQuaternion.set(0, 0, 0, 1);
      this.physics.objectsToUpdate[0].body.interpolatedQuaternion.set(
        0,
        0,
        0,
        1
      );

      // Velocity
      this.physics.objectsToUpdate[0].body.velocity.setZero();
      this.physics.objectsToUpdate[0].body.initVelocity.setZero();
      this.physics.objectsToUpdate[0].body.angularVelocity.setZero();
      this.physics.objectsToUpdate[0].body.initAngularVelocity.setZero();

      // Force
      this.physics.objectsToUpdate[0].body.force.setZero();
      this.physics.objectsToUpdate[0].body.torque.setZero();

      // Sleep state reset
      this.physics.objectsToUpdate[0].body.sleepState = 0;
      this.physics.objectsToUpdate[0].body.timeLastSleepy = 0;
      this.physics.objectsToUpdate[0].body._wakeUpAfterNarrowphase = false;
    },
    ////////////////////////////////
    //       END RESET
    ////////////////////////////////

    ////////////////////////////////
    //       START POST PROCESSING GLITCHPASS
    ////////////////////////////////

    startResetGlitch() {
      if (this.isGamePaused) return;

      this.updateGlitch(true);
      this.effectComposer.glitchResetTimeOut = setTimeout(() => {
        this.resetGlitchEnd();
        this.alerts.timeoutModal ? this.toggleGamePauseGlobally(true) : null;
      }, 700);
    },

    resetGlitchEnd() {
      this.destroyTimeout(this.effectComposer.glitchResetTimeOut);
      this.effectComposer.glitchPass.glitchPass.goWild = false;
      this.effectComposer.glitchPass.glitchPass.enabled = false;
      this.effectComposer.filmPass.filmPass.uniforms.grayscale.value = true;
    },

    // when the user is outside autorized area
    startGlitch() {
      if (this.isGamePaused) return;

      this.effectComposer.glitchPass.isGlitchPassVisible
        ? (this.effectComposer.glitchPass.glitchPass.goWild = false)
        : this.updateGlitch(true);
    },
    updateGlitch(bool) {
      this.effectComposer.glitchPass.glitchPass.enabled = bool;
      // this.effectComposer.glitchPass.isGlitchPassVisible = bool; // lead to issue on ereset: https://aurelienvigne.atlassian.net/browse/ROC-1251. It's instead on resetEffectComposer() since user can only trigger it when the mission ending
      this.effectComposer.glitchPass.glitchPass.goWild = bool;
      this.effectComposer.filmPass.filmPass.enabled = bool;
      this.effectComposer.RGBShiftPass.enabled = bool;
    },
    // removeGlitch() {
    //   // console.log("removeGlitch");
    //   if (!this.effectComposer.glitchPass.isGlitchPassVisible) return;
    //   this.effectComposer.glitchPass.isGlitchPassVisible = false;
    //   this.effectComposer.glitchPass.glitchPass.goWild = true;
    //   this.effectComposer.glitchResetTimeOut = setTimeout(() => {
    //     clearTimeout(this.effectComposer.glitchResetTimeOut);
    //     this.updateGlitch(false);
    //   }, 100);
    // },

    ////////////////////////////////
    //       END POST PROCESSING GLITCHPASS
    ////////////////////////////////

    ////////////////////////////////
    //       START PLAY T MINUS RADIO
    ////////////////////////////////

    runRadioBeforeLaunch(trackToPlay) {
      this.stopAndPlaySoundEffect(trackToPlay, "fx");
    },
    ////////////////////////////////
    //       END PLAY T MINUS RADIO
    ////////////////////////////////

    ////////////////////////////////
    //       START CAMERA INTRO
    ////////////////////////////////
    initAnimatedCamera() {
      this.isIntroAnimationVisible && !this.isGamePlayDebugging
        ? this.runIntroCameraAnimation()
        : null;
    },
    runIntroCameraAnimation() {
      // this.$store.commit("dashboardTutorial/RESET_TUTORIAL");
      // this.resetTutorial();
      // if the game is reseting, so directly display the follow rocket
      // start position
      // 1.3552905618733906 24.757845631432293 3.001261824836499

      const duration1 = 11;
      const duration2 = 5;
      // const duration2 = 1;
      // const duration1 = 1;
      this.camera.position.set(
        this.physics.objectsToUpdate[0].body.position.x + 1.5,
        24.757845631432293 + 25,
        this.physics.objectsToUpdate[0].body.position.z - 1
      );

      //

      this.gsapAnimation.intro.timeline = gsap.timeline({
        paused: true,
        onComplete: () => {
          // show the labels
          // at the end of the animation, set to follow
          // run mocked methods until the prgress page is ready
          this.displaySpaceBarTutorial();
          this.$store.commit("sharedGamePlay/TOGGLE_INTRO_ANIMATION", false);
        },
        onUpdate: () => {
          this.camera.lookAt(
            // new THREE.Vector3(-0.22856174321408051, this.camera.position.y, -1)
            new THREE.Vector3(
              this.physics.objectsToUpdate[0].body.position.x,
              this.physics.objectsToUpdate[0].body.position.y,
              this.physics.objectsToUpdate[0].body.position.z
            )
          );
        },
      });
      this.gsapAnimation.intro.timeline
        .to(this.camera.position, {
          // duration: 15,
          duration: duration1,
          x: this.physics.objectsToUpdate[0].body.position.x - 0.2,
          y: -1,
          z: this.physics.objectsToUpdate[0].body.position.z + 8,
          ease: "power1.out",
        })
        .to(
          this.camera.position,
          {
            // duration: 5,
            duration: duration2,
            x: this.physics.objectsToUpdate[0].body.position.x - (4 + 10),
            y: this.physics.objectsToUpdate[0].body.position.y + 5,
            z: this.physics.objectsToUpdate[0].body.position.z + (15 + 15),
            ease: "power1.inOut",
          },
          "-=0.5"
        )
        .add(() => {
          this.runRadioBeforeLaunch("liftOffTMinus10s"); // mock
        }, "-=4");

      // in case the loader finished before
      this.triggerPlayIntroTimeline();
    },
    triggerPlayIntroTimeline() {
      this.isPageFullyLoaded() &&
      this.isTransitionLongEnough &&
      this.gsapAnimation.intro.timeline &&
      !this.gsapAnimation.intro.isStarted
        ? this.playIntroTimeline()
        : null;
    },
    playIntroTimeline() {
      this.gsapAnimation.intro.isStarted = true;

      this.toggleIntroTimeline(!this.isGamePaused);
    },

    toggleIntroTimeline(bool) {
      // this is needed if user toggle play pause during the animation
      if (this.gsapAnimation.intro.timeline && !this.isGamePlayDebugging) {
        bool
          ? this.gsapAnimation.intro.timeline.play()
          : this.gsapAnimation.intro.timeline.pause();
      }
    },

    ////////////////////////////////
    //       END CAMERA INTRO
    ////////////////////////////////

    ////////////////////////////////
    //       START PROGRESS LOADER
    ////////////////////////////////
    runProgressLoadingPage() {
      this.loaderManager.intervalProgress = setInterval(() => {
        this.calculateSceneProgressLoader();
        this.setCombinedProgress();
        this.$store.commit(
          "sharedTransition/UPDATE_PROGRESS",
          this.loaderManager.totalProgressCombined
        );
        this.isPageFullyLoaded() ? this.gameIsReady() : null;
      }, 50);
    },
    isPageFullyLoaded() {
      return this.loaderManager.totalProgressCombined >= 1;
    },
    setCombinedProgress() {
      this.loaderManager.totalProgressCombined = this.isMobile
        ? this.calculateProgressGLTF()
        : this.calculateProgressDesktop();
    },
    calculateProgressGLTF() {
      return (
        (this.loaderManager.totalProgress / this.lengthGLTFToLoad() +
          this.loaderManager.totalProgressPhysics / 1) /
        2
      );
    },
    calculateProgressDesktop() {
      return (
        (this.calculateProgressGLTF() + this.calculateProgressClones()) / 2
      );
    },
    calculateProgressClones() {
      return this.loaderManager.totalProgressClones / 21;
    },

    lengthGLTFToLoad() {
      return this.isMobile
        ? this.gltf.GLFTList.length - 4
        : this.gltf.GLFTList.length;
    },

    gameIsReady() {
      this.resetLoaderManagerInterval();
      this.triggerPlayIntroTimeline();
      // this.$nextTick(() => {
      //   this.destroyUselessStates();
      // });
    },

    resetLoaderManagerInterval() {
      this.loaderManager.intervalProgress
        ? (clearInterval(this.loaderManager.intervalProgress),
          (this.loaderManager.intervalProgress = null))
        : null;
    },

    calculateSceneProgressLoader() {
      this.gltf.GLFTList.forEach((model) => {
        model.progress !== undefined && !model.isLoaded
          ? this.updateTotalProgress(model.progress)
          : null;

        model.progress === 1 ? (model.isLoaded = true) : null;
      });
    },
    updateTotalProgress(progressToAdd) {
      this.loaderManager.totalProgress =
        this.loaderManager.totalProgress + progressToAdd;
    },

    ////////////////////////////////
    //       END PROGRESS LOADER
    ////////////////////////////////

    ////////////////////////////////
    //       START DESTROY TIMELINES
    ////////////////////////////////

    destroyTimelines() {
      // this.timelineKiller("introTimeline");
      this.timelineCameraKiller("explosionTimeLine");
      this.timelineCameraKiller("fastTimeLine");
      this.timelineCameraKiller("slowTimeLine");
      this.timelineKiller("intro");
    },

    timelineCameraKiller(name) {
      this.gsapAnimation.cameraShake[name].timeLine
        ? (this.gsapAnimation.cameraShake[name].timeLine.kill(),
          (this.gsapAnimation.cameraShake[name].timeLine = null))
        : null;
    },
    timelineKiller(name) {
      this.gsapAnimation[name].timeLine
        ? (this.gsapAnimation[name].timeLine.kill(),
          (this.gsapAnimation[name].timeLine = null))
        : null;
    },
    // destroyUselessStates() {
    //   if (this.isGamePlayDebugging) return;
    //   this.gltf.GLFTList = [];
    //   this.gltf.GLTFLoader = null;
    //   this.isMobile ? this.meshes.clouds : null;
    // },

    destroyTimeout(interval) {
      interval ? (clearTimeout(interval), (interval = null)) : null;
    },
    destroyInterval(interval) {
      interval ? (clearInterval(interval), (interval = null)) : null;
    },

    ////////////////////////////////
    //       END DESTROY TIMELINES
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/config/vars.scss";
@import "@/assets/scss/config/mixins.scss";
@import "@/assets/scss/config/responsive.scss";
.scene {
  --opacityBleed: 0;
  &:before {
    @include exitSceneBleed($gallery, var(--opacityBleed));
  }
  &--exit {
    --opacityBleed: 1;
  }

  &__start-aniimation {
    position: fixed;
    top: 0px;
    left: 50px;
    z-index: 9999;
  }
  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 0;
    .scene--debug & {
      z-index: 999;
      cursor: pointer;
    }
  }
}
</style>
