// initial state
const state = () => ({
  // reset game
  isGameToReset: false,
  // pause game
  isGamePaused: false,
  // is game resetting (used for the camera animation)
  isIntroAnimationVisible: true,

  // temporary disable the use of keys etc...
  isGameControlDisabled: true,
  // this is mane
  isGamePlayDebug: false,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  TOGGLE_GAME_RESET(state, bool) {
    state.isGameToReset = bool;
  },
  TOGGLE_GAME_PAUSED(state, bool) {
    state.isGamePaused = bool;
  },
  TOGGLE_INTRO_ANIMATION(state, bool) {
    state.isIntroAnimationVisible = bool;
    state.isGameControlDisabled = bool;
  },
  TOGGLE_DISABLED_GAME_CONTROL(state, bool) {
    state.isGameControlDisabled = bool;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
