<template>
  <div
    class="shared-audio-manager"
    :class="{ 'shared-audio-manager--debug': isDevEnv() }"
  >
    <!-- engine -->
    <shared-audio
      which-audio="engine"
      :is-playing="isEnginePlaying"
      :current-track="currentEngineTrack"
      :up-coming-track="upComingEngineTrack"
      :default-track="currentEngineTrack"
      :volume-interval="intervalFXFade"
      :is-loop="true"
      :is-stopped="isEngineStopped"
    />
    <!-- fx -->
    <shared-audio
      which-audio="fx"
      :is-playing="isFXPlaying"
      :current-track="currentFXTrack"
      :up-coming-track="upComingFXTrack"
      :default-track="null"
      :volume-interval="intervalFXFade"
      :is-loop="false"
      :is-stopped="isFXStopped"
    />
    <!-- music -->
    <shared-audio
      :is-playing="isPlaying"
      :current-track="currentTrack"
      :up-coming-track="upComingMusicTrack"
      :default-track="null"
      :volume-interval="100"
      :is-loop="true"
      :is-stopped="false"
      :max-volume="0.5"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SharedAudio from "@/components/Shared/SharedAudio/SharedAudio";
import isDevMixin from "@/mixins/isDevMixin";
export default {
  mixins: [isDevMixin],

  components: {
    SharedAudio,
  },
  computed: {
    ...mapState({
      //   isMute: (state) => state.sharedAudio.isMute,

      isPlaying: (state) => state.sharedAudio.isPlaying,

      defaultTrack: (state) => state.sharedAudio.defaultMusicTrack,

      currentTrack: (state) => state.sharedAudio.currentTrack,
      upComingMusicTrack: (state) => state.sharedAudio.upComingMusicTrack,

      currentEngineTrack: (state) => state.sharedAudio.fx.engine.currentTrack,
      upComingEngineTrack: (state) => state.sharedAudio.fx.engine.upComingTrack,
      isEnginePlaying: (state) => state.sharedAudio.fx.engine.isPlaying,
      isEngineStopped: (state) => state.sharedAudio.fx.engine.isStopped,

      currentFXTrack: (state) => state.sharedAudio.fx.fx.currentTrack,
      upComingFXTrack: (state) => state.sharedAudio.fx.fx.upComingTrack,
      isFXPlaying: (state) => state.sharedAudio.fx.fx.isPlaying,
      isFXStopped: (state) => state.sharedAudio.fx.fx.isStopped,

      isModalVisible: (state) => state.dashboardModal.isModalVisible,
      isSceneExiting: (state) => state.sharedTransition.isSceneExiting,
    }),

    intervalFXFade() {
      return this.isModalVisible || this.isSceneExiting
        ? 100
        : this.isExplorationDelay();
    },
  },
  methods: {
    isExplorationDelay() {
      return this.$route.name === "Exploration" ? 30 : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-audio-manager {
  position: fixed;
  left: 0;
  bottom: 0;

  z-index: -999;
  opacity: 0;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  &--debug {
    z-index: 999;
    opacity: 1;
    pointer-events: inherit;
  }
}
</style>
