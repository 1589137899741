<template>
  <div
    :class="{
      'shared-button-animation--white': isWhite,
      'shared-button-animation--black': isBlack,
      'shared-button-animation--ready': isAnimationStarted,
    }"
    class="shared-button-animation"
  >
    <shared-button-svg-rectangle />
  </div>
</template>

<script>
import SharedButtonSvgRectangle from "@/components/Shared/SharedButton/SharedButtonSvg/SharedButtonSvgRectangle";
export default {
  components: {
    SharedButtonSvgRectangle,
  },
  props: {
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.shared-button-animation {
  @include buttonAnimation;
  display: flex;
  flex-direction: column;
  position: relative;
}
</style>
