<template>
  <div class="gallery-close">
    <shared-button-circle
      :is-white="false"
      :is-black="false"
      icon-name="close"
      :rotation="0"
      @clicked="close"
    />
  </div>
</template>

<script>
import SharedButtonCircle from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircle";
export default {
  components: {
    SharedButtonCircle,
  },
  methods: {
    close() {
      this.$store.dispatch("gallery/closeGallery");
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-close {
  position: absolute;
  top: $x-small-margin;
  right: $x-small-margin;
  color: white; // temporary until I add the icon
  z-index: 1;
}
</style>
