<template>
  <section class="dashboard-alert">
    <!-- alert goes here -->
    <!-- check if vehicule outside perimeter for each direction -->
    <ul v-if="isLanding">
      <dashboard-alert-item
        v-for="(item, index) in listAlert"
        :key="index"
        :is-active="isLandingAlert(item)"
        :item="item"
        :is-landing="true"
        :scene-name="sceneName"
      />
    </ul>
    <ul v-else>
      <dashboard-alert-item
        v-for="(item, index) in listAlert"
        :key="index"
        :is-active="isAlertActive(item)"
        :item="item"
        :scene-name="sceneName"
      />
    </ul>
  </section>
</template>

<script>
import DashboardAlertItem from "@/components/Dashboard/DashboardAlert/DashboardAlertItem";
export default {
  components: {
    DashboardAlertItem,
  },
  props: {
    vehiculePosition: {
      type: Object,
      required: true,
    },
    perimeters: {
      type: Object,
      required: true,
    },

    isSpaceKeyPressed: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLandedOutside: {
      type: Boolean,
      required: false,
      default: false,
    },
    sceneName: {
      type: String,
      required: true,
      validator: (val) => ["liftOff", "landing", "exploration"].includes(val),
    },
  },
  data() {
    return {
      listAlert: ["top", "right", "bottom", "left", "boost", "high", "outside"],
      landingZone: {
        // left + right keys
        x: {
          min: -200,
          max: 200,
        },
        // top + bottom keys
        z: {
          min: -200,
          max: 200,
        },
      },
    };
  },
  computed: {
    isLanding() {
      return this.sceneName === "landing";
    },
    isLiftOff() {
      return this.sceneName === "liftOff";
    },
  },
  methods: {
    isLandingAlert(alertPosition) {
      // Z axis top to bottom

      if (alertPosition === "top") {
        return this.vehiculePosition.z <= this.perimeters.z.min;
      }
      if (alertPosition === "bottom") {
        return this.vehiculePosition.z >= this.perimeters.z.max;
      }

      // X left to right
      if (alertPosition === "right") {
        return this.vehiculePosition.x >= this.perimeters.x.max;
      }

      if (alertPosition === "left") {
        return this.vehiculePosition.x <= this.perimeters.x.min;
      }

      if (alertPosition === "boost") {
        return this.isLandingBoostVisible();
      }

      if (alertPosition === "high") {
        return this.vehiculePosition.y >= this.perimeters.y.max;
      }
      if (alertPosition === "outside") {
        return this.isLandedOutside && !this.isLandingBoostVisible();
      }
    },

    isLandingBoostVisible() {
      return (
        (this.vehiculePosition.z <= this.landingZone.z.min &&
          this.vehiculePosition.y <= this.perimeters.y.min) ||
        (this.vehiculePosition.z >= this.landingZone.z.max &&
          this.vehiculePosition.y <= this.perimeters.y.min) ||
        (this.vehiculePosition.x >= this.landingZone.x.max &&
          this.vehiculePosition.y <= this.perimeters.y.min) ||
        (this.vehiculePosition.x <= this.landingZone.x.min &&
          this.vehiculePosition.y <= this.perimeters.y.min)
      );
    },

    isAlertActive(alertPosition) {
      // Z axis top to bottom
      if (alertPosition === "top") {
        return this.vehiculePosition.z <= this.perimeters.z.min;
      }
      if (alertPosition === "bottom") {
        return this.vehiculePosition.z >= this.perimeters.z.max;
      }

      // X left to right
      if (alertPosition === "right") {
        return this.vehiculePosition.x >= this.perimeters.x.max;
      }

      if (alertPosition === "left") {
        return this.vehiculePosition.x <= this.perimeters.x.min;
      }
      if (alertPosition === "boost") {
        return this.isLiftOff && !this.isSpaceKeyPressed;
      }
      if (alertPosition === "outside") {
        return this.isLandedOutside;
      }
      if (alertPosition === "high") {
        return false; // will probably use that isAltitudeOk
      }
    },

    isAltitudeOk(current, max) {
      return current > max;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-alert {
  position: absolute;
  display: flex;
  max-width: 57rem;
  top: 10rem;
  @include teq-breakpoint($x-small) {
    top: 0rem;
    position: relative;
  }
}
</style>
