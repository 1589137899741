// initial state
const state = () => ({
  isDev: false,
  isScrollDisabled: false,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  TOGGLE_SCROLL_GLOBALLY(state, bool) {
    state.isScrollDisabled = bool;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
