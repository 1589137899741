<template>
  <section class="scene-space">
    <error-main v-if="errorMain.isCopyVisible" />
    <canvas ref="sceneSpace__container" class="scene-space__container"></canvas>
  </section>
</template>

<script>
import { mapState } from "vuex";
import isDevMixin from "@/mixins/isDevMixin";
import pauseGameMixin from "@/mixins/pauseGameMixin";
import resetGamePlayStoreMixin from "@/mixins/resetGamePlayStoreMixin";
import transitionMixin from "@/mixins/transitionMixin.js";

import { gsap } from "gsap";

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

import LinearSpline from "@/vendors/LinearSpline.js";

// STATS
import Stats from "stats.js";
// import { GUI } from "@/vendors/dat.gui.module.js";

// VERTEX
import fragment from "@/shaders/earth/fragment.glsl";
import vertex from "@/shaders/earth/vertex.glsl";
import fireVertexShader from "@/shaders/fire/vertex.glsl";
import fireFragmentShader from "@/shaders/fire/fragment.glsl";

// texture
// EARTH
import earth__10K from "@/assets/textures/gallery/error/4k/lroc_color_poles.jpg";
import earthNight__10K from "@/assets/textures/gallery/error/4k/lroc_color_poles_night.jpg";

import earth__4K from "@/assets/textures/gallery/error/2k/lroc_color_poles.jpg";
import earthNight__4K from "@/assets/textures/gallery/error/2k/lroc_color_poles_night.jpg";

// LM
import bake from "@/assets/textures/service-module/baked__service-module__2.jpg";

import bakeLM from "@/assets/textures/service-module/LM/baked-LM.jpg";

import smokeTexture from "@/assets/textures/smoke/smoke.png";

import ErrorMain from "@/components/Error/ErrorMain.vue";

export default {
  mixins: [
    isDevMixin,
    pauseGameMixin,
    resetGamePlayStoreMixin,
    transitionMixin,
  ],
  components: {
    ErrorMain,
  },
  props: {
    galleryProgress: {
      type: Number,
      required: false,
      default: 1,
    },

    isGalleryAnimationRunning: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapState({
      isNavOpen: (state) => state.navigation.isNavOpen,
      isGamePaused: (state) => state.sharedGamePlay.isGamePaused,
      isGamePlayDebug: (state) => state.sharedGamePlay.isGamePlayDebug,
      isTransitionLongEnough: (state) =>
        state.sharedTransition.isTransitionLongEnough,

      isMobile: (state) => state.userDevice.isMobile,
      isSceneReady: (state) => state.sharedTransition.isSceneReady,
      isUserDeviceReady: (state) => state.userDevice.isUserDeviceReady,
      userBrowser: (state) => state.userDevice.browser,
    }),
    totalProgress() {
      return (
        (this.galleryProgress +
          this.loader.loaderManager.textures.progress +
          this.loader.loaderManager.models.GLTFLunarModule.progress +
          this.loader.loaderManager.models.GLTFLunarModule.progress) /
        4
      );
    },

    isGamePlayDebugging() {
      return this.isDevEnv() && this.isGamePlayDebug;
    },
    isSafariOrMobile() {
      return this.userBrowser.includes("safari") || this.isMobile;
    },
  },
  watch: {
    isNavOpen(bool) {
      this.runGame(!bool);

      bool
        ? this.toggleIntroTimeline(!bool)
        : (this.runIntroTimelineFromNavOpen(),
          this.isSceneReadyLocally ? this.nextAnimationFrame() : null);
    },
    isSceneReady() {
      this.startAnimation();
    },
    isSceneReadyLocally() {
      this.startAnimation();
    },
    isTransitionLongEnough() {
      this.startAnimation();
    },

    isUserDeviceReady(bool) {
      bool ? this.init() : null;
    },
    isGalleryAnimationRunning(bool) {
      this.runGalleryAnimation(bool);
    },
  },

  data() {
    return {
      // base
      container: null,
      scene: null,
      camera: null,
      controls: null,
      animation: undefined,

      isSceneReadyLocally: false,

      //  helper
      helpers: {
        stats: null,
      },

      clock: {
        clock: null,
        then60FPS: null,
        then10FPS: null,
      },

      // loaders
      gltf: {
        GLTFLoader: null,
        rotationY: 135.7,
        position: {
          x: 0,
          y: 81,
          z: 138,
        },
      },
      GLTFServiceModel: undefined,
      GLTFLunarModule: undefined,
      loader: {
        isSceneReady: false,
        textureLoader: null,
        loaderManager: {
          textures: { progress: 0 },
          models: {
            GLTFServiceModel: {
              progress: 0,
            },
            GLTFLunarModule: {
              progress: 0,
            },
          },
        },
      },

      // particles
      particles: {
        maxPoints: {
          fire: 100,
        },
        fire: {
          particles: [],
          points: undefined,
          geometry: undefined,
          alphaSpline: null,
          colourSpline: null,
          sizeSpline: null,
          alphaSplineS: null,
          colourSplineS: null,
          sizeSplineS: null,
          alphaSplineF: null,
          colourSplineF: null,
          sizeSplineF: null,
        },
      },

      meshes: {
        earth: {
          mesh: {
            mesh: null,
          },
        },
        stars: {
          mesh: {
            material: [],
          },
        },
      },

      cameras: {
        // default: {
        //   position: {
        //     x: 103,
        //     y: 83,
        //     z: -105,
        //   },
        //   target: {
        //     x: 0,
        //     y: 60,
        //     z: 0,
        //   },
        //   model: {
        //     position: {
        //       x: 0,
        //       y: 81,
        //       z: 13,
        //     },
        //   },
        // },
        start: {
          position: {
            desktop: {
              x: 60.86,
              y: 56.71,
              z: -60.22,
            },
            // duplicate value for this.responsiveCameraPosition on resize
            mobile: {
              x: 60.86,
              y: 56.71,
              z: -60.22,
            },
          },
          target: {
            desktop: {
              x: -33.36,
              y: 127.36,
              z: 64.96,
            },
            // duplicate value for this.responsiveCameraPosition on resize
            mobile: {
              x: -33.36,
              y: 127.36,
              z: 64.96,
            },
          },

          model: {
            position: {
              x: 0,
              y: 81,
              z: 138,
            },
          },
        },

        final: {
          position: {
            desktop: {
              x: 103,
              y: 83,
              z: -105,
            },
            mobile: {
              x: 114,
              y: 84,
              z: -116,
            },
          },
          target: {
            desktop: {
              x: 0,
              y: 60,
              z: 0,
            },
            mobile: {
              x: 0,
              y: 60,
              z: 0,
            },
          },
          model: {
            position: {
              x: 0,
              y: 81,
              z: 13,
            },
          },
        },

        windowUser: {
          width: 0,
          height: 0,
          isSafariAndMobile: false,
          isInnerWidthDesktop: false,
        },
      },
      gsapAnimation: {
        intro: {
          timeline: null,
          // duration: 1,
          duration: 5,

          isAnimationStarted: false,
          isAnimationEnded: false,
        },
      },
      loaderManager: {
        intervalProgress: null,
      },
      errorMain: {
        isCopyVisible: false,
        timeout: null,
      },
    };
  },
  mounted() {
    // this.resetGamePlayStore();
    this.toggleTransitionIsLongEnough(false);
    this.toggleIsSceneReadyLocally(false);
    this.toggleIsGamePausedOnce(false);
    this.setWindowSize();
    this.setInnerWidthDesktopState();
    this.isGamePlayDebugging ? this.setDebugAnimationSpeed() : null;
    this.isUserDeviceReady ? this.init() : null;
    // Register an event listener when the Vue component is ready
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animation);
    this.animation = undefined;
    window.removeEventListener("resize", this.onResize);
    this.resetLoaderManagerInterval();
    this.destroyAllGSAPAnimations();
    this.resetGamePlayStore();
    this.destroyErrorMainTimeout();
  },
  methods: {
    ////////////////////////////////
    //       START ON START METHODS
    ////////////////////////////////

    toggleIsSceneReadyLocally(bool) {
      this.isSceneReadyLocally = bool;
    },
    //   set window width
    setWindowSize() {
      this.cameras.windowUser.width = window.innerWidth;

      this.cameras.windowUser.height = this.isDesktop()
        ? window.innerHeight
        : window.screen.height;
    },
    setInnerWidthDesktopState() {
      this.cameras.windowUser.isInnerWidthDesktop = this.isDesktop();
    },

    // gameLoop is pause when game pause, so ensure that gameLoop don't run twince on mount
    toggleIsGamePausedOnce(bool) {
      this.isGamePausedOnce = bool;
    },

    //======= START USER USING SAFARI OR ON MOBILE =======//

    setDebugAnimationSpeed() {
      this.gsapAnimation.intro.duration = 2;
      //   this.gsapAnimation.endGallery.duration = 1;
    },

    //======= END USER USING SAFARI OR ON MOBILE =======//

    onResize() {
      // Update sizes
      this.setWindowSize();

      // Update camera
      this.camera.aspect =
        this.cameras.windowUser.width / this.cameras.windowUser.height;
      this.camera.updateProjectionMatrix();

      // Update renderer
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );

      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      this.loader.isSceneReady && this.isInnerWidthNewer()
        ? this.cameraAndAnimationResize()
        : null;
    },
    isInnerWidthNewer() {
      return this.cameras.windowUser.isInnerWidthDesktop != this.isDesktop();
    },

    ////////////////////////////////
    //       END ON START METHODS
    ////////////////////////////////

    ////////////////////////////////
    //       START UPDATE CAMERA ON RESIZE
    ////////////////////////////////

    cameraAndAnimationResize() {
      this.setInnerWidthDesktopState();

      // return this.loader.isSceneReady &&
      //   this.gsapAnimation.intro.isAnimationEnded
      //   ? this.upCameraPositionOnResize()
      //   : this.restartIntroAnimation();
      this.upCameraPositionOnResize();
    },

    restartEndGalleryAnimation() {
      this.upCameraPositionOnResize();
      this.startEndOfGallery();
    },

    upCameraPositionOnResize() {
      // update camera position
      this.updateCamera();
    },
    updateCamera() {
      this.updateCameraPosition();
      this.updateCameraTarget();
    },
    updateCameraPosition() {
      this.camera.position.set(
        this.responsiveCameraPosition(
          "x",
          this.returnNamePositionCamera(),
          "position"
        ),
        this.responsiveCameraPosition(
          "y",
          this.returnNamePositionCamera(),
          "position"
        ),
        this.responsiveCameraPosition(
          "z",
          this.returnNamePositionCamera(),
          "position"
        )
      );
    },
    updateCameraTarget() {
      this.controls.target.set(
        this.responsiveCameraPosition(
          "x",
          this.returnNamePositionCamera(),
          "target"
        ),
        this.responsiveCameraPosition(
          "y",
          this.returnNamePositionCamera(),
          "target"
        ),
        this.responsiveCameraPosition(
          "z",
          this.returnNamePositionCamera(),
          "target"
        )
      );
    },
    returnNamePositionCamera() {
      // legacy from rectator. LEave it so its closer to the other pages
      return "final";
    },

    ////////////////////////////////
    //       END UPDATE CAMERA ON RESIZE
    ////////////////////////////////

    ////////////////////////////////
    //       START INIT SCENE AND RENDER
    ////////////////////////////////

    init() {
      this.runProgressLoadingPage();

      this.setBaseScene();

      this.setLight();

      this.setCamera();
      this.setControl();
      this.setCameraPosition();

      this.setRenderer();

      /*------------------------------
      Start add meshes to the scenes
      ------------------------------*/
      this.textureLoader();
      this.addMeshesToScene();

      // set gui globaly
      // this.setGUI();
      /*------------------------------
      End add meshes to the scenes
      ------------------------------*/

      /*------------------------------
      Start Particles
      ------------------------------*/
      this.setParticleSystem();
      /*------------------------------
      End Particles
      ------------------------------*/

      this.gameLoop();
    },

    //======= START GAME LOOP =======//

    gameLoop() {
      // don't display the stats if on prod. Maybe this dev stuff should be handle differently
      this.isDevEnv() ? this.helpers.stats.update() : "";

      const elapsedTime = this.clock.clock.getElapsedTime();

      this.reduced60FPS(elapsedTime);
      this.reduced10FPS(elapsedTime);
      // Render
      this.controls.update();

      this.renderer.render(this.scene, this.camera);

      // Call gameLoop again on the next frame
      this.isNavOpen ? null : this.nextAnimationFrame();
    },
    nextAnimationFrame() {
      this.animation = requestAnimationFrame(this.gameLoop);
    },
    reduced60FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 60;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then60FPS;

      if (delta > interval) {
        this.meshes.earth.mesh.mesh.rotation.y -= 0.0005;
        // this.meshes.clouds.mesh.mesh.rotation.y -= 0.0006;

        this.particles.fire.geometry ? this.particleSteps(0.05) : null; // animate smokes and dust

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then60FPS = now - (delta % interval);
      }
    },
    reduced10FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 10;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then10FPS;

      if (delta > interval) {
        this.particles.fire.geometry ? this.addParticles() : null;
        // this.meshes.clouds.mesh.mesh.rotation.y -= 0.0006;

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then10FPS = now - (delta % interval);
      }
    },

    //======= END GAME LOOP =======//

    //======= START BASE THREEJS =======//

    setBaseScene() {
      // set container
      this.container = this.$refs.sceneSpace__container;

      // create scene
      this.scene = new THREE.Scene();

      this.isDevEnv() ? this.setHelpers() : "";

      this.setClock();
    },

    setHelpers() {
      // stats
      this.helpers.stats = new Stats();
      document.body.appendChild(this.helpers.stats.dom);

      // axes helpers
      const axesHelper = new THREE.AxesHelper(5);
      // x y z
      this.scene.add(axesHelper);
    },
    // setGUI() {
    //   this.helpers.gui = new GUI();
    //   //  const debugObject = {};

    //   // this.helpers.gui
    //   //   .add(this.meshes.glow.mesh.mesh.material.uniforms.c, "value")
    //   //   .min(0)
    //   //   .max(1)
    //   //   .step(0.01);
    //   // this.helpers.gui
    //   //   .add(this.meshes.glow.mesh.mesh.material.uniforms.p, "value")
    //   //   .min(0)
    //   //   .max(10)
    //   //   .step(0.01);
    // },
    setClock() {
      this.clock.clock = new THREE.Clock();
    },

    //======= END BASE THREEJS =======//

    //======= START LIGHTS =======//

    setLight() {
      const ambientLight = new THREE.AmbientLight(0xffffff, 3);
      this.scene.add(ambientLight);
    },

    //======= END LIGHTS =======//

    //======= START CAMERA AND CONTROL =======//

    setCamera() {
      this.camera = new THREE.PerspectiveCamera(
        40,
        this.cameras.windowUser.width / this.cameras.windowUser.height,
        0.1,
        3000
      );

      // this.camera.rotation.x = 0;
      // this.camera.lookAt(new THREE.Vector3(0, 0, 0));

      this.scene.add(this.camera);

      // this.camera.rotateZ(Math.PI / 4);
    },
    setCameraPosition() {
      // this.camera.position.set(
      //   this.cameras.default.position.x,
      //   this.cameras.default.position.y,
      //   this.cameras.default.position.z
      // );

      // this.controls.target.x = this.cameras.default.target.x;
      // this.controls.target.y = this.cameras.default.target.y;
      // this.controls.target.z = this.cameras.default.target.z;
      this.updateCamera();
    },

    setControl() {
      this.controls = new OrbitControls(this.camera, this.container);
      this.controls.enableDamping = true;
    },

    //======= END CAMERA AND CONTROL =======//

    //======= START RENDERER  =======//

    setRenderer() {
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.container,
        powerPreference: "high-performance",
        antialias: !this.isSafariOrMobile,
      });
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );

      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },

    //======= END RENDERER  =======//

    ////////////////////////////////
    //       END INIT SCENE AND RENDER
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MESHES TO SCENE
    ////////////////////////////////

    //======= START LOAD MANAGER =======//

    textureLoader() {
      const loadingManager = new THREE.LoadingManager();

      loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
        this.loader.loaderManager.textures.progress = itemsLoaded / itemsTotal;
      };
      loadingManager.onError = () => {
        console.log("texture loading error");
      };

      // Texture loader

      this.loader.textureLoader = new THREE.TextureLoader(loadingManager);
    },

    //======= END LOAD MANAGER =======//

    addMeshesToScene() {
      this.addEarthMeshes();
      this.addModels();
    },

    //======= START EARTH =======//

    addEarthMeshes() {
      this.addEarth();
      this.addStarField();
    },

    addEarth() {
      const uniforms = {
        sunDirection: { value: new THREE.Vector3(-1, 0.25, 0.25) },
        dayTexture: {
          value: this.loader.textureLoader.load(
            this.responsiveTextureLoader(earth__4K, earth__10K)
          ),
        },
        nightTexture: {
          value: this.loader.textureLoader.load(
            this.responsiveTextureLoader(earthNight__4K, earthNight__10K)
          ),
        },
        opacity: {
          value: 1.0,
        },
      };

      const material = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertex,
        fragmentShader: fragment,
        // transparent: true,
      });

      this.meshes.earth.mesh.mesh = new THREE.Mesh(
        new THREE.SphereGeometry(100, 32 * 2, 32 * 2),
        material
      );
      this.scene.add(this.meshes.earth.mesh.mesh);
      this.meshes.earth.mesh.mesh.rotation.y = 2.5;
    },

    /*------------------------------
    Start on responsive texture
    ------------------------------*/
    responsiveTextureLoader(textureSmall, texturelarge) {
      return this.isSafariOrMobile ? textureSmall : texturelarge;
    },
    /*------------------------------
    End on responsive texture
    ------------------------------*/

    addStarField() {
      const getRandomParticelPos = (particleCount) => {
        const arr = new Float32Array(particleCount * 3);
        for (let i = 0; i < particleCount; i++) {
          arr[i] = 0 + Math.random() * 2000 - 1000;
          // arr[i] = 0 + Math.random() * 100;
        }
        return arr;
      };

      const geometrys = [
        new THREE.BufferGeometry(),
        new THREE.BufferGeometry(),
      ];

      geometrys[0].setAttribute(
        "position",
        new THREE.BufferAttribute(getRandomParticelPos(350), 3)
      );
      geometrys[1].setAttribute(
        "position",
        new THREE.BufferAttribute(getRandomParticelPos(1500), 3)
      );

      // material
      this.meshes.stars.mesh.material = [
        new THREE.PointsMaterial({
          size: 0.5,
          color: "#ffffff",
        }),
        new THREE.PointsMaterial({
          size: 0.25,

          color: "#F1A9A7",
        }),
      ];

      const starsT1 = new THREE.Points(
        geometrys[0],
        this.meshes.stars.mesh.material[0]
      );
      const starsT2 = new THREE.Points(
        geometrys[1],
        this.meshes.stars.mesh.material[1]
      );
      starsT1.position.z = 1000;
      starsT1.position.x = -1000;

      starsT2.position.z = 1000;
      starsT2.position.x = -1000;

      this.scene.add(starsT1);
      this.scene.add(starsT2);

      // stars visible at the right (when users scrolled up the the end of the gallery)
      const starsT3 = starsT1.clone();
      const starsT4 = starsT2.clone();
      starsT3.position.z = -1000;
      starsT3.position.x = -1000;
      starsT4.position.z = -1000;
      starsT4.position.x = -1000;
      this.scene.add(starsT3);
      this.scene.add(starsT4);
    },

    //======= END EARTH =======//

    //======= START ROCKETS =======//

    addModels() {
      this.setBaseLoader();
      this.loadServiceModule();
    },

    setBaseLoader() {
      // set loader
      // this.gltf.GLTFLoader = new GLTFLoader();
      this.setDraco();
      this.setLoader();
    },
    setDraco() {
      this.gltf.dracoLoader = new DRACOLoader();
      //  path to a folder containing WASM/JS decoding libraries.
      this.gltf.dracoLoader.setDecoderPath("/vendors/draco/"); // path needs be public
      this.gltf.dracoLoader.preload();
    },
    setLoader() {
      // set loader
      this.gltf.GLTFLoader = new GLTFLoader();
      this.gltf.GLTFLoader.setDRACOLoader(this.gltf.dracoLoader);
    },

    loadServiceModule() {
      const bakedTexture = this.loader.textureLoader.load(bake);
      // const bakedNormal = this.loader.textureLoader.load(normal);
      bakedTexture.flipY = false;
      bakedTexture.encoding = THREE.sRGBEncoding;
      // bakedNormal.flipY = false;
      // bakedNormal.encoding = THREE.sRGBEncoding;

      const bakedMaterial = new THREE.MeshStandardMaterial();

      bakedMaterial.map = bakedTexture;
      // bakedMaterial.normalMap = bakedNormal;
      // bakedMaterial.normalScale = new THREE.Vector2(30, 30);

      this.gltf.GLTFLoader.load(
        `/three-assets/space/service-module.glb`,
        (gltf) => {
          this.GLTFServiceModel = gltf;
          gltf.scene.traverse((child) => {
            child.material = bakedMaterial;
            if (child.isMesh) {
              child.castShadow = false;
              child.receiveShadow = false;
            }
          });

          this.scene.add(gltf.scene);

          gltf.scene.scale.set(0.07, 0.07, 0.07);
          gltf.scene.position.set(
            this.cameras.start.position.desktop.x,
            this.cameras.start.position.desktop.y,
            this.cameras.start.position.desktop.z
          );
          gltf.scene.rotation.y = this.calculateRadian(this.gltf.rotationY);

          this.isGLTFFullyLoaded();
        },
        (xhr) => {
          this.loader.loaderManager.models.GLTFServiceModel.progress =
            xhr.loaded / xhr.total;
          // progress
          if ((xhr.loaded / xhr.total) * 100 === 100) {
            this.loadLM();
          }
        },
        undefined
      );
    },
    loadLM() {
      const bakedLMTexture = this.loader.textureLoader.load(bakeLM);
      bakedLMTexture.flipY = false;
      bakedLMTexture.encoding = THREE.sRGBEncoding;

      const bakedLMMaterial = new THREE.MeshStandardMaterial();

      bakedLMMaterial.map = bakedLMTexture;

      this.gltf.GLTFLoader.load(
        `/three-assets/space/service-module__lm.glb`,
        (gltf) => {
          this.GLTFLunarModule = gltf;
          gltf.scene.traverse((child) => {
            child.material = bakedLMMaterial;
            if (child.isMesh) {
              child.castShadow = false;
              child.receiveShadow = false;
            }
          });

          this.scene.add(gltf.scene);

          gltf.scene.scale.set(0.07, 0.07, 0.07);
          gltf.scene.position.set(
            this.cameras.start.position.desktop.x,
            this.cameras.start.position.desktop.y,
            this.cameras.start.position.desktop.z
          );

          gltf.scene.rotation.y = this.calculateRadian(this.gltf.rotationY);
          // this.updatePositionGLTF();
          this.isGLTFFullyLoaded();
        },
        (xhr) => {
          if ((xhr.loaded / xhr.total) * 100 === 100) {
            this.loader.loaderManager.models.GLTFLunarModule.progress =
              xhr.loaded / xhr.total;
          }
        },
        undefined
      );
    },
    isGLTFFullyLoaded() {
      this.GLTFLunarModule && this.GLTFServiceModel
        ? this.toggleIsSceneReadyLocally(true)
        : null;
    },

    calculateEuler(rotation) {
      return new THREE.Euler(0, this.calculateRadian(rotation), 0, "XYZ");
    },

    calculateRadian(deg) {
      return deg * (Math.PI / 180);
    },

    //======= END ROCKETS =======//

    ////////////////////////////////
    //       END ADD MESHES TO SCENE
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD CUBE
    ////////////////////////////////

    addBaseCube() {
      const geometry = new THREE.BoxGeometry(1, 1, 1);
      const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
      const cube = new THREE.Mesh(geometry, material);
      this.scene.add(cube);
    },

    ////////////////////////////////
    //       END ADD CUBE
    ////////////////////////////////

    animateCameraIntro(bool) {
      this.loader.isSceneReady = true;
      this.updatePositionGLTF();

      this.startAnimationFloatingModule();
      this.initAnimationCamera();
      this.$nextTick(() => {
        this.toggleIntroTimeline(bool);
      });
    },
    updatePositionGLTF() {
      // avoid a lag when the camera zoom out by dislay the model on from of the camera on mount
      // then udpate position when animation start
      this.GLTFServiceModel.scene.position.set(
        this.cameras.start.model.position.x,
        this.cameras.start.model.position.y,
        this.cameras.start.model.position.z
      );
      this.GLTFServiceModel.scene.position.applyEuler(
        this.calculateEuler(this.gltf.rotationY)
      );
      this.GLTFLunarModule.scene.position.set(
        this.cameras.start.model.position.x,
        this.cameras.start.model.position.y,
        this.cameras.start.model.position.z
      );
      this.GLTFLunarModule.scene.position.applyEuler(
        this.calculateEuler(this.gltf.rotationY)
      );
    },
    initAnimationCamera() {
      this.errorMain.timeout = setTimeout(() => {
        this.errorMain.isCopyVisible = true;
        this.destroyErrorMainTimeout();
      }, 1000);
    },
    destroyErrorMainTimeout() {
      this.errorMain.timeout
        ? (clearTimeout(this.errorMain.timeout),
          (this.errorMain.timeout = null))
        : null;
    },

    /*------------------------------
    Start responsive camera
    ------------------------------*/

    responsiveCameraPosition(axis, camera, type) {
      return this.cameras[camera][type][this.setResponsivePositionCamera()][
        axis
      ];
    },
    setResponsivePositionCamera() {
      return this.isDesktop() ? "desktop" : "mobile";
    },
    isDesktop() {
      return window.innerWidth >= 640;
    },
    /*------------------------------
    End responsive camera
    ------------------------------*/

    // applyEulerMethod(model, rotation) {
    //   model.scene.position.applyEuler(this.calculateEuler(rotation));
    // },

    ////////////////////////////////
    //       START FLYING MODULE ANIMATION
    ////////////////////////////////
    startAnimationFloatingModule() {
      const durationAnimation = 10;
      const movementScale = 0.8;

      const floatingServiceModule = gsap.timeline({
        repeatDelay: 0,
        repeat: -1,
        yoyo: true,
      });

      floatingServiceModule.to(this.GLTFLunarModule.scene.position, {
        x: this.GLTFLunarModule.scene.position.x,
        y: this.GLTFLunarModule.scene.position.y + movementScale,
        z: this.GLTFLunarModule.scene.position.z,
        duration: durationAnimation,
        ease: "power1.inOut",
      });
      floatingServiceModule.to(
        this.GLTFServiceModel.scene.position,
        {
          x: this.GLTFLunarModule.scene.position.x,
          y: this.GLTFLunarModule.scene.position.y + movementScale,
          z: this.GLTFLunarModule.scene.position.z,
          duration: durationAnimation,
          ease: "power1.inOut",
        },
        "<"
      );
    },
    ////////////////////////////////
    //       END FLYING MODULE ANIMATION
    ////////////////////////////////

    toggleStateAnimation(animation, stateName, bool) {
      this.gsapAnimation[animation][stateName] = bool;
    },

    //======= END SET TIMELINE =======//

    ////////////////////////////////
    //       START PARTICLES
    ////////////////////////////////

    //======= START CREATE PARTICLES =======//

    setParticleSystem() {
      /*------------------------------
      Start materials
      ------------------------------*/

      const smokeMaterial = new THREE.ShaderMaterial({
        uniforms: {
          diffuseTexture: {
            value: this.loader.textureLoader.load(smokeTexture),
          },
          pointMultiplier: {
            value:
              (window.innerHeight /
                (2.0 * Math.tan((0.5 * 60.0 * Math.PI) / 180.0))) *
              this.renderer.getPixelRatio(),
          },
        },
        vertexShader: fireVertexShader,
        fragmentShader: fireFragmentShader,
        blending: THREE.CustomBlending,
        blendEquation: THREE.AddEquation,
        blendSrc: THREE.OneFactor,
        blendDst: THREE.OneMinusSrcAlphaFactor,
        depthTest: true,
        depthWrite: false,
        transparent: true,
        vertexColors: true,
      });

      this.particles.fire.particles = [];

      /*------------------------------
      End materials
      ------------------------------*/

      /*------------------------------
      Start particule geometry
      ------------------------------*/

      this.particles.fire.geometry = new THREE.BufferGeometry();

      const defaultBufferGeometryValuesFire = this.setDefaultStartingParticles(
        this.particles.maxPoints.fire
      );
      //   const defaultBufferGeometryValuesSmoke = this.setDefaultStartingParticles(
      //     this.particles.maxPoints.smoke
      //   );

      this.setParticlesAttributes(
        defaultBufferGeometryValuesFire.position,
        defaultBufferGeometryValuesFire.particlePosition,
        defaultBufferGeometryValuesFire.size,
        defaultBufferGeometryValuesFire.colour,
        defaultBufferGeometryValuesFire.angle,
        defaultBufferGeometryValuesFire.blend,
        "fire",
        defaultBufferGeometryValuesFire.uv,
        defaultBufferGeometryValuesFire.normal,
        false
      );

      /*------------------------------
      End particule geometry
      ------------------------------*/

      this.particles.fire.points = new THREE.Mesh(
        this.particles.fire.geometry,
        smokeMaterial
      );

      this.scene.add(this.particles.fire.points);

      const splinesArray = [
        // START FIRE
        {
          particleName: "fire",
          splineName: "alphaSpline",
          pointsArray: [
            [0.0, 0.0],
            [0.2, 1.0],
            [0.5, 1.0],
            [1.0, 0.0],
          ],
          isColour: false,
        },
        {
          particleName: "fire",
          splineName: "colourSpline",
          pointsArray: [
            [0.0, new THREE.Color(0x4d4d4d)],
            [1.0, new THREE.Color(0x202020)],
          ],
          isColour: true,
        },
        {
          particleName: "fire",
          splineName: "sizeSpline",
          pointsArray: [
            [0.0, 0.2],
            [0.5, 1.0],
            [1.0, 1.0],
          ],
          isColour: false,
        },
        // END FIRE
      ];

      splinesArray.forEach((element) => {
        this.addParticulePointForSpline(
          element.particleName,
          element.splineName,
          element.pointsArray,
          element.isColour
        );
      });

      this.updateGeometry("fire");
    },

    setDefaultStartingParticles(maxPoints) {
      return {
        position: new Float32Array(maxPoints * 6),
        particlePosition: new Float32Array(maxPoints * 3 * 6),
        size: new Float32Array(maxPoints * 6),
        colour: new Float32Array(maxPoints * 4 * 6),
        angle: new Float32Array(maxPoints * 6),
        blend: new Float32Array(maxPoints * 6),
        uv: new Float32Array(maxPoints * 6 * 6),
        normal: new Float32Array(maxPoints * 6),
      };
    },

    /*------------------------------
    Start Add points
    ------------------------------*/
    addParticulePointForSpline(
      particuleName,
      splineName,
      arrayOfPoints,
      isColour
    ) {
      isColour
        ? (this.particles[particuleName][splineName] = new LinearSpline(
            (t, a, b) => {
              const c = a.clone();
              return c.lerp(b, t);
            }
          ))
        : (this.particles[particuleName][splineName] = new LinearSpline(
            (t, a, b) => {
              return a + t * (b - a);
            }
          ));

      arrayOfPoints.forEach((points) => {
        this.particles[particuleName][splineName].AddPoint(
          points[0],
          points[1]
        );
      });
    },

    /*------------------------------
    End Add points
    ------------------------------*/

    /*------------------------------
    Start create or udpate particules attributes
    ------------------------------*/
    setParticlesAttributes(
      worldPosition,
      particlePosition,
      sizes,
      colours,
      angles,
      blends,
      particuleName,
      uvs,
      normals,
      isNeedUpdate
    ) {
      const arrayAttributes = [
        { key: "position", vecLength: 3, att: worldPosition },
        { key: "particlePosition", vecLength: 3, att: particlePosition },
        {
          key: "size",
          vecLength: 1,
          att: sizes,
        },
        { key: "colour", vecLength: 4, att: colours },
        { key: "angle", vecLength: 1, att: angles },
        { key: "blend", vecLength: 1, att: blends },
        { key: "uv", vecLength: 3, att: uvs },
        { key: "normal", vecLength: 3, att: normals },
      ];

      arrayAttributes.forEach((element) => {
        isNeedUpdate
          ? (this.particles[particuleName].geometry.setAttribute(
              `${element.key}`,
              new THREE.Float32BufferAttribute(element.att, element.vecLength)
            ),
            (this.particles[particuleName].geometry.attributes[
              element.key
            ].needsUpdate = true),
            (this.particles[particuleName].points.frustumCulled = false),
            this.particles[particuleName].geometry.computeBoundingSphere())
          : this.particles[particuleName].geometry.setAttribute(
              `${element.key}`,
              new THREE.BufferAttribute(element.att, element.vecLength)
            );
      });
    },

    /*------------------------------
    End create or udpate particules attributes
    ------------------------------*/

    //======= END SET PARTICLES ATTRIBUTES =======//

    //======= START ADD PARTICLES =======//

    addParticles() {
      if (
        this.particles.fire.particles.length > this.particles.maxPoints.fire ||
        !this.GLTFServiceModel
      )
        return;

      const n = 1;

      //  fire
      for (let i = 0; i < n; i++) {
        this.particles.fire.particles.push(
          this.sharedParticuleGenerator(
            [
              this.GLTFServiceModel.scene.position.x +
                1 +
                Math.random() * 0.5 * 0.5,
              this.GLTFServiceModel.scene.position.y -
                0 -
                Math.random() * 0.5 * 0.5,
              this.GLTFServiceModel.scene.position.z +
                0.5 +
                Math.random() * 0.5 * 0.5,
            ],
            (Math.random() * 0.5 + 0.5) * 2.0,
            (Math.random() * 0.75 + 0.25) * 10.0,
            Math.random() * 0.5 * Math.PI,
            [0.5, 0, 0.5],
            1.0,
            "",
            1.0
          )
        );
      }
    },

    /*------------------------------
    Start particule generator
    ------------------------------*/
    // generate dynamicly the partcile to add
    sharedParticuleGenerator(
      position,
      size,
      life,
      rotation,
      velocity,
      blend,
      splineName,
      originalSize
    ) {
      // https://stackoverflow.com/questions/34215682/wrong-location-of-texture-on-the-second-triangle-of-buffergeometry-square
      return {
        // position: new THREE.Vector3(position[0], position[1], position[2]),
        position: new THREE.Vector3(position[0], position[1], position[2]),
        worldPosition: new Float32Array([
          -originalSize,
          -originalSize,
          0.0,

          originalSize,
          -originalSize,
          0.0,

          originalSize,
          originalSize,
          0.0,

          originalSize,
          originalSize,
          0.0,

          -originalSize,
          originalSize,
          0.0,

          -originalSize,
          -originalSize,
          0.0,
        ]),
        size: size,
        colour: new THREE.Color(),
        alpha: 1.0,
        life: life,
        maxLife: life,
        rotation: rotation,
        velocity: new THREE.Vector3(velocity[0], velocity[1], velocity[2]),
        blend: blend,
        splineName: splineName,
        uvs: new Float32Array(),
      };
    },
    /*------------------------------
    End particule generator
    ------------------------------*/

    //======= END ADD PARTICLES =======//

    updatedValue(particules, index, axis) {
      // return particules[index] ? particules[index].position[axis] : 0;
      return particules[index] ? 0 : 0;
    },
    updateGeometry(particleName) {
      const worldPosition = [];
      const particlesPosition = [];
      const sizes = [];
      const colours = [];
      const angles = [];
      const blends = [];
      const uvs = [];
      const normals = [];

      for (let p of this.particles[particleName].particles) {
        worldPosition.push(
          p.worldPosition[0],
          p.worldPosition[1],
          p.worldPosition[2],

          p.worldPosition[3],
          p.worldPosition[4],
          p.worldPosition[5],

          p.worldPosition[6],
          p.worldPosition[7],
          p.worldPosition[8],

          p.worldPosition[9],
          p.worldPosition[10],
          p.worldPosition[11],

          p.worldPosition[12],
          p.worldPosition[13],
          p.worldPosition[14],

          p.worldPosition[15],
          p.worldPosition[16],
          p.worldPosition[17]
        );

        for (let i = 0; i < 6; i++) {
          particlesPosition.push(p.position.x, p.position.y, p.position.z);
          blends.push(p.blend);
          colours.push(p.colour.r, p.colour.g, p.colour.b, p.alpha);
          sizes.push(p.currentSize);
          angles.push(p.rotation);

          uvs.push(
            -1.0,
            -1.0,
            0.0,

            1.0,
            -1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            -1.0,
            1.0,
            0.0,

            -1.0,
            -1.0,
            0.0
          );

          normals.push(
            -1.0,
            -1.0,
            0.0,

            1.0,
            -1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            -1.0,
            1.0,
            0.0,

            -1.0,
            -1.0,
            0.0
          );
        }
      }
      this.setParticlesAttributes(
        worldPosition,
        particlesPosition,
        sizes,
        colours,
        angles,
        blends,
        particleName,
        uvs,
        normals,
        true
      );
    },

    particleSteps(timeElapsed) {
      // previously used the timeElapsed instead of hard coded value, but it looked really bad when FTS was higher
      this.updateParticles(timeElapsed, "fire");
      this.updateGeometry("fire");
    },
    updateParticles(timeElapsed, particleName) {
      for (let p of this.particles[particleName].particles) {
        p.life -= timeElapsed;
      }

      this.particles[particleName].particles = this.particles[
        particleName
      ].particles.filter((p) => {
        return p.life > 0.0;
      });

      for (let p of this.particles[particleName].particles) {
        const t = 1.0 - p.life / p.maxLife;
        p.rotation += timeElapsed * 0.5;

        p.position.add(p.velocity.clone().multiplyScalar(timeElapsed));

        this.splineSwitcher(p, t, particleName, p.splineName);

        const drag = p.velocity.clone();
        drag.multiplyScalar(timeElapsed * 0.1);
        drag.x =
          Math.sign(p.velocity.x) *
          Math.min(Math.abs(drag.x), Math.abs(p.velocity.x));
        drag.y =
          Math.sign(p.velocity.y) *
          Math.min(Math.abs(drag.y), Math.abs(p.velocity.y));
        drag.z =
          Math.sign(p.velocity.z) *
          Math.min(Math.abs(drag.z), Math.abs(p.velocity.z));
        p.velocity.sub(drag);
      }

      this.particles[particleName].particles.sort((a, b) => {
        const d1 = this.camera.position.distanceTo(a.position);
        const d2 = this.camera.position.distanceTo(b.position);
        if (d1 > d2) {
          return -1;
        }
        if (d1 < d2) {
          return 1;
        }
        return 0;
      });
    },

    //======= START UPDATE SPLINE =======//

    splineSwitcher(p, t, particleName, alphaSpline) {
      return (
        (p.alpha = this.particles[particleName][
          `alphaSpline${alphaSpline}`
        ].Get(t)),
        (p.currentSize =
          p.size *
          this.particles[particleName][`sizeSpline${alphaSpline}`].Get(t)),
        p.colour.copy(
          this.particles[particleName][`colourSpline${alphaSpline}`].Get(t)
        )
      );
    },

    //======= END UPDATE SPLINE =======//
    ////////////////////////////////
    //       END PARTICLES
    ////////////////////////////////

    ////////////////////////////////
    //       START GLOBAL LOADER
    ////////////////////////////////

    runProgressLoadingPage() {
      this.loaderManager.intervalProgress = setInterval(() => {
        this.emitProgressToStore();

        this.totalProgress >= 1
          ? (this.toggleTransitionIsLongEnough(true),
            this.resetLoaderManagerInterval())
          : null;
      }, 50);
    },

    emitProgressToStore() {
      this.$store.commit(
        "sharedTransition/UPDATE_PROGRESS",
        this.totalProgress
      );
    },

    resetLoaderManagerInterval() {
      this.loaderManager.intervalProgress
        ? (clearInterval(this.loaderManager.intervalProgress),
          (this.loaderManager.intervalProgress = null))
        : null;
    },

    startAnimation() {
      this.isTransitionLongEnough &&
      !this.isNavOpen &&
      this.isSceneReady &&
      this.isSceneReadyLocally &&
      !this.loader.isSceneReady &&
      !this.gsapAnimation.intro.isAnimationEnded
        ? this.animateCameraIntro(!this.isGamePaused)
        : null;
    },

    runIntroTimelineFromNavOpen() {
      this.isTransitionLongEnough &&
      this.isSceneReady &&
      this.isSceneReadyLocally
        ? this.animateCameraIntro(true)
        : null;
    },

    toggleIntroTimeline(bool) {
      // this is needed if user toggle play pause during the animation
      if (
        this.gsapAnimation.intro.timeline &&
        !this.gsapAnimation.intro.isAnimationEnded
      ) {
        bool
          ? this.gsapAnimation.intro.timeline.play()
          : this.gsapAnimation.intro.timeline.pause();
      }
    },

    ////////////////////////////////
    //       END GLOBAL LOADER
    ////////////////////////////////

    ////////////////////////////////
    //       START DESTROY TIMELINE
    ////////////////////////////////

    destroyAllGSAPAnimations() {
      this.killTimeLine("intro", "timeline");
    },

    killTimeLine(animationName, timeline) {
      this.gsapAnimation[animationName][timeline]
        ? (this.gsapAnimation[animationName][timeline].kill(),
          (this.gsapAnimation[animationName][timeline] = null))
        : null;
    },

    ////////////////////////////////
    //       END DESTROY TIMELINE
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.scene-space {
  &__infos {
    position: fixed;
    top: 0px;
    left: 50%;
    z-index: 9990;
  }
  &__button {
    position: fixed;
    top: 0px;
    left: 100px;
    z-index: 9990;
  }
  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: -10;
  }
}
</style>
