<template>
  <div class="shared-progress-main-cta">
    <transition name="slide-up" mode="out-in">
      <div
        v-if="isProgressStarted"
        class="shared-progress-main-cta__count"
        key="count-down"
      >
        {{
          $t("shared.transition.progress.countDown", {
            cent: countDownCentimal,
            decimals: addZeros,
          })
        }}
      </div>
      <div v-else class="shared-progress-main-cta__btn" key="cta">
        <!-- btn  -->
        <shared-button-copy
          :is-white="false"
          :is-black="isBlack"
          icon-name="arrow"
          :copy="copyButton"
          :is-reverse="false"
          unique-ref="progress"
          @clicked="clicked"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import gtagMixin from "@/mixins/gtagMixin.js";

import SharedButtonCopy from "@/components/Shared/SharedButton/SharedButtonCopy/SharedButtonCopy";

export default {
  mixins: [gtagMixin],
  components: {
    SharedButtonCopy,
  },
  data() {
    return {
      urls: {
        exploration: "/moon-buggy",
        firstSteps: "/first-steps",
        landing: "/landing",
        liftOff: "/lift-off",
        space: "/space-travel",
      },
      timeoutRedirection: null,
    };
  },
  props: {
    progressToDisplay: {
      type: String,
      required: false,
      default: "space",
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  filters: {
    firstNumber(value) {
      return ("00" + value).slice(-2);
    },
  },
  beforeDestroy() {
    this.timeoutDestroyer();
  },
  computed: {
    ...mapState({
      progress: (state) => state.sharedTransition.progress,
      isProgressStarted: (state) => state.sharedTransition.isProgressStarted,
    }),
    returnValueAboveZero() {
      return this.progress >= 0 ? this.progress : 0;
    },
    countDown() {
      return (100 - this.returnValueAboveZero * 100).toFixed(0);
    },
    addZeros() {
      return ("00" + this.countDown).slice(-2);
    },
    countDownCentimal() {
      return this.countDown >= 100 ? 1 : 0;
    },
    copyButton() {
      return this.checkProgressToDisplayValues("lift-off") ||
        this.checkProgressToDisplayValues("landing") ||
        this.checkProgressToDisplayValues("exploration") ||
        this.checkProgressToDisplayValues("buggy")
        ? this.$t("shared.transition.cta.mission")
        : this.$t("shared.transition.cta.gallery");
    },
  },
  methods: {
    checkProgressToDisplayValues(valueToCheck) {
      return this.progressToDisplay === valueToCheck;
    },
    ////////////////////////////////
    //       START USER CLICKED
    ////////////////////////////////
    clicked() {
      this.updateTransitionValueInStore();

      this.goToNextPage();

      this.emitGA();
    },
    updateTransitionValueInStore() {
      // start progress page
      this.$store.commit("sharedTransition/TOGGLE_PROGRESS", true);
      // toggle the scene ready
      this.$store.commit("sharedTransition/TOGGLE_SCENE", false);

      // user is going to next page (used for gallery to landing and first step to buggy)
      this.$store.commit("gallery/TOGGLE_USER_GOING_NEXT_PAGE", true);

      // Tell next page to not load a new transition
      // this.$store.commit("sharedTransition/TOGGLE_TRANSITION_STARTED", true);
    },
    goToNextPage() {
      this.progressToDisplay === "landing" ||
      this.progressToDisplay === "exploration"
        ? this.delayThenRedirect()
        : this.redirectToNextPage();
    },
    delayThenRedirect() {
      // user from space have an animation before it redirect to the next page
      this.timeoutRedirection = setTimeout(() => {
        this.redirectToNextPage();
        this.timeoutDestroyer();
      }, 3500);
    },
    timeoutDestroyer() {
      this.timeoutRedirection
        ? (clearTimeout(this.timeoutRedirection),
          (this.timeoutRedirection = null))
        : null;
    },
    redirectToNextPage() {
      // go to next page with the router
      this.$router.push(this.urls[this.progressToDisplay]);
    },

    //======= START EMIT TO GA =======//

    emitGA() {
      this.emitGtag(
        `GoToNext_${this.progressToDisplay}`,
        "Navigation",
        "Click"
      );
    },

    //======= END EMIT TO GA =======//

    ////////////////////////////////
    //       END USER CLICKED
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.shared-progress-main-cta {
  display: flex;
  flex-direction: column;
  padding-top: $medium-margin;
  align-items: center;

  &__count {
    @include countDown;
    color: var(--color-golden);
    min-height: 66px; // avoid the clunchy transition
  }
}
</style>
