<template>
  <div class="home">
    <!-- <three-scene /> -->
    <exploration />
  </div>
</template>

<script>
// @ is an alias to /src

// import ThreeScene from "@/components/ThreeScene.vue";
import Exploration from "@/components/Exploration/Exploration";

export default {
  metaInfo: {
    title: "Moon Buggy | The Leap For Mankind",
  },
  name: "Home",
  components: {
    Exploration,
    // ThreeScene,
  },
};
</script>
