import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      isGamePaused: (state) => state.sharedGamePlay.isGamePaused,
    }),
  },
  methods: {
    toggleGamePause(bool) {
      // reset modal end game
      bool ? this.setPause() : this.gamePlay();
    },
    setPause() {
      if (this.isGamePaused) return;
      this.$store.commit("dashboardModal/OPEN_MODAL", "pause");
      this.$store.commit("sharedGamePlay/TOGGLE_GAME_PAUSED", true);
      this.$store.commit("sharedAudio/toggleSoundEffectPlayback", {
        whichAudio: "fx",
        bool: false,
      });
    },
    gamePlay() {
      this.$store.commit("dashboardModal/RESET_MODAL");
      this.$store.commit("sharedGamePlay/TOGGLE_GAME_PAUSED", false);
    },
    runGame() {
      this.$store.commit("sharedGamePlay/TOGGLE_GAME_PAUSED", false);
    },
  },
};
