<template>
  <div class="shared-layout">
    <ul class="shared-layout__inner">
      <li>
        <shared-layout-title :title="heading" :is-visible="title.isVisible" />
      </li>
      <li
        class="shared-layout__inner__main"
        :class="{ 'shared-layout__inner__main--visible': copy.isVisible }"
      >
        <slot></slot>
      </li>
    </ul>
  </div>
</template>

<script>
import resetGamePlayStoreMixin from "@/mixins/resetGamePlayStoreMixin";
import SharedLayoutTitle from "@/components/Shared/SharedLayout/SharedLayoutTitle.vue";
export default {
  mixins: [resetGamePlayStoreMixin],
  components: {
    SharedLayoutTitle,
  },
  props: {
    heading: {
      type: String,
      required: false,
      default: null,
    },
    parent: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      load: {
        timeout: null,
      },
      title: {
        isVisible: false,
        timeout: null,
        duration: 1300,
      },
      copy: {
        isVisible: false,
        timeout: null,
        duration: 2000,
      },
    };
  },
  mounted() {
    this.setPageLoad();
  },
  beforeDestroy() {
    this.timeoutDestroyer("title");
    this.timeoutDestroyer("copy");
    this.timeoutDestroyer("load");
    this.resetGamePlayStore();
  },
  methods: {
    ////////////////////////////////
    //       START REMOVE LOADER
    ////////////////////////////////
    setPageLoad() {
      this.$store.commit("sharedTransition/SET_NEW_PROGRESS", this.parent);
      this.load.timeout = setTimeout(() => {
        this.endPageLoad();
        this.startAnimation();
        this.timeoutDestroyer("load");
      }, 500);
    },
    endPageLoad() {
      this.$store.commit(
        "sharedTransition/TOGGLE_TRANSITION_ENOUGH_LONG",
        true
      );
      this.$store.commit("sharedTransition/UPDATE_PROGRESS", 1);
    },
    ////////////////////////////////
    //       END REMOVE LOADER
    ////////////////////////////////
    ////////////////////////////////
    //       START ANIMATION
    ////////////////////////////////
    startAnimation() {
      this.fadeOutTimeout("title");
      this.fadeOutTimeout("copy");
    },
    fadeOutTimeout(path) {
      this[path].timeout = setTimeout(() => {
        this.displayer(path, true);
        this.timeoutDestroyer(path);
      }, this[path].duration);
    },
    displayer(path, bool) {
      this[path].isVisible = bool;
    },
    timeoutDestroyer(path) {
      this[path].timeout
        ? (clearTimeout(this[path].timeout), (this[path].timeout = null))
        : null;
    },
    ////////////////////////////////
    //       END ANIMATION
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.shared-layout {
  @include responsiveLayoutPadding;
  @include centeredPageLayout;
  background: $ebony-clay;
  min-height: 100vh;
  padding-bottom: 10rem;
  &__inner {
    display: flex;
    flex-direction: column;
    &__main {
      $transition-copy: 0.3s ease opacity, 0.5s ease transform;
      --shared-layout-opacity: 0;
      --shared-layout-translateY: 5rem;
      @include transition($transition-copy);
      @include transform(translateY(var(--shared-layout-translateY)));
      display: flex;
      justify-content: center;
      opacity: var(--shared-layout-opacity);
      &--visible {
        --shared-layout-opacity: 1;
        --shared-layout-translateY: 0rem;
      }
    }
  }
}
</style>
