<template>
  <div
    class="shared-progress"
    :class="{
      'shared-progress--transparent': isBackgroundInvisible,
      'shared-progress--next': isUserGoingToNextPage,
    }"
    :style="`--background-color-progress: ${backgroundColor}`"
  >
    <div class="shared-progress__inner">
      <shared-progress-main :is-black="isWhite()" />
      <!-- tips -->
      <shared-progress-tutorial
        v-if="isTutorialVisible"
        :is-black="isWhite()"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import navigationMixin from "@/mixins/navigationMixin.js";

import SharedProgressMain from "@/components/Shared/SharedProgress/SharedProgressMain/SharedProgressMain";
import SharedProgressTutorial from "@/components/Shared/SharedProgress/SharedProgressTutorial/SharedProgressTutorial";
export default {
  mixins: [navigationMixin],
  components: {
    SharedProgressMain,
    SharedProgressTutorial,
  },

  computed: {
    ...mapState({
      progressToDisplay: (state) => state.sharedTransition.progressToDisplay,
      isProgressStarted: (state) => state.sharedTransition.isProgressStarted,
      isUserGoingToNextPage: (state) => state.gallery.isUserGoingToNextPage,
    }),
    backgroundColor() {
      switch (this.progressToDisplay) {
        case "space":
          return "#eeeeee"; // $gallery

        case "liftOff":
          return "#222831"; // $ebony-clay

        case "home":
          return "#222831"; // $ebony-clay
        case "credits":
          return "#222831"; // $ebony-clay
        case "privacy":
          return "#222831"; // $ebony-clay

        default:
          return "#0e0e0f"; // $wood-smoke
      }
    },
    isBackgroundInvisible() {
      return (
        (this.progressToDisplay === "landing" ||
          this.progressToDisplay === "exploration") &&
        !this.isProgressStarted
      );
    },
    isTutorialVisible() {
      return (
        this.isProgressStarted &&
        this.progressToDisplay !== "error" &&
        this.progressToDisplay !== "firstSteps" &&
        this.progressToDisplay !== "space" &&
        this.progressToDisplay !== "home" &&
        this.progressToDisplay !== "privacy" &&
        this.progressToDisplay !== "credits"
      );
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.shared-progress {
  --background-color-progress: rgb(33, 39, 48);

  @include centeredPageLayout;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--background-color-progress);

  z-index: 90;

  &--transparent {
    background: rgba(33, 39, 48, 0);
  }
  &--next {
    // there is a vue transition bug if this stuff is on the root
    @include transition(2s ease background 1s);
  }

  &__inner {
    position: relative;
  }
}
</style>
