<template>
  <nav class="navigation" :class="{ 'navigation--open': isNavOpen }">
    <!-- navigation main -->
    <navigation-main />
    <!-- navigation toggle -->
    <navigation-toggle v-if="overwriteIsNavOpen" @hideNav="hideNav" />
  </nav>
</template>

<script>
import { mapState } from "vuex";
import NavigationMain from "@/components/Navigation/NavigationMain/NavigationMain";
import NavigationToggle from "@/components/Navigation/navigationToggle/NavigationToggle";
export default {
  components: {
    NavigationMain,
    NavigationToggle,
  },
  data() {
    return {
      overwriteIsNavOpen: false,
    };
  },
  computed: {
    ...mapState({
      isNavOpen: (state) => state.navigation.isNavOpen,
    }),
  },
  watch: {
    isNavOpen(bool) {
      bool ? this.toggleOverwriteIsNavOpen(true) : null;
    },
  },
  methods: {
    ////////////////////////////////
    //       START FIX PERFORMANCE ISSUE ON MOBILE. THE NAV IS WEIRDLY TOO HEAVY
    ////////////////////////////////

    toggleOverwriteIsNavOpen(bool) {
      this.overwriteIsNavOpen = bool;
    },
    hideNav() {
      this.toggleOverwriteIsNavOpen(false);
    },
    ////////////////////////////////
    //       END FIX PERFORMANCE ISSUE ON MOBILE. THE NAV IS WEIRDLY TOO HEAVY
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  --pointer-event-nav: none;
  position: fixed;
  z-index: 91;
  pointer-events: var(--pointer-event-nav);
  min-height: 100vh;
  &--open {
    --pointer-event-nav: auto;
  }
}
</style>
