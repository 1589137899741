<template>
  <div
    class="shared-button-menu-moon"
    :class="{ 'shared-button-menu-moon--visible': isVisible }"
  ></div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-menu-moon {
  width: 100%;
  height: 5px;
  top: calc(75px / 2 - 5px / 2);
  position: absolute;

  animation: rotationMoon 10s linear infinite;
  @keyframes rotationMoon {
    to {
      @include rotate(-360deg);
    }
  }
  &:after {
    @include transition(4s ease all);
    @include transition-delay(2s);
    @include transform(scale(0));
    @include radius(50%);
    opacity: 0;
    content: " ";
    height: 5px;
    width: 5px;
    background-color: var(--color-golden);
    position: absolute;
    right: -2px;
  }
  &--visible {
    &:after {
      @include transform(scale(1));
      opacity: 1;
    }
  }
}
</style>
