<template>
  <div class="home-page-background">
    <video
      preload="none"
      ref="backgroundVideo"
      class="home-page-background__video"
      autoplay
      loop
      muted
      playsinline
    >
      <source
        :src="videoBackground"
        :poster="videoBackgroundPoster"
        type="video/mp4"
      />

      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
import { mapState } from "vuex";
import videoBackground from "@/assets/videos/leap-for-mankind-background.mp4";
import videoBackgroundPoster from "@/assets/poster/leap-for-mankind-background-poster.jpg";
export default {
  data() {
    return {
      videoBackground,
      videoBackgroundPoster,
      videoIsReady: false,
      video: null,
    };
  },
  computed: {
    ...mapState({
      isIntroVisible: (state) => state.intro.isVisible,
    }),
  },
  watch: {
    isIntroVisible(bool) {
      bool ? this.pauseVideo() : this.playVideo();
    },
  },

  mounted() {
    this.setVideo();
  },
  beforeDestroy() {
    this.video.removeEventListener("canplay", this.videoLoaded);
  },
  methods: {
    setVideo() {
      this.video = this.$refs.backgroundVideo;
      this.video.addEventListener("canplay", this.videoLoaded);
    },
    playVideo() {
      this.videoIsReady ? this.video.play() : null;
    },
    pauseVideo() {
      this.videoIsReady ? this.video.pause() : null;
    },

    ////////////////////////////////
    //       START HIDE TRANSITION PAGE WHEN RETURNING TO HOME PAGE
    ////////////////////////////////
    videoLoaded() {
      this.isIntroVisible ? null : this.hideTransitionPage();
    },
    hideTransitionPage() {
      // fall back in case the video wasn't ready when the intro animation is running
      this.playVideo();
      // if this is page intro, so everything below don't matter and can still run
      this.toggleTransitionIsLongEnough(true);
      this.$nextTick(() => {
        this.$store.commit("sharedTransition/UPDATE_PROGRESS", 1);
      });
    },
    toggleTransitionIsLongEnough(bool) {
      this.$store.commit(
        "sharedTransition/TOGGLE_TRANSITION_ENOUGH_LONG",
        bool
      );
    },
    ////////////////////////////////
    //       END HIDE TRANSITION PAGE WHEN RETURNING TO HOME PAGE
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.home-page-background {
  @include fullScreenDom;
  height: 100vh;

  justify-content: center;
  align-items: center;
  align-content: center;

  .is-mobile & {
    display: flex;
  }

  &:before {
    @include fullScreenDom;
    content: " ";

    z-index: 1;
    background: rgba(37, 42, 52, 0.53);
  }

  &__video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    .is-mobile & {
      @include transform(translateX(-25%));
      min-width: 100vw;
      width: auto;

      @include teq-breakpoint($small) {
        @include transform(translateX(0%));
      }
    }
  }
}
</style>
