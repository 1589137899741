<template>
  <section class="dashboard">
    <transition name="modal">
      <dashboard-paused-panel
        v-if="isModalVisible && whichModal"
        :which-modal="whichModal"
        :scene-name="sceneName"
      />
    </transition>

    <div class="dashboard__inner">
      <dashboard-left
        :floor-position-adjustment="floorPositionAdjustment"
        :total-altitude="totalAltitude"
        :vehicule-position="vehiculePosition"
        :is-landing="isLanding"
        :position-adjustment="positionAdjustment"
        :is-altimeter-visible="isAltimeterVisible"
      />
      <!-- :is-visible="sceneName" -->
      <dashboard-center
        :vehicule-position="vehiculePosition"
        :perimeters="perimeters"
        :is-space-key-pressed="isSpaceKeyPressed"
        :is-landed-outside="isLandedOutside"
        :scene-name="sceneName"
        :is-paused="isPaused"
      />

      <!-- <dasbhoard-localization
        :vehicule-localization-x="vehiculePosition.x"
        :vehicule-localization-z="vehiculePosition.z"
        :vehicule-localization-y="vehiculePosition.y"
        :value-adjustment="2"
      /> -->

      <!-- <dashboard-bottom /> -->
    </div>
  </section>
</template>

<script>
// import DasbhoardLocalization from "@/components/Dashboard/DashboardLocalization/DasbhoardLocalization";
import { mapState } from "vuex";
import DashboardCenter from "@/components/Dashboard/DashboardCenter";
import DashboardLeft from "@/components/Dashboard/DashboardLeft";
import DashboardPausedPanel from "@/components/Dashboard/DashboardPausedPanel/DashboardPausedPanel";
// import DashboardBottom from "@/components/Dashboard/DashboardBottom/DashboardBottom";
export default {
  components: {
    // DashboardAltitude,
    DashboardCenter,
    DashboardLeft,
    DashboardPausedPanel,
    // DasbhoardLocalization,

    // DashboardBottom,
  },
  props: {
    vehiculePosition: {
      type: Object,
      required: true,
    },
    perimeters: {
      type: Object,
      required: true,
    },
    isLanding: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSpaceKeyPressed: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLandedOutside: {
      type: Boolean,
      required: false,
      default: false,
    },
    sceneName: {
      type: String,
      required: true,
      validator: (val) => ["liftOff", "landing", "exploration"].includes(val),
    },
    isPaused: {
      type: Boolean,
      required: false,
      default: false,
    },

    totalAltitude: {
      type: Number,
      required: false,
      default: 4000,
    },
    floorPositionAdjustment: {
      type: Number,
      required: false,
      default: 23.5,
    },
    positionAdjustment: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  computed: {
    ...mapState({
      isModalVisible: (state) => state.dashboardModal.isModalVisible,
      whichModal: (state) => state.dashboardModal.whichModal,
    }),

    isAltimeterVisible() {
      return this.sceneName !== "exploration";
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/config/vars.scss";
@import "@/assets/scss/config/mixins.scss";
@import "@/assets/scss/config/responsive.scss";
.dashboard {
  @include fullScreenDom();
  @include dashboardLayout;
  z-index: 80;

  color: $gallery;
  pointer-events: none;
}
</style>
