<template>
  <div class="gallery-manager">
    <!-- <three-scene /> -->
    <gallery-manager
      v-if="images.list.length"
      :images="images"
      parent="space"
    />
  </div>
</template>

<script>
import GalleryManager from "@/components/Gallery/GalleryManager/GalleryManager";
import space from "@/data/galleries/space.json";

export default {
  metaInfo: {
    title: "Space Travel | The Leap For Mankind",
  },
  name: "Home",
  components: {
    GalleryManager,
  },
  data() {
    return {
      images: space,
    };
  },
};
</script>
