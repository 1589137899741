<template>
  <li
    class="shared-altimeter-lines"
    :class="{
      'shared-altimeter-lines--landing': isLanding,
      'shared-altimeter-lines--visible': isAnimationStarted,
    }"
  >
    <ul
      class="shared-altimeter-lines__list"
      :style="`transform: translateY(${progress}%)`"
    >
      <li v-for="index in 80" :key="index" />
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: false,
      default: 0,
    },
    isLanding: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-altimeter-lines {
  --opacity-altimeters-line: 0;
  --start-position: 2rem;
  @include transition(opacity 0.5s linear 2s);

  display: flex;
  flex-direction: column;
  max-height: 23rem;

  height: 100vh;

  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  opacity: var(--opacity-altimeters-line);

  &--landing {
    justify-content: flex-start;
    // --start-position: 0rem;
  }

  &--visible {
    --opacity-altimeters-line: 1;
    --start-position: 0;
  }

  &__list {
    @include transition(bottom 1s ease 2s);
    bottom: var(--start-position);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $x-small-margin;
    position: absolute;
    li {
      width: 2rem;
      height: 0.1rem;
      background-color: $gallery;
      opacity: 0.3;
    }
  }
}
</style>
