<template>
  <li
    class="shared-bottom-control-center"
    :class="{ 'shared-bottom-control-center--right': !isLiftOffVisible }"
  >
    <transition name="slide-tutorial-mobile">
      <shared-bottom-control-tutorial
        v-if="isJoystickTutorialVisible"
        :is-centered="isLiftOffVisible"
        :copy="copyTutorial()"
      />
    </transition>
    <div class="shared-bottom-control-center__inner">
      <shared-joystick :is-visible="isVisible" />
    </div>
  </li>
</template>

<script>
import { mapState } from "vuex";
import SharedBottomControlTutorial from "@/components/Shared/SharedBottomControl/SharedBottomControlTutorial";
import SharedJoystick from "@/components/Shared/SharedJoystick/SharedJoystick";
export default {
  components: {
    SharedBottomControlTutorial,
    SharedJoystick,
  },
  props: {
    isLiftOffVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    sceneName: {
      type: String,
      required: false,
      default: null,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isJoystickTutorialVisible: false,
      timeout: null,
    };
  },
  beforeDestroy() {
    this.timeoutDestroyer();
  },
  computed: {
    ...mapState({
      isTutorialVisible: (state) => state.dashboardTutorial.isTutorialVisible,
      isCombinedTutorial: (state) => state.dashboardTutorial.isCombinedTutorial,
      pathCopyTutorial: (state) => state.dashboardTutorial.pathCopyTutorial,
    }),
  },
  watch: {
    isTutorialVisible(bool) {
      bool && this.pathCopyTutorial !== "space"
        ? this.delayVisiblityTutorial()
        : this.toggleVisiblityTutorial(false);
    },
  },
  methods: {
    copyTutorial() {
      return this.isCombinedTutorial
        ? this.$t(`shared.tutorial.${this.sceneName}.combined.mobile`)
        : this.$t(
            `shared.tutorial.${this.sceneName}.${this.pathCopyTutorial}.mobile`
          );
    },
    delayVisiblityTutorial() {
      // so animation of the button is done before the copy show up

      this.timeout = setTimeout(() => {
        this.toggleVisiblityTutorial(true);
        this.timeoutDestroyer();
      }, 2500);
    },
    toggleVisiblityTutorial(bool) {
      this.isJoystickTutorialVisible = bool;
    },
    timeoutDestroyer() {
      this.timeout ? (clearTimeout(this.timeout), (this.timeout = null)) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-bottom-control-center {
  --joystick-opacity: 0;
  --joystick-pointer-events: none;
  --joystick-index: -999;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-column: 2/3;
  // @include teq-breakpoint($medium) {
  opacity: var(--joystick-opacity);
  pointer-events: var(--joystick-pointer-events);
  z-index: var(--joystick-index);
  // }

  .app--mobile & {
    --joystick-opacity: 1;
    --joystick-pointer-events: auto;
    --joystick-index: 1;
  }
  &--right {
    grid-column: 3/4;
  }
  &__inner {
    display: flex;
    justify-content: center;
    height: 100%;
    min-height: 14rem;
  }
}
</style>
