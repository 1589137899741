// initial state
const state = () => ({
  isSceneReady: false,

  isPageTransitionVisible: true, // should be true by default
  isTransitionLongEnough: false, // this will allow user to read the tutorial
  // isBackgroundBlack: false, // white background for from liftoff to the start of the gallery
  // isTransitionStarted: false, // avoid page to reload in an other color during a transition
  isMissionSuccess: false, // display success message before to display next mission
  isProgressStarted: false, // should be true by default
  progressToDisplay: "space", // needed for the copy
  progress: 0,

  isSceneExiting: false,
  isIntro: true,
});

// getters
const getters = {};

// actions
const actions = {
  DISABLE_INTRO(store) {
    store.commit("DISABLE_INTRO_TRANSITION");
  },
};

// mutations
const mutations = {
  TOGGLE_TRANSITION(state, bool) {
    state.isPageTransitionVisible = bool;
  },
  TOGGLE_PROGRESS(state, bool) {
    state.isProgressStarted = bool;
  },
  UPDATE_PROGRESS(state, val) {
    state.progress = val >= 0 ? val : 0;
  },
  RESET_PROGRESS(state) {
    state.progress = 0;
  },
  TOGGLE_SCENE(state, bool) {
    state.isSceneReady = bool;
  },
  UPDATE_PROGRESS_DISPLAY(state, val) {
    state.progressToDisplay = val;
  },

  // TOGGLE_TRANSITION_STARTED(state, bool) {
  //   state.isTransitionStarted = bool;
  // },
  SET_NEW_TRANSITION(state, val) {
    state.progress = 0;
    //  set progress started to false
    state.isProgressStarted = false;
    // set next page
    state.progressToDisplay = val;
    // make sure the page transition is open
    state.isPageTransitionVisible = true;
  },
  SET_NEW_PROGRESS(state, val) {
    //  make sure progress is 0
    state.progress = 0;
    //  set progress started to true
    state.isProgressStarted = true;
    // set next page
    state.progressToDisplay = val;
    // make sure the page transition is open
    state.isPageTransitionVisible = true;
    // ensure users have time to read the tutorial
    state.isTransitionLongEnough = false;
  },
  SET_SUCCESS_MISSION(state, val) {
    //  make sure progress is 0
    state.progress = 0;
    //  set progress started to true
    state.isProgressStarted = false;
    // set success mission to true
    state.isMissionSuccess = true;
    // set next page
    state.progressToDisplay = val;
    // make sure the page transition is open
    state.isPageTransitionVisible = true;
  },
  TOGGLE_SUCCESS_MISSION(state, bool) {
    state.isMissionSuccess = bool;
  },

  TOGGLE_TRANSITION_ENOUGH_LONG(state, bool) {
    // ensure that users can read the tutorial
    state.isTransitionLongEnough = bool;
  },

  DISABLE_INTRO_TRANSITION(state) {
    state.isIntro = false;
  },

  ////////////////////////////////
  //       START USER EXIT A SCENE (GAME IS COMPLETED)
  ////////////////////////////////
  TOGGLE_EXIT_SCENE(state, bool) {
    state.isSceneExiting = bool;
  },
  ////////////////////////////////
  //       END USER EXIT A SCENE (GAME IS COMPLETED)
  ////////////////////////////////
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
