import { isMobile, browserName } from "mobile-device-detect";

export default {
  mounted() {
    // Add Window events
    // window.addEventListener("resizeEnd", this.setUserDevice);
  },
  methods: {
    setUserDevice() {
      const userDevice = {
        // browser: {
        //   name: browserName.toLowerCase().replace(" ", "-"),
        //   os: osName.toLowerCase().replace(" ", "-"),
        // },
        isMobile,
        browser: browserName.toLowerCase().replace(" ", "-"),
      };
      this.$store.commit("userDevice/SET_USER_DEVICE", userDevice);
    },
  },
};
