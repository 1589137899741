<template>
  <section class="dashboard-center">
    <dashboard-alert
      :vehicule-position="vehiculePosition"
      :perimeters="perimeters"
      :is-space-key-pressed="isSpaceKeyPressed"
      :is-landed-outside="isLandedOutside"
      :scene-name="sceneName"
    />

    <dashboard-tutorial :scene-name="sceneName" />
  </section>
</template>

<script>
import DashboardAlert from "@/components/Dashboard/DashboardAlert/DashboardAlert";
import DashboardTutorial from "@/components/Dashboard/DashboardTutorial/DashboardTutorial";

export default {
  components: {
    DashboardAlert,
    DashboardTutorial,
    // DashboardRestart,
  },
  props: {
    vehiculePosition: {
      type: Object,
      required: true,
    },
    perimeters: {
      type: Object,
      required: true,
    },

    isSpaceKeyPressed: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLandedOutside: {
      type: Boolean,
      required: false,
      default: false,
    },
    sceneName: {
      type: String,
      required: true,
      validator: (val) => ["liftOff", "landing", "exploration"].includes(val),
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-center {
  grid-column: 2 / 3;
  grid-row: 1/1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  @include teq-breakpoint($small) {
    justify-content: space-between;
  }
}
</style>
