<template>
  <div class="credits">
    <shared-layout :heading="$t('pages.credits.title')" parent="credits"
      ><credits-main
    /></shared-layout>
  </div>
</template>

<script>
import CreditsMain from "@/components/Credits/Credits";
import SharedLayout from "@/components/Shared/SharedLayout/SharedLayout";
export default {
  metaInfo: {
    title: "Credits | The Leap For Mankind",
  },
  components: {
    CreditsMain,
    SharedLayout,
  },
};
</script>
