var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shared-button-animation",class:{
    'shared-button-animation--white': _vm.isWhite,
    'shared-button-animation--black': _vm.isBlack,
    'shared-button-animation--ready': _vm.isAnimationStarted,
    'shared-button-animation--circle': _vm.isCircle,
    'shared-button-animation--hover': _vm.isHover,
    'shared-button-animation--circle-visible': _vm.isCircleShadowVisible,
  }},[(_vm.isCircle)?_c('shared-button-svg-circle'):_c('shared-button-svg-square'),(_vm.isCircle)?_c('div',{staticClass:"shared-button-animation__circle"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }