// initial state
const state = () => ({
  isMute: true,
  isPlaying: false,
  currentTrack: null,
  upComingMusicTrack: null,
  defaultMusicTrack: "scott-buckley-passage-of-time.mp3",
  defaultFXTrack:
    "touch-down-555136__f-m-audio__body-falling-on-carpeted-floor-1.mp3",

  fx: {
    engine: {
      currentTrack: null,
      upComingTrack: null,
      isPlaying: false,
      isStopped: false,
    },
    fx: {
      currentTrack: null,
      upComingTrack: null,
      isPlaying: false,
      isStopped: false,
    },
  },

  //  engine
  // currentEngineTrack: null,
  // upComingEngineTrack: null,
  // isEnginePlaying: false,

  // maybe too much
  // currentRadioTrack: null,

  // explosion, touch down //   appolo radio
  // currentFXTrack: null,
  // upComingFXTrack: null,
  // isFXPlaying: false,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  /*------------------------------
    Start Audio
    ------------------------------*/
  toggleMute(state) {
    state.isMute = !state.isMute;
  },
  togglePlayback(state, bool) {
    state.isPlaying = bool;
  },
  updateCurrentMusicTrack(state, url) {
    state.currentTrack = url;
    state.isPlaying = true;
  },
  updateUpComingMusicTrack(state, url) {
    state.upComingMusicTrack = url;
  },

  /*------------------------------
    End Audio
    ------------------------------*/

  ////////////////////////////////
  //       START FX
  ////////////////////////////////
  updateCurrentSoundEffectTrack(state, val) {
    state.fx[val.whichAudio].currentTrack = val.url;
    // state.fx[val.whichAudio].isPlaying = true;
    // state.fx[val.whichAudio].isStopped = false;
  },

  updateUpComingSoundEffectTrack(state, val) {
    state.fx[val.whichAudio].isStopped = false;
    state.fx[val.whichAudio].upComingTrack = val.url;
  },

  toggleSoundEffectPlayback(state, val) {
    // val.bool ? (state.fx[val.whichAudio].isStopped = false) : null;
    state.fx[val.whichAudio].isStopped = false;
    state.fx[val.whichAudio].isPlaying = val.bool;
  },

  toggleStopSoundEffect(state, val) {
    state.fx[val.whichAudio].isStopped = val.bool;
    state.fx[val.whichAudio].isPlaying = !val.bool;
  },

  toggleAllSoundsEffectsPlayback(state, bool) {
    state.fx.engine.isPlaying = bool;
    state.fx.fx.isPlaying = bool;
  },
  ////////////////////////////////
  //       END FX
  ////////////////////////////////
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
