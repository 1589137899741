<template>
  <div class="shared-button-video">
    <!-- circle with animation -->
    <shared-button-video-progress />
    <div class="shared-button-video__main">
      <!-- button div -->
      <shared-button-circle
        aria-label="Skip Video"
        :is-white="false"
        :is-black="false"
        icon-name="skip"
        :rotation="0"
        :overwrite-time-out="0"
        :is-animation-running="isAnimationRunning"
        :is-disable="false"
        @clicked="skipVideo"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import gtagMixin from "@/mixins/gtagMixin.js";

import SharedButtonCircle from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircle";
import SharedButtonVideoProgress from "@/components/Shared/SharedButton/SharedButtonVideo/SharedButtonVideoProgress";
export default {
  mixins: [gtagMixin],
  components: {
    SharedButtonCircle,
    SharedButtonVideoProgress,
  },
  props: {
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isSkipRunning: false,
      isAnimationRunning: false,
      isHover: false,
    };
  },
  computed: {
    ...mapState({
      isMute: (state) => state.sharedAudio.isMute,
    }),
  },
  mounted() {
    this.startAnimation();
  },

  methods: {
    // I think it will be from the store instead
    startAnimation() {
      const timeOut = setTimeout(() => {
        this.isAnimationRunning = true;
        clearTimeout(timeOut);
      }, 10);
    },
    ////////////////////////////////
    //       START USER INTERACTIVE WITH BUTTON
    ////////////////////////////////
    skipVideo() {
      if (this.isSkipRunning) return;
      this.isSkipRunning = true;

      this.$store.dispatch("intro/TOGGLE_VIDEO", false);
      setTimeout(() => {
        this.$store.dispatch("intro/HIDE_INTRO", false);
      }, 500);

      this.emitSkipToGA();
    },

    //======= START EMIT TO GA =======//

    emitSkipToGA() {
      const introVideo = document.getElementById("tvVideo");
      if (!introVideo) return;

      const currentTimeVideo = Math.round(introVideo.currentTime);

      this.emitGA(currentTimeVideo);
    },

    emitGA(currentTimeVideo) {
      this.emitGtag(
        `SkipIntro_${currentTimeVideo}s_Audio_${this.humanizeBoolean(
          this.isMute
        )}`,
        "Intro",
        "Click"
      );
    },

    //======= END EMIT TO GA =======//

    ////////////////////////////////
    //       END USER INTERACTIVE WITH BUTTON
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.shared-button-video {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 5px; // (7px -3px from the padding of the button)
  max-width: 75px;
  height: 75px;
  margin-left: -5px;
  margin-top: -5px;

  &__main {
  }
}
</style>
