<template>
  <div
    class="navigation-main-center-list-circle"
    :class="[
      `navigation-main-center-list-circle--${size}`,
      { 'navigation-main-center-list-circle--visible': isVisible },
    ]"
  >
    <div class="navigation-main-center-list-circle__inner">
      <svg
        :viewBox="customViewBox(size)"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath :id="customId">
          <circle
            :cx="customXY(size)"
            :cy="customXY(size)"
            :r="customR(size)"
            stroke="#E8E8F2"
            stroke-opacity="1"
          />
        </clipPath>

        <circle
          :clip-path="`url(#${customId})`"
          class="navigation-main-center-list-circle__inner__circle"
          fill="none"
          stroke="#bc85ff"
          stroke-miterlimit="1"
          :cx="customXY(size)"
          :cy="customXY(size)"
          :r="customR(size)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: "medium",
    },
    customId: {
      type: String,
      required: true,
    },
  },
  methods: {
    customViewBox(size) {
      switch (size) {
        case "small":
          return "0 0 184 184";

        case "medium":
          return "0 0 338 338";

        case "large":
          return "0 0 673 673";

        case "xl":
          return "0 0 1638 1638";

        default:
          return "0 0 673 673";
      }
    },

    customXY(size) {
      switch (size) {
        case "small":
          return "92";

        case "medium":
          return "169";

        case "large":
          return "336.5";

        case "xl":
          return "819";

        default:
          return "336.5";
      }
    },
    customR(size) {
      switch (size) {
        case "small":
          return "91.5";

        case "medium":
          return "168.5";

        case "large":
          return "336";

        case "xl":
          return "819.5";

        default:
          return "336";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation-main-center-list-circle {
  position: absolute;
  top: 0px;
  pointer-events: none;
  margin: -30px 0px 0px 0px;

  @include teq-breakpoint($medium) {
    left: 0px;
    top: inherit;
    margin: 0px 0px 0px -30px;
  }

  &--small {
    display: none;
    @include teq-breakpoint($medium) {
      height: auto;
      width: 50%;
    }
  }
  &--medium {
    width: calc((1000px / 6) - (22px));
    @include teq-breakpoint($medium) {
      height: auto;
      width: 100%;
    }
  }
  &--large {
    width: calc((1000px / 4 + 6.5rem - 27px));
    @include teq-breakpoint($medium) {
      height: auto;
      width: 200%;
    }
  }
  &--xl {
    @include transform(translate(0px, calc(0px - (6.5rem - 28px))));
    width: calc((1000px - 6.5rem - 30px));

    @include teq-breakpoint($medium) {
      @include transform(translate(0px));
      height: auto;
      width: calc(500% + 6.5rem - 30px);
      margin-left: -6.5rem;
    }
  }

  &__inner {
    @include rotate(180deg);
    --play-pause: paused;
    pointer-events: none;
    &__circle {
      stroke-dasharray: 5150;
      stroke-dashoffset: 5150;

      stroke-width: 1px;
      stroke: rgba(232, 232, 242, 0.2);

      pointer-events: none;

      animation: draw 0.75s ease-in-out forwards;
      animation-play-state: var(--play-pause);

      .navigation-main-center-list-circle--small & {
        stroke-dasharray: 1100;
        stroke-dashoffset: 1100;
      }
      .navigation-main-center-list-circle--medium & {
        stroke-dasharray: 1100;
        stroke-dashoffset: 1100;
      }
      .navigation-main-center-list-circle--large & {
        stroke-dasharray: 2110;
        stroke-dashoffset: 2110;
      }

      .navigation-main-center-list-circle--visible & {
        --play-pause: running;
      }
    }

    @keyframes draw {
      to {
        stroke-dashoffset: 0;
      }
    }
  }
}
</style>
