<template>
  <section class="scene">
    <dashboard
      v-if="physics.objectsToUpdate.length"
      :vehicule-position="physics.objectsToUpdate[0].body.position"
      :perimeters="perimeters.alert"
      :is-paused="this.isGamePaused"
      scene-name="exploration"
    />
    <dashboard-bottom scene-name="exploration" :is-lift-off-visible="false" />
    <compass />
    <canvas
      ref="scene__container"
      class="scene__container"
      :class="{
        'scene__container--visible': this.initiate.isCompleted,
      }"
    ></canvas>
  </section>
</template>

<script>
import { mapState } from "vuex";

import compassMixin from "@/mixins/compassMixin";
import gtagMixin from "@/mixins/gtagMixin.js";
import isDevMixin from "@/mixins/isDevMixin";
import resetGamePlayStoreMixin from "@/mixins/resetGamePlayStoreMixin";
import sharedFXAudioMixin from "@/mixins/sharedFXAudioMixin";
import toggleDashboardBottomMixin from "@/mixins/toggleDashboardBottomMixin";
import transitionMixin from "@/mixins/transitionMixin";
import tutorialMixin from "@/mixins/tutorialMixin";

import { gsap } from "gsap";

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// Post Processing
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { RGBShiftShader } from "three/examples/jsm/shaders/RGBShiftShader.js";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass.js";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";

// vendors
import * as CANNON from "cannon-es";
import CannonDebugRenderer from "@/vendors/CannonDebugRenderer.js";
import LinearSpline from "@/vendors/LinearSpline.js";
import JoyStick from "@/vendors/toon3d.js";

// STATS
import Stats from "stats.js";
import { GUI } from "@/vendors/dat.gui.module.js";

// shaders
import moonVertexShader from "@/shaders/moon/vertex.glsl";
import moonFragmentShader from "@/shaders/moon/fragment.glsl";
import moonSafariFragment from "@/shaders/moon/safariFragment.glsl";
import fireVertexShader from "@/shaders/fire/vertex.glsl";
import fireFragmentShader from "@/shaders/fire/fragment.glsl";

// heigthmap
import heightmap from "@/assets/textures/moon/heightmap/center.jpg";
import heightmapTop from "@/assets/textures/moon/heightmap/north.jpg";
import heightmapWest from "@/assets/textures/moon/heightmap/west.jpg";
import heightmapNorthWest from "@/assets/textures/moon/heightmap/northwest.jpg";

// components
import Compass from "@/components/Compass/Compass";
import Dashboard from "@/components/Dashboard/Dashboard";
import DashboardBottom from "@/components/Dashboard/DashboardBottom/DashboardBottom";

export default {
  mixins: [
    gtagMixin,
    compassMixin,
    isDevMixin,
    resetGamePlayStoreMixin,
    sharedFXAudioMixin,
    toggleDashboardBottomMixin,
    transitionMixin,
    tutorialMixin,
  ],
  components: {
    Compass,
    Dashboard,
    DashboardBottom,
  },
  data() {
    return {
      // base
      container: null,
      scene: null,
      camera: null,
      controls: null,
      animation: undefined,
      isGamePausedOnce: false,

      initiate: {
        isInitStarted: false,
        isInitiating: false,
        isCompleted: false,
        timeout: null,
        timeoutGameloop: null,
        timeoutMounted: null,
      },

      effectComposer: {
        effectComposer: null,
        renderTarger: null,
        glitchPass: {
          GlitchPass: null,
          isGlitchPassVisible: false,
        },
        RGBShiftPass: null,
        filmPass: {
          filmPass: null,
        },
        glitchResetTimeOut: null,
      },

      //  helper
      helpers: {
        stats: null,
        clock: null,
      },
      clock: {
        clock: null,
        oldElapsedTime: 0,
        then: 0,
        then10FPS: 0,
        then60FPS: 0,
      },
      lights: {
        directionalLight: null,
      },
      cameras: {
        defaultCameraPosition: {
          camX: -280,
          camY: 280,
          camZ: 280,
        },

        windowUser: {
          width: 0,
          height: 0,
        },
      },
      perimeters: {
        alert: {
          x: {
            min: -180,
            max: 180,
          },
          // left + right keys
          z: {
            min: -180,
            max: 180,
          },
          // top + bottom keys
        },
        reset: {
          x: {
            min: -200,
            max: 200,
          },
          // left + right keys
          z: {
            min: -200,
            max: 200,
          },
          // top + bottom keys
        },
        isEnabled: true,
        timeOutIsEnable: null,
      },
      // loader GLTF
      gltf: {
        dracoLoader: null,
        GLTFLoader: null,
        GLFTList: [
          {
            key: "rocket",

            path: "LM/lunar-module--draco.glb",

            material: {
              small: {
                texture: "LM/bake__512.jpg",
                normal: "LM/bake__3--normals__256.jpg",
              },
              large: {
                texture: "LM/bake.jpg",
                normal: "LM/bake__3--normals.jpg",
              },
            },
            position: [0, 0, 0],
            scale: 0.4,
            progress: 0,
            loaded: false,
            isCastShadow: false,
            isEmitShadow: false,
            isAnimated: false,
            isLoadedByLoop: false,
            isMobileFriendly: true,
          },
          {
            key: "rover",

            path: "rover/rover--draco.glb",

            material: {
              small: {
                texture: "rover/baked__256.jpg",
                normal: null,
              },
              large: {
                texture: "rover/baked.jpg",
                normal: null,
              },
            },
            position: [0, 0, 0],
            scale: 0.32,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: true,
            isAnimated: false,
            isLoadedByLoop: true,
            isMobileFriendly: true,
          },

          {
            key: "box",

            path: "moon/details/box--centered.glb",
            material: {
              large: {
                texture: "moon-details/baked-moon-details__512.jpg",
                normal: null,
              },
            },
            position: [0, 0, 0],

            physics: {
              width: 0.8,
              height: 0.6,
              depth: 1.2,
              position: { x: 10, y: 4.5, z: 10 },
              quaternion: -2,
            },
            scale: 0.4,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: false,
            isAnimated: false,
            isLoadedByLoop: false,
            isDetails: true,
            isMobileFriendly: false,
          },
          {
            key: "box",

            path: "moon/details/box--centered.glb",
            material: {
              large: {
                texture: "moon-details/baked-moon-details__512.jpg",
                normal: null,
              },
            },
            position: [0, 0, 0],

            physics: {
              width: 0.8,
              height: 0.6,
              depth: 1.2,
              position: { x: 12, y: 4.5, z: 11 },
              quaternion: 4,
            },
            scale: 0.4,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: false,
            isAnimated: false,
            isLoadedByLoop: false,
            isDetails: true,
            isMobileFriendly: false,
          },
          {
            key: "box",

            path: "moon/details/box--centered.glb",
            material: {
              large: {
                texture: "moon-details/baked-moon-details__512.jpg",
                normal: null,
              },
            },
            position: [0, 0, 0],

            physics: {
              width: 0.8,
              height: 0.6,
              depth: 1.2,
              position: { x: 12.1, y: 5.5, z: 10.9 },
              quaternion: 10,
            },
            scale: 0.4,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: false,
            isAnimated: false,
            isLoadedByLoop: false,
            isDetails: true,
            isMobileFriendly: false,
          },
          {
            key: "antenna",

            path: "moon/details/antenna--centered.glb",
            material: {
              large: {
                texture: "moon-details/baked-moon-details__512.jpg",
                normal: null,
              },
            },
            position: [0, 0, 0],

            physics: {
              width: 1.2,
              height: 1.4,
              depth: 1.2,
              position: { x: 0, y: 4.5, z: 0 },
              quaternion: 0.5,
            },
            scale: 0.4,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: false,
            isAnimated: false,
            isLoadedByLoop: false,
            isDetails: true,
            isMobileFriendly: false,
          },
          {
            key: "camera",

            path: "moon/details/camera--centered.glb",
            material: {
              large: {
                texture: "moon-details/baked-moon-details__512.jpg",
                normal: null,
              },
            },
            position: [0, 0, 0],

            physics: {
              width: 0.2,
              height: 1.3,
              depth: 0.2,
              position: { x: 12, y: 4.5, z: 5 },
              quaternion: -0.5,
            },
            scale: 0.4,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: false,
            isAnimated: false,
            isLoadedByLoop: false,
            isDetails: true,
            isMobileFriendly: false,
          },
        ],
      },
      loader: {
        textureLoader: null,
        intervalProgress: null,
        timeOutStartGame: null,
        totalProgress: 0,
        mapTotalProgress: 0,
      },
      //   meshes
      meshes: {
        vehicule: {
          chassis: null,
          wheelVisuals: [],
          previousRoverPosition: { x: 0, z: 0 },
          isResetting: false,
          timeout: null,
        },
        shadowLM: {
          mesh: null,
        },
        moon: {
          repeatTexture: 15.0,
        },
        cube: {
          mesh: null,
        },
        cube2: {
          mesh: null,
        },
      },
      particles: {
        maxPoints: {
          fire: 100,
        },
        fire: {
          particles: [],
          points: undefined,
          geometry: undefined,
          alphaSpline: null,
          colourSpline: null,
          sizeSpline: null,
          alphaSplineS: null,
          colourSplineS: null,
          sizeSplineS: null,
          alphaSplineF: null,
          colourSplineF: null,
          sizeSplineF: null,
        },
      },

      //   physics
      physics: {
        world: null,
        helper: null,
        cannonDebugRenderer: null,
        isPaused: false,
        joystick: null,
        height: 15.0,
        materials: {
          defaultMaterial: null,
          groundMaterial: null,
          wheelMaterial: null,
          LMMaterial: null,
          detailsMaterial: null,
        },
        objectsToUpdate: [],
        object: {
          vehicle: null,
          chassisBody: null,
          wheelBodies: [],
        },
        startPosition: {
          x: 5,
          y: 6,
          z: 10,
        },
        engineForce: 0.7,
        isEngineRunning: false,
        isEngineRunningBackward: false,

        heightMapMatrixMoon: [],
      },

      alerts: {
        timeoutModal: null,
      },

      gameIsResetTimeout: null,
      gsapAnimation: {
        intro: {
          timeline: null,
          timelineEnd: null,
          isAnimationRunning: true,
        },
      },
      tutorials: {
        isResetStarted: false,
      },
      compass: {
        point: {
          position: new THREE.Vector3(7, 0, 3),
          element: document.querySelector(".compass"),
        },
        size: 35,
      },
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.userDevice.isMobile,
      isGamePaused: (state) => state.sharedGamePlay.isGamePaused,
      isGameControlDisabled: (state) =>
        state.sharedGamePlay.isGameControlDisabled,
      isGamePlayDebug: (state) => state.sharedGamePlay.isGamePlayDebug,
      isGameToReset: (state) => state.sharedGamePlay.isGameToReset,
      isIntroAnimationVisible: (state) =>
        state.sharedGamePlay.isIntroAnimationVisible,
      isTransitionLongEnough: (state) =>
        state.sharedTransition.isTransitionLongEnough,

      isUserDeviceReady: (state) => state.userDevice.isUserDeviceReady,
      userBrowser: (state) => state.userDevice.browser,
    }),
    isGamePlayDebugging() {
      return this.isDevEnv() && this.isGamePlayDebug;
      // return this.isGamePlayDebug; // to debug
    },
    isSafari() {
      return this.userBrowser.includes("safari") || this.isMobile;
    },
    isCompassInvisibleOverwritten() {
      return (
        !this.gsapAnimation.intro.isAnimationRunning &&
        !this.tutorials.isResetStarted
      );
    },
    lengthModelsToLoad() {
      return this.isMobile ? 2 : this.gltf.GLFTList.length;
    },
  },
  watch: {
    isGameToReset(bool) {
      bool ? this.resetGame() : this.displayTutorialAfterReset();
    },
    isUserDeviceReady(bool) {
      bool && !this.initiate.isInitiating ? this.delayInit() : null;
    },
    isGamePaused(bool) {
      bool
        ? (this.playPauseFX("car", "engine", false),
          this.toggleIntroTimeline(!bool),
          this.toggleIsGamePausedOnce(true))
        : this.toggleIntroTimeline(!bool),
        this.isGamePausedOnce && this.isPageFullyLoaded()
          ? (this.nextAnimationFrame(), this.stopEngineAndAudio())
          : null;
    },
    isTransitionLongEnough(bool) {
      bool && this.isPageFullyLoaded() ? this.gameIsReady() : null;
    },
  },

  mounted() {
    this.delayMountedMethods();
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animation);
    this.animation = undefined;

    // reset event listener
    this.detroyAllEventListener();

    // reset all timeout
    this.destroyAllTimeouts();

    // destroy gsap animation
    this.destroyTimeline();

    // reset global variables
    this.resetGamePlayStore();
    this.clearDisplayAndHideTimeOut();
  },
  methods: {
    ////////////////////////////////
    //       START TOGGLE
    ////////////////////////////////

    delayMountedMethods() {
      this.initiate.timeoutMounted = setTimeout(() => {
        this.methodsOnMount();
        this.destroyTimeout(this.initiate.timeoutMounted);
      }, 300);
    },

    methodsOnMount() {
      this.toggleIsGamePausedOnce(false);
      this.setWindowSize();
      this.toggleResetLocally(false);
      this.isUserDeviceReady && !this.initiate.isInitiating
        ? this.delayInit()
        : null;
      // Register an event listener when the Vue component is ready
      window.addEventListener("resize", this.onResize);
      window.addEventListener("keydown", this.keyPressed);
      window.addEventListener("keyup", this.keyPressed);
    },

    /*------------------------------
      Start Reset on mount
    ------------------------------*/

    // gameLoop is pause when game pause, so ensure that gameLoop don't run twince on mount
    toggleIsGamePausedOnce(bool) {
      this.isGamePausedOnce = bool;
    },

    /*------------------------------
      End Reset on mount
    ------------------------------*/

    //   set window width
    setWindowSize() {
      this.cameras.windowUser.width = window.innerWidth;
      this.cameras.windowUser.height = window.innerHeight;
    },

    onResize() {
      // Update sizes
      this.setWindowSize();

      // Update camera
      this.camera ? this.updateCameraOnResize() : null;

      this.renderer ? this.setRenderSize() : null;

      this.effectComposer.composer
        ? this.setEffectComposerRenderTarget()
        : null;

      // add joystick if needed
      this.setJoyStick();

      // set compass size
      this.setCompassSize();
    },

    updateCameraOnResize() {
      this.camera.aspect =
        this.cameras.windowUser.width / this.cameras.windowUser.height;
      this.camera.updateProjectionMatrix();
    },

    setRenderSize() {
      // Update renderer
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },

    setEffectComposerRenderTarget() {
      this.effectComposer.renderTarget = new THREE.WebGLRenderTarget(
        window.innerWidth,
        window.innerHeight,
        {
          minFilter: THREE.LinearFilter,
          magFilter: THREE.LinearFilter,
          format: THREE.RGBAFormat,
          encoding: THREE.sRGBEncoding,
        }
      );
    },

    setCompassSize() {
      this.compass.size = window.innerWidth >= 1080 ? 35 : 23;
    },

    ////////////////////////////////
    //       END ON START METHODS
    ////////////////////////////////

    ////////////////////////////////
    //       START INIT SCENE AND RENDER
    ////////////////////////////////
    delayInit() {
      this.initiate.isInitiating = true;
      this.initiate.timeout = setTimeout(
        () => {
          this.init();
          this.destroyTimeout(this.initiate.timeout);
        },
        this.isMobile ? 2000 : 300
      );
    },
    init() {
      if (this.initiate.isInitStarted) return;
      this.initiate.isInitStarted = true;
      // ensure that user have enough time to read the tutorial
      this.startTimerTransitionIsLongEnough();

      this.setBaseScene();

      this.setLight();

      this.setCamera();
      this.setControl();

      /*------------------------------
      Start add meshes to the scenes
      ------------------------------*/
      this.addPhysics();
      this.loadModels();
      this.addMeshesToScene();
      /*------------------------------
      End add meshes to the scenes
      ------------------------------*/

      // this.meshes.vehicule.wheelVisuals.length
      //   ? this.particleSteps((deltaTime + 0.01) * 1.5)
      //   : null;

      /*------------------------------
      Start add Joytick
      ------------------------------*/
      this.setJoyStick();
      /*------------------------------
      End add Joytick
      ------------------------------*/

      /*------------------------------
      Start Run Progress Loading
      ------------------------------*/
      this.runProgressLoadingPage();
      /*------------------------------
      End Run Progress Loading
      ------------------------------*/
      this.initiate.timeoutGameloop = setTimeout(() => {
        this.clock.then = this.clock.clock.getElapsedTime();

        this.setRenderer();
        this.setPostProcessing();

        // skip animation in debug mode
        this.isGamePlayDebugging ? this.runDebuggedGameMode() : null;

        /*------------------------------
      Start Particles
      ------------------------------*/
        !this.isMobile ? this.setParticleSystem() : null;
        /*------------------------------
      End Particles
      ------------------------------*/

        // set compass size
        this.setCompassSize();

        this.gameLoop();
        this.initiate.isInitiating = false;
        this.destroyTimeout(this.initiate.timeoutGameloop);
      }, 500);
    },

    //======= START BASE THREEJS =======//

    setBaseScene() {
      // set container
      this.container = this.$refs.scene__container;

      // create scene
      this.scene = new THREE.Scene();

      this.isDevEnv() ? this.setHelpers() : "";

      this.setClock();
    },

    setHelpers() {
      // stats
      this.helpers.stats = new Stats();
      document.body.appendChild(this.helpers.stats.dom);

      // axes helpers
      const axesHelper = new THREE.AxesHelper(5);
      // x y z
      this.scene.add(axesHelper);

      // set gui globaly
      this.setGUI();
    },
    setGUI() {
      this.helpers.gui = new GUI();
      const debugObject = {};

      debugObject.reset = () => {
        this.resetGame();
      };
      this.helpers.gui.add(debugObject, "reset");
    },
    setClock() {
      this.clock.clock = new THREE.Clock();
    },

    //======= END BASE THREEJS =======//

    //======= START LIGHTS =======//

    setLight() {
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      this.scene.add(ambientLight);

      this.lights.directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      // this.lights.directionalLight.position.set(-0, 120, -0.5);
      this.lights.directionalLight.position.set(-0, 120, -0);
      this.lights.directionalLight.castShadow = true;
      let d = this.isMobile ? 10 : 50;
      this.lights.directionalLight.shadow.camera.left = -d;
      this.lights.directionalLight.shadow.camera.right = d;
      this.lights.directionalLight.shadow.camera.top = d;
      this.lights.directionalLight.shadow.camera.bottom = -d;

      this.lights.directionalLight.shadow.camera.near = 0;
      this.lights.directionalLight.shadow.camera.far = 150;

      this.lights.directionalLight.shadow.mapSize.x = this.isMobile
        ? 256 / 2
        : 256 * 8;
      this.lights.directionalLight.shadow.mapSize.y = this.isMobile
        ? 256 / 2
        : 256 * 8;
      this.lights.directionalLight.target.position.set(0, 0, 0);
      this.scene.add(this.lights.directionalLight.target);

      this.scene.add(this.lights.directionalLight);

      // this.isDevEnv() ? this.directionLightHelper() : null;
    },
    directionLightHelper() {
      const directionalLightHelper = new THREE.DirectionalLightHelper(
        this.lights.directionalLight,
        0.1
      );
      this.scene.add(directionalLightHelper);
      const directionalLightCameraHelper = new THREE.CameraHelper(
        this.lights.directionalLight.shadow.camera
      );
      directionalLightCameraHelper.visible = true;
      this.scene.add(directionalLightCameraHelper);
    },

    /*------------------------------
    Start lights follow Vehicule
    ------------------------------*/
    updatePositionLight() {
      this.lights.directionalLight.position.set(
        this.physics.objectsToUpdate[0].body.position.x,

        this.physics.objectsToUpdate[0].body.position.y + 10,
        this.physics.objectsToUpdate[0].body.position.z
      );
      this.lights.directionalLight.target.position.set(
        this.physics.objectsToUpdate[0].body.position.x,
        0,
        this.physics.objectsToUpdate[0].body.position.z
      );

      this.moveShadow();
    },
    moveShadow() {
      this.meshes.shadowLM.mesh.position.set(
        this.physics.objectsToUpdate[1].mesh.position.x,
        3.9,
        this.physics.objectsToUpdate[1].mesh.position.z
      );

      this.meshes.shadowLM.mesh.rotation.z = this.physics.objectsToUpdate[1].mesh.rotation.z;
      this.meshes.shadowLM.mesh.rotation.y = this.physics.objectsToUpdate[1].mesh.rotation.y;
    },
    /*------------------------------
    End lights follow Vehicule
    ------------------------------*/

    //======= END LIGHTS =======//

    //======= START CAMERA AND CONTROL =======//

    setCamera() {
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.cameras.windowUser.width / this.cameras.windowUser.height,
        0.1,
        this.isMobile ? 1000 : 2000
      );
      this.camera.position.set(
        this.cameras.defaultCameraPosition.camX,
        this.cameras.defaultCameraPosition.camY,
        this.cameras.defaultCameraPosition.camZ
      );

      // this.camera.lookAt(new THREE.Vector3(0, 0, 0));

      this.scene.add(this.camera);
    },

    setControl() {
      this.controls = new OrbitControls(this.camera, this.container);
      this.controls.enableDamping = true;
    },

    //======= END CAMERA AND CONTROL =======//

    //======= START RENDERER  =======//

    setRenderer() {
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.container,
        powerPreference: "high-performance",
        antialias: !this.isSafari,
      });
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );

      this.setRenderSize();

      this.renderer.outputEncoding = THREE.sRGBEncoding;

      // shadow
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMapSoft = true;
      // https://stackoverflow.com/questions/15140832/what-are-the-possible-shadowmaptypes
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    },

    /*------------------------------
    Start Post Processing
    ------------------------------*/

    setPostProcessing() {
      this.setEffectComposerRenderTarget();

      this.effectComposer.composer = new EffectComposer(
        this.renderer,
        this.effectComposer.renderTarget
      );
      this.effectComposer.composer.setPixelRatio(
        Math.min(window.devicePixelRatio, 2)
      );
      this.effectComposer.composer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );
      this.effectComposer.composer.addPass(
        new RenderPass(this.scene, this.camera)
      );

      this.setGlitchPass();
      this.setFilmPass();
    },

    // set Pass

    setGlitchPass() {
      this.effectComposer.glitchPass.glitchPass = new GlitchPass(64);
      this.effectComposer.glitchPass.glitchPass.renderToScreen = true;
      this.effectComposer.composer.addPass(
        this.effectComposer.glitchPass.glitchPass
      );
      this.effectComposer.glitchPass.glitchPass.enabled = false;
    },
    setFilmPass() {
      this.effectComposer.RGBShiftPass = new ShaderPass(RGBShiftShader);
      this.effectComposer.composer.addPass(this.effectComposer.RGBShiftPass);
      this.effectComposer.RGBShiftPass.enabled = false;

      this.effectComposer.filmPass.filmPass = new FilmPass(
        0.2,
        0.6,
        256,
        false
      );

      this.effectComposer.filmPass.filmPass.renderToScreen = true;
      this.effectComposer.composer.addPass(
        this.effectComposer.filmPass.filmPass
      );
      this.effectComposer.filmPass.filmPass.enabled = false;
    },

    /*------------------------------
    End Post Processing
    ------------------------------*/

    //======= END RENDERER  =======//

    ////////////////////////////////
    //       END INIT SCENE AND RENDER
    ////////////////////////////////

    //======= START GAME LOOP =======//

    gameLoop() {
      // don't display the stats if on prod. Maybe this dev stuff should be handle differently
      this.isDevEnv() ? this.helpers.stats.update() : null;

      const elapsedTime = this.clock.clock.getElapsedTime();
      const deltaTime = elapsedTime - this.clock.oldElapsedTime;
      this.clock.oldElapsedTime = elapsedTime;

      this.isGamePaused || this.alerts.timeoutModal
        ? null
        : this.animatePhysics(deltaTime);

      this.groupedRecudedFPSMethods(elapsedTime);

      // Render
      this.controls.update();

      this.effectComposer.composer.render();

      // Call gameLoop again on the next frame
      this.isGamePaused ? null : this.nextAnimationFrame();
    },
    nextAnimationFrame() {
      this.animation = requestAnimationFrame(this.gameLoop);
    },

    groupedRecudedFPSMethods(elapsedTime) {
      this.reduced10FPS(elapsedTime);
      this.reduced60FPS(elapsedTime);
    },

    reduced60FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 60;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then60FPS;

      if (delta > interval) {
        for (const object of this.physics.objectsToUpdate) {
          object.mesh.position.copy(object.body.position);
          object.mesh.quaternion.copy(object.body.quaternion);
        }

        !this.isMobile ? this.particleSteps(0.05) : null; // animate smokes and dust

        // update position light to update shadow position
        // length 2 because Rover + LEMs
        this.physics.objectsToUpdate.length >= 2 &&
        this.meshes.shadowLM.mesh != null
          ? this.updatePositionLight()
          : null;

        // move camera
        !this.isIntroAnimationVisible && this.physics.objectsToUpdate.length
          ? this.followVehicule()
          : null;

        // if rover is outside the perimeter reset it
        this.physics.objectsToUpdate.length
          ? this.updateCompass(
              this.compass.point,
              this.camera,
              this.physics.objectsToUpdate[0].body.position,
              this.compass.size,
              this.isCompassInvisibleOverwritten
            )
          : null;

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then60FPS = now - (delta % interval);
      }
    },

    reduced10FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 10;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then10FPS;

      if (delta > interval) {
        this.physics.objectsToUpdate.length &&
        this.physics.object.vehicle.wheelInfos.length
          ? (this.resetVehicleIfUpSideDown(), this.isRoverGoesTooFast())
          : null;

        !this.isMobile ? this.addParticleOnMove() : null;

        // this.isDevEnv() ? this.physics.cannonDebugRenderer.update() : null;

        this.physics.objectsToUpdate.length && this.perimeters.isEnabled
          ? (this.manageCarEngine(), this.checkPositionRover())
          : null;

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then10FPS = now - (delta % interval);
      }
    },
    //--- start Rover is Upside down ---//
    resetVehicleIfUpSideDown() {
      this.isVehicleImmobile() &&
      this.isRoverUpsideDown() &&
      !this.meshes.vehicule.isResetting
        ? this.resetUpsideDowVehicle()
        : null;
    },
    isRoverUpsideDown() {
      return (
        this.physics.object.vehicle.wheelInfos[0].worldTransform.position.y +
          this.physics.object.vehicle.wheelInfos[3].worldTransform.position.y >
        this.physics.object.chassisBody.position.y * 2
      );
    },
    isVehicleImmobile() {
      return this.calculateVelocityRover() < 0.01;
    },
    calculateVelocityRover() {
      const velocity = Math.abs(
        (this.physics.objectsToUpdate[0].body.velocity.x +
          this.physics.objectsToUpdate[0].body.velocity.y +
          this.physics.objectsToUpdate[0].body.velocity.z) /
          3
      );
      return velocity;
    },
    resetUpsideDowVehicle() {
      // toggle rover is reseting
      this.toggleIsRoverResetting(true);
      // timeout for a bit
      this.meshes.vehicule.timeout = setTimeout(() => {
        // reset
        this.isVehicleImmobile() && this.isRoverUpsideDown()
          ? this.resetRover(
              this.physics.objectsToUpdate[0].body.position.x,
              this.physics.objectsToUpdate[0].body.position.y + 1.2,
              this.physics.objectsToUpdate[0].body.position.z,
              0,
              0
            )
          : null;

        // allow this method to run again
        this.toggleIsRoverResetting(false);
        // destroy this timepout
        this.destroyTimeout(this.meshes.vehicule.timeout);
      }, 1000);
    },
    toggleIsRoverResetting(bool) {
      this.meshes.vehicule.isResetting = bool;
    },

    //--- end Rover is Upside down ---//

    //--- start rover goes too fast ---//
    isRoverGoesTooFast() {
      this.calculateVelocityRover() >= 2
        ? this.updateRoverEngineSpeed(0)
        : this.updateRoverEngineSpeed(1);
    },
    updateRoverEngineSpeed(speed) {
      this.physics.engineForce === speed
        ? null
        : (this.physics.engineForce = speed);
    },
    //--- end rover goes too fast ---//

    /*------------------------------
    Start add Rover dust using the gameloop
    ------------------------------*/

    animatePhysics(deltaTime) {
      this.physics.world.step(1 / 60, deltaTime, 3);
    },
    /*------------------------------
    End add Rover dust using the gameloop
    ------------------------------*/

    //======= END GAME LOOP =======//

    ////////////////////////////////
    //       START ADD PHYSICS
    ////////////////////////////////
    addPhysics() {
      this.setPhysicsWorld();
      this.setPhysicFLoor();
    },

    //======= START SET PHYSICS WORLD =======//

    setPhysicsWorld() {
      // world
      this.physics.world = new CANNON.World();
      this.physics.world.broadphase = new CANNON.SAPBroadphase(
        this.physics.world
      );
      this.physics.world.allowSleep = true;
      this.physics.world.gravity.set(0, -2, 0); // moon

      // material
      this.physics.materials.groundMaterial = new CANNON.Material(
        "groundMaterial"
      );
      this.physics.materials.groundMaterial = new CANNON.Material(
        "detailsMaterial"
      );
      this.physics.materials.LMMaterial = new CANNON.Material("LMMaterial");
      this.physics.materials.wheelMaterial = new CANNON.Material(
        "wheelMaterial"
      );
      this.physics.materials.detailsMaterial = new CANNON.Material(
        "detailsMaterial"
      );

      const wheelGroundContactMaterial = new CANNON.ContactMaterial(
        this.physics.materials.wheelMaterial,
        this.physics.materials.groundMaterial,
        {
          friction: 0.1,
          restitution: 0,
          contactEquationStiffness: 1000,
        }
      );

      const LMRoverContactMaterial = new CANNON.ContactMaterial(
        this.physics.materials.wheelMaterial,
        this.physics.materials.LMMaterial,
        {
          friction: 0.1,
          restitution: 0,
          contactEquationStiffness: 1000,
        }
      );

      const detailsContactMaterial = new CANNON.ContactMaterial(
        this.physics.materials.groundMaterial,
        this.physics.materials.detailsMaterial,
        {
          friction: 0.8,
          restitution: 0.8,
          contactEquationStiffness: 1000,
        }
      );

      this.physics.world.addContactMaterial(wheelGroundContactMaterial);
      this.physics.world.addContactMaterial(LMRoverContactMaterial);
      this.physics.world.addContactMaterial(detailsContactMaterial);
      // this.physics.world.defaultContactMaterial = wheelGroundContactMaterial;

      this.physics.cannonDebugRenderer = new CannonDebugRenderer(
        this.scene,
        this.physics.world
      );
    },

    //======= END SET PHYSICS WORLD =======//

    //======= START PHYSIC FLOOR =======//

    setPhysicFLoor() {
      const heigtMapImage = new Image();
      heigtMapImage.src = heightmap;

      // load the image first to avoid: Uncaught TypeError: Failed to execute 'drawImage' on
      heigtMapImage.onload = () => {
        // image  has been loaded
        this.getTerrainPixelData(heigtMapImage);
      };
    },

    setDemoPhysicFLoor() {
      const floorShape = new CANNON.Plane();
      const floorBody = new CANNON.Body();
      floorBody.material = this.physics.materials.groundMaterial;
      floorBody.mass = 0;
      floorBody.addShape(floorShape);
      floorBody.quaternion.setFromAxisAngle(
        new CANNON.Vec3(-1, 0, 0),
        Math.PI * 0.5
      );
      this.physics.world.addBody(floorBody);
    },

    // To get the pixels, draw the image onto a canvas. From the canvas get the Pixel (R,G,B,A)
    // https://www.lukaszielinski.de/blog/posts/2014/11/07/webgl-creating-a-landscape-mesh-with-three-dot-js-using-a-png-heightmap/ // https://github.com/lukas2/threejs_landscape
    getTerrainPixelData(heightMapImg) {
      const sizeCanvas = 201;
      var canvas = document.createElement("canvas");

      canvas.width = sizeCanvas;
      canvas.height = sizeCanvas;
      canvas
        .getContext("2d")
        .drawImage(heightMapImg, 0, 0, sizeCanvas, sizeCanvas);

      var data = canvas
        .getContext("2d")
        .getImageData(0, 0, sizeCanvas, sizeCanvas).data;
      var normPixels = [];

      for (var i = 0, n = data.length; i < n; i += 4) {
        // get the average value of R, G and B.
        normPixels.push(
          ((data[i] + data[i + 1] + data[i + 2]) / 3 / 255) *
            this.physics.height
        );
      }

      // Heightfield need an array in a matrix, size
      this.physics.heightMapMatrixMoon = normPixels;
      this.addHeightMapToPhysic(sizeCanvas);
    },
    listToMatrix(list, elementsPerSubArray) {
      var matrix = [],
        i,
        k;
      for (i = 0, k = -1; i < list.length; i++) {
        if (i % elementsPerSubArray === 0) {
          k++;
          matrix[k] = [];
        }
        matrix[k].push(list[i]);
      }
      return matrix;
    },
    addHeightMapToPhysic(sizeCanvas) {
      var body = new CANNON.Body({ mass: 0 });
      body.material = this.physics.materials.groundMaterial;

      var shape = new CANNON.Heightfield(
        this.listToMatrix(this.physics.heightMapMatrixMoon, sizeCanvas),
        { elementSize: 2 }
      );
      var quat = new CANNON.Quaternion(-0.5, 0, 0, 0.5);
      quat.normalize();
      body.addShape(shape, new CANNON.Vec3(), quat);
      body.position.set(-200, 0.1, 200);
      this.physics.world.addBody(body);

      // body.addEventListener("collide", this.collisions);
    },

    //======= END PHYSIC FLOOR =======//

    //======= START PHYSICS GENERATOR =======//

    LMphysicGenerator(position, width, height, depth, mesh) {
      // Cannon.js body
      const shape = new CANNON.Box(
        new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5)
      );

      const body = new CANNON.Body({
        mass: 20,
        position: new CANNON.Vec3(position),
        shape: shape,
        material: this.physics.materials.LMMaterial,
        name: "LM",
      });
      body.position.copy(position);

      this.physics.world.addBody(body);

      var feet_sides = new CANNON.Box(new CANNON.Vec3(0.8, 1.2, 0.5));
      var feet = new CANNON.Box(new CANNON.Vec3(0.5, 1.2, 0.8));
      // sides left right
      body.addShape(
        feet_sides,
        new CANNON.Vec3(-2.6, -1.4, 0),
        new CANNON.Quaternion()
      );
      body.addShape(
        feet_sides,
        new CANNON.Vec3(2.6, -1.4, 0),
        new CANNON.Quaternion()
      );
      // front
      body.addShape(
        feet,
        new CANNON.Vec3(0, -1.4, 2.6),
        new CANNON.Quaternion()
      );
      //back
      body.addShape(
        feet,
        new CANNON.Vec3(0, -1.4, -2.6),
        new CANNON.Quaternion()
      );

      // Save in objects
      this.physics.objectsToUpdate.push({
        mesh: mesh,
        body: body,
      });

      this.gltf.GLFTList.forEach((element, index) => {
        element.isDetails ? this.gltfLoader(element, index) : null;
      });
    },

    collisions(collision) {
      const impactStrength = collision.contact.getImpactVelocityAlongNormal();

      impactStrength > 1
        ? this.playContactAudio(collision.body.material.name, collision.contact)
        : null;
    },
    playContactAudio(collisionName) {
      collisionName === "LMMaterial"
        ? this.stopAndPlaySoundEffect("metal", "fx")
        : this.stopAndPlaySoundEffect("touchDown", "fx");
    },

    //======= END PHYSICS GENERATOR =======//

    //======= START CAR =======//

    setPhysicCar(position, width, height, depth, mesh) {
      const chassisShape = new CANNON.Box(
        new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5)
      );
      this.physics.object.chassisBody = new CANNON.Body({
        mass: 1,
        shape: chassisShape,
        material: this.physics.materials.groundMaterial,
        name: "car",
      });
      this.physics.object.chassisBody.addShape(chassisShape);
      this.physics.object.chassisBody.position.set(
        position.x,
        4.52, // overwrite the value to avoid some issue one init
        position.z
      );
      this.physics.object.chassisBody.angularVelocity.set(0, 0.5, 0);

      const options = {
        radius: 0.4,
        directionLocal: new CANNON.Vec3(0, -1, 0),
        suspensionStiffness: 43,
        suspensionRestLength: 0.4,
        frictionSlip: 8,
        dampingRelaxation: 3,
        dampingCompression: 3.5,
        maxSuspensionForce: 10,
        rollInfluence: 0,
        axleLocal: new CANNON.Vec3(-1, 0, 0),
        chassisConnectionPointLocal: new CANNON.Vec3(1, 0, 1),
        maxSuspensionTravel: 0.6,
        customSlidingRotationalSpeed: 30,
        useCustomSlidingRotationalSpeed: true,
      };

      const antenna = new CANNON.Box(new CANNON.Vec3(0.45, 0.4, 0.3));
      // sides left right
      this.physics.object.chassisBody.addShape(
        antenna,
        new CANNON.Vec3(0.35, 0.9, 1.5),
        new CANNON.Quaternion()
      );

      this.physics.object.chassisBody.addEventListener(
        "collide",
        this.collisions
      );

      // Create the vehicle
      this.physics.object.vehicle = new CANNON.RaycastVehicle({
        chassisBody: this.physics.object.chassisBody,
        indexForwardAxis: 2,
        indexRightAxis: 0,
        indexUpAxis: 1,
      });

      this.physics.objectsToUpdate.push({
        mesh: mesh,
        body: this.physics.object.chassisBody,
        name: "chassisBody",
      });
      // init the camera animation after there is at least one object, but the animation will be trigger somewhere else
      options.chassisConnectionPointLocal.set(0.9, -0.01, 0.88);
      this.physics.object.vehicle.addWheel(options);

      options.chassisConnectionPointLocal.set(-0.9, -0.01, 0.88);
      this.physics.object.vehicle.addWheel(options);

      options.chassisConnectionPointLocal.set(0.9, -0.01, -1.3);
      this.physics.object.vehicle.addWheel(options);

      options.chassisConnectionPointLocal.set(-0.9, -0.01, -1.3);
      this.physics.object.vehicle.addWheel(options);

      this.physics.object.vehicle.addToWorld(this.physics.world);

      this.physics.object.wheelBodies = [];
      this.meshes.vehicule.wheelVisuals = [];

      this.physics.object.vehicle.wheelInfos.forEach((wheel) => {
        const cylinderShape = new CANNON.Cylinder(
          wheel.radius,
          wheel.radius,
          wheel.radius / 2,
          40
        );
        const wheelBody = new CANNON.Body({
          mass: 1,
          material: this.physics.materials.wheelMaterial,
        });
        const q = new CANNON.Quaternion();
        q.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), Math.PI / 2);
        wheelBody.addShape(cylinderShape, new CANNON.Vec3(), q);
        this.physics.object.wheelBodies.push(wheelBody);

        this.physics.object.wheelBodies.length >= 4
          ? (this.loadGLTFWheel(),
            this.initAnimatedCamera(),
            this.gltfLoader(this.gltf.GLFTList[0], 0))
          : null;
      });
    },

    loadGLTFWheel() {
      const bakedWheelTexture = this.requiredLoad("rover/baked_wheels.jpg");
      bakedWheelTexture.flipY = false;
      bakedWheelTexture.encoding = THREE.sRGBEncoding;

      const bakedWheelMaterial = new THREE.MeshStandardMaterial();
      bakedWheelMaterial.map = bakedWheelTexture;

      this.physics.object.wheelBodies.map((d, i) => {
        this.gltf.GLTFLoader.load(
          `/three-assets/rover/rover__wheels--draco.glb`,
          (gltf) => {
            gltf.scene.traverse((child) => {
              child.material = bakedWheelMaterial;
              if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
              }
            });

            gltf.scene.scale.set(0.35, 0.35, 0.35);

            this.meshes.vehicule.wheelVisuals.push(gltf.scene);
            this.scene.add(gltf.scene);
          },
          (xhr) => {
            // progress
            if ((xhr.loaded / xhr.total) * 100 === 100) {
              this.setEventListenerUpdateWheel();
            }
          },
          undefined
        );
      });
    },

    setEventListenerUpdateWheel() {
      this.physics.world.addEventListener("postStep", this.updateWheel);
    },
    removeEventListenerUpdateWheel() {
      this.physics.world.removeEventListener("postStep", this.updateWheel);
    },

    updateWheel() {
      for (var i = 0; i < this.physics.object.vehicle.wheelInfos.length; i++) {
        this.meshes.vehicule.wheelVisuals[i]
          ? this.updateWheelPosition(i)
          : null;
      }
    },
    updateWheelPosition(i) {
      this.physics.object.vehicle.updateWheelTransform(i);
      var t = this.physics.object.vehicle.wheelInfos[i].worldTransform;
      this.physics.object.wheelBodies[i].position.copy(t.position);
      this.meshes.vehicule.wheelVisuals[i].position.copy(t.position);

      this.physics.object.wheelBodies[i].quaternion.copy(t.quaternion);
      this.meshes.vehicule.wheelVisuals[i].quaternion.copy(t.quaternion);
    },

    //======= END CAR =======//

    ////////////////////////////////
    //       END ADD PHYSICS
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MESHES TO SCENE
    ////////////////////////////////

    /*------------------------------
    Start Terrain
    ------------------------------*/
    addMeshesToScene() {
      const grassTexture = this.loadResponsiveTexture(
        "moon/textures/ground_grey_diff_128/ground_grey_diff_128__light.jpg",
        "moon/textures/ground_grey_diff_512/ground_grey_diff_512__light.jpg"
      );
      grassTexture.wrapS = grassTexture.wrapT = THREE.RepeatWrapping;

      const rockyTexture = this.loadResponsiveTexture(
        "moon/textures/ground_grey_diff_128/ground_grey_diff_128__dark.jpg",
        "moon/textures/ground_grey_diff_512/ground_grey_diff_512__dark.jpg"
      );
      rockyTexture.wrapS = rockyTexture.wrapT = THREE.RepeatWrapping;

      const snowyTexture = this.loadResponsiveTexture(
        "moon/textures/ground_grey_diff_128/ground_grey_diff_128.jpg",
        "moon/textures/ground_grey_diff_512/ground_grey_diff_512.jpg"
      );
      snowyTexture.wrapS = snowyTexture.wrapT = THREE.RepeatWrapping;

      const normalmap = this.loadResponsiveTexture(
        "moon/normal/ground_grey_nor_128.jpg",
        "moon/normal/ground_grey_nor_4k.jpg"
      );

      normalmap.wrapS = normalmap.wrapT = THREE.RepeatWrapping;

      const heightMapSize = this.isMobile ? 8 : 16;

      this.addMeshToHeightMap(
        heightmap,
        normalmap,
        [400, 400, 128 * 2, 128 * 2],
        this.meshes.moon.repeatTexture,
        true,
        grassTexture,
        rockyTexture,
        snowyTexture,
        {
          position: [0, 0, 0],
          rotation: Math.PI * 0.5,
        }
      );
      // west + north east + south west
      this.addMeshToHeightMap(
        heightmapWest,
        normalmap,
        [400, 400, heightMapSize, heightMapSize],
        this.meshes.moon.repeatTexture,
        true,
        grassTexture,
        rockyTexture,
        snowyTexture,
        {
          position: [-399.5, 0, 0],
          rotation: Math.PI * 0.5,
        },
        {
          position: [399.5, 0, -399.5],
          rotation: Math.PI * 1.5,
        },
        {
          position: [399.5, 0, 399.5],
          rotation: Math.PI * 2,
        }
      );

      // north West + Est + South West
      this.addMeshToHeightMap(
        heightmapNorthWest,
        normalmap,
        [400, 400, heightMapSize, heightMapSize],
        this.meshes.moon.repeatTexture,
        true,
        grassTexture,
        rockyTexture,
        snowyTexture,
        {
          position: [-399.5, 0, -399.5],
          rotation: Math.PI * 0.5,
        },
        {
          position: [399.5, 0, 0],
          rotation: Math.PI * 0.5,
        },
        {
          position: [-399.5, 0, 399.5],
          rotation: Math.PI * 0.5,
        }
      );

      // noth + south
      this.addMeshToHeightMap(
        heightmapTop,
        normalmap,
        [400, 400, heightMapSize, heightMapSize],
        this.meshes.moon.repeatTexture,
        true,
        grassTexture,
        rockyTexture,
        snowyTexture,
        {
          position: [0, 0, -399.5],
          rotation: Math.PI * 0.5,
        },
        // south
        {
          position: [0, 0, 399.5],
          rotation: Math.PI * 0.5,
        }
      );
    },

    addMeshToHeightMap(
      selectedheigthMap,
      normalmap,
      size,
      textureRepeat,
      receiveShadow,
      grassTexture,
      rockyTexture,
      snowyTexture,
      primaryShader,
      secondaryShader,
      thirdaryShader,
      forthShader,
      fifthShader,
      sithShader,
      seventhShader,
      eigthShader
    ) {
      // https://stemkoski.github.io/Three.js/Shader-Heightmap-Textures.html
      const bumpTexture = this.loader.textureLoader.load(selectedheigthMap);
      bumpTexture.wrapS = bumpTexture.wrapT = THREE.RepeatWrapping;

      const moonGeometry = new THREE.PlaneBufferGeometry(
        size[0],
        size[1],
        size[2],
        size[3]
      );

      // https://github.com/mrdoob/three.js/issues/8016#issuecomment-254371295
      const customUniforms = {
        bumpTexture: { type: "t", value: bumpTexture },
        bumpScale: { type: "f", value: this.physics.height },
        opacity: { type: "f", value: 1.0 },
        grassTexture: { type: "t", value: grassTexture },
        rockyTexture: { type: "t", value: rockyTexture },
        snowyTexture: { type: "t", value: snowyTexture },
        uRepeat: {
          value: textureRepeat,
        },
        normalRepeat: {
          value: this.isMobile ? 0.5 : 8.0,
        },
        normalIntensity: {
          value: this.isMobile ? 0.3 : 0.6,
        },
        normalMap: {
          type: "t",
          value: normalmap,
        },
      };

      var shaderUniforms = THREE.UniformsUtils.clone(
        THREE.UniformsLib["lights"]
      );
      var shaderUniformsNormal = THREE.UniformsUtils.clone(
        THREE.UniformsLib["normalmap"]
      );
      const uniforms = Object.assign(
        shaderUniforms,
        shaderUniformsNormal,
        customUniforms
      );
      const moonMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: moonVertexShader,
        fragmentShader: this.isFragmentForSafariOnly(),
        lights: true,
      });
      // https://stackoverflow.com/questions/11071693/enabling-an-extension-on-a-three-js-shader
      moonMaterial.extensions.derivatives = true;

      const moon = new THREE.Mesh(moonGeometry, moonMaterial);
      moon.rotation.x = -Math.PI * 0.5;
      moon.rotation.z = primaryShader.rotation;
      moon.position.set(
        primaryShader.position[0],
        primaryShader.position[1],
        primaryShader.position[2]
      );

      moon.receiveShadow = receiveShadow;
      this.scene.add(moon);

      secondaryShader
        ? this.cloneShader(
            moon,
            secondaryShader.rotation,
            secondaryShader.position
          )
        : null;

      thirdaryShader
        ? this.cloneShader(
            moon,
            thirdaryShader.rotation,
            thirdaryShader.position
          )
        : null;
      forthShader
        ? this.cloneShader(moon, forthShader.rotation, forthShader.position)
        : null;
      fifthShader
        ? this.cloneShader(moon, fifthShader.rotation, fifthShader.position)
        : null;
      sithShader
        ? this.cloneShader(moon, sithShader.rotation, sithShader.position)
        : null;
      seventhShader
        ? this.cloneShader(moon, seventhShader.rotation, seventhShader.position)
        : null;
      eigthShader
        ? this.cloneShader(moon, eigthShader.rotation, eigthShader.position)
        : null;
    },
    cloneShader(shaderToClome, rotation, position) {
      const clonedShader = shaderToClome.clone();
      clonedShader.rotation.x = -Math.PI * 0.5;
      clonedShader.rotation.z = rotation;
      clonedShader.position.set(position[0], position[1], position[2]);

      clonedShader.receiveShadow = true;
      this.scene.add(clonedShader);

      this.updateMapProgress();
    },

    updateMapProgress() {
      this.loader.mapTotalProgress = this.loader.mapTotalProgress + 1;
    },

    isFragmentForSafariOnly() {
      // const isSafari = window.safari !== undefined;
      // I didnt find yet a solution to fix a bug on Safari so until I find something I created a separated fragement shader
      return this.isSafari ? moonSafariFragment : moonFragmentShader;
    },
    /*------------------------------
    End Terrain
    ------------------------------*/

    ////////////////////////////////
    //       END ADD MESHES
    ////////////////////////////////

    ////////////////////////////////
    //       START MOVE CAMERA
    ////////////////////////////////
    followVehicule() {
      this.camera.position.set(
        this.physics.objectsToUpdate[0].body.position.x - 5,
        this.physics.objectsToUpdate[0].body.position.y + 10,
        this.physics.objectsToUpdate[0].body.position.z + 5
      );

      this.controls.target.set(
        this.physics.objectsToUpdate[0].body.position.x,
        this.physics.objectsToUpdate[0].body.position.y,
        this.physics.objectsToUpdate[0].body.position.z
      );
    },

    ////////////////////////////////
    //       END MOVE CAMERA
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MODELS
    ////////////////////////////////

    loadModels() {
      // this.isMobile ? this.setResponsiveTextures() : null;
      this.setBaseLoader();
      this.textureLoader();
      this.loopGLTFLoader();
    },
    setBaseLoader() {
      // draco always before GLTF Loader
      this.setDraco();
      this.setLoader();
    },

    setDraco() {
      this.gltf.dracoLoader = new DRACOLoader();
      //  path to a folder containing WASM/JS decoding libraries.
      this.gltf.dracoLoader.setDecoderPath("/vendors/draco/"); // path needs be public
      this.gltf.dracoLoader.preload();
    },
    setLoader() {
      // set loader
      this.gltf.GLTFLoader = new GLTFLoader();
      this.gltf.GLTFLoader.setDRACOLoader(this.gltf.dracoLoader);
    },

    ////////////////////////////////
    //       START LOAD TEXTURE
    ////////////////////////////////

    textureLoader() {
      // Texture loader
      this.loader.textureLoader = new THREE.TextureLoader();
    },

    loadResponsiveTexture(mobileTexture, desktopTexture) {
      return this.isMobile
        ? this.requiredLoad(mobileTexture)
        : this.requiredLoad(desktopTexture);
    },
    requiredLoad(path) {
      return this.loader.textureLoader.load(this.requireFile(path));
    },
    requireFile(path) {
      return require(`@/assets/textures/${path}`);
    },
    ////////////////////////////////
    //       END LOAD TEXTURE
    ////////////////////////////////

    loopGLTFLoader() {
      this.gltf.GLFTList.forEach((model, index) => {
        !model.isLoadedByLoop ? null : this.gltfLoader(model, index);
      });
    },

    gltfLoader(model, index) {
      if (this.isMobile && !model.isMobileFriendly) return;
      const childMaterial = this.setMaterial(model.material);

      this.gltf.GLTFLoader.load(
        `/three-assets/${model.path}`,
        (gltf) => {
          gltf.scene.traverse((child) => {
            child.material = childMaterial;
            if (child.isMesh) {
              child.castShadow = model.isCastShadow;
              child.receiveShadow = model.isEmitShadow;
            }
            model.key !== "rover" && model.key !== "rocket"
              ? (child.material.side = THREE.DoubleSide)
              : null;
          });

          this.scene.add(gltf.scene);

          gltf.scene.scale.set(model.scale, model.scale, model.scale);
          model.key === "rover" ? this.setPhysicsCar(gltf.scene) : null;
          model.key === "rocket"
            ? (this.setPhysicsRocket(gltf.scene), this.addFakeLMShadow())
            : null;
          model.physics
            ? this.physicGenerator(
                model.physics.position,
                model.physics.width,
                model.physics.height,
                model.physics.depth,
                gltf.scene,
                model.physics.quaternion
              )
            : null;
        },
        (xhr) => {
          this.GLTFProgressLoader(index, xhr.loaded / xhr.total);
        },
        undefined
      );
    },

    setMaterial(material) {
      const bakedTexture = this.loadResponsiveTexture(
        this.isItemHasResponsiveTexture(material, "texture"),
        material.large.texture
      );

      bakedTexture.flipY = false;
      bakedTexture.encoding = THREE.sRGBEncoding;

      const bakedMaterial = new THREE.MeshStandardMaterial();

      bakedMaterial.map = bakedTexture;

      // can be refacotred or not"
      if (material.normal) {
        const normalScale = this.isMobile ? 0.5 : 4;
        const bakedNormal = this.loadResponsiveTexture(
          this.isItemHasResponsiveTexture(material, "normal"),
          material.large.normal
        );
        bakedNormal.flipY = false;
        bakedNormal.encoding = THREE.sRGBEncoding;
        bakedMaterial.normalMap = bakedNormal;
        bakedMaterial.normalScale = new THREE.Vector2(normalScale, normalScale);
        return bakedMaterial;
      } else {
        return bakedMaterial;
      }
    },
    isItemHasResponsiveTexture(material, textureOrNormal) {
      return material.small
        ? material.small[textureOrNormal]
        : material.large[textureOrNormal];
    },

    setPhysicsCar(gltfScene) {
      const width = 2.2;
      const height = 0.8;
      const depth = 2.9;

      this.setPhysicCar(
        this.physics.startPosition,
        width,
        height,
        depth,
        gltfScene
      );
    },
    setPhysicsRocket(gltfScene) {
      const position = { x: 7, y: 6.7, z: 3 };
      const width = 3;
      const height = 4.2;
      const depth = 3;

      this.LMphysicGenerator(position, width, height, depth, gltfScene);
    },

    // setPhysicsDetails(gltfScene) {
    //   this.physicGenerator(position, width, height, depth, gltfScene);
    // },

    physicGenerator(position, width, height, depth, mesh, quaternion) {
      // Cannon.js body
      const shape = new CANNON.Box(
        new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5)
      );

      const body = new CANNON.Body({
        mass: 0.25,
        position: new CANNON.Vec3(position),

        shape: shape,
        material: this.physics.materials.detailsMaterial,
        name: "Details",
      });

      body.position.copy(position);
      body.quaternion.y = quaternion;

      this.physics.world.addBody(body);

      // Save in objects
      this.physics.objectsToUpdate.push({
        mesh: mesh,
        body: body,
      });
    },
    addFakeLMShadow() {
      // save CPU with a fake shadow of the LEM because it shouldn't move much

      const fakeShadowTexture = this.loadResponsiveTexture(
        "LM/shadow__256.png",
        "LM/shadow.png"
      );
      this.meshes.shadowLM.mesh = new THREE.Mesh(
        new THREE.PlaneGeometry(7.7, 7.7),
        new THREE.MeshStandardMaterial({
          map: fakeShadowTexture,
          transparent: true,
          opacity: 0.7,
        })
      );
      this.meshes.shadowLM.mesh.castShadow = false;
      this.meshes.shadowLM.mesh.receiveShadow = false;
      this.meshes.shadowLM.mesh.rotation.x = -Math.PI * 0.5;
      this.meshes.shadowLM.mesh.position.set(6, 3.9, -1);
      this.scene.add(this.meshes.shadowLM.mesh);
    },

    ////////////////////////////////
    //       END ADD MODELS
    ////////////////////////////////

    ////////////////////////////////
    //       START PARTICLES
    ////////////////////////////////

    //======= START CREATE PARTICLES =======//

    setParticleSystem() {
      /*------------------------------
      Start materials
      ------------------------------*/

      const smokeMaterial = new THREE.ShaderMaterial({
        uniforms: {
          diffuseTexture: {
            value: this.requiredLoad("smoke/smoke.png"),
          },
          pointMultiplier: {
            value:
              (window.innerHeight /
                (2.0 * Math.tan((0.5 * 60.0 * Math.PI) / 180.0))) *
              this.renderer.getPixelRatio(),
          },
        },
        vertexShader: fireVertexShader,
        fragmentShader: fireFragmentShader,
        blending: THREE.CustomBlending,
        blendEquation: THREE.AddEquation,
        blendSrc: THREE.OneFactor,
        blendDst: THREE.OneMinusSrcAlphaFactor,
        depthTest: true,
        depthWrite: false,
        transparent: true,
        vertexColors: true,
      });

      this.particles.fire.particles = [];

      /*------------------------------
      End materials
      ------------------------------*/

      /*------------------------------
      Start particule geometry
      ------------------------------*/

      this.particles.fire.geometry = new THREE.BufferGeometry();

      const defaultBufferGeometryValuesFire = this.setDefaultStartingParticles(
        this.particles.maxPoints.fire
      );
      const defaultBufferGeometryValuesSmoke = this.setDefaultStartingParticles(
        this.particles.maxPoints.smoke
      );

      this.setParticlesAttributes(
        defaultBufferGeometryValuesFire.position,
        defaultBufferGeometryValuesFire.particlePosition,
        defaultBufferGeometryValuesFire.size,
        defaultBufferGeometryValuesFire.colour,
        defaultBufferGeometryValuesFire.angle,
        defaultBufferGeometryValuesFire.blend,
        "fire",
        defaultBufferGeometryValuesFire.uv,
        defaultBufferGeometryValuesFire.normal,
        false
      );

      /*------------------------------
      End particule geometry
      ------------------------------*/

      this.particles.fire.points = new THREE.Mesh(
        this.particles.fire.geometry,
        smokeMaterial
      );

      this.scene.add(this.particles.fire.points);

      const splinesArray = [
        // START FIRE
        {
          particleName: "fire",
          splineName: "alphaSpline",
          pointsArray: [
            [0.0, 0.0],
            [0.2, 1.0],
            [0.5, 1.0],
            [1.0, 0.0],
          ],
          isColour: false,
        },
        {
          particleName: "fire",
          splineName: "colourSpline",
          pointsArray: [
            [0.0, new THREE.Color(0x4d4d4d)],
            [1.0, new THREE.Color(0x202020)],
          ],
          isColour: true,
        },
        {
          particleName: "fire",
          splineName: "sizeSpline",
          pointsArray: [
            [0.0, 0.8],
            [0.5, 1.0],
            [1.0, 0.8],
          ],
          isColour: false,
        },
        // END FIRE
      ];

      splinesArray.forEach((element) => {
        this.addParticulePointForSpline(
          element.particleName,
          element.splineName,
          element.pointsArray,
          element.isColour
        );
      });

      this.updateGeometry("fire");
    },

    setDefaultStartingParticles(maxPoints) {
      return {
        position: new Float32Array(maxPoints * 6),
        particlePosition: new Float32Array(maxPoints * 3 * 6),
        size: new Float32Array(maxPoints * 6),
        colour: new Float32Array(maxPoints * 4 * 6),
        angle: new Float32Array(maxPoints * 6),
        blend: new Float32Array(maxPoints * 6),
        uv: new Float32Array(maxPoints * 6 * 6),
        normal: new Float32Array(maxPoints * 6),
      };
    },

    /*------------------------------
    Start Add points
    ------------------------------*/
    addParticulePointForSpline(
      particuleName,
      splineName,
      arrayOfPoints,
      isColour
    ) {
      isColour
        ? (this.particles[particuleName][splineName] = new LinearSpline(
            (t, a, b) => {
              const c = a.clone();
              return c.lerp(b, t);
            }
          ))
        : (this.particles[particuleName][splineName] = new LinearSpline(
            (t, a, b) => {
              return a + t * (b - a);
            }
          ));

      arrayOfPoints.forEach((points) => {
        this.particles[particuleName][splineName].AddPoint(
          points[0],
          points[1]
        );
      });
    },

    /*------------------------------
    End Add points
    ------------------------------*/

    /*------------------------------
    Start create or udpate particules attributes
    ------------------------------*/
    setParticlesAttributes(
      worldPosition,
      particlePosition,
      sizes,
      colours,
      angles,
      blends,
      particuleName,
      uvs,
      normals,
      isNeedUpdate
    ) {
      const arrayAttributes = [
        { key: "position", vecLength: 3, att: worldPosition },
        { key: "particlePosition", vecLength: 3, att: particlePosition },
        {
          key: "size",
          vecLength: 1,
          att: sizes,
        },
        { key: "colour", vecLength: 4, att: colours },
        { key: "angle", vecLength: 1, att: angles },
        { key: "blend", vecLength: 1, att: blends },
        { key: "uv", vecLength: 3, att: uvs },
        { key: "normal", vecLength: 3, att: normals },
      ];

      arrayAttributes.forEach((element) => {
        isNeedUpdate
          ? (this.particles[particuleName].geometry.setAttribute(
              `${element.key}`,
              new THREE.Float32BufferAttribute(element.att, element.vecLength)
            ),
            (this.particles[particuleName].geometry.attributes[
              element.key
            ].needsUpdate = true),
            (this.particles[particuleName].points.frustumCulled = false),
            this.particles[particuleName].geometry.computeBoundingSphere())
          : this.particles[particuleName].geometry.setAttribute(
              `${element.key}`,
              new THREE.BufferAttribute(element.att, element.vecLength)
            );
      });
    },

    /*------------------------------
    End create or udpate particules attributes
    ------------------------------*/

    //======= END SET PARTICLES ATTRIBUTES =======//

    //======= START ADD PARTICLES =======//

    addParticleOnMove() {
      this.meshes.vehicule.wheelVisuals.length
        ? this.compareRoverPostion(
            this.meshes.vehicule.wheelVisuals[0].position
          )
        : null;
    },
    setPositionRover() {
      this.meshes.vehicule.previousRoverPosition.x = this.meshes.vehicule.wheelVisuals[0].position.x;
      this.meshes.vehicule.previousRoverPosition.z = this.meshes.vehicule.wheelVisuals[0].position.z;
    },
    compareRoverPostion(newRoverPosition) {
      (Math.abs(
        this.meshes.vehicule.previousRoverPosition.x - newRoverPosition.x
      ) >= 0.03 ||
        Math.abs(
          this.meshes.vehicule.previousRoverPosition.z - newRoverPosition.z
        ) >= 0.03) &&
      this.meshes.vehicule.wheelVisuals.length === 4
        ? this.addParticles()
        : null;
      this.setPositionRover();
    },

    addParticles() {
      if (this.particles.fire.particles.length > this.particles.maxPoints.fire)
        return;
      const n = 1;
      //  fire
      for (let i = 0; i < n; i++) {
        this.particles.fire.particles.push(
          this.sharedParticuleGenerator(
            [
              this.meshes.vehicule.wheelVisuals[2].position.x +
                Math.random() * 0.5 * 0.5,
              this.meshes.vehicule.wheelVisuals[2].position.y -
                Math.random() * 0.5 * 0.5,
              this.meshes.vehicule.wheelVisuals[2].position.z +
                Math.random() * 0.5 * 0.5,
            ],
            (Math.random() * 0.5 + 0.5) * 2.0,
            (Math.random() * 0.75 + 0.25) * 15.0,
            Math.random() * 0.5 * Math.PI,
            [0, 0.1, 0],
            1.0,
            "",
            1.0
          ),
          this.sharedParticuleGenerator(
            [
              this.meshes.vehicule.wheelVisuals[3].position.x +
                Math.random() * 0.5 * 0.5,
              this.meshes.vehicule.wheelVisuals[3].position.y -
                Math.random() * 0.5 * 0.5,
              this.meshes.vehicule.wheelVisuals[3].position.z +
                Math.random() * 0.5 * 0.5,
            ],
            (Math.random() * 0.5 + 0.5) * 2.0,
            (Math.random() * 0.75 + 0.25) * 15.0,
            Math.random() * 0.5 * Math.PI,
            [0, 0.1, 0],
            1.0,
            "",
            1.0
          )
        );
      }
    },

    /*------------------------------
    Start particule generator
    ------------------------------*/
    // generate dynamicly the partcile to add
    sharedParticuleGenerator(
      position,
      size,
      life,
      rotation,
      velocity,
      blend,
      splineName,
      originalSize
    ) {
      // https://stackoverflow.com/questions/34215682/wrong-location-of-texture-on-the-second-triangle-of-buffergeometry-square
      return {
        // position: new THREE.Vector3(position[0], position[1], position[2]),
        position: new THREE.Vector3(position[0], position[1], position[2]),
        worldPosition: new Float32Array([
          -originalSize,
          -originalSize,
          0.0,

          originalSize,
          -originalSize,
          0.0,

          originalSize,
          originalSize,
          0.0,

          originalSize,
          originalSize,
          0.0,

          -originalSize,
          originalSize,
          0.0,

          -originalSize,
          -originalSize,
          0.0,
        ]),
        size: size,
        colour: new THREE.Color(),
        alpha: 1.0,
        life: life,
        maxLife: life,
        rotation: rotation,
        velocity: new THREE.Vector3(velocity[0], velocity[1], velocity[2]),
        blend: blend,
        splineName: splineName,
        uvs: new Float32Array(),
      };
    },
    /*------------------------------
    End particule generator
    ------------------------------*/

    //======= END ADD PARTICLES =======//

    updatedValue(particules, index, axis) {
      // return particules[index] ? particules[index].position[axis] : 0;
      return particules[index] ? 0 : 0;
    },
    updateGeometry(particleName) {
      const worldPosition = [];
      const particlesPosition = [];
      const sizes = [];
      const colours = [];
      const angles = [];
      const blends = [];
      const uvs = [];
      const normals = [];

      for (let p of this.particles[particleName].particles) {
        worldPosition.push(
          p.worldPosition[0],
          p.worldPosition[1],
          p.worldPosition[2],

          p.worldPosition[3],
          p.worldPosition[4],
          p.worldPosition[5],

          p.worldPosition[6],
          p.worldPosition[7],
          p.worldPosition[8],

          p.worldPosition[9],
          p.worldPosition[10],
          p.worldPosition[11],

          p.worldPosition[12],
          p.worldPosition[13],
          p.worldPosition[14],

          p.worldPosition[15],
          p.worldPosition[16],
          p.worldPosition[17]
        );

        for (let i = 0; i < 6; i++) {
          particlesPosition.push(p.position.x, p.position.y, p.position.z);
          blends.push(p.blend);
          colours.push(p.colour.r, p.colour.g, p.colour.b, p.alpha);
          sizes.push(p.currentSize);
          angles.push(p.rotation);

          uvs.push(
            -1.0,
            -1.0,
            0.0,

            1.0,
            -1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            -1.0,
            1.0,
            0.0,

            -1.0,
            -1.0,
            0.0
          );

          normals.push(
            -1.0,
            -1.0,
            0.0,

            1.0,
            -1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            -1.0,
            1.0,
            0.0,

            -1.0,
            -1.0,
            0.0
          );
        }
      }
      this.setParticlesAttributes(
        worldPosition,
        particlesPosition,
        sizes,
        colours,
        angles,
        blends,
        particleName,
        uvs,
        normals,
        true
      );
    },

    particleSteps(timeElapsed) {
      // previously used the timeElapsed instead of hard coded value, but it looked really bad when FTS was higher
      this.updateParticles(timeElapsed, "fire");
      this.updateGeometry("fire");
    },
    updateParticles(timeElapsed, particleName) {
      for (let p of this.particles[particleName].particles) {
        p.life -= timeElapsed;
      }

      this.particles[particleName].particles = this.particles[
        particleName
      ].particles.filter((p) => {
        return p.life > 0.0;
      });

      for (let p of this.particles[particleName].particles) {
        const t = 1.0 - p.life / p.maxLife;
        p.rotation += timeElapsed * 0.5;

        p.position.add(p.velocity.clone().multiplyScalar(timeElapsed));

        this.splineSwitcher(p, t, particleName, p.splineName);

        const drag = p.velocity.clone();
        drag.multiplyScalar(timeElapsed * 0.1);
        drag.x =
          Math.sign(p.velocity.x) *
          Math.min(Math.abs(drag.x), Math.abs(p.velocity.x));
        drag.y =
          Math.sign(p.velocity.y) *
          Math.min(Math.abs(drag.y), Math.abs(p.velocity.y));
        drag.z =
          Math.sign(p.velocity.z) *
          Math.min(Math.abs(drag.z), Math.abs(p.velocity.z));
        p.velocity.sub(drag);
      }

      this.particles[particleName].particles.sort((a, b) => {
        const d1 = this.camera.position.distanceTo(a.position);
        const d2 = this.camera.position.distanceTo(b.position);
        if (d1 > d2) {
          return -1;
        }
        if (d1 < d2) {
          return 1;
        }
        return 0;
      });
    },

    //======= START UPDATE SPLINE =======//

    splineSwitcher(p, t, particleName, alphaSpline) {
      return (
        (p.alpha = this.particles[particleName][
          `alphaSpline${alphaSpline}`
        ].Get(t)),
        (p.currentSize =
          p.size *
          this.particles[particleName][`sizeSpline${alphaSpline}`].Get(t)),
        p.colour.copy(
          this.particles[particleName][`colourSpline${alphaSpline}`].Get(t)
        )
      );
    },

    //======= END UPDATE SPLINE =======//
    ////////////////////////////////
    //       END PARTICLES
    ////////////////////////////////

    ////////////////////////////////
    //       START MOVE VEHICULE
    ////////////////////////////////

    keyPressed(keyPressed) {
      if (
        this.isGamePaused ||
        this.isGameControlDisabled ||
        (keyPressed.type !== "keydown" &&
          keyPressed.type !== "keyup" &&
          this.physics.object.vehicle)
      )
        return;

      const keyup = keyPressed.type === "keyup";

      const maxSteerVal = 0.5;

      // if (keyPressed.code === "KeyT") {
      //   //usefull for debug
      //   this.physics.objectsToUpdate[0].body.applyLocalImpulse(
      //     new CANNON.Vec3(0.1, 0, 0),
      //     new CANNON.Vec3(0, 3, 0)
      //   );
      // }
      switch (keyPressed.code) {
        case "ArrowUp": // forward
          this.playOrPauseEngineAudioFX(keyup);

          this.toggleEngineRunning(!keyup);

          keyup ? this.carBreak(0.01, 0.01, 0.01, 0.01) : null;
          break;

        case "ArrowDown": // backward
          this.playOrPauseEngineAudioFX(keyup);

          this.toggleEngineRunningBackward(!keyup);
          // keyup ? this.carBreak(0.1, 0.1, 0.001, 0.001) : null;
          this.physics.engineForce <= 0
            ? this.carBreak(0, 0, 0.01, 0.01)
            : null;
          break;

        case "ArrowRight": // right
          this.physics.object.vehicle.setSteeringValue(
            keyup ? 0 : -maxSteerVal,
            0
          );
          this.physics.object.vehicle.setSteeringValue(
            keyup ? 0 : -maxSteerVal,
            1
          );
          this.physics.engineForce <= 0
            ? this.carBreak(0, 0, 0.001, 0.001)
            : this.carBreak(0, 0, 0, 0);

          break;

        case "ArrowLeft": // left
          this.physics.object.vehicle.setSteeringValue(
            keyup ? 0 : maxSteerVal,
            0
          );
          this.physics.object.vehicle.setSteeringValue(
            keyup ? 0 : maxSteerVal,
            1
          );
          this.physics.engineForce <= 0
            ? this.carBreak(0, 0, 0.001, 0.001)
            : this.carBreak(0, 0, 0, 0);

          break;
        // Leave it there for debug
        // case "KeyQ": // left
        //   this.physics.objectsToUpdate[0].body.applyForce(
        //     new CANNON.Vec3(10, 30, 100)
        //     // new CANNON.Vec3(0, 12, 0)
        //   );

        //   break;
        // case "KeyE": // left
        //   this.physics.objectsToUpdate[0].body.quaternion.w = 0.578564496777852;
        //   this.physics.objectsToUpdate[0].body.quaternion.x = 0.38822751515292303;
        //   this.physics.objectsToUpdate[0].body.quaternion.y = 0.3819902353476233;
        //   this.physics.objectsToUpdate[0].body.quaternion.z = 0.6071501517699722;

        //   break;
      }
    },
    carBreak(breakValue0, breakValue1, breakValue2, breakValue3) {
      this.physics.object.vehicle.setBrake(breakValue0, 0);
      this.physics.object.vehicle.setBrake(breakValue1, 1);
      this.physics.object.vehicle.setBrake(breakValue2, 2);
      this.physics.object.vehicle.setBrake(breakValue3, 3);
    },
    toggleEngineRunning(bool) {
      this.physics.isEngineRunning = bool;
    },
    toggleEngineRunningBackward(bool) {
      this.physics.isEngineRunningBackward = bool;
    },
    manageCarEngine() {
      this.physics.isEngineRunning || this.physics.isEngineRunningBackward
        ? this.runEngine()
        : this.stopEngine();
    },

    runEngine() {
      this.physics.object.vehicle.applyEngineForce(
        this.setDirectionalEngineForce(),
        0
      );
      this.physics.object.vehicle.applyEngineForce(
        this.setDirectionalEngineForce(),
        1
      );
      this.carBreak(0, 0, 0, 0);
    },
    setDirectionalEngineForce() {
      return this.physics.isEngineRunning
        ? -this.physics.engineForce
        : this.physics.engineForce / 2;
    },
    stopEngine() {
      this.physics.object.vehicle.applyEngineForce(0, 0);
      this.physics.object.vehicle.applyEngineForce(0, 1);
    },
    playOrPauseEngineAudioFX(isKeyUnPressed) {
      isKeyUnPressed
        ? this.playPauseFX("car", "engine", false)
        : this.playPauseFX("car", "engine", true);
    },

    //======= START JOYSTICK =======//

    /*------------------------------
    Start Add joystick if user on mobile
    ------------------------------*/
    setJoyStick() {
      this.isJoystickVisibile() ? this.addJoystick() : null;
    },
    isJoystickVisibile() {
      return window.innerWidth <= 640 && !this.physics.joystick;
    },

    addJoystick() {
      this.physics.joystick = new JoyStick({
        game: this,
        onMove: this.userMoveWithJoyStick,
      });
    },
    /*------------------------------
    End Add joystick if user on mobile
    ------------------------------*/

    /*------------------------------
    Start Drive with the joystick
    ------------------------------*/
    userMoveWithJoyStick(forward, turn) {
      const maxSteerVal = 0.5;
      const maxForce = 1;
      const brakeForce = 0.1;

      const force = -maxForce * forward;
      const steer = maxSteerVal * -turn;

      if (forward != 0) {
        this.physics.object.vehicle.setBrake(0, 0);
        this.physics.object.vehicle.setBrake(0, 1);
        this.physics.object.vehicle.setBrake(0, 2);
        this.physics.object.vehicle.setBrake(0, 3);

        this.physics.object.vehicle.applyEngineForce(force, 1);
        this.physics.object.vehicle.applyEngineForce(force, 2);
        this.playOrPauseEngineAudioFX(false);
      } else {
        this.physics.object.vehicle.setBrake(brakeForce, 2);
        this.physics.object.vehicle.setBrake(brakeForce, 3);
        this.playOrPauseEngineAudioFX(true);
      }

      this.physics.object.vehicle.setSteeringValue(steer, 0);
      this.physics.object.vehicle.setSteeringValue(steer, 1);
    },
    /*------------------------------
    End Drive with the joystick
    ------------------------------*/

    //======= END JOYSTICK =======//

    ////////////////////////////////
    //       END MOVE VEHICULE
    ////////////////////////////////

    ////////////////////////////////
    //       START RESET POSITION ROVER
    ////////////////////////////////

    checkPositionRover() {
      this.isRoverOutsideDrivingArea()
        ? (this.displayModalWithDelay(true), this.startResetGlitch())
        : this.alertManagement();
    },

    alertManagement() {
      this.isRoverOutsideMainArea() ? this.startGlitch() : this.removeGlitch();
    },

    isRoverOutsideDrivingArea() {
      return (
        this.physics.objectsToUpdate[0].mesh.position.x <=
          this.perimeters.reset.x.min ||
        this.physics.objectsToUpdate[0].mesh.position.x >=
          this.perimeters.reset.x.max ||
        this.physics.objectsToUpdate[0].mesh.position.z <=
          this.perimeters.reset.z.min ||
        this.physics.objectsToUpdate[0].mesh.position.z >=
          this.perimeters.reset.z.max
      );
    },
    isRoverOutsideMainArea() {
      return (
        this.physics.objectsToUpdate[0].mesh.position.x <=
          this.perimeters.alert.x.min ||
        this.physics.objectsToUpdate[0].mesh.position.x >=
          this.perimeters.alert.x.max ||
        this.physics.objectsToUpdate[0].mesh.position.z <=
          this.perimeters.alert.z.min ||
        this.physics.objectsToUpdate[0].mesh.position.z >=
          this.perimeters.alert.z.max
      );
    },

    ////////////////////////////////
    //       END RESET POSITION ROVER
    ////////////////////////////////

    ////////////////////////////////
    //       START RESET
    ////////////////////////////////

    //======= START DISPLAY RESTART/PAUSE MODAL =======//
    displayModalWithDelay(isRoverOustide) {
      if (
        !this.alerts.timeoutModal &&
        // !this.isGamePaused &&
        !this.gameIsResetTimeout
      ) {
        this.alerts.timeoutModal = setTimeout(() => {
          this.displayDashboardModal(isRoverOustide);
          this.destroyTimeout(this.alerts.timeoutModal);
          this.alerts.timeoutModal = null;
        }, 2000);
      }
    },

    displayDashboardModal() {
      // isRoverOustide is useless but I leave it so I remember later if I want to add other stuff
      this.displayDashboardModalGlobally("out");
    },
    displayDashboardModalGlobally(val) {
      this.$store.commit("dashboardModal/OPEN_MODAL", val);
    },

    //======= END DISPLAY RESTART/PAUSE MODAL =======//

    resetGame() {
      this.enableCheckPositionRover(false);

      this.stopEngineAndAudio();

      this.gameIsResetTimeout = setTimeout(() => {
        // reset position stuff
        this.resetAllPhysics(
          this.physics.startPosition.x,
          this.physics.startPosition.y,
          this.physics.startPosition.z,
          0,
          0
        );

        this.alerts.timeoutModal = null;
        // reset locally (used only to reset the tutorial);
        this.toggleResetLocally(true);

        // reset modal pause
        this.toggleGamePauseGlobally(false);

        // reset composer
        this.resetEffectComposer();

        // reset dust and smoke
        this.resetDust();
        // reset tutorial (reset)
        this.resetTutorial();

        // the first frame will still display previous car position, so disable check position
        this.enableCheckPostionNextFrame();

        this.emitGtag(`Reset_Exploration`, "Reset", "Click");

        this.destroyTimeout(this.gameIsResetTimeout);
        this.gameIsResetTimeout = null;
      }, 500);
    },
    stopEngineAndAudio() {
      this.playOrPauseEngineAudioFX(true);
      this.toggleEngineRunning(false);
      this.carBreak(0.01, 0.01, 0.01, 0.01);
    },

    resetAllPhysics() {
      this.resetRover(
        this.physics.startPosition.x,
        this.physics.startPosition.y,
        this.physics.startPosition.z,
        0,
        0
      );

      this.resetVehicule(
        this.gltf.GLFTList[2].physics.position.x,
        this.gltf.GLFTList[2].physics.position.y,
        this.gltf.GLFTList[2].physics.position.z,
        2,
        this.gltf.GLFTList[3].physics.quaternion
      );
      this.resetVehicule(
        this.gltf.GLFTList[3].physics.position.x,
        this.gltf.GLFTList[3].physics.position.y,
        this.gltf.GLFTList[3].physics.position.z,
        3,
        this.gltf.GLFTList[3].physics.quaternion
      );
      this.resetVehicule(
        this.gltf.GLFTList[4].physics.position.x,
        this.gltf.GLFTList[4].physics.position.y,
        this.gltf.GLFTList[4].physics.position.z,
        4,
        this.gltf.GLFTList[4].physics.quaternion
      );
      this.resetVehicule(
        this.gltf.GLFTList[5].physics.position.x,
        this.gltf.GLFTList[5].physics.position.y,
        this.gltf.GLFTList[5].physics.position.z,
        5,
        this.gltf.GLFTList[5].physics.quaternion
      );
      this.resetVehicule(
        this.gltf.GLFTList[6].physics.position.x,
        this.gltf.GLFTList[6].physics.position.y,
        this.gltf.GLFTList[6].physics.position.z,
        6,
        this.gltf.GLFTList[6].physics.quaternion
      );
    },
    resetRover(x, y, z, index, quaternion) {
      this.resetVehicule(x, y, z, index, quaternion);
    },

    resetEffectComposer() {
      this.updateGlitch(false);
      this.effectComposer.filmPass.filmPass.uniforms.grayscale.value = false;
    },

    resetTutorial() {
      this.hideTutorials();
      this.clearDisplayAndHideTimeOut();
      this.displayJoystickAndReset(false);
    },

    displayTutorialAfterReset() {
      this.tutorials.isResetStarted
        ? (this.displayTutorial(), this.toggleResetLocally(true))
        : null;
    },

    toggleResetLocally(bool) {
      this.tutorials.isResetStarted = bool;
    },

    toggleGamePauseGlobally(bool) {
      this.$store.commit("sharedGamePlay/TOGGLE_GAME_PAUSED", bool);
    },

    resetDust() {
      this.particles.fire.particles = [];
    },

    resetVehicule(x, y, z, index, quaternion) {
      // resetPosition
      // https://github.com/schteppe/cannon.js/issues/215

      this.physics.objectsToUpdate[index].body.position.x = x;
      this.physics.objectsToUpdate[index].body.position.y = y;
      this.physics.objectsToUpdate[index].body.position.z = z;

      // orientation
      this.physics.objectsToUpdate[index].body.quaternion.set(
        0,
        quaternion,
        0,
        1
      );
      this.physics.objectsToUpdate[index].body.initQuaternion.set(0, 0, 0, 1);
      this.physics.objectsToUpdate[index].body.previousQuaternion.set(
        0,
        0,
        0,
        1
      );
      this.physics.objectsToUpdate[index].body.interpolatedQuaternion.set(
        0,
        0,
        0,
        1
      );

      // Velocity
      this.physics.objectsToUpdate[index].body.velocity.setZero();
      this.physics.objectsToUpdate[index].body.initVelocity.setZero();
      this.physics.objectsToUpdate[index].body.angularVelocity.setZero();
      this.physics.objectsToUpdate[index].body.initAngularVelocity.setZero();

      // Force
      this.physics.objectsToUpdate[index].body.force.setZero();
      this.physics.objectsToUpdate[index].body.torque.setZero();

      // Sleep state reset
      this.physics.objectsToUpdate[index].body.sleepState = 0;
      this.physics.objectsToUpdate[index].body.timeLastSleepy = 0;
      this.physics.objectsToUpdate[index].body._wakeUpAfterNarrowphase = false;
    },

    enableCheckPostionNextFrame() {
      this.perimeters.timeOutIsEnable = setTimeout(() => {
        this.enableCheckPositionRover(true);
        this.destroyTimeout(this.perimeters.timeOutIsEnable);
        this.perimeters.timeOutIsEnable = null;
      }, 300);
    },
    enableCheckPositionRover(bool) {
      this.perimeters.isEnabled = bool;
    },

    destroyTimeout(methodName) {
      methodName ? (clearTimeout(methodName), (methodName = null)) : null;
    },

    ////////////////////////////////
    //       END RESET
    ////////////////////////////////

    ////////////////////////////////
    //       START POST PROCESSING GLITCHPASS
    ////////////////////////////////

    // when the user is outside autorized area
    startGlitch() {
      if (this.isGamePaused) return;

      this.effectComposer.glitchPass.isGlitchPassVisible
        ? (this.effectComposer.glitchPass.glitchPass.goWild = false)
        : this.updateGlitch(true);
    },
    updateGlitch(bool) {
      this.effectComposer.glitchPass.glitchPass.enabled = bool;
      this.effectComposer.glitchPass.isGlitchPassVisible = bool;
      this.effectComposer.glitchPass.glitchPass.goWild = bool;
      this.effectComposer.filmPass.filmPass.enabled = bool;
      this.effectComposer.RGBShiftPass.enabled = bool;
    },
    removeGlitch() {
      this.effectComposer.glitchPass.isGlitchPassVisible = false;
      this.effectComposer.glitchPass.glitchPass.goWild = true;
      this.effectComposer.glitchResetTimeOut = setTimeout(() => {
        this.destroyTimeout(this.effectComposer.glitchResetTimeOut);
        this.updateGlitch(false);
      }, 100);
    },

    startResetGlitch() {
      if (this.isGamePaused) return;

      this.updateGlitch(true);
      this.effectComposer.glitchResetTimeOut = setTimeout(() => {
        this.resetGlitchEnd();
        this.alerts.timeoutModal ? this.toggleGamePauseGlobally(true) : null;
      }, 700);
    },

    resetGlitchEnd() {
      this.destroyTimeout(this.effectComposer.glitchResetTimeOut);
      this.effectComposer.glitchPass.glitchPass.goWild = false;
      this.effectComposer.glitchPass.glitchPass.enabled = false;
      this.effectComposer.filmPass.filmPass.uniforms.grayscale.value = true;
    },

    ////////////////////////////////
    //       END POST PROCESSING GLITCHPASS
    ////////////////////////////////

    ////////////////////////////////
    //       START DISPLAY TUTORIAL
    ////////////////////////////////
    displayTutorial() {
      this.displayJoystickAndReset(true);

      const explorationTutorial = {
        isCombinedTutorial: false,
        copy: "arrows",
      };
      this.displayThenHideATutorial(10000, explorationTutorial);
    },
    displayJoystickAndReset(bool) {
      this.toggleDashboardBottom("joystick", bool);
      this.toggleDashboardBottom("reset", bool);
    },
    ////////////////////////////////
    //       END DISPLAY TUTORIAL
    ////////////////////////////////

    ////////////////////////////////
    //       START PROGRESS LOADER
    ////////////////////////////////
    runProgressLoadingPage() {
      // const totalGLTF = this.isMobile ? 2 : this.gltf.GLFTList.length;
      this.loader.intervalProgress = setInterval(() => {
        this.calculateSceneProgressLoader();
        this.$store.commit(
          "sharedTransition/UPDATE_PROGRESS",
          this.calculateCombinedProgress(this.lengthModelsToLoad)
        );

        this.isPageFullyLoaded() ? this.gameIsReady() : null;
      }, 100);
    },
    calculateCombinedProgress(totalGLTF) {
      return (
        (this.loader.totalProgress / totalGLTF +
          this.loader.mapTotalProgress / 5) /
        2
      );
      // I am not sure why only 5 cloned shaders
    },
    isPageFullyLoaded() {
      return this.calculateCombinedProgress(this.lengthModelsToLoad) >= 1;
    },

    gameIsReady() {
      this.isTransitionLongEnough
        ? (this.setCameraPositionPreAnimation(),
          this.resetLoaderManagerInterval(),
          this.toggleIntroTimeline(!this.isGamePaused))
        : // ,this.destroyUselessStates()
          null;
    },

    // destroyUselessStates() {
    //   if (this.isGamePlayDebugging) return;
    //   // this.gltf.GLFTList = []; // leads to issue on reset
    //   this.gltf.GLTFLoader = null;
    // },

    resetLoaderManagerInterval() {
      this.loader.intervalProgress
        ? (clearInterval(this.loader.intervalProgress),
          (this.loader.intervalProgress = null))
        : null;
    },

    calculateSceneProgressLoader() {
      this.gltf.GLFTList.forEach((model) => {
        model.progress !== undefined && !model.isLoaded
          ? this.updateTotalProgress(model.progress)
          : null;

        model.progress === 1 ? (model.isLoaded = true) : null;
      });
    },
    updateTotalProgress(progressToAdd) {
      this.loader.totalProgress = this.loader.totalProgress + progressToAdd;
    },
    GLTFProgressLoader(index, progress) {
      // update this gltf progress
      this.gltf.GLFTList[index].progress = progress;
    },

    ////////////////////////////////
    //       END PROGRESS LOADER
    ////////////////////////////////

    ////////////////////////////////
    //       START INTRO ANIMATION
    ////////////////////////////////
    initAnimatedCamera() {
      this.isIntroAnimationVisible && !this.isGamePlayDebugging
        ? this.iniIntroCameraAnimation()
        : null;
    },

    iniIntroCameraAnimation() {
      // this.setCameraPositionPreAnimation(); // I tough it would be needed for the animation but I was wrong

      const rotationYDuration = 7;

      this.gsapAnimation.intro.timeline = gsap
        .timeline({
          paused: true,
          delay: 1,
          onStart: () => {
            this.initiate.isCompleted = true;
          },
          onComplete: () => {
            this.runEndOfAnimation();
          },
        })
        .to(
          this.controls.target,
          {
            y: this.physics.objectsToUpdate[0].body.position.y,

            ease: "power1.out",
            duration: rotationYDuration,
          },
          "started"
        )
        .to(
          this.camera.position,
          {
            x: 4,
            y: 8,

            ease: "power1.out",
            duration: rotationYDuration,
          },
          "started"
        )
        .add(() => {
          this.stopAndPlaySoundEffect("doYouCopy", "fx");
        }, "-=3");
    },
    setCameraPositionPreAnimation() {
      this.camera.position.set(8, 15, 15);

      this.controls.target.set(
        this.physics.objectsToUpdate[0].body.position.x,
        20,
        this.physics.objectsToUpdate[0].body.position.z
      );
    },

    runEndOfAnimation() {
      const endingDuration = 3 / 2;
      this.gsapAnimation.intro.timelineEnd = gsap
        .timeline({
          paused: false,

          onComplete: () => {
            this.displayTutorial();
            this.$store.commit("sharedGamePlay/TOGGLE_INTRO_ANIMATION", false);
            this.gsapAnimation.intro.isAnimationRunning = false;
          },
        })
        .to(
          this.camera.position,
          {
            x: this.physics.objectsToUpdate[0].body.position.x - 5,
            y: this.physics.objectsToUpdate[0].body.position.y + 10,
            z: this.physics.objectsToUpdate[0].body.position.z + 5,
            ease: "power1.inOut",
            duration: endingDuration,
          },
          "ending"
        )
        .to(
          this.controls.target,
          {
            x: this.physics.objectsToUpdate[0].body.position.x,
            y: this.physics.objectsToUpdate[0].body.position.y,
            z: this.physics.objectsToUpdate[0].body.position.z,
            ease: "power1.inOut",
            duration: endingDuration,
          },
          "ending"
        );
    },
    toggleIntroTimeline(bool) {
      // this is needed if user toggle play pause during the animation
      if (this.gsapAnimation.intro.timeline && !this.isGamePlayDebugging) {
        bool
          ? this.gsapAnimation.intro.timeline.play()
          : this.gsapAnimation.intro.timeline.pause();
      }
    },

    runDebuggedGameMode() {
      this.$store.commit("sharedGamePlay/TOGGLE_INTRO_ANIMATION", false);
      this.displayTutorial();
      this.gsapAnimation.intro.isAnimationRunning = false;
    },

    destroyTimeline() {
      this.gsapAnimation.intro.timeline
        ? (this.gsapAnimation.intro.timeline.kill(),
          (this.gsapAnimation.intro.timeline = null))
        : null;
    },
    ////////////////////////////////
    //       END INTRO ANIMATION
    ////////////////////////////////

    ////////////////////////////////
    //       START BEFORE DESTROY
    ////////////////////////////////

    detroyAllEventListener() {
      this.physics.object.chassisBody.removeEventListener(
        "collide",
        this.collisions
      );
      window.removeEventListener("resize", this.onResize);

      window.removeEventListener("keydown", this.keyPressed);
      window.removeEventListener("keyup", this.keyPressed);
    },

    destroyAllTimeouts() {
      this.destroyTimeout(this.alerts.timeoutModal);
      this.destroyTimeout(this.gameIsResetTimeout);
      this.destroyTimeout(this.initiate.timeout);
      this.destroyTimeout(this.initiate.timeoutGameloop);
      this.destroyTimeout(this.initiate.timeoutMounted);
      this.destroyTimeout(this.effectComposer.glitchResetTimeOut);
      this.destroyTimeout(this.meshes.vehicule.timeout);
      this.destroyTimeout(this.perimeters.timeOutIsEnable);
    },

    ////////////////////////////////
    //       END BEFORE DESTROY
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/config/vars.scss";
@import "@/assets/scss/config/mixins.scss";
@import "@/assets/scss/config/responsive.scss";

.scene {
  background: black;
  img {
    height: 100px;
    width: 100px;
  }
  &__container {
    --opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 0;
    opacity: var(--opacity);
    &--visible {
      --opacity: 1;
    }
  }
}
.dg.ac {
  z-index: 2 !important;
}
</style>
