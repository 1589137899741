<template>
  <div
    class="shared-progress-main"
    :class="{ 'shared-progress-main--black': isBlack }"
  >
    <div class="shared-progress-main__inner">
      <transition v-if="!isIntro" name="slide-success" mode="out-in">
        <shared-progress-main-success v-if="isMissionSuccess" key="success" />
        <shared-progress-main-copy
          v-else
          key="progress"
          :is-black="isBlack"
          :progress-to-display="progressToDisplay"
        />
      </transition>
      <intro v-else />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Intro from "@/components/Intro/Intro.vue";
import SharedProgressMainCopy from "@/components/Shared/SharedProgress/SharedProgressMain/SharedProgressMainCopy";
import SharedProgressMainSuccess from "@/components/Shared/SharedProgress/SharedProgressMain/SharedProgressMainSuccess";
export default {
  components: {
    Intro,
    SharedProgressMainCopy,
    SharedProgressMainSuccess,
  },
  props: {
    isBlack: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      hideProgressTimeOut: null,
    };
  },
  beforeDestroy() {
    this.timeoutDestroyer();
  },
  computed: {
    ...mapState({
      progress: (state) => state.sharedTransition.progress,
      isIntro: (state) => state.sharedTransition.isIntro,
      isMissionSuccess: (state) => state.sharedTransition.isMissionSuccess,
      progressToDisplay: (state) => state.sharedTransition.progressToDisplay,
      isTransitionLongEnough: (state) =>
        state.sharedTransition.isTransitionLongEnough,
    }),
  },
  watch: {
    progress() {
      return this.isTransitionCanEnd();
    },
    isTransitionLongEnough() {
      return this.isTransitionCanEnd();
    },
  },
  methods: {
    isTransitionCanEnd() {
      this.progress >= 1 && this.isTransitionLongEnough
        ? this.startScene()
        : null;
    },
    startScene() {
      this.$store.commit("sharedTransition/TOGGLE_SCENE", true);

      this.hideProgressTimeOut = setTimeout(() => {
        this.hideCurrentPage();
        this.timeoutDestroyer();
      }, 500);
    },
    hideCurrentPage() {
      this.$store.commit("sharedTransition/TOGGLE_TRANSITION", false);
      this.$store.commit("sharedTransition/RESET_PROGRESS");
    },
    timeoutDestroyer() {
      this.hideProgressTimeOut
        ? (clearTimeout(this.hideProgressTimeOut),
          (this.hideProgressTimeOut = null))
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-progress-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--color-gallery);
  &--black {
    color: var(--color-ebony);
  }

  &__inner {
    @include verticalGrid;
    text-align: center;
  }
}
</style>
