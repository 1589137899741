<template>
  <li class="intro-location">
    <VueTextTransition tag="h2" name="fade" :show="isVisible" :interval="40">{{
      $t("intro.where")
    }}</VueTextTransition>
  </li>
</template>

<script>
import VueTextTransition from "vue-text-transition";
export default {
  components: {
    VueTextTransition,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-location {
  h2 {
    overflow: hidden;
  }
}
</style>
