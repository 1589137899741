<template>
  <div ref="navigationToggleMain" class="navigation-toggle-main">
    <div class="navigation-toggle-main__inner">
      <!-- column left -->
      <navigation-toggle-main-side :is-left="true">
        <navigation-toggle-main-side-list
          :list="sidesLinks.left"
          :is-left="true"
        />
      </navigation-toggle-main-side>
      <!-- column center -->
      <navigation-toggle-main-center
        :is-circle-visible="isCircleVisible"
        :is-buttons-visible="isButtonsVisible"
      />

      <!-- column right -->
      <navigation-toggle-main-side :is-left="false">
        <navigation-toggle-main-side-list
          :list="sidesLinks.right"
          :is-left="false"
        />
        <navigation-toggle-main-side-legal :is-mobile="true" />
      </navigation-toggle-main-side>
    </div>
  </div>
</template>

<script>
import NavigationToggleMainCenter from "@/components/Navigation/navigationToggle/NavigationToggleMain/NavigationToggleMainCenter/NavigationToggleMainCenter";
import NavigationToggleMainSide from "@/components/Navigation/navigationToggle/NavigationToggleMain/NavigationToggleMainSide/NavigationToggleMainSide";
import NavigationToggleMainSideLegal from "@/components/Navigation/navigationToggle/NavigationToggleMain/NavigationToggleMainSide/NavigationToggleMainSideLegal";
import NavigationToggleMainSideList from "@/components/Navigation/navigationToggle/NavigationToggleMain/NavigationToggleMainSide/NavigationToggleMainSideList/NavigationToggleMainSideList";
export default {
  components: {
    NavigationToggleMainCenter,

    NavigationToggleMainSide,
    NavigationToggleMainSideLegal,
    NavigationToggleMainSideList,
  },

  props: {
    isNavOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCircleVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    isButtonsVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    isNavOpen(bool) {
      bool ? this.scrollToTop() : null;
    },
  },
  data() {
    return {
      sidesLinks: {
        left: {
          mobile: ["contact", "credits", "privacy"],
          desktop: ["contact"],
        },
        right: {
          mobile: [],
          desktop: ["credits", "privacy"],
        },
      },
    };
  },
  methods: {
    scrollToTop() {
      this.$refs.navigationToggleMain.scrollTo({ top: 0 });
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-toggle-main {
  position: fixed;
  /* hide this page when navitation is closed */

  z-index: -1;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  overflow-y: scroll;
  @include teq-breakpoint($medium) {
    overflow-y: visible;
  }

  .navigation-toggle--open & {
    z-index: 12;
    opacity: 1;
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100vw;
    @include teq-breakpoint($medium) {
      flex-direction: row;
    }
  }
}
</style>
