<template>
  <div class="shared-btn-svg shared-btn-base">
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- square -->
      <clipPath id="shared-btn-svg__square-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50 10H10V50H50V10ZM9 9V51H51V9H9Z"
          fill="#E8E8F2"
        />
      </clipPath>
      <path
        clip-path="url(#shared-btn-svg__square-path)"
        class="shared-btn-svg__square-path"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
        d="M50 10H10V50H50V10ZM9 9V51H51V9H9Z"
      />

      <!-- circle -->
      <clipPath id="shared-btn-svg__circle-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30 50C41.0457 50 50 41.0457 50 30C50 18.9543 41.0457 10 30 10C18.9543 10 10 18.9543 10 30C10 41.0457 18.9543 50 30 50ZM30 51C41.598 51 51 41.598 51 30C51 18.402 41.598 9 30 9C18.402 9 9 18.402 9 30C9 41.598 18.402 51 30 51Z"
        />
      </clipPath>
      <path
        clip-path="url(#shared-btn-svg__circle-path)"
        class="shared-btn-svg__circle-path"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
        d="M30 50C41.0457 50 50 41.0457 50 30C50 18.9543 41.0457 10 30 10C18.9543 10 10 18.9543 10 30C10 41.0457 18.9543 50 30 50ZM30 51C41.598 51 51 41.598 51 30C51 18.402 41.598 9 30 9C18.402 9 9 18.402 9 30C9 41.598 18.402 51 30 51Z"
      />
      <!-- line -->
      <clipPath id="shared-btn-svg__line-1-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M49.9999 51.0001L8.99992 10.0001L9.70703 9.29297L50.707 50.293L49.9999 51.0001Z"
        />
      </clipPath>
      <path
        clip-path="url(#shared-btn-svg__line-1-path)"
        class="shared-btn-svg__line-1-path"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
        d="M49.9999 51.0001L8.99992 10.0001L9.70703 9.29297L50.707 50.293L49.9999 51.0001Z"
      />
      <!-- line -->
      <clipPath id="shared-btn-svg__line-2-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M51.0001 10.0001L10.0001 51.0001L9.29297 50.293L50.293 9.29297L51.0001 10.0001Z"
        />
      </clipPath>
      <path
        clip-path="url(#shared-btn-svg__line-2-path)"
        class="shared-btn-svg__line-2-path"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
        d="M51.0001 10.0001L10.0001 51.0001L9.29297 50.293L50.293 9.29297L51.0001 10.0001Z"
      />
      <!-- yellow circle -->
      <clipPath id="shared-btn-svg__yellow-circle">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30 59C46.0163 59 59 46.0163 59 30C59 13.9837 46.0163 1 30 1C13.9837 1 1 13.9837 1 30C1 46.0163 13.9837 59 30 59ZM30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
        />
      </clipPath>
      <path
        clip-path="url(#shared-btn-svg__yellow-circle)"
        class="shared-btn-svg__yellow-circle"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
        d="M30 59C46.0163 59 59 46.0163 59 30C59 13.9837 46.0163 1 30 1C13.9837 1 1 13.9837 1 30C1 46.0163 13.9837 59 30 59ZM30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.shared-btn-svg {
  @include buttonAnimation;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60px;
  .app--mobile & {
    svg {
      padding: 1px;
    }
  }
}
</style>
