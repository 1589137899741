<template>
  <div class="shared-joystick-svg">
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- yellow circle -->
      <clipPath id="shared-joystick-svg__yellow-circle">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M65 129C100.346 129 129 100.346 129 65C129 29.6538 100.346 1 65 1C29.6538 1 1 29.6538 1 65C1 100.346 29.6538 129 65 129ZM65 130C100.899 130 130 100.899 130 65C130 29.1015 100.899 0 65 0C29.1015 0 0 29.1015 0 65C0 100.899 29.1015 130 65 130Z"
        />
      </clipPath>

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        clip-path="url(#shared-joystick-svg__yellow-circle)"
        class="shared-joystick-svg__yellow-circle"
        fill="none"
        stroke-miterlimit="2"
        stroke-width="2"
        d="M65 129C100.346 129 129 100.346 129 65C129 29.6538 100.346 1 65 1C29.6538 1 1 29.6538 1 65C1 100.346 29.6538 129 65 129ZM65 130C100.899 130 130 100.899 130 65C130 29.1015 100.899 0 65 0C29.1015 0 0 29.1015 0 65C0 100.899 29.1015 130 65 130Z"
      />

      <!-- rect -->
      <clipPath id="shared-joystick-svg__square-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.029 19.9855H19.9814V110.015H109.029V19.9855ZM19 19V111H110.011V19H19Z"
        />
      </clipPath>

      <path
        clip-path="url(#shared-joystick-svg__square-path)"
        class="shared-joystick-svg__square-path"
        stroke-miterlimit="2"
        stroke-width="2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M109.029 19.9855H19.9814V110.015H109.029V19.9855ZM19 19V111H110.011V19H19Z"
      />

      <!-- grey circle -->
      <clipPath id="shared-joystick-svg__circle-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M64.5 110.015C89.0808 110.015 109.019 89.8671 109.019 65C109.019 40.1329 89.0808 19.9855 64.5 19.9855C39.9192 19.9855 19.9813 40.1329 19.9813 65C19.9813 89.8671 39.9192 110.015 64.5 110.015ZM64.5 111C89.629 111 110 90.4051 110 65C110 39.5949 89.629 19 64.5 19C39.371 19 19 39.5949 19 65C19 90.4051 39.371 111 64.5 111Z"
        />
      </clipPath>
      <path
        clip-path="url(#shared-joystick-svg__circle-path)"
        class="shared-joystick-svg__circle-path"
        fill="none"
        stroke-miterlimit="2"
        stroke-width="2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64.5 110.015C89.0808 110.015 109.019 89.8671 109.019 65C109.019 40.1329 89.0808 19.9855 64.5 19.9855C39.9192 19.9855 19.9813 40.1329 19.9813 65C19.9813 89.8671 39.9192 110.015 64.5 110.015ZM64.5 111C89.629 111 110 90.4051 110 65C110 39.5949 89.629 19 64.5 19C39.371 19 19 39.5949 19 65C19 90.4051 39.371 111 64.5 111Z"
      />

      <!-- lines -->
      <clipPath id="shared-joystick-svg__line-1-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.759 111.203L18.9998 19.7036L19.6997 19L110.458 110.5L109.759 111.203Z"
        />
      </clipPath>

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        clip-path="url(#shared-joystick-svg__line-1-path)"
        class="shared-joystick-svg__line-1-path"
        fill="none"
        stroke-miterlimit="2"
        stroke-width="2"
        d="M109.759 111.203L18.9998 19.7036L19.6997 19L110.458 110.5L109.759 111.203Z"
      />

      <clipPath id="shared-joystick-svg__line-2-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M110.233 19.7314L19.6994 110.873L19 110.168L109.534 19.0273L110.233 19.7314Z"
        />
      </clipPath>

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        clip-path="url(#shared-joystick-svg__line-2-path)"
        class="shared-joystick-svg__line-2-path"
        fill="none"
        stroke-miterlimit="2"
        stroke-width="2"
        d="M110.233 19.7314L19.6994 110.873L19 110.168L109.534 19.0273L110.233 19.7314Z"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.shared-joystick-svg {
  @include buttonAnimation;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
</style>
