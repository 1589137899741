<template>
  <div v-if="isActive" class="dashboard-warning-reset">
    <shared-button-circle
      :is-white="false"
      :is-black="false"
      icon-name="reset"
      :rotation="0"
      :is-animation-running="isAnimationStarted"
      :aria-label="$t('shared.restart.ariaLabel')"
      @clicked="clicked"
    />
  </div>
</template>

<script>
import pauseGameMixin from "@/mixins/pauseGameMixin";
import SharedButtonCircle from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircle";
export default {
  mixins: [pauseGameMixin],
  components: {
    SharedButtonCircle,
  },
  props: {
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      timeOut: {
        timeOut: null,
      },
      isAnimationStarted: false,
    };
  },
  watch: {
    isActive(bool) {
      this.clearTimeOut();
      bool ? this.startAnimation() : this.toggleAnimation(false);
    },
  },

  methods: {
    clicked() {
      this.toggleGamePause(true);
      // this.$store.commit("sharedGamePlay/TOGGLE_GAME_RESET", true); // debug
    },
    ////////////////////////////////
    //       START ANIMATION
    ////////////////////////////////
    startAnimation() {
      this.toggleAnimation(false);
      this.timeOut.timeOut = setTimeout(() => {
        this.toggleAnimation(true);
        this.clearTimeOut();
      }, 300);
    },
    toggleAnimation(bool) {
      this.isAnimationStarted = bool;
    },
    clearTimeOut() {
      this.timeOut.timeOut
        ? (clearTimeout(this.timeOut.timeOut), (this.timeOut.timeOut = null))
        : null;
    },
    ////////////////////////////////
    //       END ANIMATION
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.dashboard-warning-reset {
  position: absolute;
  width: 100%;
  bottom: calc(0px - 6rem - 2rem);
  display: flex;
  justify-content: center;
}
</style>
