<template>
  <section class="shared-progress-main-success">
    <!-- next -->
    <h2>{{ $t("shared.transition.success.mission") }}</h2>
    <!-- title -->
    <h1>{{ $t("shared.transition.success.completed") }}</h1>
  </section>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      this.hideSuccessTransition();
    });
  },
  methods: {
    hideSuccessTransition() {
      setTimeout(() => {
        this.$store.commit("sharedTransition/TOGGLE_SUCCESS_MISSION", false);
      }, 2500);
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-progress-main-success {
  h2 {
    @include marginBetweenTitles;
  }
}
</style>
