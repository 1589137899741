<template>
  <div class="dashboard-warning-main-content">
    <div class="dashboard-warning-main-content__inner">
      <!-- title -->
      <h3>{{ copy.title }}</h3>
      <!-- copy -->
      <p v-if="copy.copy" class="dashboard-warning-main-content__inner__copy">
        {{ copy.copy }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    copy: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-warning-main-content {
  opacity: 1;
  display: flex;
  flex: 1;
  padding: 1.8rem 4.2rem;
  @include teq-breakpoint($small) {
    padding: 1.4rem 6.6rem;
  }
  @include teq-breakpoint($large) {
    padding: 2.5rem 8rem;
  }

  &__inner {
    // @include transition(0.3s ease-in-out all);

    display: flex;
    flex: 1;
    padding: 0.5rem;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    h3 {
    }
    &__copy {
      padding-top: 0.3rem;
      color: $goldenrod;
      line-height: 1;
    }
  }
}
</style>
