// initial state
const state = () => ({
  isMobile: false,
  browser: false,
  isUserDeviceReady: false,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  SET_USER_DEVICE(state, deviceValues) {
    state.isMobile = deviceValues.isMobile;
    state.browser = deviceValues.browser;
    // you should load page content such as Threejs scene after this page was loaded
    state.isUserDeviceReady = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
