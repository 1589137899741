<template>
  <shared-audio-player
    v-if="audioSources.length"
    class="shared-audio"
    :class="[`shared-audio--${whichAudio}`]"
    :sources="audioSources"
    :loop="isLoop"
    :is-playing="isPlaying"
    :volume-interval="volumeInterval"
    :is-audio-source-updated="isAudioSourceUpdated"
    :current-track="currentTrack"
    :up-coming-track="upComingTrack"
    :is-stopped="isStopped"
    :max-volume="maxVolume"
    :which-audio="whichAudio"
    @resetAudioSourceIsUpdated="fadeInAudio(false)"
  />
</template>

<script>
// import { mapState } from "vuex";

import SharedAudioPlayer from "@/components/Shared/SharedAudio/SharedAudioPlayer";
export default {
  components: {
    SharedAudioPlayer,
  },
  props: {
    whichAudio: {
      type: String,
      required: false,
      default: "music",
    },
    isPlaying: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentTrack: {
      type: String,
      required: false,
      default: null,
    },
    upComingTrack: {
      type: String,
      required: false,
      default: null,
    },
    defaultTrack: {
      type: String,
      required: false,
      default: null,
    },
    isLoop: {
      type: Boolean,
      required: false,
      default: false,
    },
    volumeInterval: {
      type: Number,
      required: false,
      default: 50,
    },
    isStopped: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxVolume: {
      type: Number,
      required: false,
      default: 1,
    },
  },

  //   data() {
  //     return {
  //       audioSources: ["assets/audio/mock-alexander-nakarada-superepic.mp3"],
  //     };
  //   },

  data() {
    return {
      //   audioSources: [],
      audioSources: [],
      isAudioSourceUpdated: false,
    };
  },
  // computed: {
  //   ...mapState({
  //     isMute: (state) => state.sharedAudio.isMute,
  //   }),
  // },
  watch: {
    currentTrack(val) {
      // console.log("shared audio", val);
      val !== this.audioSources[0] ? this.updateTrack() : null;
    },
    // isStopped(bool) {
    //   bool ? this.stop() : null;
    // },
  },

  methods: {
    updateTrack() {
      this.clearAudio();
      this.updateAudio(this.isDefaultTrack());
      this.toggleStopIfFX();
    },
    toggleStopIfFX() {
      this.whichAudio === "fx" ? this.stopOrPlaySoundEffect("fx", false) : null;
    },
    stopOrPlaySoundEffect(whichAudio, bool) {
      this.$store.commit("sharedAudio/toggleStopSoundEffect", {
        whichAudio: whichAudio,
        bool: bool,
      });
    },
    // clear audio
    clearAudio() {
      this.audioSources = [];
    },
    isDefaultTrack() {
      return this.currentTrack ? this.currentTrack : this.defaultMusicTrack;
    },
    // addAudio
    updateAudio(url) {
      // console.log("updateAudio", url);
      this.addAudio(url);
      !this.currentTrack ? null : this.fadeInAudio(true);
    },
    addAudio(url) {
      this.audioSources.push(`assets/audio/${url}`);
    },
    fadeInAudio(bool) {
      this.isAudioSourceUpdated = bool;
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-audio {
  &--music {
    // bottom: 0;
    background-color: green;
  }

  &--engine {
    background-color: yellow;
  }
  &--fx {
    // bottom: 20px;
    background-color: blue;
  }
}
</style>
