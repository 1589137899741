export default {
  methods: {
    emitGtag(action, category, label) {
      return this.$gtag.event(
        action, //The value that will appear as the event action in Google Analytics Event reports.
        {
          event_category: category, //
          event_label: label, //The label of the event.
          value: 1, //A non-negative integer that will appear as the vent value.
        }
      );
    },
    humanizeBoolean(bool) {
      return !bool ? "ON" : "OFF";
    },
  },
};
