<template>
  <div class="home">
    <transition name="fade-progress">
      <intro-video v-if="isVisible" />
    </transition>
    <home-page />
  </div>
</template>

<script>
import { mapState } from "vuex";
import IntroVideo from "@/components/IntroVideo/IntroVideo";
import HomePage from "@/components/HomePage/HomePage";
export default {
  metaInfo: {
    title: "The Leap For Mankind",
  },
  name: "Home",
  mixins: [],
  components: {
    IntroVideo,
    HomePage,
  },

  beforeDestroy() {},

  computed: {
    ...mapState({
      isVisible: (state) => state.intro.isVisible,
    }),
  },
  methods: {
    resetIntroLoad() {
      this.$store.dispatch("home/UPDATE_INTRO_LOAD", 0);
      this.$store.dispatch("home/UPDATE_BACKGROUND_LOAD", 0);
    },
  },
};
</script>
