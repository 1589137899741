<template>
  <div class="dashboard-tutorial">
    <transition name="slide-tutorial">
      <div v-if="isTutorialVisible" class="dashboard-tutorial__inner">
        <div class="dashboard-tutorial__inner__tutorial">
          <tutorial-combined
            v-if="isCombinedTutorial"
            :copy="copyTutorial()"
            :is-white="false"
            :is-black="false"
          />
          <tutorial-block
            v-else
            :copy="copyTutorial()"
            :icons="pathCopyTutorial"
            :is-white="false"
            :is-black="false"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TutorialBlock from "@/components/Tutorial/TutorialBlock/TutorialBlock";
import TutorialCombined from "@/components/Tutorial/TutorialCombined/TutorialCombined";
export default {
  components: {
    TutorialBlock,
    TutorialCombined,
  },
  props: {
    sceneName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      isTutorialVisible: (state) => state.dashboardTutorial.isTutorialVisible,
      isCombinedTutorial: (state) => state.dashboardTutorial.isCombinedTutorial,
      pathCopyTutorial: (state) => state.dashboardTutorial.pathCopyTutorial,
    }),
  },
  methods: {
    copyTutorial() {
      return this.isCombinedTutorial
        ? this.$t(`shared.tutorial.${this.sceneName}`)
        : this.$t(
            `shared.tutorial.${this.sceneName}.${this.pathCopyTutorial}.desktop`
          );
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-tutorial {
  --display-tutorial: none;
  display: var(--display-tutorial);

  .app--mobile & {
    --display-tutorial: none;
  }

  @include teq-breakpoint($small) {
    --display-tutorial: flex;
  }

  &__inner {
    &__tutorial {
    }
  }
}
</style>
