// initial state
const state = () => ({
  // display alert panel
  isTutorialVisible: false,
  isCombinedTutorial: false,
  pathCopyTutorial: null,
});

// getters
const getters = {};

// actions
const actions = {
  //   DISPLAY_TUTORIAL_TEMPORARY(store, val) {
  //     store.commit("DISPLAY_TUTORIAL", val);
  //     setTimeout(() => {
  //       store.commit("HIDE_TUTORIAL");
  //     }, 1000);
  //   },
  HIDE_TUTORIAL(store) {
    store.commit("SET_TUTORIAL_INVISIBLE");
    setTimeout(() => {
      store.commit("RESET_TUTORIAL");
    }, 300);
  },
};

// mutations
const mutations = {
  DISPLAY_TUTORIAL(state, val) {
    state.isCombinedTutorial = val.isCombinedTutorial;
    state.pathCopyTutorial = val.copy;
    state.isTutorialVisible = true;
  },
  SET_TUTORIAL_INVISIBLE(state) {
    state.isTutorialVisible = false;
  },
  RESET_TUTORIAL(state) {
    state.isTutorialVisible = false;
    state.isCombinedTutorial = false;
    state.pathCopyTutorial = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
