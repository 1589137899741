<template>
  <div class="tutorial-arrows">
    <!-- arrows -->
    <ul
      class="tutorial-arrows__list"
      :class="{ 'tutorial-arrows__list--single': arrowsDirection.length === 1 }"
    >
      <li v-for="(arrow, index) in arrowsDirection" :key="index">
        <shared-button-squares
          :is-white="isWhite"
          :is-black="isBlack"
          icon-name="arrow"
          :rotation="arrow"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import SharedButtonSquares from "@/components/Shared/SharedButton/SharedButtonSquares/SharedButtonSquares";
export default {
  components: {
    SharedButtonSquares,
  },
  props: {
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    arrowsDirection: {
      type: Array,
      required: false,
      default: () => [270, 180, 90, 0],
    },
  },
  data() {
    return {
      // arrowDirection: [270, 180, 90, 0],
    };
  },
};
</script>

<style lang="scss" scoped>
.tutorial-arrows {
  position: relative;

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-gap: $x-small-margin;
    @include teq-breakpoint($large) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    &--single {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-gap: 0px;
    }
    li {
      grid-row: 1/2;
      @include teq-breakpoint($large) {
        grid-row: 2/3;
      }
      &:nth-child(1) {
        grid-row: 1/2;
        @include teq-breakpoint($large) {
          grid-row: 1/2;
          grid-column: 2/3;
        }
      }
      &:nth-child(2) {
        grid-column: 2/3;
        @include teq-breakpoint($large) {
          grid-column: 1/2;
        }
      }
      &:nth-child(3) {
        grid-column: 3/4;
        @include teq-breakpoint($large) {
          grid-column: 2/3;
        }
      }
      &:nth-child(4) {
        grid-column: 4/5;
        @include teq-breakpoint($large) {
          grid-column: 3/4;
        }
      }
    }
  }
}
</style>
