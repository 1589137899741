<template>
  <section class="privacy-main">
    <ul class="privacy-main__list">
      <li v-for="(copy, index) in $t('pages.privacy.copy')" :key="index">
        <p>{{ copy.copy }}</p>
      </li>
    </ul>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.privacy-main {
  @include sharedLayoutMain;
  display: flex;
  flex-direction: column;
  color: var(--color-gallery);
  li {
    margin-bottom: $small-margin;
  }
}
</style>
