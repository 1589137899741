<template>
  <section class="scene-space">
    <!-- <p v-if="camera" class="scene-space__infos" style="color: red"></p>

    <button class="scene-space__button" @click="toggleEndGalleryTimeline()">
      end gallery
    </button>
    <button class="scene-space__button" @click="setGoToNextCameraTimeline()">
      next
    </button> -->
    <canvas ref="sceneSpace__container" class="scene-space__container"></canvas>
  </section>
</template>

<script>
import { mapState } from "vuex";
import isDevMixin from "@/mixins/isDevMixin";
import pauseGameMixin from "@/mixins/pauseGameMixin";
import resetGamePlayStoreMixin from "@/mixins/resetGamePlayStoreMixin";
import transitionMixin from "@/mixins/transitionMixin.js";

import { gsap } from "gsap";

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

// STATS
import Stats from "stats.js";
// import { GUI } from "@/vendors/dat.gui.module.js";

// VERTEX
import fragment from "@/shaders/earth/fragment.glsl";
import vertex from "@/shaders/earth/vertex.glsl";

import fragmentGlow from "@/shaders/earth/glow/fragment.glsl";
import vertexGlow from "@/shaders/earth/glow/vertex.glsl";

export default {
  mixins: [
    isDevMixin,
    pauseGameMixin,
    resetGamePlayStoreMixin,
    transitionMixin,
  ],
  components: {},
  props: {
    galleryProgress: {
      type: Number,
      required: false,
      default: 0,
    },
    isEndOfGallery: {
      type: Boolean,
      required: false,
      default: false,
    },
    isGalleryAnimationRunning: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapState({
      isNavOpen: (state) => state.navigation.isNavOpen,
      isGamePaused: (state) => state.sharedGamePlay.isGamePaused,
      isGamePlayDebug: (state) => state.sharedGamePlay.isGamePlayDebug,
      isTransitionLongEnough: (state) =>
        state.sharedTransition.isTransitionLongEnough,

      isMobile: (state) => state.userDevice.isMobile,
      isSceneReady: (state) => state.sharedTransition.isSceneReady,
      isUserDeviceReady: (state) => state.userDevice.isUserDeviceReady,
      userBrowser: (state) => state.userDevice.browser,
      isUserGoingToNextPage: (state) => state.gallery.isUserGoingToNextPage,
    }),
    totalProgress() {
      return (
        (this.galleryProgress +
          this.loader.loaderManager.textures.progress +
          this.loader.loaderManager.intro.progress +
          this.loader.loaderManager.models.GLTFLunarModule.progress +
          this.loader.loaderManager.models.GLTFLunarModule.progress) /
        5
      );
    },

    isGamePlayDebugging() {
      return this.isDevEnv() && this.isGamePlayDebug;
    },
    isSafariOrMobile() {
      return this.userBrowser.includes("safari") || this.isMobile;
    },
  },
  watch: {
    isEndOfGallery(val) {
      this.toggleEndGalleryIsStarted(!val);
      this.toggleEndGalleryTimeline();
    },
    isNavOpen(bool) {
      this.runGame(!bool);
      bool
        ? this.toggleIntroTimeline(!bool)
        : (this.runIntroTimelineFromNavOpen(),
          !this.initiate.isInitiating ? this.nextAnimationFrame() : null);
    },
    isSceneReady() {
      this.startAnimation();
    },
    isSceneReadyLocally() {
      this.startAnimation();
    },
    isTransitionLongEnough() {
      this.startAnimation();
    },

    isUserDeviceReady(bool) {
      bool && this.totalProgress >= 1 ? this.delayInit() : null;
    },
    isUserGoingToNextPage(bool) {
      bool ? this.setGoToNextCameraTimeline() : null;
    },
    isGalleryAnimationRunning(bool) {
      this.runGalleryAnimation(bool);
    },
  },

  data() {
    return {
      // base
      container: null,
      scene: null,
      camera: null,
      controls: null,
      animation: undefined,

      isSceneReadyLocally: false,

      //  helper
      helpers: {
        stats: null,
      },

      initiate: {
        isInitStarted: false,
        isInitiating: false,
        timeout: null,
        timeoutGameloop: null,
        timeoutMounted: null,
      },

      clock: {
        clock: null,
        then60FPS: null,
      },

      // loaders
      gltf: {
        GLTFLoader: null,
        rotationY: 135.7,
        position: {
          x: 0,
          y: 81,
          z: 138,
        },
      },
      GLTFServiceModel: undefined,
      GLTFLunarModule: undefined,
      loader: {
        isSceneReady: false,
        textureLoader: null,
        loaderManager: {
          textures: { progress: 0 },
          intro: { progress: 0 },
          models: {
            GLTFServiceModel: {
              progress: 0,
            },
            GLTFLunarModule: {
              progress: 0,
            },
          },
        },
      },

      meshes: {
        moon: {
          position: {
            desktop: {
              // adjust adjustMoonPosition() with + 100
              x: 200 + 100,
              y: 70,
              z: 200 + 100,
              euler: 180,
            },
            mobile: {
              // adjust adjustMoonPosition() with + 150
              x: 150 + 150,
              y: 70,
              z: 150 + 150,
              euler: 205,
            },
          },
          mesh: {
            mesh: null,
            material: null,
          },
        },
        earth: {
          mesh: {
            mesh: null,
          },
        },
        clouds: {
          mesh: {
            mesh: null,
          },
        },
        glow: {
          mesh: {
            mesh: null,
          },
        },
        stars: {
          mesh: {
            material: [],
          },
        },
        animationClouds: {
          meshes: [
            {
              key: "cloud1",
              texture: {
                large: {
                  texture: "gallery/clouds/resized/rescale/gallery_cloud1.png",
                },
                small: {
                  texture:
                    "gallery/clouds/resized/rescale/gallery_cloud1__256.png",
                },
              },
              mesh: null,
              geometry: null,
              material: null,
              position: [59, 57, -59.22],
              scale: 10,
              rotate: 0,
              color: 0xffffff,
              opacity: 1.0,
              clones: [
                {
                  position: [59, 58, -59],
                  scale: 3,
                  rotate: 0,
                  color: 0xffffff,
                  opacity: 1.0,
                  mesh: null,
                },
                {
                  position: [61, 57, -58],
                  scale: 5,
                  rotate: 0,
                  color: 0xffffff,
                  opacity: 1.0,
                  mesh: null,
                },
              ],
            },
          ],
          timeOut: null,
        },
      },

      cameras: {
        default: {
          position: {
            x: 103,
            y: 83,
            z: -105,
          },
          target: {
            x: 0,
            y: 60,
            z: 0,
          },
          model: {
            position: {
              x: 0,
              y: 81,
              z: 13,
            },
          },
        },
        start: {
          position: {
            desktop: {
              x: 60.86,
              y: 56.71,
              z: -60.22,
            },
            // duplicate value for this.responsiveCameraPosition on resize
            mobile: {
              x: 60.86,
              y: 56.71,
              z: -60.22,
            },
          },
          target: {
            desktop: {
              x: -33.36,
              y: 127.36,
              z: 64.96,
            },
            // duplicate value for this.responsiveCameraPosition on resize
            mobile: {
              x: -33.36,
              y: 127.36,
              z: 64.96,
            },
          },

          // gallery position
          // position: {
          //   desktop: {
          //     x: 103 - 20,
          //     y: 83,
          //     z: -105 - 20,
          //   },
          //   mobile: {
          //     x: 110 - 20,
          //     y: 83,
          //     z: -112 - 20,
          //   },
          // },
          // target: {
          //   desktop: {
          //     x: 0 - 120,
          //     y: 60,
          //     z: 0 - 120,
          //   },
          //   mobile: {
          //     x: 0 - 80,
          //     y: 60,
          //     z: 0 - 80,
          //   },
          // },

          model: {
            position: {
              x: 0,
              y: 81,
              z: 138,
            },
          },
        },

        final: {
          position: {
            desktop: {
              x: 103,
              y: 83,
              z: -105,
            },
            mobile: {
              x: 110,
              y: 84,
              z: -112,
            },
          },
          target: {
            desktop: {
              x: 0,
              y: 60,
              z: 0,
            },
            mobile: {
              x: 0,
              y: 60,
              z: 0,
            },
          },
          model: {
            position: {
              x: 0,
              y: 81,
              z: 13,
            },
          },
        },
        endGallery: {
          position: {
            desktop: {
              x: 103 - 20,
              y: 83,
              z: -105 - 20,
            },
            mobile: {
              x: 110 - 20,
              y: 83,
              z: -112 - 20,
            },
          },
          target: {
            desktop: {
              x: 0 - 120,
              y: 60,
              z: 0 - 120,
            },
            mobile: {
              x: 0 - 80,
              y: 60,
              z: 0 - 80,
            },
          },
          model: {
            position: {
              x: 0,
              y: 81,
              z: 13,
            },
          },
        },
        goToNext: {
          // position: {
          //   desktop: {
          //     x: 103 - 100,
          //     y: 83,
          //     z: -105 - 30,
          //   },
          //   mobile: {
          //     x: 110 - 110,
          //     y: 83,
          //     z: -112 - 30,
          //   },
          // },
          target: {
            desktop: {
              x: 0 - 200,
              y: 60 - 0,
              z: 0 - 200,
            },
            mobile: {
              x: 0 - 100,
              y: 60 + 10,
              z: 0 - 100,
            },
          },
        },

        // position: {
        //   x: 103 - 100,
        //   y: 83,
        //   z: -105 - 100,
        // },
        // target: {
        //   x: 0 - 900,
        //   y: 60,
        //   z: 0 - 900,
        // },
        windowUser: {
          width: 0,
          height: 0,
          isSafariAndMobile: false,
          isInnerWidthDesktop: false,
        },
      },
      gsapAnimation: {
        intro: {
          timeline: null,
          // duration: 1,
          duration: 5,

          isAnimationStarted: false,
          isAnimationEnded: false,
        },
        gallery: {
          timeline: null,
        },
        endGallery: {
          timeline: null,
          isStarted: false,
          duration: 3,
        },
        goToNext: {
          timeline: null,
          isAnimationStarted: false,
        },
      },
      loaderManager: {
        intervalProgress: null,
      },
    };
  },
  mounted() {
    this.toggleTransitionIsLongEnough(false);
    this.toggleIsSceneReadyLocally(false);

    this.delayMountedMethods();
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animation);
    this.animation = undefined;
    window.removeEventListener("resize", this.onResize);
    this.resetLoaderManagerInterval();
    this.destroyAllGSAPAnimations();
    this.destroyTimeout(this.initiate.timeoutMounted);
    this.resetGamePlayStore();
  },
  methods: {
    ////////////////////////////////
    //       START ON START METHODS
    ////////////////////////////////

    delayMountedMethods() {
      this.initiate.timeoutMounted = setTimeout(() => {
        this.methodsOnMount();
        this.destroyTimeout(this.initiate.timeoutMounted);
      }, 300);
    },
    methodsOnMount() {
      this.toggleIsGamePausedOnce(false);
      this.setWindowSize();
      this.setInnerWidthDesktopState();
      this.isGamePlayDebugging ? this.setDebugAnimationSpeed() : null;
      !this.initiate.isInitiating ? this.delayInit() : null;
      // Register an event listener when the Vue component is ready
      window.addEventListener("resize", this.onResize);
    },

    toggleIsSceneReadyLocally(bool) {
      this.isSceneReadyLocally = bool;
    },
    //   set window width
    setWindowSize() {
      this.cameras.windowUser.width = window.innerWidth;

      this.cameras.windowUser.height = this.isDesktop()
        ? window.innerHeight
        : window.screen.height;
    },
    setInnerWidthDesktopState() {
      this.cameras.windowUser.isInnerWidthDesktop = this.isDesktop();
    },

    // gameLoop is pause when game pause, so ensure that gameLoop don't run twince on mount
    toggleIsGamePausedOnce(bool) {
      this.isGamePausedOnce = bool;
    },

    //======= START USER USING SAFARI OR ON MOBILE =======//

    setDebugAnimationSpeed() {
      this.gsapAnimation.intro.duration = 2;
      this.gsapAnimation.endGallery.duration = 1;
    },

    //======= END USER USING SAFARI OR ON MOBILE =======//

    onResize() {
      // Update sizes
      this.setWindowSize();

      // Update camera
      this.camera ? this.updateCameraOnResize() : null;

      this.renderer ? this.upRendererOnResize() : null;

      this.loader.isSceneReady && this.isInnerWidthNewer()
        ? this.cameraAndAnimationResize()
        : null;
      this.loader.isSceneReady ? this.onResizeUpdatePositionMoon() : null;
    },

    updateCameraOnResize() {
      this.camera.aspect =
        this.cameras.windowUser.width / this.cameras.windowUser.height;
      this.camera.updateProjectionMatrix();
    },

    upRendererOnResize() {
      // Update renderer
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );

      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },

    isInnerWidthNewer() {
      return this.cameras.windowUser.isInnerWidthDesktop != this.isDesktop();
    },

    ////////////////////////////////
    //       END ON START METHODS
    ////////////////////////////////

    ////////////////////////////////
    //       START UPDATE CAMERA ON RESIZE
    ////////////////////////////////

    cameraAndAnimationResize() {
      this.setInnerWidthDesktopState();

      if (this.gsapAnimation.endGallery.isStarted) {
        return this.restartEndGalleryAnimation();
      } else if (
        (this.loader.isSceneReady &&
          this.gsapAnimation.intro.isAnimationEnded) ||
        this.gsapAnimation.goToNext.isAnimationStarted
      ) {
        return this.upCameraPositionOnResize();
      } else {
        return this.restartIntroAnimation();
      }
    },

    restartEndGalleryAnimation() {
      this.resetEndGalleryAnimation();
      this.upCameraPositionOnResize();
      this.startEndOfGallery();
    },
    resetEndGalleryAnimation() {
      this.killTimeLine("endGallery", "timeline");
      this.setCameraToRightTimeline();
      this.toggleEndGalleryIsStarted(false);
    },

    restartIntroAnimation() {
      this.destroyTimeout(this.meshes.animationClouds.timeOut);
      this.killTimeLine("intro", "timeline");
      this.upCameraPositionOnResize();
      this.startAnimation();
    },

    killTimeLine(animationName, timeline) {
      this.gsapAnimation[animationName][timeline]
        ? (this.gsapAnimation[animationName][timeline].kill(),
          (this.gsapAnimation[animationName][timeline] = null))
        : null;
    },

    upCameraPositionOnResize() {
      // update camera position
      this.updateCamera();

      // update moon position
      this.updateMoonPosition();

      this.resetEndGalleryAnimation();
    },
    updateCamera() {
      this.updateCameraPosition();
      this.updateCameraTarget();
    },
    updateCameraPosition() {
      this.camera.position.set(
        this.responsiveCameraPosition(
          "x",
          this.returnNamePositionCamera(),
          "position"
        ),
        this.responsiveCameraPosition(
          "y",
          this.returnNamePositionCamera(),
          "position"
        ),
        this.responsiveCameraPosition(
          "z",
          this.returnNamePositionCamera(),
          "position"
        )
      );
    },
    updateCameraTarget() {
      this.controls.target.set(
        this.responsiveCameraPosition(
          "x",
          this.returnNamePositionCamera(),
          "target"
        ),
        this.responsiveCameraPosition(
          "y",
          this.returnNamePositionCamera(),
          "target"
        ),
        this.responsiveCameraPosition(
          "z",
          this.returnNamePositionCamera(),
          "target"
        )
      );
    },
    returnNamePositionCamera() {
      if (!this.gsapAnimation.intro.isAnimationEnded) {
        return "start";
      } else if (this.gsapAnimation.goToNext.isAnimationStarted) {
        return "goToNext";
      } else {
        return "final";
      }
    },

    updateMoonPosition() {
      this.meshes.moon.mesh.mesh.position.set(
        this.responsiveMeshPosition("moon", "x"),
        70,
        this.responsiveMeshPosition("moon", "z")
      );
      this.meshes.moon.mesh.mesh.position.applyEuler(
        this.calculateEuler(this.responsiveMeshPosition("moon", "euler"))
      );
    },

    ////////////////////////////////
    //       END UPDATE CAMERA ON RESIZE
    ////////////////////////////////

    ////////////////////////////////
    //       START INIT SCENE AND RENDER
    ////////////////////////////////
    delayInit() {
      this.initiate.isInitiating = true;
      this.initiate.timeout = setTimeout(() => {
        this.init();
        this.destroyTimeout(this.initiate.timeout);
      }, 2000);
    },

    init() {
      if (this.initiate.isInitStarted) return;
      this.initiate.isInitStarted = true;

      this.runProgressLoadingPage();

      this.setBaseScene();

      this.setLight();

      this.setCamera();
      this.setControl();
      this.setCameraPosition();

      this.setRenderer();

      /*------------------------------
      Start add meshes to the scenes
      ------------------------------*/
      this.textureLoader();
      this.addMeshesToScene();

      // set gui globaly
      // this.setGUI();
      /*------------------------------
      End add meshes to the scenes
      ------------------------------*/
      this.initiate.isInitiating = false;
      this.gameLoop();
    },

    //======= START GAME LOOP =======//

    gameLoop() {
      // don't display the stats if on prod. Maybe this dev stuff should be handle differently
      this.isDevEnv() ? this.helpers.stats.update() : "";

      const elapsedTime = this.clock.clock.getElapsedTime();

      this.reduced60FPS(elapsedTime);
      // Render
      this.controls.update();

      this.renderer.render(this.scene, this.camera);

      // Call gameLoop again on the next frame
      this.isNavOpen ? null : this.nextAnimationFrame();
    },
    nextAnimationFrame() {
      this.animation = requestAnimationFrame(this.gameLoop);
    },
    reduced60FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 60;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then60FPS;

      if (delta > interval) {
        this.meshes.earth.mesh.mesh.rotation.y -= 0.0005;
        this.meshes.clouds.mesh.mesh.rotation.y -= 0.0006;

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then60FPS = now - (delta % interval);
      }
    },

    //======= END GAME LOOP =======//

    //======= START BASE THREEJS =======//

    setBaseScene() {
      // set container
      this.container = this.$refs.sceneSpace__container;

      // create scene
      this.scene = new THREE.Scene();

      this.isDevEnv() ? this.setHelpers() : "";

      this.setClock();
    },

    setHelpers() {
      // stats
      this.helpers.stats = new Stats();
      document.body.appendChild(this.helpers.stats.dom);

      // axes helpers
      const axesHelper = new THREE.AxesHelper(5);
      // x y z
      this.scene.add(axesHelper);
    },
    // setGUI() {
    //   // this.helpers.gui = new GUI();
    //   //  const debugObject = {};
    //   // this.helpers.gui
    //   //   .add(this.meshes.glow.mesh.mesh.material.uniforms.c, "value")
    //   //   .min(0)
    //   //   .max(1)
    //   //   .step(0.01);
    //   // this.helpers.gui
    //   //   .add(this.meshes.glow.mesh.mesh.material.uniforms.p, "value")
    //   //   .min(0)
    //   //   .max(10)
    //   //   .step(0.01);
    // },
    setClock() {
      this.clock.clock = new THREE.Clock();
    },

    //======= END BASE THREEJS =======//

    //======= START LIGHTS =======//

    setLight() {
      const ambientLight = new THREE.AmbientLight(0xffffff, 3);
      this.scene.add(ambientLight);
    },

    //======= END LIGHTS =======//

    //======= START CAMERA AND CONTROL =======//

    setCamera() {
      this.camera = new THREE.PerspectiveCamera(
        40,
        this.cameras.windowUser.width / this.cameras.windowUser.height,
        0.1,
        3000
      );

      // this.camera.rotation.x = 0;
      // this.camera.lookAt(new THREE.Vector3(0, 0, 0));

      this.scene.add(this.camera);

      // this.camera.rotateZ(Math.PI / 4);
    },
    setCameraPosition() {
      this.camera.position.set(
        this.cameras.default.position.x,
        this.cameras.default.position.y,
        this.cameras.default.position.z
      );

      this.controls.target.x = this.cameras.default.target.x;
      this.controls.target.y = this.cameras.default.target.y;
      this.controls.target.z = this.cameras.default.target.z;
    },

    setControl() {
      this.controls = new OrbitControls(this.camera, this.container);
      this.controls.enableDamping = true;
    },

    //======= END CAMERA AND CONTROL =======//

    //======= START RENDERER  =======//

    setRenderer() {
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.container,
        powerPreference: "high-performance",
        antialias: !this.isSafariOrMobile,
      });
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );

      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },

    //======= END RENDERER  =======//

    ////////////////////////////////
    //       END INIT SCENE AND RENDER
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MESHES TO SCENE
    ////////////////////////////////

    //======= START LOAD MANAGER =======//

    textureLoader() {
      const loadingManager = new THREE.LoadingManager();

      loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
        this.loader.loaderManager.textures.progress = itemsLoaded / itemsTotal;
      };
      loadingManager.onError = () => {
        console.log("texture loading error");
      };

      // Texture loader

      this.loader.textureLoader = new THREE.TextureLoader(loadingManager);
    },

    loadResponsiveTexture(mobileTexture, desktopTexture) {
      return this.isMobile
        ? this.requiredLoad(mobileTexture)
        : this.requiredLoad(desktopTexture);
    },
    requiredLoad(path) {
      return this.loader.textureLoader.load(this.requireFile(path));
    },
    requireFile(path) {
      return require(`@/assets/textures/${path}`);
    },

    //======= END LOAD MANAGER =======//

    addMeshesToScene() {
      this.addEarthMeshes();
      this.addModels();
    },

    //======= START EARTH =======//

    addEarthMeshes() {
      this.addEarth();
      this.addClouds();
      this.addGlow();
      this.addMoon();
      !this.isMobile ? this.addStarField() : null;
      this.addAnimationClouds();
    },

    addEarth() {
      const uniforms = {
        sunDirection: { value: new THREE.Vector3(-1, 0.25, 0.25) },
        dayTexture: {
          value: this.loadResponsiveTexture(
            "gallery/space/earth/2048/earth.jpg",
            "gallery/space/earth/8192/earth.jpg"
          ),
        },
        nightTexture: {
          value: this.loadResponsiveTexture(
            "gallery/space/earth/2048/earth-night.jpg",
            "gallery/space/earth/8192/earth-night.jpg"
          ),
        },
        opacity: {
          value: 1.0,
        },
      };

      const material = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertex,
        fragmentShader: fragment,
        // transparent: true,
      });

      this.meshes.earth.mesh.mesh = new THREE.Mesh(
        new THREE.SphereGeometry(100, 32 * 2, 32 * 2),
        material
      );
      this.scene.add(this.meshes.earth.mesh.mesh);
      this.meshes.earth.mesh.mesh.rotation.y = 2.5;
    },

    addClouds() {
      const uniformsClouds = {
        sunDirection: { value: new THREE.Vector3(-1, 0.25, 0.25) },

        dayTexture: {
          value: this.loadResponsiveTexture(
            "gallery/space/earth/2048/earth_clouds.jpg",
            "gallery/space/earth/8192/earth_clouds.jpg"
          ),
        },
        nightTexture: {
          value: this.loadResponsiveTexture(
            "gallery/space/earth/2048/earth_clouds_night.jpg",
            "gallery/space/earth/8192/earth_clouds_night.jpg"
          ),
        },
        opacity: {
          value: 1.0,
        },
      };

      const materialClouds = new THREE.ShaderMaterial({
        uniforms: uniformsClouds,
        vertexShader: vertex,
        fragmentShader: fragment,
        blending: THREE.AdditiveBlending,
        depthTest: 0,
        side: THREE.FrontSide,
        // transparent: true,
      });

      this.meshes.clouds.mesh.mesh = new THREE.Mesh(
        new THREE.SphereGeometry(100.1, 32 * 2, 32 * 2),
        materialClouds
      );

      this.scene.add(this.meshes.clouds.mesh.mesh);
    },

    addGlow() {
      var sphereGeom = new THREE.SphereGeometry(101, 32, 32);

      var customMaterial = new THREE.ShaderMaterial({
        uniforms: {
          c: { type: "f", value: 1 },
          p: { type: "f", value: 4.31 },
          glowColor: { type: "c", value: new THREE.Color(0x4983d7) },
          viewVector: { type: "v3", value: this.camera.position },
        },
        vertexShader: vertexGlow,
        fragmentShader: fragmentGlow,
        side: THREE.BackSide,
        blending: THREE.AdditiveBlending,
        transparent: true,
      });

      this.meshes.glow.mesh.mesh = new THREE.Mesh(
        sphereGeom.clone(),
        customMaterial.clone()
      );
      this.meshes.glow.mesh.mesh.position.x = 25;
      this.meshes.glow.mesh.mesh.position.y = 55;

      this.scene.add(this.meshes.glow.mesh.mesh);

      this.moveCameraToStartPositionAfterGlowIsSet();
    },
    moveCameraToStartPositionAfterGlowIsSet() {
      // the glow is weirdly based on the position on load and I didn't look why it do that, but this is a cheap trick that work.
      // I first set the camera on the main position, then I move it back to the start position after the glow is loaded
      this.camera.position.set(
        this.cameras.start.position.desktop.x,
        this.cameras.start.position.desktop.y,
        this.cameras.start.position.desktop.z
      );

      this.controls.target.x = this.cameras.start.target.desktop.x;
      this.controls.target.y = this.cameras.start.target.desktop.y;
      this.controls.target.z = this.cameras.start.target.desktop.z;
    },

    addMoon() {
      const uniforms = {
        sunDirection: { value: new THREE.Vector3(-1, 0.25, 0.25) },
        dayTexture: {
          value: this.loadResponsiveTexture(
            "gallery/space/moon/moon_64.jpg",
            "gallery/space/moon/moon_256.jpg"
          ),
        },
        nightTexture: {
          value: this.loadResponsiveTexture(
            "gallery/space/moon/moon_night_64.jpg",
            "gallery/space/moon/moon_night_256.jpg"
          ),
        },
        opacity: {
          value: 1.0,
        },
      };

      const materialMoon = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertex,
        fragmentShader: fragment,
        transparent: true,
      });

      this.meshes.moon.mesh.mesh = new THREE.Mesh(
        new THREE.SphereGeometry(4, 32, 32),
        materialMoon
      );
      this.scene.add(this.meshes.moon.mesh.mesh);
      this.meshes.moon.mesh.mesh.position.set(
        this.responsiveMeshPosition("moon", "x") * this.adjustMoonPosition(),
        70,
        this.responsiveMeshPosition("moon", "z") * this.adjustMoonPosition()
      );
      this.meshes.moon.mesh.mesh.position.applyEuler(
        this.calculateEuler(this.responsiveMeshPosition("moon", "euler"))
      );
    },

    responsiveMeshPosition(model, axis) {
      return this.meshes[model].position[this.setResponsivePositionCamera()][
        axis
      ];
    },

    adjustMoonPosition() {
      // optimal screensize is 27inch (which is 2560px )
      // calculate difference between current size and optimal size
      //  ((current size / optimal size))
      const percentPosition = window.innerWidth / 2560;
      // make sure its not to small neither
      return percentPosition > 0.5 ? percentPosition : 0.5;
      // make sure sure is a max
    },
    onResizeUpdatePositionMoon() {
      this.meshes.moon.mesh.mesh.position.set(
        this.responsiveMeshPosition("moon", "x") * this.adjustMoonPosition(),
        70,
        this.responsiveMeshPosition("moon", "z") * this.adjustMoonPosition()
      );
      this.meshes.moon.mesh.mesh.position.applyEuler(
        this.calculateEuler(this.responsiveMeshPosition("moon", "euler"))
      );
    },

    /*------------------------------
    Start on responsive texture
    ------------------------------*/
    // responsiveTextureLoader(textureSmall, texturelarge) {
    //   return this.isSafariOrMobile ? textureSmall : texturelarge;
    // },
    /*------------------------------
    End on responsive texture
    ------------------------------*/

    addStarField() {
      const getRandomParticelPos = (particleCount) => {
        const arr = new Float32Array(particleCount * 3);
        for (let i = 0; i < particleCount; i++) {
          arr[i] = 0 + Math.random() * 2000 - 1000;
          // arr[i] = 0 + Math.random() * 100;
        }
        return arr;
      };

      const geometrys = [
        new THREE.BufferGeometry(),
        new THREE.BufferGeometry(),
      ];

      geometrys[0].setAttribute(
        "position",
        new THREE.BufferAttribute(getRandomParticelPos(350), 3)
      );
      geometrys[1].setAttribute(
        "position",
        new THREE.BufferAttribute(getRandomParticelPos(1500), 3)
      );

      // material
      this.meshes.stars.mesh.material = [
        new THREE.PointsMaterial({
          size: 0.5,
          color: "#ffffff",
        }),
        new THREE.PointsMaterial({
          size: 0.25,

          color: "#F1A9A7",
        }),
      ];

      const starsT1 = new THREE.Points(
        geometrys[0],
        this.meshes.stars.mesh.material[0]
      );
      const starsT2 = new THREE.Points(
        geometrys[1],
        this.meshes.stars.mesh.material[1]
      );
      starsT1.position.z = 1000;
      starsT1.position.x = -1000;

      starsT2.position.z = 1000;
      starsT2.position.x = -1000;

      this.scene.add(starsT1);
      this.scene.add(starsT2);

      // stars visible at the right (when users scrolled up the the end of the gallery)
      const starsT3 = starsT1.clone();
      const starsT4 = starsT2.clone();
      starsT3.position.z = -1000;
      starsT3.position.x = -1000;
      starsT4.position.z = -1000;
      starsT4.position.x = -1000;
      this.scene.add(starsT3);
      this.scene.add(starsT4);
    },

    //======= END EARTH =======//

    //======= START INTRO ANIMATION CLOUDS =======//

    addAnimationClouds() {
      this.meshes.animationClouds.meshes.forEach((cloud, index) => {
        this.generateCloud(cloud, index);
      });
    },
    generateCloud(cloud) {
      const cloudTexture = this.loadResponsiveTexture(
        cloud.texture.small.texture,
        cloud.texture.large.texture
      );
      const cloudMaterial = new THREE.SpriteMaterial({
        map: cloudTexture,
        transparent: true,
        color: cloud.color,
        opacity: cloud.opacity,
      });

      this.meshes.animationClouds.meshes[0].mesh = new THREE.Sprite(
        cloudMaterial
      );

      this.scene.add(this.meshes.animationClouds.meshes[0].mesh);
      this.meshes.animationClouds.meshes[0].mesh.scale.set(
        cloud.scale,
        cloud.scale,
        cloud.scale
      );
      this.meshes.animationClouds.meshes[0].mesh.position.set(
        cloud.position[0],
        cloud.position[1],
        cloud.position[2]
      );

      cloud.clones.forEach((clonedCloud, index) => {
        this.addClonedClouds(
          clonedCloud,
          this.meshes.animationClouds.meshes[0].mesh,
          index
        );
      });
    },
    addClonedClouds(clonedCloudInfo, cloudToClone, index) {
      this.meshes.animationClouds.meshes[0].clones[
        index
      ].mesh = cloudToClone.clone();

      this.scene.add(this.meshes.animationClouds.meshes[0].clones[index].mesh);
      this.meshes.animationClouds.meshes[0].clones[index].mesh.scale.set(
        clonedCloudInfo.scale,
        clonedCloudInfo.scale,
        clonedCloudInfo.scale
      );

      this.meshes.animationClouds.meshes[0].clones[index].mesh.position.set(
        clonedCloudInfo.position[0],
        clonedCloudInfo.position[1],
        clonedCloudInfo.position[2]
      );

      this.meshes.animationClouds.meshes[0].clones[index].mesh.color =
        clonedCloudInfo.color;
    },

    /*------------------------------
    Start Hide clouds
    ------------------------------*/
    hideAnimationClouds() {
      // hide animation clouds when user is far away to save GPU and CPU
      this.meshes.animationClouds.timeOut = setTimeout(() => {
        this.disposeAllCloudsClouds();
        this.destroyTimeout(this.meshes.animationClouds.timeOut);
      }, 4000);
    },
    disposeAllCloudsClouds() {
      this.meshes.animationClouds.meshes.forEach((mainCloud, index) => {
        this.scene.remove(this.meshes.animationClouds.meshes[index].mesh);
        this.meshes.animationClouds.meshes[index].mesh.geometry.dispose();
        this.meshes.animationClouds.meshes[index].mesh.material.dispose();
        this.removeClonedClouds(index);
      });
    },
    removeClonedClouds(index) {
      this.meshes.animationClouds.meshes[index].clones.forEach(
        (clonedCloud) => {
          this.scene.remove(clonedCloud.mesh);
          clonedCloud.mesh.geometry.dispose();
          clonedCloud.mesh.material.dispose();
        }
      );
    },
    /*------------------------------
    End Hide clouds
    ------------------------------*/

    //======= END INTRO ANIMATION CLOUDS =======//

    //======= START ROCKETS =======//

    addModels() {
      this.setBaseLoader();
      this.loadServiceModule();
    },

    setBaseLoader() {
      // set loader
      // this.gltf.GLTFLoader = new GLTFLoader();
      this.setDraco();
      this.setLoader();
    },
    setDraco() {
      this.gltf.dracoLoader = new DRACOLoader();
      //  path to a folder containing WASM/JS decoding libraries.
      this.gltf.dracoLoader.setDecoderPath("/vendors/draco/"); // path needs be public
      this.gltf.dracoLoader.preload();
    },
    setLoader() {
      // set loader
      this.gltf.GLTFLoader = new GLTFLoader();
      this.gltf.GLTFLoader.setDRACOLoader(this.gltf.dracoLoader);
    },

    loadServiceModule() {
      const bakedTexture = this.loadResponsiveTexture(
        "service-module/baked__service-module__2__512.jpg",
        "service-module/baked__service-module__2.jpg"
      );

      bakedTexture.flipY = false;
      bakedTexture.encoding = THREE.sRGBEncoding;

      const bakedMaterial = new THREE.MeshStandardMaterial();

      bakedMaterial.map = bakedTexture;

      this.gltf.GLTFLoader.load(
        `/three-assets/space/service-module.glb`,
        (gltf) => {
          this.GLTFServiceModel = gltf;
          gltf.scene.traverse((child) => {
            child.material = bakedMaterial;
            if (child.isMesh) {
              child.castShadow = false;
              child.receiveShadow = false;
            }
          });

          this.scene.add(gltf.scene);

          gltf.scene.scale.set(0.07, 0.07, 0.07);
          gltf.scene.position.set(
            this.cameras.start.position.desktop.x,
            this.cameras.start.position.desktop.y,
            this.cameras.start.position.desktop.z
          );
          gltf.scene.rotation.y = this.calculateRadian(this.gltf.rotationY);

          this.isGLTFFullyLoaded();
        },
        (xhr) => {
          this.loader.loaderManager.models.GLTFServiceModel.progress =
            xhr.loaded / xhr.total;
          // progress
          if ((xhr.loaded / xhr.total) * 100 === 100) {
            this.loadLM();
          }
        },
        undefined
      );
    },
    loadLM() {
      const bakedLMTexture = this.loadResponsiveTexture(
        "service-module/LM/baked-LM__512.jpg",
        "service-module/LM/baked-LM.jpg"
      );

      bakedLMTexture.flipY = false;
      bakedLMTexture.encoding = THREE.sRGBEncoding;

      const bakedLMMaterial = new THREE.MeshStandardMaterial();

      bakedLMMaterial.map = bakedLMTexture;

      this.gltf.GLTFLoader.load(
        `/three-assets/space/service-module__lm.glb`,
        (gltf) => {
          this.GLTFLunarModule = gltf;
          gltf.scene.traverse((child) => {
            child.material = bakedLMMaterial;
            if (child.isMesh) {
              child.castShadow = false;
              child.receiveShadow = false;
            }
          });

          this.scene.add(gltf.scene);

          gltf.scene.scale.set(0.07, 0.07, 0.07);
          gltf.scene.position.set(
            this.cameras.start.position.desktop.x,
            this.cameras.start.position.desktop.y,
            this.cameras.start.position.desktop.z
          );

          gltf.scene.rotation.y = this.calculateRadian(this.gltf.rotationY);
          // this.updatePositionGLTF();
          this.isGLTFFullyLoaded();
        },
        (xhr) => {
          if ((xhr.loaded / xhr.total) * 100 === 100) {
            this.loader.loaderManager.models.GLTFLunarModule.progress =
              xhr.loaded / xhr.total;
          }
        },
        undefined
      );
    },
    isGLTFFullyLoaded() {
      this.GLTFLunarModule && this.GLTFServiceModel
        ? (this.toggleIsSceneReadyLocally(true), this.initAnimationCamera())
        : null;
    },

    calculateEuler(rotation) {
      return new THREE.Euler(0, this.calculateRadian(rotation), 0, "XYZ");
    },

    calculateRadian(deg) {
      return deg * (Math.PI / 180);
    },

    //======= END ROCKETS =======//

    ////////////////////////////////
    //       END ADD MESHES TO SCENE
    ////////////////////////////////

    animateCameraIntro(bool) {
      this.loader.isSceneReady = true;
      this.updatePositionGLTF();

      this.startAnimationFloatingModule();
      this.$nextTick(() => {
        this.toggleIntroTimeline(bool);
      });
    },
    updatePositionGLTF() {
      // avoid a lag when the camera zoom out by dislay the model on from of the camera on mount
      // then udpate position when animation start
      this.GLTFServiceModel.scene.position.set(
        this.cameras.start.model.position.x,
        this.cameras.start.model.position.y,
        this.cameras.start.model.position.z
      );
      this.GLTFServiceModel.scene.position.applyEuler(
        this.calculateEuler(this.gltf.rotationY)
      );
      this.GLTFLunarModule.scene.position.set(
        this.cameras.start.model.position.x,
        this.cameras.start.model.position.y,
        this.cameras.start.model.position.z
      );
      this.GLTFLunarModule.scene.position.applyEuler(
        this.calculateEuler(this.gltf.rotationY)
      );
    },
    initAnimationCamera() {
      this.gsapAnimation.intro.timeline = gsap.timeline({
        repeatDelay: 0,
        paused: true,
        onStart: () => {
          this.hideAnimationClouds();
        },
        onComplete: () => {
          this.toggleStateAnimation("intro", "isAnimationEnded", true);
          this.setCameraToRightTimeline();

          // animation when user click on an image
          this.initGalleryAnimation();
        },
      });

      this.gsapAnimation.intro.timeline.to(this.camera.position, {
        x: this.responsiveCameraPosition("x", "final", "position"),
        y: this.responsiveCameraPosition("y", "final", "position"),
        z: this.responsiveCameraPosition("z", "final", "position"),
        duration: this.gsapAnimation.intro.duration,
        ease: "power2.inOut",
      });
      this.gsapAnimation.intro.timeline.to(
        this.meshes.glow.mesh.mesh.position,
        {
          x: 10,
          y: 5,
          duration: this.gsapAnimation.intro.duration - 1,
          ease: "power2.inOut",
        },
        "<"
      );
      this.gsapAnimation.intro.timeline
        .to(
          this.controls.target,
          {
            x: this.cameras.final.target.desktop.x,
            y: this.cameras.final.target.desktop.y,
            z: this.cameras.final.target.desktop.z,
            duration: this.gsapAnimation.intro.duration + 3,
            ease: "power2.inOut",
          },
          "<"
        )
        .add(() => {
          this.emitCompletionToParent();
        }, "-=1.5");

      this.loader.loaderManager.intro.progress = 1;
    },

    emitCompletionToParent() {
      this.$emit("introAnimationCompleted");
    },

    /*------------------------------
    Start responsive camera
    ------------------------------*/

    responsiveCameraPosition(axis, camera, type) {
      return this.cameras[camera][type][this.setResponsivePositionCamera()][
        axis
      ];
    },
    setResponsivePositionCamera() {
      return this.isDesktop() ? "desktop" : "mobile";
    },
    isDesktop() {
      return window.innerWidth >= 640;
    },
    /*------------------------------
    End responsive camera
    ------------------------------*/

    // applyEulerMethod(model, rotation) {
    //   model.scene.position.applyEuler(this.calculateEuler(rotation));
    // },

    ////////////////////////////////
    //       START FLYING MODULE ANIMATION
    ////////////////////////////////
    startAnimationFloatingModule() {
      const durationAnimation = 10;
      const movementScale = 0.8;

      const floatingServiceModule = gsap.timeline({
        repeatDelay: 0,
        repeat: -1,
        yoyo: true,
      });

      floatingServiceModule.to(this.GLTFLunarModule.scene.position, {
        x: this.GLTFLunarModule.scene.position.x,
        y: this.GLTFLunarModule.scene.position.y + movementScale,
        z: this.GLTFLunarModule.scene.position.z,
        duration: durationAnimation,
        ease: "power1.inOut",
      });
      floatingServiceModule.to(
        this.GLTFServiceModel.scene.position,
        {
          x: this.GLTFLunarModule.scene.position.x,
          y: this.GLTFLunarModule.scene.position.y + movementScale,
          z: this.GLTFLunarModule.scene.position.z,
          duration: durationAnimation,
          ease: "power1.inOut",
        },
        "<"
      );
    },
    ////////////////////////////////
    //       END FLYING MODULE ANIMATION
    ////////////////////////////////

    ////////////////////////////////
    //       START GALLERY ANIMATION
    ////////////////////////////////
    initGalleryAnimation() {
      if (this.isMobile) return;
      this.gsapAnimation.gallery.timeline = gsap
        .timeline({ paused: true })
        .to(this.camera.position, {
          y: this.cameras.default.position.y + 10,
          ease: "power1.inOut",
          duration: this.isGamePlayDebugging ? 0.5 : 2,
        });
    },
    runGalleryAnimation(bool) {
      if (!this.gsapAnimation.gallery.timeline) return;
      bool
        ? this.gsapAnimation.gallery.timeline.play()
        : this.gsapAnimation.gallery.timeline.reverse();
    },
    ////////////////////////////////
    //       END GALLERY ANIMATION
    ////////////////////////////////

    ////////////////////////////////
    //       START END OF THE GALLERY
    ////////////////////////////////

    //======= START SET TIMELINE =======//

    setCameraToRightTimeline() {
      this.gsapAnimation.endGallery.timeline = gsap.timeline({
        repeatDelay: 0,
        paused: true,
        onStart: () => {},
        onComplete: () => {
          this.toggleEndGalleryIsStarted(true);
          setTimeout(() => {
            this.toggleScrollGlobally(false);
          }, 1000);
        },

        onReverseComplete: () => {
          setTimeout(() => {
            this.toggleScrollGlobally(false);
          }, 1000);
        },
      });

      this.gsapAnimation.endGallery.timeline.to(this.camera.position, {
        x: this.responsiveCameraPosition("x", "endGallery", "position"),
        y: this.responsiveCameraPosition("y", "endGallery", "position"),
        z: this.responsiveCameraPosition("z", "endGallery", "position"),
        duration: this.gsapAnimation.endGallery.duration,
        ease: "power3.inOut",
      });

      this.gsapAnimation.endGallery.timeline.to(
        this.controls.target,
        {
          x: this.responsiveCameraPosition("x", "endGallery", "target"),
          y: this.responsiveCameraPosition("y", "endGallery", "target"),
          z: this.responsiveCameraPosition("z", "endGallery", "target"),

          duration: this.gsapAnimation.endGallery.duration,
          ease: "power3.inOut",
        },
        "<"
      );
      // this.gsapAnimation.endGallery.timeline.pause();
    },

    toggleStateAnimation(animation, stateName, bool) {
      this.gsapAnimation[animation][stateName] = bool;
    },

    toggleScrollGlobally(bool) {
      //  disable during the animation
      this.$store.commit("shared/TOGGLE_SCROLL_GLOBALLY", bool);
    },

    //======= END SET TIMELINE =======//

    //======= START START ANIMATION =======//

    toggleEndGalleryTimeline() {
      this.toggleScrollGlobally(true);
      this.gsapAnimation.endGallery.isStarted
        ? this.resetEndOfGallery()
        : this.startEndOfGallery();
    },
    startEndOfGallery() {
      this.gsapAnimation.endGallery.timeline.play();
    },
    resetEndOfGallery() {
      this.toggleEndGalleryIsStarted(false);
      this.gsapAnimation.endGallery.timeline.reverse();
    },
    toggleEndGalleryIsStarted(bool) {
      this.gsapAnimation.endGallery.isStarted = bool;
      this.toggleProgressPage(bool);
    },
    toggleProgressPage(bool) {
      bool
        ? this.$store.commit("sharedTransition/SET_NEW_TRANSITION", "landing")
        : this.$store.commit("sharedTransition/TOGGLE_TRANSITION", false);
    },

    //======= END START ANIMATION =======//

    ////////////////////////////////
    //       END END OF THE GALLERY
    ////////////////////////////////

    ////////////////////////////////
    //       START GO TO NEXT STEP
    ////////////////////////////////
    updateTabletSizeTarget() {
      this.cameras.goToNext.target.desktop.x = -150;
      this.cameras.goToNext.target.desktop.z = -150;
    },

    setGoToNextCameraTimeline() {
      // console.log(" this.meshes.moon", this.meshes.moon.position);

      this.isDesktop() && window.innerWidth <= 1600
        ? this.updateTabletSizeTarget()
        : null;

      this.toggleStateAnimation("goToNext", "isAnimationStarted", true);

      this.meshes.clouds.mesh.mesh.material.uniforms.transparent = true;
      this.meshes.earth.mesh.mesh.material.uniforms.transparent = true;
      !this.isMobile ? this.fadeInStarField() : null;
      const animationDuration = 4;
      const animationFadeOutDuration = animationDuration - 0.5;

      this.gsapAnimation.goToNext.timeline = gsap.timeline({
        repeatDelay: 0,
        onUpdate: () => {
          this.camera.updateProjectionMatrix();
        },
      });

      this.gsapAnimation.goToNext.timeline

        .to(
          this.controls.target,
          {
            x: this.responsiveCameraPosition("x", "goToNext", "target"),
            y: this.responsiveCameraPosition("y", "goToNext", "target"),
            z: this.responsiveCameraPosition("z", "goToNext", "target"),

            duration: animationFadeOutDuration - 0.5,
            ease: "power1.in",
          },
          "goToNextAnimation"
        )
        // .to(
        //   this.camera.position,
        //   {
        //     x: this.responsiveCameraPosition("x", "goToNext", "position"),
        //     z: this.responsiveCameraPosition("z", "goToNext", "position"),
        //     duration: animationDuration - 0.5,
        //     ease: "power1.in",
        //   },
        //   "goToNextAnimation"
        // )
        .to(
          this.camera,
          {
            zoom: 1.5,
            duration: animationFadeOutDuration - 0.5,
            ease: "power1.in",
          },
          "goToNextAnimation"
        )
        .to(
          this.meshes.glow.mesh.mesh.position,
          {
            x: 1000,

            duration: animationFadeOutDuration,
            ease: "power3.inOut",
          },
          "goToNextAnimation"
        )
        .to(
          this.meshes.moon.mesh.mesh.material.uniforms.opacity,
          {
            value: 0.0,

            duration: animationFadeOutDuration,
            ease: "power3.inOut",
          },
          "goToNextAnimation+=0.5"
        )
        .to(
          this.meshes.earth.mesh.mesh.material.uniforms.opacity,
          {
            value: 0.0,

            duration: animationFadeOutDuration,
            ease: "power3.inOut",
          },
          "goToNextAnimation+=0.5"
        )
        .to(
          this.meshes.clouds.mesh.mesh.material.uniforms.opacity,
          {
            value: 0.0,

            duration: animationFadeOutDuration,
            ease: "power3.inOut",
          },
          "goToNextAnimation+=0.5"
        );
      !this.isMobile
        ? this.addFadeInStarFieldToTimeline(animationFadeOutDuration)
        : null;
      // this.gsapAnimation.goToNext.cameraTimeLine.pause();
    },

    fadeInStarField() {
      // I think it's less expensive to make it transparent when I only need to
      this.meshes.stars.mesh.material[0].transparent = true;
      this.meshes.stars.mesh.material[1].transparent = true;
    },
    addFadeInStarFieldToTimeline(animationFadeOutDuration) {
      this.gsapAnimation.goToNext.timeline
        .to(
          this.meshes.stars.mesh.material[0],
          {
            opacity: 0,

            duration: animationFadeOutDuration - 0.3,
            ease: "power3.inOut",
          },
          "goToNextAnimation+=0.5"
        )
        .to(
          this.meshes.stars.mesh.material[1],
          {
            opacity: 0,

            duration: animationFadeOutDuration - 0.3,
            ease: "power3.inOut",
          },
          "goToNextAnimation+=0.5"
        );
    },

    ////////////////////////////////
    //       END GO TO NEXT STEP
    ////////////////////////////////

    ////////////////////////////////
    //       START GLOBAL LOADER
    ////////////////////////////////

    runProgressLoadingPage() {
      this.loaderManager.intervalProgress = setInterval(() => {
        this.emitProgressToStore();

        this.totalProgress >= 1
          ? (this.toggleTransitionIsLongEnough(true),
            this.resetLoaderManagerInterval())
          : null;
      }, 50);
    },

    emitProgressToStore() {
      this.$store.commit(
        "sharedTransition/UPDATE_PROGRESS",
        this.totalProgress
      );
    },

    resetLoaderManagerInterval() {
      this.loaderManager.intervalProgress
        ? (clearInterval(this.loaderManager.intervalProgress),
          (this.loaderManager.intervalProgress = null))
        : null;
    },

    startAnimation() {
      this.isTransitionLongEnough &&
      !this.isNavOpen &&
      this.isSceneReady &&
      this.isSceneReadyLocally &&
      !this.loader.isSceneReady &&
      !this.gsapAnimation.intro.isAnimationEnded
        ? this.animateCameraIntro(!this.isGamePaused)
        : null;
    },

    runIntroTimelineFromNavOpen() {
      this.isTransitionLongEnough &&
      this.isSceneReady &&
      this.isSceneReadyLocally
        ? this.animateCameraIntro(true)
        : null;
    },

    toggleIntroTimeline(bool) {
      // this is needed if user toggle play pause during the animation
      if (
        this.gsapAnimation.intro.timeline &&
        !this.gsapAnimation.intro.isAnimationEnded
      ) {
        bool
          ? this.gsapAnimation.intro.timeline.play()
          : this.gsapAnimation.intro.timeline.pause();
      }
    },

    ////////////////////////////////
    //       END GLOBAL LOADER
    ////////////////////////////////

    ////////////////////////////////
    //       START DESTROY TIMELINE
    ////////////////////////////////

    destroyAllGSAPAnimations() {
      this.timeLineDestroyer("intro");
      this.timeLineDestroyer("gallery");
      this.timeLineDestroyer("endGallery");
      this.timeLineDestroyer("goToNext");
    },

    timeLineDestroyer(name) {
      this.gsapAnimation[name].timeline
        ? (this.gsapAnimation[name].timeline.kill(),
          (this.gsapAnimation[name].timeline = null))
        : null;
    },

    destroyTimeout(methodName) {
      methodName ? (clearTimeout(methodName), (methodName = null)) : null;
    },

    ////////////////////////////////
    //       END DESTROY TIMELINE
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.scene-space {
  &__infos {
    position: fixed;
    top: 0px;
    left: 50%;
    z-index: 9990;
  }
  &__button {
    position: fixed;
    top: 0px;
    left: 100px;
    z-index: 9990;
  }
  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: -10;
  }
}
</style>
