<template>
  <section class="credit-main">
    <ul class="credit-main__list">
      <credits-list v-for="(item, index) in list" :key="index" :item="item" />
    </ul>
  </section>
</template>

<script>
import creditsCopy from "@/data/credits.json";
import CreditsList from "@/components/Credits/CreditsList";
export default {
  components: {
    CreditsList,
  },
  data() {
    return {
      list: creditsCopy,
    };
  },
};
</script>

<style lang="scss" scoped>
.credit-main {
  @include sharedLayoutMain;
  display: flex;
  flex-direction: column;
  color: var(--color-gallery);
  li {
    // margin-bottom: $small-margin;
  }
}
</style>
