<template>
  <div class="shared-joystick-thumb"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.shared-joystick-thumb {
  position: absolute;
  left: 35px;
  top: 35px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--color-gallery);
  z-index: 2;
  cursor: pointer;
}
</style>
