<template>
  <li>
    <transition name="warning">
      <dashboard-warning
        v-show="isActive"
        :copy="setCopy()"
        :is-animation-started="isActive"
        :is-animation-delay="true"
        :is-reset-visible="isResetVisible()"
      />
    </transition>
  </li>
</template>

<script>
import { mapState } from "vuex";
import DashboardWarning from "@/components/Dashboard/DashboardWarning/DashboardWarning";
export default {
  components: {
    DashboardWarning,
  },
  props: {
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    item: {
      type: String,
      required: true,
    },
    isLanding: {
      type: Boolean,
      required: false,
      default: false,
    },
    sceneName: {
      type: String,
      required: true,
      validator: (val) => ["liftOff", "landing", "exploration"].includes(val),
    },
  },

  computed: {
    ...mapState({
      countDownBooster: (state) => state.dashboardAlerts.countDownBooster,
    }),
    isItemBooster() {
      return this.item === "boost" && !this.isLanding && this.isActive;
    },
    whichkey() {
      return window.innerWidth <= 764 ? "arrow up" : "spacebar";
    },
  },
  methods: {
    setCopy() {
      // tecthnicly this is the booster copy, but it leads to some issue when user hit and release
      return {
        title: this.$t(
          `dashboard.warning.${this.sceneName}.${this.item}.title`
        ),
        copy: this.$t(`dashboard.warning.${this.sceneName}.${this.item}.copy`, {
          key: this.whichkey,
          countdown: this.countDownBooster,
        }),
      };
    },
    isResetVisible() {
      return (
        (this.sceneName === "exploration" &&
          (this.item === "top" ||
            this.item === "right" ||
            this.item === "bottom" ||
            this.item === "left")) ||
        (this.sceneName === "landing" && this.item === "outside")
      );
    },
  },
};
</script>

<style></style>
