export default {
  methods: {
    updateCompass(point, camera, vehiclePosition, arrowSize, isInvisible) {
      const screenPosition = point.position.clone();
      screenPosition.project(camera);

      const translateX = screenPosition.x * window.innerWidth * 0.5;
      const translateY = -screenPosition.y * window.innerHeight * 0.5;

      const newTranslateX = this.setLimitValue(
        (vehiclePosition.x > 0 &&
          vehiclePosition.z < 0 &&
          translateX < window.innerWidth * 0.5 &&
          translateY < window.innerHeight * 0.5) ||
          (vehiclePosition.x > 0 &&
            vehiclePosition.z < 0 &&
            translateX > 0 &&
            translateY < -window.innerHeight * 0.5) ||
          (vehiclePosition.x < 0 && vehiclePosition.z < 0 && translateX < 0) ||
          (vehiclePosition.x > 0 &&
            vehiclePosition.z > 0 &&
            translateX > window.innerWidth * 0.5 &&
            translateY < 0)
          ? -translateX
          : translateX,
        window.innerWidth * 0.5,
        arrowSize
      );
      const newTranslateY = this.setLimitValue(
        (vehiclePosition.x > 0 &&
          vehiclePosition.z < 0 &&
          translateX > 0 &&
          translateY < 0) ||
          (vehiclePosition.x > 0 &&
            vehiclePosition.z < 0 &&
            translateX < 0 &&
            translateY < 0) ||
          (vehiclePosition.x < 0 &&
            vehiclePosition.z < 0 &&
            translateX < 0 &&
            translateY < 0) ||
          (vehiclePosition.x > 0 &&
            vehiclePosition.z > 0 &&
            translateX > window.innerWidth * 0.5 &&
            translateY < 0)
          ? -translateY
          : translateY,

        window.innerHeight * 0.5,
        arrowSize
      );

      document.getElementById(
        "compass"
      ).style.transform = `translateX(${newTranslateX}px) translateY(${newTranslateY}px)`;

      document.getElementById(
        "compass-arrow"
      ).style = `--rotation: ${this.calculateRotationCompass(
        newTranslateX,
        newTranslateY,
        arrowSize
      )}deg; --opacity: ${
        this.isCompassVisible(
          newTranslateX,
          newTranslateY,
          isInvisible,
          arrowSize
        )
          ? 1
          : 0
      }`;
    },

    setLimitValue(translate, translateLimit, arrowSize) {
      return translate < -translateLimit ||
        translate > translateLimit - arrowSize
        ? this.setNewLimitValue(translate, translateLimit, arrowSize)
        : translate;
    },
    setNewLimitValue(translate, translateLimit, arrowSize) {
      return translate > 0 ? translateLimit - arrowSize : -translateLimit;
    },

    calculateRotationCompass(translateX, translateY, arrowSize) {
      if (translateX <= 0) {
        return 270 - (translateY / window.innerHeight) * 100;
      } else if (translateX >= window.innerWidth * 0.5 - arrowSize) {
        return 90 + (translateY / window.innerHeight) * 100;
      } else if (translateY <= -window.innerHeight * 0.5) {
        return 0 + (translateX / window.innerWidth) * 100;
      } else if (translateY >= window.innerHeight * 0.5 - arrowSize) {
        return 180 + (-translateX / window.innerWidth) * 100;
      }
      return 0;
    },

    isCompassVisible(newTranslateX, translateY, isInvisible, arrowSize) {
      return (
        (newTranslateX === -window.innerWidth * 0.5 ||
          newTranslateX === window.innerWidth * 0.5 - arrowSize ||
          translateY === -window.innerHeight * 0.5 ||
          translateY === window.innerHeight * 0.5 - arrowSize) &&
        isInvisible
      );
    },
  },
};
