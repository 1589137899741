<template>
  <button
    class="shared-button-nav"
    :class="{
      'shared-button-nav--active': isActive,
      'shared-button-nav--hover': isMouseHover,
      'shared-button-nav--visible': isButtonsVisible,
      'shared-button-nav--disabled': !isButtonsVisible || isActive,
    }"
    @mouseover="isUserHovering(true)"
    @mouseleave="isUserHovering(false)"
    @focusin="isUserHovering(true)"
    @focusout="isUserHovering(false)"
    @click="userClicked"
    :disabled="!isButtonsVisible || isActive"
    :aria-label="ariaLabel"
  >
    <shared-button-circle-main
      v-if="isButtonsVisible"
      :is-animation-started="isButtonsVisible"
      :is-white="!isActive"
      :icon-name="iconName"
      :is-active="false"
      :is-hover="isMouseHover"
    />
    <label
      class="shared-button-nav__label"
      :class="{ 'shared-button-nav__label--visible': label.isVisible }"
      >{{ $t(`pages.${button.key}.title`) }}</label
    >
  </button>
</template>

<script>
import SharedButtonCircleMain from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircleMain";
export default {
  components: {
    SharedButtonCircleMain,
  },
  props: {
    button: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    isButtonsVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    ariaLabel: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      iconName: "navigation",
      isMouseHover: false,
      label: {
        isVisible: false,
        timeline: null,
      },
    };
  },

  beforeDestroy() {
    this.destroyTimeline();
  },

  mounted() {
    this.startAnimationLabel();
  },
  methods: {
    ////////////////////////////////
    //       START INTRO ANIMATION
    ////////////////////////////////
    startAnimationLabel() {
      this.label.timeline = setTimeout(() => {
        this.toggleLabel(true);
        this.destroyTimeline();
      }, 2000);
    },
    toggleLabel(bool) {
      this.label.isVisible = bool;
    },
    destroyTimeline() {
      this.label.timeline ? clearTimeout(this.label.timeline) : null;
    },
    ////////////////////////////////
    //       END INTRO ANIMATION
    ////////////////////////////////

    ////////////////////////////////
    //       START USER HOVER
    ////////////////////////////////
    isUserHovering(bool) {
      this.isMouseHover = bool;
    },

    ////////////////////////////////
    //       END USER HOVER
    ////////////////////////////////

    ////////////////////////////////
    //       START USER CLICKED
    ////////////////////////////////
    userClicked() {
      // get info
      this.$emit("clicked", this.button);
      // open loading page
      this.$store.commit("sharedTransition/SET_NEW_PROGRESS", this.button.key);
      // close nav
      this.toggleNavigation();
      const redirectTimeOut = setTimeout(() => {
        // go to next page
        this.pageRedirection(this.button.url);
        clearTimeout(redirectTimeOut);
      }, 1000);
    },
    displayLoadingPage() {},

    pageRedirection(pageName) {
      this.$router.push(pageName);
    },
    toggleNavigation() {
      this.$store.commit("navigation/TOGGLE_NAVIGATION");
    },
    ////////////////////////////////
    //       END USER CLICKED
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.shared-button-nav {
  @include removeButtonStyle;
  --pointer: none;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: $small-margin;
  cursor: var(--pointer);

  &--visible {
    --pointer: pointer;
  }
  &--disabled {
    --pointer: auto;
  }

  &__label {
    --shared-button-nav: 0px 0px 5px #eeeeee00;
    @include transition(0.3s ease all);
    @include transform(translatex(-50%));
    @include p;
    position: absolute;
    /* bottom: 0px; */
    left: 50%;
    right: 0px;
    top: calc(60px + 1.5rem);
    text-align: center;
    color: $gallery;
    opacity: 0;
    width: 100px;
    &--visible {
      /* opacity: 1; */
    }

    .shared-button-nav--active & {
      color: $goldenrod;

      --shared-button-nav: $golden-text-shadow;
      @include textShadow($golden-text-shadow);
    }

    /* .shared-button-nav:focus, */
    .shared-button-nav--hover & {
      @include textShadow($white-text-shadow-large);
    }

    /* .shared-button-nav--active:focus, */
    .shared-button-nav--active.shared-button-nav--hover & {
      @include textShadow($golden-text-shadow-large);
    }
  }
}
</style>
