<template>
  <div class="navigation-main-center-lines">
    <ul class="navigation-main-center-lines__lines">
      <li v-for="index in 5" :key="index"></li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.navigation-main-center-lines {
  /* @include rotate(90deg); */
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  /* @include teq-breakpoint($medium) {
    @include rotate(0deg);
  } */
  &__lines {
    width: 100%;
    --width: 0%;
    height: 100%;
    .navigation-toggle--open & {
      --width: 100%;
    }
    /* display: flex;
    flex-direction: row;
    @include teq-breakpoint($medium) {
      flex-direction: column;
    } */
    li {
      position: absolute;
      width: var(--width);

      background-color: $gallery-transparent;

      transform-origin: 0% 0%;

      width: 1px;
      left: 50%;

      height: var(--width);

      @include teq-breakpoint($medium) {
        /* @include rotate(0deg); */
        left: 0%;
        top: 50%;
        height: 1px;
        width: var(--width);
      }

      &:nth-child(1) {
        /* @include rotate(90deg); */
      }
      &:nth-child(2) {
        /* top: 0px; */
        @include rotate(-55deg);
        @include teq-breakpoint($medium) {
          margin-top: -1px;
        }
      }

      &:nth-child(3) {
        @include rotate(-28deg);
        @include teq-breakpoint($medium) {
          margin-top: -1px;
        }
      }

      &:nth-child(4) {
        @include rotate(28deg);
        @include teq-breakpoint($medium) {
          margin-top: -1px;
        }
      }
      &:nth-child(5) {
        @include rotate(55deg);
        @include teq-breakpoint($medium) {
          margin-top: -1px;
        }
      }
    }
  }
}
</style>
