<template>
  <div class="shared-button-menu">
    <!-- circle with animation -->
    <shared-button-menu-circle :is-visible="isAnimationRunning" />
    <div class="shared-button-menu__main">
      <!-- button div -->
      <shared-button-circle
        :aria-label="ariaLabel"
        :is-white="false"
        :is-black="isBlack"
        icon-name="menu"
        :rotation="270"
        :overwrite-time-out="0"
        :is-animation-running="isAnimationRunning"
        :is-disable="isMenuButtonDisabled"
        @clicked="toggleNavigation"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SharedButtonCircle from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircle";
import SharedButtonMenuCircle from "@/components/Shared/SharedButton/SharedButtonMenu/SharedButtonMenuCircle";
export default {
  components: {
    SharedButtonCircle,
    SharedButtonMenuCircle,
  },
  props: {
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isAnimationRunning: false,
      isHover: false,
    };
  },
  mounted() {
    this.startAnimation();
    window.addEventListener("keydown", this.toggleNavOnEscape);
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.toggleNavOnEscape);
  },
  computed: {
    ...mapState({
      isMenuButtonDisabled: (state) => state.navigation.isMenuButtonDisabled,
      isNavOpen: (state) => state.navigation.isNavOpen,
    }),
    ariaLabel() {
      return this.isNavOpen
        ? this.$t("navigation.ariaLabel.close")
        : this.$t("navigation.ariaLabel.open");
    },
  },
  methods: {
    // I think it will be from the store instead
    startAnimation() {
      const timeOut = setTimeout(() => {
        this.isAnimationRunning = true;
        clearTimeout(timeOut);
      }, 10);
    },
    ////////////////////////////////
    //       START USER INTERACTIVE WITH BUTTON
    ////////////////////////////////
    toggleNavigation() {
      this.$store.commit("navigation/TOGGLE_NAVIGATION");
    },

    ////////////////////////////////
    //       END USER INTERACTIVE WITH BUTTON
    ////////////////////////////////

    ////////////////////////////////
    //       START CLOSE NAV USING ESCAPE BUTTON
    ////////////////////////////////

    toggleNavOnEscape(e) {
      e.key === "Escape" && this.isNavOpen && !this.isMenuButtonDisabled
        ? this.closeNav()
        : null;
    },
    closeNav() {
      this.$store.commit("navigation/UPDATE_NAVIGATION", false);
    },
    ////////////////////////////////
    //       END CLOSE NAV USING ESCAPE BUTTON
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.shared-button-menu {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 5px; // (7px -3px from the padding of the button)
  max-width: 75px;
  height: 75px;
  margin-left: -5px;
  margin-top: -5px;

  &__main {
  }
}
</style>
