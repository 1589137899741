// initial state
const state = () => ({
  // display alert panel
  isResetVisible: false,
  isJoystickVisible: false,
  isArrowUpVisible: false,
  isAltimeterVisible: false,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  RESET_ALL(state) {
    state.isResetVisible = false;
    state.isJoystickVisible = false;
    state.isArrowUpVisible = false;
    state.isAltimeterVisible = false;
  },
  TOGGLE_RESET(state, bool) {
    state.isResetVisible = bool;
  },
  TOGGLE_JOYSTICK(state, bool) {
    state.isJoystickVisible = bool;
  },
  TOGGLE_ARROW_UP(state, bool) {
    state.isArrowUpVisible = bool;
  },
  TOGGLE_ALTIMETER(state, bool) {
    state.isAltimeterVisible = bool;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
