export default {
  data() {
    return {
      timeOutTutorials: null,
    };
  },
  methods: {
    ////////////////////////////////
    //       START DISPLAY HIDE
    ////////////////////////////////

    displayTutorial(val) {
      this.$store.commit("dashboardTutorial/DISPLAY_TUTORIAL", val);
    },

    hideTutorials() {
      this.$store.dispatch("dashboardTutorial/HIDE_TUTORIAL");
    },

    ////////////////////////////////
    //       END DISPLAY HIDE
    ////////////////////////////////
    ////////////////////////////////
    //       START DISPLAY THEN HIDE
    ////////////////////////////////
    displayThenHideATutorial(delayUntilHide, copy) {
      this.$store.commit("dashboardTutorial/DISPLAY_TUTORIAL", copy);
      this.timeOutTutorials = setTimeout(() => {
        this.$store.dispatch("dashboardTutorial/HIDE_TUTORIAL");
      }, delayUntilHide);
    },
    clearDisplayAndHideTimeOut() {
      if (this.timeOutTutorials) {
        clearTimeout(this.timeOutTutorials);
        this.timeOutTutorials = null;
      }
    },
    ////////////////////////////////
    //       END DISPLAY THEN HIDE
    ////////////////////////////////
  },
};
