<template>
  <div class="shared-button-main">
    <shared-button-circle-base
      :is-animation-started="isAnimationStarted"
      :is-white="isWhite"
      :is-black="isBlack"
      :is-circle="isCircle"
      :is-hover="isHover"
    />
    <shared-button-circle-icon
      :icon-name="iconName"
      :is-black="isBlack"
      :rotation="rotation"
      :is-active="isActive"
      :is-hover="isHover"
      :is-animation-started="isAnimationStarted"
      :copy="copy"
    />
    <shared-button-corner
      v-if="!isCircle"
      :is-animation-started="isAnimationStarted"
    />
  </div>
</template>

<script>
import SharedButtonCircleBase from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircleBase";
import SharedButtonCircleIcon from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircleIcon/SharedButtonCircleIcon";
import SharedButtonCorner from "@/components/Shared/SharedButton/SharedButtonCorner/SharedButtonCorner";
export default {
  components: {
    SharedButtonCircleBase,
    SharedButtonCircleIcon,
    SharedButtonCorner,
  },
  props: {
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconName: {
      type: String,
      required: true,
    },
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCircle: {
      type: Boolean,
      required: false,
      default: true,
    },
    rotation: {
      type: Number,
      required: false,
      default: 0,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    copy: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-main {
  position: relative;
  pointer-events: none;
}
</style>
