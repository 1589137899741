<template>
  <div class="dashboard-warning-main">
    <!-- svgs  -->
    <dashboard-warning-main-lines />
    <!-- copy -->
    <dashboard-warning-main-content :copy="copy" />
  </div>
</template>

<script>
import DashboardWarningMainLines from "@/components/Dashboard/DashboardWarning/DashboardWarningMainLines";
import DashboardWarningMainContent from "@/components/Dashboard/DashboardWarning/DashboardWarningMainContent";
export default {
  components: {
    DashboardWarningMainLines,
    DashboardWarningMainContent,
  },
  props: {
    copy: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-warning-main {
  @include transition(1s ease all);
  // @include transition-delay(0.3s);
  // overflow: hidden;
  display: flex;
  position: relative;
  flex: 1;
  opacity: 0;
  .dashboard-warning--visible & {
    opacity: 1;
  }
}
</style>
