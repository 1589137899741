<template>
  <div>
    <div class="compass" id="compass">
      <div
        class="compass__arrow"
        id="compass-arrow"
        :style="`--rotation: 0deg`"
      >
        <svg class="compass__arrow__circles">
          <use xlink:href="#icon__compass" />
        </svg>
        <svg class="compass__arrow__pointer">
          <use xlink:href="#icon__arrow-compass" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.compass {
  --compass-width: 23px;
  --compass-margin: 0px;
  --compass-circle: 23px;
  --compass-pointer: 18px;
  --compass-pointer-top: 2px;
  --compass-pointer-left: 3px;
  --padding-circle: 2px;

  position: fixed;
  top: 50%;
  left: 50%;
  width: var(--compass-width);
  height: var(--compass-width);

  aspect-ratio: 1;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  pointer-events: none;

  @include teq-breakpoint($medium) {
    --compass-width: 35px;
    --compass-margin: 4px;
    --compass-circle: 31px;
    --compass-pointer: 20px;
    --compass-pointer-top: 5px;
    --compass-pointer-left: 6px;
    --padding-circle: 0px;
  }

  &__arrow {
    --opacity: 0;
    @include transform(rotate(var(--rotation)));
    @include transition(opacity 0.1s linear);
    margin: var(--compass-margin);
    position: relative;

    opacity: var(--opacity);

    &__circles {
      width: var(--compass-circle);
      height: var(--compass-circle);
      padding: var(--padding-circle);
    }
    &__pointer {
      @include filterShadow(0px 0px 3px $gallery);
      position: absolute;
      top: var(--compass-pointer-top);
      left: var(--compass-pointer-left);
      max-width: var(--compass-pointer);
      max-height: var(--compass-pointer);
      fill: $gallery;
    }
  }
}
</style>
