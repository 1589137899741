<template>
  <ul class="navigation-main-right">
    <!-- audio -->
    <li class="navigation-main-right__audio">
      <shared-button-audio v-if="isVisible" :is-black="isBlack" />
    </li>
    <!-- cookies or lift off -->
    <li class="navigation-main-right__cookies">
      <!-- // @click="toggleGame(true)" -->
      <!-- cookies -->
      <!-- <shared-button-audio /> -->
    </li>
  </ul>
</template>

<script>
import pauseGameMixin from "@/mixins/pauseGameMixin";
import SharedButtonAudio from "@/components/DesignSystem/SharedButtonAudio/SharedButtonAudio";
export default {
  mixins: [pauseGameMixin],
  components: {
    SharedButtonAudio,
  },
  props: {
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    toggleGame(bool) {
      // this is test for game pause. Real pause will be run only on click cookie
      this.toggleGamePause(bool);
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-main-right {
  display: flex;
  flex: 0;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;
  &__audio {
    pointer-events: auto;
  }
  &__cookies {
    pointer-events: auto;
  }
}
</style>
