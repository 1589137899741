<template>
  <div class="home">
    <!-- <three-scene /> -->
    <landing />
  </div>
</template>

<script>
// @ is an alias to /src

// import ThreeScene from "@/components/ThreeScene.vue";
import Landing from "@/components/Landing/Landing";

export default {
  metaInfo: {
    title: "Moon Landing | The Leap For Mankind",
  },
  name: "Home",
  components: {
    Landing,
    // ThreeScene,
  },
};
</script>
