<template>
  <div class="error-page-main">
    <!-- copy -->
    <home-page-main-copy :titles="titles" :is-line-visible="false" />
    <!-- button -->
    <div class="error-page-main__cta">
      <shared-button-copy
        v-if="button.isVisible"
        :is-white="false"
        :is-black="false"
        icon-name="arrow"
        :copy="$t('pages.error.main.cta')"
        :is-reverse="false"
        unique-ref="progress"
        @clicked="clicked"
      />
    </div>
  </div>
</template>

<script>
import SharedButtonCopy from "@/components/Shared/SharedButton/SharedButtonCopy/SharedButtonCopy";
import HomePageMainCopy from "@/components/HomePage/HomePageMain/HomePageMainCopy";
export default {
  components: {
    HomePageMainCopy,
    SharedButtonCopy,
  },
  data() {
    return {
      button: {
        timeout: null,
        isVisible: false,
      },
      isVisible: false,
      titles: {
        top: {
          copy: this.$t("pages.error.main.title.top"),
          headerNumber: "h1",
        },
        bottom: {
          copy: this.$t("pages.error.main.title.bottom"),
          headerNumber: "h2",
        },
      },
    };
  },
  mounted() {
    this.displayButton();
  },
  beforeDestroy() {
    this.timeoutDestroyer();
  },
  methods: {
    displayButton() {
      this.button.timeout = setTimeout(() => {
        this.button.isVisible = true;
      }, 2000);
    },
    timeoutDestroyer() {
      this.button.timeout
        ? (clearTimeout(this.button.timeout), (this.button.timeout = null))
        : null;
    },
    ////////////////////////////////
    //       START GO TO NEXT PAGE
    ////////////////////////////////
    clicked() {
      // open loading page
      this.$store.commit("sharedTransition/SET_NEW_PROGRESS", "home");
      const redirectTimeOut = setTimeout(() => {
        // go to next page
        this.pageRedirection("/");
        clearTimeout(redirectTimeOut);
      }, 1000);
    },
    pageRedirection(pageName) {
      this.$router.push(pageName);
    },
    ////////////////////////////////
    //       END GO TO NEXT PAGE
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.error-page-main {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__cta {
    margin-top: $medium-margin;

    height: 6.6rem;
    @include teq-breakpoint($large) {
      margin-top: $xl-margin;
    }
  }
}
</style>
