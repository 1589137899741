<template>
  <section class="shared-progress-main-copy">
    <!-- next -->
    <h2>{{ $t("shared.transition.next") }}</h2>
    <!-- title -->
    <!-- <h1>{{ $t(`pages.${progressToDisplay}.title`) }}</h1> -->
    <VueTextTransition
      tag="h1"
      name="fade"
      :show="title.isVisible"
      :interval="40"
      >{{ $t(`pages.${progressToDisplay}.title`) }}</VueTextTransition
    >

    <!-- component either button or progress -->
    <shared-progress-main-cta
      :is-black="isBlack"
      :progress-to-display="progressToDisplay"
    />
  </section>
</template>

<script>
import SharedProgressMainCta from "@/components/Shared/SharedProgress/SharedProgressMain/SharedProgressMainCta";
import VueTextTransition from "vue-text-transition";
export default {
  components: {
    SharedProgressMainCta,
    VueTextTransition,
  },

  props: {
    progressToDisplay: {
      type: String,
      required: false,
      default: null,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      title: {
        isVisible: false,
        timeout: null,
      },
    };
  },
  mounted() {
    this.displayTitle();
  },
  beforeDestroy() {
    this.timeoutDestroyer();
  },
  methods: {
    displayTitle() {
      this.title.timeout = setTimeout(() => {
        this.title.isVisible = true;
        this.timeoutDestroyer();
      }, 300);
    },
    timeoutDestroyer() {
      this.title.timeout
        ? (clearTimeout(this.title.timeout), (this.title.timeout = null))
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-progress-main-copy {
  h2 {
    @include marginBetweenTitles;
  }
  h1 {
    overflow: hidden;
  }
}
</style>
