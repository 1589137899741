<template>
  <li
    class="shared-button-corner-svg"
    :class="{ 'shared-button-corner-svg--ready': isAnimationStarted }"
  >
    <svg
      class="shared-button-corner-svg__svg"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 1L1 1L1 10" stroke="#FFD369" stroke-width="2" />
    </svg>
  </li>
</template>

<script>
export default {
  props: {
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-corner-svg {
  display: flex;
  flex-direction: column;
  position: relative;

  width: 10px;
  height: 10px;
  &__svg {
    @include transition(0.3s ease all);
    @include transform(translate(100%, 100%));
    opacity: 0;
    .shared-button-corner-svg--ready & {
      @include transform(translate(0%, 0%));
      opacity: 1;
    }
  }
}
</style>
