<template>
  <div class="navigation-toggle-main-center">
    <div class="navigation-toggle-main-center__inner">
      <!-- 2x2 lines and 1 horizontal line -->
      <navigation-main-center-lines />

      <ul class="navigation-toggle-main-center__inner__list">
        <!-- home -->

        <navigation-main-center-list
          v-for="(item, index) in navigation"
          :key="index"
          :item="item"
          :is-circle-visible="isCircleVisible"
          :is-buttons-visible="isButtonsVisible"
        >
        </navigation-main-center-list>
      </ul>
      <navigation-toggle-main-center-legal />
    </div>
  </div>
</template>

<script>
import NavigationMainCenterLines from "@/components/Navigation/NavigationMain/NavigationMainCenter/NavigationMainCenterList/NavigationMainCenterLines";
import NavigationMainCenterList from "@/components/Navigation/NavigationMain/NavigationMainCenter/NavigationMainCenterList/NavigationMainCenterList";
import NavigationToggleMainCenterLegal from "@/components/Navigation/navigationToggle/NavigationToggleMain/NavigationToggleMainCenter/NavigationToggleMainCenterLegal";

export default {
  components: {
    NavigationMainCenterList,
    NavigationMainCenterLines,
    NavigationToggleMainCenterLegal,
  },
  props: {
    isCircleVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    isButtonsVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      navigation: [
        {
          key: "home",
          url: "/",
          routeName: "Home",
          circle: ["xl"],
          isVisible: false,
        },
        {
          key: "liftOff",
          url: "/lift-off",
          routeName: "LiftOff",
          circle: ["small", "medium", "large"],
          isVisible: false,
        },
        {
          key: "space",
          url: "/space-travel",
          routeName: "Space",
          circle: [],
          isVisible: false,
        },
        {
          key: "landing",
          url: "/landing",
          routeName: "Landing",
          circle: [],
          isVisible: false,
        },
        {
          key: "firstSteps",
          url: "/first-steps",
          routeName: "FirstSteps",
          circle: ["large"],
          isVisible: false,
        },
        {
          key: "exploration",
          url: "/moon-buggy",
          routeName: "Exploration",
          circle: ["medium"],
          isVisible: false,
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.navigation-toggle-main-center {
  --navigation-toggle-min-height: 100rem;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;

  min-height: var(--navigation-toggle-min-height);

  overflow: hidden;

  @include teq-breakpoint($medium) {
    --navigation-toggle-min-height: auto;
  }
  &__inner {
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;

    &__list {
      display: flex;

      flex-direction: column;

      flex: 1;
      padding: 0rem 0px 13rem 0px;
      @include teq-breakpoint($medium) {
        padding: 0px 6.5rem 0px 6.5rem;
        flex-direction: row;
      }
      li {
        display: flex;
        flex: 1;
      }
    }
  }
}
</style>
