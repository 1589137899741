<template>
  <section>
    <exploration-scene />
  </section>
</template>

<script>
import ExplorationScene from "@/components/Exploration/ExplorationScene";
export default {
  components: {
    ExplorationScene,
  },
  mounted() {
    this.updateProgressInformation();
  },
  methods: {
    updateProgressInformation() {
      this.$store.commit("sharedTransition/SET_NEW_PROGRESS", "exploration");
    },
  },
};
</script>

<style lang="scss" scoped>
/* @import "@/assets/scss/config/vars.scss";
@import "@/assets/scss/config/mixins.scss";
@import "@/assets/scss/config/responsive.scss"; */
</style>
