<template>
  <section>
    <!-- gallery scene -->
    <gallery-scene
      v-if="images.list.length"
      :images="images.list"
      :parent="parent"
    />
    <!-- <planet-scene /> -->
    <transition name="modal">
      <gallery v-if="isGalleryOpen" :parent="parent" />
    </transition>
  </section>
</template>

<script>
import { mapState } from "vuex";
import Gallery from "@/components/Gallery/Gallery";
import GalleryScene from "@/components/Gallery/GalleryScene/GalleryScene";

// import space from "@/data/galleries/space.json";
export default {
  components: {
    Gallery,
    GalleryScene,
  },
  data() {
    return {
      //   arrayOfImages: imagesList,
    };
  },
  props: {
    images: {
      type: Object,
      required: false,
      default: null,
    },
    parent: {
      type: String,
      required: false,
      default: null,
    },
  },

  mounted() {
    this.updateProgressInformation();
    this.hideIntroPage();
  },
  computed: {
    ...mapState({
      isGalleryOpen: (state) => state.gallery.isGalleryOpen,
    }),
  },
  methods: {
    updateProgressInformation() {
      this.$store.commit("sharedTransition/SET_NEW_PROGRESS", this.parent);
    },
    hideIntroPage() {
      // hide intro page if user go to home page
      this.$store.dispatch("intro/HIDE_INTRO");
    },
  },
};
</script>

<style lang="scss" scoped>
/* @import "@/assets/scss/config/vars.scss";
@import "@/assets/scss/config/mixins.scss";
@import "@/assets/scss/config/responsive.scss"; */
</style>
