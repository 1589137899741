<template>
  <li
    class="intro-count-down"
    :class="{
      'intro-count-down--done': progress,
      'intro-count-down--visible': isVisible,
    }"
  >
    <ul>
      <li>
        <h1 id="introDate">{{ $t("intro.date") }}</h1>
      </li>
      <li>
        <h1>{{ countDown }}</h1>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  props: {
    progress: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  mounted() {
    this.displayYears();
  },
  computed: {
    countDown() {
      // last year - ((last year - first year) /100) * progress
      return Math.round(2022 - ((2022 - 1962) / 100) * this.progress);
    },
  },
  methods: {
    displayYears() {
      setTimeout(() => {
        this.isVisible = true;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-count-down {
  --width-count-down: 0%;
  --intro-count-down-opacity: 0;
  &--visible {
    --intro-count-down-opacity: 1;
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    li {
      &:nth-child(1) {
        // @include transition(1s ease width);
        width: var(--width-count-down);
        overflow: hidden;
        display: block;
        h1 {
          white-space: nowrap;
          display: block;
        }
      }
      &:nth-child(2) {
        @include transition(0.5s ease all);
        opacity: var(--intro-count-down-opacity);
        h1 {
          font-variant-numeric: tabular-nums;
        }
      }
    }
  }
}
</style>
