<template>
  <div class="navigation-toggle-main-center-legal">
    <div class="navigation-toggle-main-center-legal__inner">
      <navigation-toggle-main-side-legal />
    </div>
  </div>
</template>

<script>
import NavigationToggleMainSideLegal from "@/components/Navigation/navigationToggle/NavigationToggleMain/NavigationToggleMainSide/NavigationToggleMainSideLegal";
export default {
  components: {
    NavigationToggleMainSideLegal,
  },
};
</script>

<style lang="scss" scoped>
.navigation-toggle-main-center-legal {
  --opacity: 0;
  --cursor: none;
  position: absolute;
  bottom: $xl-margin;
  left: 0px;
  right: 0px;
  opacity: var(--opacity);
  pointer-events: var(--cursor);
  @include teq-breakpoint($medium) {
    --opacity: 1;
    --cursor: inherit;
  }
  &__inner {
    @include transform(translate(0px, 50px));
    opacity: 0;
    display: flex;
    justify-content: center;
  }
}
</style>
