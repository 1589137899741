<template>
  <div
    class="shared-button-circle-copy"
    :class="{ 'shared-button-circle-copy--visible': isAnimationStarted }"
  >
    {{ copy }}
  </div>
</template>

<script>
export default {
  props: {
    copy: {
      type: String,
      required: false,
      default: null,
    },
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-circle-copy {
  --opacity-copy: 0;
  @include transition(0.5s ease all 0.8s);
  @include legal;
  @include textShadow($white-text-shadow);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: $gallery;
  opacity: var(--opacity-copy);

  &--visible {
    --opacity-copy: 1;
  }
}
</style>
