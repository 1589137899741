<template>
  <ul
    class="home-main-copy-animation"
    :class="{
      'home-main-copy-animation--visible-lines': isLinesVisible,
    }"
  >
    <li
      v-if="hasLines"
      class="home-main-copy-animation__line home-main-copy-animation__line--before"
    ></li>
    <li
      class="home-main-copy-animation__copy"
      :class="{
        'home-main-copy-animation__copy--lines': hasLines,
      }"
    >
      <VueTextTransition
        :tag="headerNumber"
        name="fade"
        :show="isVisible"
        :interval="40"
        >{{ copy }}</VueTextTransition
      >
    </li>
    <li
      v-if="hasLines"
      class="home-main-copy-animation__line home-main-copy-animation__line--after"
    ></li>
  </ul>
</template>

<script>
import VueTextTransition from "vue-text-transition";
export default {
  components: {
    VueTextTransition,
  },
  props: {
    copy: {
      type: String,
      required: false,
      default: null,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    headerNumber: {
      type: String,
      required: false,
      default: "h1",
    },
    isLinesVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasLines: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.home-main-copy-animation {
  display: flex;
  flex-direction: row;
  &__line {
    flex: 1;
    display: flex;
    align-items: center;
    &:before {
      @include homeLines;

      width: 0%;
      .home-main-copy-animation--visible-lines & {
        width: calc(100%);
      }
    }
    &--before {
      &:before {
        right: 0px;
      }
    }

    &--after {
      &:before {
        left: 0px;
      }
    }
  }
  &__copy {
    display: flex;
    justify-content: center;
    color: var(--color-gallery);
    text-align: center;
    &--lines {
      padding: 0px $small-margin;
    }

    h1,
    h2 {
      overflow: hidden;
    }
  }
}
</style>
