<template>
  <ul
    class="shared-button-label"
    :class="{ 'shared-button-label--visible': isAnimationRunning }"
  >
    <li
      v-if="label"
      class="shared-button-label__item shared-button-label__item--label"
    >
      <p>{{ label }}</p>
    </li>
    <li class="shared-button-label__item shared-button-label__item--button">
      <shared-button-circle
        :is-white="false"
        :is-black="false"
        :icon-name="icon"
        :is-animation-running="isAnimationRunning"
        :aria-label="label"
        @clicked="clicked"
      />
    </li>
  </ul>
</template>

<script>
import SharedButtonCircle from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircle";
export default {
  components: {
    SharedButtonCircle,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: "reset",
    },
    isAnimationRunning: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-label {
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  align-content: stretch;

  --opacity-label: 0;
  --transform-label: 1rem;

  &--visible {
    --opacity-label: 1;
    --transform-label: 0rem;
  }

  &__item {
    display: flex;
    justify-content: center;
    &--label {
      @include transition(0.5s ease all);
      @include transform(translateY(var(--transform-label)));
      transition-delay: 2.5s;
      opacity: var(--opacity-label);
      padding-bottom: $x-small-margin;

      color: $gallery;
      line-height: 1rem;
      text-align: center;
      height: 100%;
      @include teq-breakpoint($small) {
        padding-bottom: $small-margin;
      }
    }
    &--button {
    }
  }
}
</style>
