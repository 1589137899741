<template>
  <div class="shared-gallery-scroll-arrow">
    <tutorial-block
      :copy="$t('gallery.scroll')"
      icons="arrow"
      :is-black="false"
    />
  </div>
</template>

<script>
import TutorialBlock from "@/components/Tutorial/TutorialBlock/TutorialBlock";
export default {
  components: {
    TutorialBlock,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-gallery-scroll-arrow {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: $xl-margin;

  .tutorial-block {
    z-index: 1;
  }
  &:after {
    content: " ";
    position: absolute;
    width: 40rem;
    bottom: -15rem;
    left: calc(-20rem + 3.5rem);
    aspect-ratio: 1/1;
    z-index: 0;

    background: rgb(14, 14, 15);
    background: radial-gradient(
      circle,
      rgba(14, 14, 15, 1) 0%,
      rgba(14, 14, 15, 0) 56%
    );
  }
}
</style>
