<template>
  <button
    class="navigation-toggle-side-list-link"
    :class="{
      'navigation-toggle-side-list-link--disabled': isSideBarButtonDisabled,
      'navigation-toggle-side-list-link--active': isActive,
    }"
    :disabled="isButtonDisabled"
    :aria-label="$t(`navigation.links.${item}`)"
    @click="userClicked"
  >
    <span>{{ $t(`navigation.links.${item}`) }}</span>
  </button>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    item: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      isSideBarButtonDisabled: (state) =>
        state.navigation.isSideBarButtonDisabled,
    }),
    isButtonDisabled() {
      return this.isActive || this.isSideBarButtonDisabled;
    },
  },
  methods: {
    userClicked() {
      this.emitToParent();
    },
    emitToParent() {
      this.$emit("clicked", this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-toggle-side-list-link {
  @include removeButtonStyle;
  @include p;
  @include transition(0.3s ease all);

  --cursor: inherit;
  cursor: pointer;
  pointer-events: var(--cursor);
  color: var(--color-gallery);
  width: 100%;
  text-align: left;

  padding: 0px 3px; //make it easier to select

  &--disabled {
    --cursor: none;
  }
  &--active {
    --cursor: none;
  }

  .navigation-toggle-main-side--right & {
    text-align: right;
  }
  &--active,
  &:focus,
  &:hover {
    @include textShadow($white-text-shadow);
  }
}
</style>
