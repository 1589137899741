<template>
  <div
    class="shared-button-circle-menu"
    :class="{
      'shared-button-circle-menu--black': isBlack,
      'shared-button-circle-menu--visible': isAnimationStarted,
    }"
  >
    <ul class="shared-button-circle-menu__list">
      <transition name="menu" mode="out-in">
        <li
          class="shared-button-circle-menu__list__copy"
          v-if="!isActive"
          key="menu"
        >
          <p>MENU</p>
        </li>
        <li v-else class="shared-button-circle-menu__list__copy" key="close">
          <p>CLOSE</p>
        </li>
      </transition>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-circle-menu {
  @include transition(0.3s ease-in-out all);
  @include transition-delay(1.5s);
  @include transform(translate(0px, -5px));
  opacity: 0;
  &--visible {
    @include transform(translate(0px, 0px));
    opacity: 1;
  }
  &__list {
    &__copy {
      @include transition(0.5s ease-in-out all);
      @include textShadow($white-text-shadow);
      @include legal;
      color: var(--color-gallery);
      .shared-button-circle-menu--black & {
        @include textShadow($black-text-shadow);
        color: var(--color-ebony);
      }
    }
  }
}
</style>
