<template>
  <div class="shared-button-copy">
    <div class="shared-button-copy__inner">
      <p
        class="shared-button-copy__inner__copy"
        :class="{ 'shared-button-copy__inner__copy--black': isBlack }"
      >
        {{ copy }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    copy: {
      type: String,
      required: true,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-copy {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    &__copy {
      --shared-button-rectangle: $white-text-shadow;
      @include p;
      @include transition(0.5s);
      @include transform(translate(0px, 30px));
      @include textShadow(var(--shared-button-rectangle));
      animation: 0.3s scaleAnimation ease-in-out forwards;
      animation-delay: 1.5s;

      color: $gallery;

      &--black {
        color: $ebony-clay;

        --shared-button-rectangle: $black-text-shadow;
      }
    }
  }
}
@keyframes scaleAnimation {
  to {
    @include transform(translate(0px, 0px));
  }
}
</style>
