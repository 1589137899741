<template>
  <div class="shared-icon" :class="`shared-icon--${name}`">
    <svg>
      <use :xlink:href="`#icon__${name}`" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
      default: "arrow",
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-icon {
  display: flex;
  flex-direction: column;
  flex: 1;
  svg {
    @include filterShadow(0px 0px 3px $gallery);
    width: 100%;
    max-width: 20px;
    // height: 100%;
    height: 100px; // fix for safari
    max-height: 20px;
    .circular-button-icon--black & {
      @include filterShadow(0px 0px 3px $wood-smoke);
    }
  }
  &--joystick {
    svg {
      max-width: 30px;
      max-height: 30px;
    }
  }
}
</style>
