<template>
  <button
    @mouseover="isUserHovering(true)"
    @mouseleave="isUserHovering(false)"
    @focusin="isUserHovering(true)"
    @focusout="isUserHovering(false)"
    class="circular-button"
    :class="{
      'circular-button--clickable': isButtonClickable(),
      'circular-button--active': isNavOpen,
    }"
    :disabled="!isButtonClickable()"
    @touchmove="userTouchMove()"
    @keydown="filterKeyDown"
    @mousedown="mouseDown"
    @touchstart="touchStart"
    :aria-label="ariaLabel"
  >
    <!-- @click="clicker" -->
    <shared-button-circle-main
      :is-animation-started="isAnimationStarted"
      :is-white="isWhite"
      :is-black="isBlack"
      :icon-name="iconName"
      :rotation="rotation"
      :is-active="isNavOpen"
      :is-hover="isMouseHover"
    />
  </button>
</template>

<script>
import { mapState } from "vuex";

import SharedButtonCircleMain from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircleMain";
export default {
  components: {
    SharedButtonCircleMain,
  },
  props: {
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconName: {
      type: String,
      required: true,
    },
    rotation: {
      type: Number,
      required: false,
      default: 0,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    overwriteTimeOut: {
      type: Number,
      required: false,
      default: 500,
    },
    isAnimationRunning: {
      type: Boolean,
      required: false,
      default: true,
    },
    isHoldToToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    ariaLabel: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      timeOut: {
        timeOut: null,
        time: this.overwriteTimeOut,
      },
      isAnimationStarted: false,
      isMouseHover: false,
      isActive: false,
    };
  },
  watch: {
    isAnimationRunning(bool) {
      bool ? this.startAnimation() : null;
    },
  },

  mounted() {
    this.isAnimationRunning ? this.startAnimation() : null;
  },
  beforeDestroy() {
    this.clearTimeOut();
  },
  computed: {
    ...mapState({
      isNavOpen: (state) => state.navigation.isNavOpen,
      isMobile: (state) => state.userDevice.userDevice,
    }),
  },
  methods: {
    ////////////////////////////////
    //       START ANIMATION
    ////////////////////////////////
    startAnimation() {
      this.timeOut.timeOut = setTimeout(() => {
        this.toggleAnimation(true);
        this.clearTimeOut();
      }, this.timeOut.time);
    },
    toggleAnimation(bool) {
      this.isAnimationStarted = bool;
    },
    clearTimeOut() {
      clearTimeout(this.timeOut.timeOut);
    },
    ////////////////////////////////
    //       END ANIMATION
    ////////////////////////////////

    isButtonClickable() {
      return !this.isDisable && this.isAnimationStarted;
    },

    ////////////////////////////////
    //       START MANAGE HOVER STATE
    ////////////////////////////////
    isUserHovering(bool) {
      this.toggleHover(bool);
    },
    toggleHover(bool) {
      this.isMouseHover = bool;
    },

    ////////////////////////////////
    //       END MANAGE HOVER STATE
    ////////////////////////////////

    ////////////////////////////////
    //       START ON CLICK
    ////////////////////////////////

    mouseDown() {
      this.isMobile ? null : this.clicked();
    },
    touchStart() {
      this.isMobile ? this.clicked() : null;
    },

    userTouchMove() {
      this.isHoldToToggle ? this.clicked(false) : null;
    },
    filterKeyDown(e) {
      e.code === "Enter" ? this.clicked() : null;
    },
    clicker(e) {
      this.clicked();
    },
    clicked() {
      return this.isButtonClickable() ? this.$emit("clicked") : null;
    },

    ////////////////////////////////
    //       END ON CLICK
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.circular-button {
  @include removeButtonStyle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  pointer-events: none;

  /* -webkit-touch-callout: none;
  -webkit-user-select: none; */
  -webkit-user-drag: none;
  -webkit-user-modify: none;
  -webkit-highlight: none;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  &--clickable {
    pointer-events: auto;
    cursor: pointer;
  }
}
</style>
