<template>
  <ul class="home-page-main-copy">
    <!-- the -->
    <li class="home-page-main-copy__kicker">
      <home-main-copy-animation
        :copy="titles.top.copy"
        :is-visible="copy.the.isVisible"
        :header-number="titles.top.headerNumber"
        :has-lines="true"
        :is-lines-visible="copy.lines.isVisible"
      />
    </li>
    <!-- man kind leap -->
    <li>
      <home-main-copy-animation
        :copy="titles.bottom.copy"
        :is-visible="copy.leap.isVisible"
        :header-number="titles.bottom.headerNumber"
      />
    </li>
  </ul>
</template>

<script>
import HomeMainCopyAnimation from "@/components/HomePage/HomePageMain/HomeMainCopyAnimation";
export default {
  components: {
    HomeMainCopyAnimation,
  },
  props: {
    titles: {
      type: Object,
      required: false,
      default: null,
    },
    isLineVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      copy: {
        the: {
          isVisible: false,
          timeout: null,
          duration: 1000,
        },
        leap: {
          isVisible: false,
          timeout: null,
          duration: 1500,
        },
        lines: {
          isVisible: false,
          timeout: null,
          duration: 2000,
        },
      },
    };
  },
  mounted() {
    this.displayCopyWithTimeout();
  },
  beforeDestroy() {
    this.destroyAllTimeout();
  },
  methods: {
    displayCopyWithTimeout() {
      this.timeoutCopy("the");
      this.timeoutCopy("leap");
      this.isLineVisible ? this.timeoutCopy("lines") : null;
    },
    timeoutCopy(path) {
      this.copy[path].timeout = setTimeout(() => {
        this.toggleCopy(path);
      }, this.copy[path].duration);
    },

    toggleCopy(path) {
      this.copy[path].isVisible = true;
    },
    ////////////////////////////////
    //       START DESTROY ALL TIMELINES
    ////////////////////////////////
    destroyAllTimeout() {
      this.timeoutDestroyer("the");
      this.timeoutDestroyer("leap");
      this.isLineVisible ? this.timeoutDestroyer("lines") : null;
    },
    timeoutDestroyer(path) {
      this.copy[path].timeline
        ? (clearTimeout(this.copy[path].timeline),
          (this.copy[path].timeline = null))
        : null;
    },
    ////////////////////////////////
    //       END DESTROY ALL TIMELINES
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.home-page-main-copy {
  @include resetList;
  flex-direction: column;
  position: relative;

  &__kicker {
    margin-bottom: $small-margin;
  }
}
</style>
