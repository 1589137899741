<template>
  <div class="dashboard-modal">
    <dashboard-warning
      :which-modal="whichModal"
      :copy="$t(`dashboard.modal.${whichModal}`)"
      :is-animation-started="isAnimationStarted"
    />
    <dashboard-modal-main
      :which-modal="whichModal"
      :is-animation-started="isAnimationStarted"
      :scene-name="sceneName"
    />
  </div>
</template>

<script>
import DashboardWarning from "@/components/Dashboard/DashboardWarning/DashboardWarning";
import DashboardModalMain from "@/components/Dashboard/DashboardModal/DashboardModalMain";
export default {
  components: {
    DashboardModalMain,
    DashboardWarning,
  },
  props: {
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: true,
    },
    whichModal: {
      type: String,
      required: true,
      default: "pause",
      // validator: (val) => ["restart", "pause", "crash", "boost"].includes(val),
    },
    sceneName: {
      type: String,
      required: true,
      validator: (val) => ["liftOff", "landing", "exploration"].includes(val),
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.dashboard-modal {
  display: flex;
  flex-direction: column;

  position: absolute;
  // top: 10rem;
  @include teq-breakpoint($small) {
    position: relative;
    max-width: 100%;
  }
}
</style>
