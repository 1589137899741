<template>
  <button
    class="shared-button-copy"
    :class="{
      'shared-button-copy--clickable': isAnimationStarted,
      'shared-button-copy--reverse': isReverse,
    }"
    :aria-label="copy"
    :disabled="!isButtonClickable()"
    @mouseover="isUserHovering(true)"
    @mouseleave="isUserHovering(false)"
    @focusin="isUserHovering(true)"
    @focusout="isUserHovering(false)"
    @click="clicked"
  >
    <!-- copy -->
    <div
      class="shared-button-copy__label"
      :class="{ 'shared-button-copy__label--black': isBlack }"
    >
      <p :ref="uniqueRef">{{ copy }}</p>
    </div>
    <!-- icon -->
    <div class="shared-button-copy__icon">
      <shared-button-circle-main
        :isAnimationStarted="isAnimationStarted"
        :is-white="isWhite"
        :is-black="isBlack"
        :icon-name="iconName"
        :rotation="rotation"
        :is-hover="isMouseHover"
      />
    </div>
  </button>
</template>

<script>
import SharedButtonCircleMain from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircleMain";
export default {
  components: {
    SharedButtonCircleMain,
  },
  props: {
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconName: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      required: true,
    },
    isReverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    uniqueRef: {
      type: String,
      required: true,
    },
    rotation: {
      type: Number,
      required: false,
      default: 0,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isMouseHover: false,
      timeOut: {
        timeOut: null,
        time: 500,
      },
      isAnimationStarted: false,
      copyTimeline: null,
    };
  },

  mounted() {
    this.isVisible ? this.startAnimation() : null;
  },
  beforeDestroy() {
    this.clearTimeOut();
  },
  methods: {
    ////////////////////////////////
    //       START ANIMATION
    ////////////////////////////////
    startAnimation() {
      this.timeOut.timeOut = setTimeout(() => {
        this.toggleAnimation(true);
        this.clearTimeOut();
        // this.animationTimeLine();
      }, this.timeOut.time);
    },
    toggleAnimation(bool) {
      this.isAnimationStarted = bool;
    },
    clearTimeOut() {
      clearTimeout(this.timeOut.timeOut);
    },
    ////////////////////////////////
    //       END ANIMATION
    ////////////////////////////////

    ////////////////////////////////
    //       START LABEL ANIMATION
    ////////////////////////////////
    // animationTimeLine() {
    //   console.log("animationTimeLine");
    //   gsap.to(this.$refs[this.uniqueRef], {
    //     duration: 0.5,
    //     x: "100%",
    //   });
    // },
    ////////////////////////////////
    //       END LABEL ANIMATION
    ////////////////////////////////

    isButtonClickable() {
      return this.isAnimationStarted;
    },

    ////////////////////////////////
    //       START USER HOVER
    ////////////////////////////////
    isUserHovering(bool) {
      this.isMouseHover = bool;
    },

    ////////////////////////////////
    //       END USER HOVER
    ////////////////////////////////

    ////////////////////////////////
    //       START ON CLICK
    ////////////////////////////////
    clicked() {
      return this.isButtonClickable() ? this.$emit("clicked") : null;
    },
    ////////////////////////////////
    //       END ON CLICK
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.shared-button-copy {
  @include removeButtonStyle;
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: none;
  padding: 3px;
  &--clickable {
    pointer-events: auto;
    cursor: pointer;
  }
  &__label {
    overflow: hidden;
    order: 1;
    color: $gallery;
    &--black {
      color: $ebony-clay;
    }
    p {
      @include transform(translate(100%));
      animation: 0.5s slideText ease-in-out forwards;
      animation-delay: 2.5s;
      opacity: 0;
    }
    .shared-button-copy--reverse & {
      order: 2;
      p {
        @include transform(translate(-100%));
      }
    }
  }
  &__icon {
    order: 2;
    padding: 0px 0px 0px 1.5rem;
    .shared-button-copy--reverse & {
      order: 1;
      padding: 0px 1.5rem 0px 0px;
    }
  }
}
@keyframes slideText {
  to {
    @include transform(translate(0%));
    opacity: 1;
  }
}
</style>
