<template>
  <li class="gallery-copy" :class="{ 'gallery-copy--visible': isVisible }">
    <p>
      <span>{{ $t(`gallery.${parent}.${selectedImgId}.info`) }}</span>
    </p>
  </li>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    parent: {
      type: String,
      required: false,
      default: null,
    },
    selectedImgId: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.gallery-copy {
  margin-top: $small-margin;
  --transitionY: 100%;
  display: flex;
  //   overflow: hidden;
  &--visible {
    --transitionY: 0%;
  }

  p {
    @include legal;
    overflow: hidden;
    color: $gallery;
    span {
      @include transition(0.5s ease top 1s);
      //   @include transform(translateY(var(--transitionY)));
      top: var(--transitionY);
    }
  }
}
</style>
