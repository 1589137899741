var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation-toggle-main-side-legal",class:{ 'navigation-toggle-main-side-legal--mobile': _vm.isMobile }},[_c('div',{staticClass:"navigation-toggle-main-side-legal__copy",class:{
      'navigation-toggle-main-side-legal__copy--disabled': _vm.isSideBarButtonDisabled,
    }},[_c('p',{attrs:{"disabled":_vm.isSideBarButtonDisabled},domProps:{"innerHTML":_vm._s(
        _vm.$t('navigation.legal.made', {
          author: ("<a href='http://aurelienvigne.com' target='_blank' rel='noopener noreferrer'>" + (_vm.$t(
            'navigation.legal.by'
          )) + "</a>"),
        })
      )}})])])}
var staticRenderFns = []

export { render, staticRenderFns }