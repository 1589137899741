<template>
  <div class="tutorial-block" :class="{ 'tutorial-block--flex-end': !copy }">
    <!-- copy -->
    <div
      v-if="copy"
      class="tutorial-block__copy"
      :class="{ 'tutorial-block__copy--black': isBlack }"
    >
      <p v-html="copy" />
    </div>
    <!-- icons -->
    <tutorial-arrows
      v-if="icons === 'arrows'"
      :is-white="false"
      :is-black="isBlack"
    />
    <tutorial-arrows
      v-else-if="icons === 'arrow'"
      :is-white="false"
      :is-black="isBlack"
      :arrows-direction="[90]"
    />
    <shared-button-rectangle
      v-else-if="icons === 'space'"
      :is-white="false"
      :is-black="isBlack"
      :copy="$t('shared.tutorial.spacebar')"
    />
    <shared-button-circle
      v-else-if="icons === 'joystick'"
      :is-white="false"
      :is-black="isBlack"
      icon-name="joystick"
      :rotation="0"
      :is-disable="true"
    />
    <shared-button-circle
      v-else-if="icons === 'arrowUp'"
      :is-white="false"
      :is-black="isBlack"
      icon-name="arrow"
      :rotation="270"
      :is-disable="true"
    />
  </div>
</template>

<script>
import SharedButtonCircle from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircle";
import SharedButtonRectangle from "@/components/Shared/SharedButton/SharedButtonRectangle/SharedButtonRectangle";
import TutorialArrows from "@/components/Tutorial/TutorialArrows/TutorialArrows";
export default {
  components: {
    SharedButtonCircle,
    SharedButtonRectangle,
    TutorialArrows,
  },
  props: {
    copy: {
      type: String,
      required: false,
      default: null,
    },

    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    icons: {
      type: String,
      required: true,
      default: "arrow",
      validator: (text) =>
        ["arrows", "arrow", "space", "joystick", "arrowUp"].includes(text),
    },
  },
};
</script>

<style lang="scss" scoped>
.tutorial-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 260px;
  padding: $x-small-margin $x-small-margin 0 $x-small-margin;
  &--flex-end {
    justify-content: flex-end;
  }
  &__copy {
    color: var(--color-gallery);
    margin-bottom: $small-margin;
    text-align: center;

    overflow: hidden;
    &--black {
      color: var(--color-ebony);
    }
    p {
      @include p;
      @include transform(translateY(30px));
      animation: 0.5s slideTextTutorial ease-in-out forwards;
      animation-delay: 2s;
      opacity: 0;
    }
  }
}
@keyframes slideTextTutorial {
  to {
    @include transform(translateY(0px));
    opacity: 1;
  }
}
</style>
