<template>
  <div class="gallery-manager">
    <!-- <three-scene /> -->
    <gallery-manager
      v-if="images.list.length"
      :images="images"
      parent="firstSteps"
    />
  </div>
</template>

<script>
// import ThreeScene from "@/components/ThreeScene.vue";
import GalleryManager from "@/components/Gallery/GalleryManager/GalleryManager";
import moon from "@/data/galleries/moon.json";

export default {
  metaInfo: {
    title: "First Steps | The Leap For Mankind",
  },
  name: "Home",
  components: {
    GalleryManager,
    // ThreeScene,
  },
  data() {
    return {
      images: moon,
    };
  },
};
</script>
