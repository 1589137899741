<template>
  <section class="scene">
    <canvas ref="scene__container" class="scene__container"></canvas>
  </section>
</template>

<script>
import { mapState } from "vuex";

import isDevMixin from "@/mixins/isDevMixin";
import sharedFXAudioMixin from "@/mixins/sharedFXAudioMixin";
import resetGamePlayStoreMixin from "@/mixins/resetGamePlayStoreMixin";
import toggleDashboardBottomMixin from "@/mixins/toggleDashboardBottomMixin";
import transitionMixin from "@/mixins/transitionMixin";
import tutorialMixin from "@/mixins/tutorialMixin";
import pauseGameMixin from "@/mixins/pauseGameMixin";

import { gsap } from "gsap";

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// vendors
import LinearSpline from "@/vendors/LinearSpline.js";

// STATS
import Stats from "stats.js";
// import { GUI } from "@/vendors/dat.gui.module.js";

// shaders
import moonVertexShader from "@/shaders/moon/vertex.glsl";
import moonFragmentShader from "@/shaders/moon/fragment.glsl";
import moonSafariFragment from "@/shaders/moon/safariFragment.glsl";
import fireVertexShader from "@/shaders/fire/vertex.glsl";
import fireFragmentShader from "@/shaders/fire/fragment.glsl";

import flagVertexShader from "@/shaders/flag/vertex.glsl";
import flagFragmentShader from "@/shaders/flag/fragment.glsl";

// heigthmap
import heightmap from "@/assets/textures/moon/heightmap/center.jpg";

// texture
export default {
  mixins: [
    isDevMixin,
    resetGamePlayStoreMixin,
    sharedFXAudioMixin,
    toggleDashboardBottomMixin,
    transitionMixin,
    tutorialMixin,
    pauseGameMixin,
  ],
  props: {
    galleryProgress: {
      type: Number,
      required: false,
      default: 0,
    },
    isEndOfGallery: {
      type: Boolean,
      required: false,
      default: false,
    },
    isGalleryAnimationRunning: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // base
      container: null,
      scene: null,
      camera: null,
      controls: null,
      animation: undefined,
      // isGamePausedOnce: false,
      isSceneReadyLocally: false,

      // effectComposer: {
      //   effectComposer: null,
      //   renderTarger: null,
      //   glitchPass: {
      //     GlitchPass: null,
      //     isGlitchPassVisible: false,
      //   },
      //   RGBShiftPass: null,
      //   filmPass: {
      //     filmPass: null,
      //   },
      //   glitchResetTimeOut: null,
      // },

      initiate: {
        isInitStarted: false,
        isInitiating: false,
        timeout: null,
        timeoutGameloop: null,
        timeoutMounted: null,
      },

      //  helper
      helpers: {
        stats: null,
        clock: null,
      },
      clock: {
        clock: null,
        oldElapsedTime: 0,
        then: 0,
        then10FPS: 0,
        then60FPS: 0,
      },
      lights: {
        directionalLight: null,
      },
      cameras: {
        defaultCameraPosition: {
          camX: -280,
          camY: 280,
          camZ: 280,
        },

        windowUser: {
          width: 0,
          height: 0,
        },
      },

      // loader GLTF
      gltf: {
        dracoLoader: null,
        GLTFLoader: null,
        GLFTList: [
          {
            key: "rocket",

            path: "LM/lunar-module--draco.glb",
            material: {
              small: {
                texture: "LM/bake__512.jpg",
                normal: null,
              },
              large: {
                texture: "LM/bake.jpg",
                normal: null,
              },
            },
            position: [0, 2.6, 0],
            rotation: 0.1,
            scale: 0.4,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: false,
            isAnimated: false,
            isMobile: true,
          },
          {
            key: "rover",

            path: "rover/rover-without-driver--draco.glb",
            material: {
              small: {
                texture: "rover/bake-full__512.jpg",
                normal: null,
              },
              large: {
                texture: "rover/bake-full.jpg",
                normal: null,
              },
            },
            position: [6, -0.05, 3],
            rotation: -1,
            scale: 0.32,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: true,
            isAnimated: false,
            isMobile: true,
          },
          {
            key: "camera",

            path: "moon/details/camera--centered.glb",
            material: {
              large: {
                texture: "moon-details/baked-moon-details__512.jpg",
                normal: null,
              },
            },
            position: [8, 0.8, -2],
            rotation: -0.6,
            scale: 0.5,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: false,
            isAnimated: false,
            isMobile: false,
          },

          {
            key: "box",

            path: "moon/details/box--centered.glb",
            material: {
              large: {
                texture: "moon-details/baked-moon-details__512.jpg",
                normal: null,
              },
            },
            position: [2, 0.2, 2],
            rotation: 0.3,
            scale: 0.4,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: false,
            isAnimated: false,
            isMobile: false,
          },
          {
            key: "box",

            path: "moon/details/box--centered.glb",
            material: {
              large: {
                texture: "moon-details/baked-moon-details__512.jpg",
                normal: null,
              },
            },
            position: [3, 0.2, 1],
            rotation: -0.7,
            scale: 0.4,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: false,
            isAnimated: false,
            isMobile: false,
          },
          {
            key: "antenna",

            path: "moon/details/antenna--centered.glb",
            material: {
              large: {
                texture: "moon-details/baked-moon-details__512.jpg",
                normal: null,
              },
            },
            position: [-5, 1, 2],
            rotation: 1,
            scale: 0.4,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: false,
            isAnimated: false,
            isMobile: false,
          },
          {
            key: "astronaut",

            path: "moon/details/astronaut.glb",
            material: {
              large: {
                texture: "moon-details/astronaut.jpg",
                normal: null,
              },
              small: {
                texture: "moon-details/astronaut__128.jpg",
                normal: null,
              },
            },
            position: [0, -0.1, 4],
            rotation: 1,
            scale: 0.4,
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isEmitShadow: false,
            isAnimated: false,

            timeline: null,
            gltf: null,
            time: 0,
            isMobile: true,
          },
        ],
      },
      loader: {
        textureLoader: null,
        intervalProgress: null,
        timeOutStartGame: null,
        totalProgress: 0,
        mapTotalProgress: 0,
      },
      //   meshes
      meshes: {
        vehicule: {
          chassis: null,
          wheelVisuals: [],
          previousRoverPosition: { x: 0, y: 0 },
          isResetting: false,
          timeout: null,
        },
        shadowLM: {
          mesh: null,
        },
        moon: {
          repeatTexture: 15.0,
        },

        astronaut: null,
      },
      particles: {
        maxPoints: {
          fire: 100,
        },
        fire: {
          particles: [],
          points: undefined,
          geometry: undefined,
          alphaSpline: null,
          colourSpline: null,
          sizeSpline: null,
          alphaSplineS: null,
          colourSplineS: null,
          sizeSplineS: null,
          alphaSplineF: null,
          colourSplineF: null,
          sizeSplineF: null,
        },
      },

      //   physics
      physics: {
        height: 15.0,
      },

      flag: {
        texture: null,
        geometry: null,
        material: null,
        mesh: null,
      },

      gsapAnimation: {
        intro: {
          timeline: null,
          timelineEnd: null,
          isAnimationRunning: true,
          isAnimationEnded: false,
        },
        gallery: {
          timeline: null,
          isStarted: false,
        },
        endGallery: {
          timeline: null,
          isStarted: false,
        },
        goToNext: {
          timeline: null,
          isAnimationStarted: false,
        },
      },
      gltfAnimation: {
        astronaut: {
          time: 0,
          animationMixer: null,
          isRotationRunning: true,
          isRotationRunningTimeout: null,
          // animation: null,
          pivotPoint: null,
        },
      },
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.userDevice.isMobile,
      isNavOpen: (state) => state.navigation.isNavOpen,
      isGamePaused: (state) => state.sharedGamePlay.isGamePaused,
      isGamePlayDebug: (state) => state.sharedGamePlay.isGamePlayDebug,
      isGameControlDisabled: (state) =>
        state.sharedGamePlay.isGameControlDisabled,

      isIntroAnimationVisible: (state) =>
        state.sharedGamePlay.isIntroAnimationVisible,
      isSceneReady: (state) => state.sharedTransition.isSceneReady,
      isTransitionLongEnough: (state) =>
        state.sharedTransition.isTransitionLongEnough,

      isUserDeviceReady: (state) => state.userDevice.isUserDeviceReady,
      userBrowser: (state) => state.userDevice.browser,
      isUserGoingToNextPage: (state) => state.gallery.isUserGoingToNextPage,
    }),
    totalProgress() {
      return this.galleryProgress + this.calculateCombinedProgress() / 2;
    },
    isGamePlayDebugging() {
      return this.isDevEnv() && this.isGamePlayDebug;
    },
    isSafariOrMobile() {
      return this.userBrowser.includes("safari") || this.isMobile;
    },
    lengthModelsToLoad() {
      return this.isMobile ? 3 : this.gltf.GLFTList.length;
    },
  },
  watch: {
    isUserDeviceReady(bool) {
      bool && !this.initiate.isInitiating ? this.delayInit() : null;
    },
    isSceneReady() {
      this.startAnimation();
    },
    isSceneReadyLocally() {
      this.startAnimation();
    },
    isGalleryAnimationRunning(bool) {
      this.runGalleryAnimation(bool);
    },
    isTransitionLongEnough() {
      this.startAnimation();
    },
    isEndOfGallery(val) {
      this.toggleEndGalleryIsStarted(!val);
      this.toggleEndGalleryTimeline();
    },
    isNavOpen(bool) {
      this.runGame(!bool);

      bool
        ? this.toggleIntroTimeline(!bool)
        : (!this.initiate.isInitiating ? this.nextAnimationFrame() : null,
          this.runIntroTimelineFromNavOpen());
    },
    isUserGoingToNextPage(bool) {
      bool ? this.setGoToNextCameraTimeline() : null;
    },
  },

  mounted() {
    // this.resetGamePlayStore();
    this.toggleTransitionIsLongEnough(false);
    this.toggleIsSceneReadyLocally(false);
    // this.toggleIsGamePausedOnce(false);

    this.delayMountedMethods();
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animation);
    this.animation = undefined;

    // reset event listener

    window.removeEventListener("resize", this.onResize);

    // destroy gsap animation
    this.killTimeLine();
    this.destroyTimeout(this.initiate.timeout);
    this.destroyTimeout(this.initiate.timeoutMounted);

    // reset global variables

    this.clearDisplayAndHideTimeOut();
    this.resetGamePlayStore();
  },
  methods: {
    isTheSceneLoadedAndReadyToStart() {
      return !this.isNavOpen && this.totalProgress >= 1
        ? this.startAnimation()
        : null;
    },
    ////////////////////////////////
    //       START ON START METHODS
    ////////////////////////////////

    delayMountedMethods() {
      this.initiate.timeoutMounted = setTimeout(() => {
        this.methodsOnMount();
        this.destroyTimeout(this.initiate.timeoutMounted);
      }, 300);
    },
    methodsOnMount() {
      this.setWindowSize();
      this.setInnerWidthDesktopState();
      this.isUserDeviceReady && !this.initiate.isInitiating
        ? this.delayInit()
        : null;
      // Register an event listener when the Vue component is ready
      window.addEventListener("resize", this.onResize);
    },

    toggleIsSceneReadyLocally(bool) {
      this.isSceneReadyLocally = bool;
    },

    /*------------------------------
      Start Reset on mount
    ------------------------------*/

    // gameLoop is pause when game pause, so ensure that gameLoop don't run twince on mount
    // toggleIsGamePausedOnce(bool) {
    //   this.isGamePausedOnce = bool;
    // },

    /*------------------------------
      End Reset on mount
    ------------------------------*/

    //   set window width
    setWindowSize() {
      this.cameras.windowUser.width = window.innerWidth;

      this.cameras.windowUser.height = this.isDesktop()
        ? window.innerHeight
        : window.screen.height;
    },

    setInnerWidthDesktopState() {
      this.cameras.windowUser.isInnerWidthDesktop = this.isDesktop();
    },

    onResize() {
      // Update sizes
      this.setWindowSize();

      // Update camera
      this.camera ? this.updateCameraOnResize() : null;

      this.renderer ? this.setRenderSize() : null;

      this.loader.isSceneReady && this.isInnerWidthNewer()
        ? this.cameraAndAnimationResize()
        : null;

      this.effectComposer ? this.setEffectComposerRenderTarget() : null;
    },

    updateCameraOnResize() {
      this.camera.aspect =
        this.cameras.windowUser.width / this.cameras.windowUser.height;
      this.camera.updateProjectionMatrix();
    },

    isInnerWidthNewer() {
      return this.cameras.windowUser.isInnerWidthDesktop != this.isDesktop();
    },

    setRenderSize() {
      // Update renderer
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },

    setEffectComposerRenderTarget() {
      this.effectComposer.renderTarget = new THREE.WebGLRenderTarget(
        window.innerWidth,
        window.innerHeight,
        {
          minFilter: THREE.LinearFilter,
          magFilter: THREE.LinearFilter,
          format: THREE.RGBAFormat,
          encoding: THREE.sRGBEncoding,
        }
      );
    },

    ////////////////////////////////
    //       END ON START METHODS
    ////////////////////////////////

    ////////////////////////////////
    //       START INIT SCENE AND RENDER
    ////////////////////////////////

    delayInit() {
      this.initiate.isInitiating = true;
      this.initiate.timeout = setTimeout(
        () => {
          this.init();
          this.destroyTimeout(this.initiate.timeout);
        },
        this.isMobile ? 2000 : 300
      );
    },

    init() {
      if (this.initiate.isInitStarted) return;
      this.initiate.isInitStarted = true;
      // ensure that user have enough time to read the tutorial
      this.startTimerTransitionIsLongEnough();

      this.setBaseScene();

      this.setLight();

      /*------------------------------
      Start add meshes to the scenes
      ------------------------------*/

      this.loadModels();
      this.addMeshesToScene();
      /*------------------------------
      End add meshes to the scenes
      ------------------------------*/

      /*------------------------------
      Start Run Progress Loading
      ------------------------------*/
      this.runProgressLoadingPage();
      /*------------------------------
      End Run Progress Loading
      ------------------------------*/

      this.clock.then = this.clock.clock.getElapsedTime();

      this.setCamera();
      this.setControl();
      this.startAnimationCamera();

      this.setRenderer();
      // this.setPostProcessing();

      /*------------------------------
      Start Particles
      ------------------------------*/
      this.setParticleSystem();
      /*------------------------------
      End Particles
      ------------------------------*/

      this.setFlag();
      this.gameLoop();
      this.initiate.isInitiating = false;
    },

    //======= START BASE THREEJS =======//

    setBaseScene() {
      // set container
      this.container = this.$refs.scene__container;

      // create scene
      this.scene = new THREE.Scene();

      this.isDevEnv() ? this.setHelpers() : "";

      this.setClock();
    },

    setHelpers() {
      // stats
      this.helpers.stats = new Stats();
      document.body.appendChild(this.helpers.stats.dom);

      // axes helpers
      const axesHelper = new THREE.AxesHelper(5);
      // x y z
      this.scene.add(axesHelper);

      // set gui globaly
      // this.setGUI();
    },
    // setGUI() {
    //   this.helpers.gui = new GUI();
    //   // const debugObject = {};
    // },
    setClock() {
      this.clock.clock = new THREE.Clock();
    },

    //======= END BASE THREEJS =======//

    //======= START LIGHTS =======//

    setLight() {
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      this.scene.add(ambientLight);

      this.lights.directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      // this.lights.directionalLight.position.set(-0, 120, -0.5);
      this.lights.directionalLight.position.set(-0, 10, -0);
      this.lights.directionalLight.castShadow = true;
      let d = 15;
      this.lights.directionalLight.shadow.camera.left = -d;
      this.lights.directionalLight.shadow.camera.right = d;
      this.lights.directionalLight.shadow.camera.top = d;
      this.lights.directionalLight.shadow.camera.bottom = -d;

      this.lights.directionalLight.shadow.camera.near = 0;
      this.lights.directionalLight.shadow.camera.far = 20;

      this.lights.directionalLight.shadow.mapSize.y = this.lights.directionalLight.shadow.mapSize.x = this
        .isMobile
        ? 64
        : 256 * 3;

      this.lights.directionalLight.target.position.set(-0.5, 0, 0.5);
      this.scene.add(this.lights.directionalLight.target);

      this.scene.add(this.lights.directionalLight);

      // this.isDevEnv() ? this.directionLightHelper() : null;
    },
    // directionLightHelper() {
    //   const directionalLightHelper = new THREE.DirectionalLightHelper(
    //     this.lights.directionalLight,
    //     0.1
    //   );
    //   this.scene.add(directionalLightHelper);
    //   const directionalLightCameraHelper = new THREE.CameraHelper(
    //     this.lights.directionalLight.shadow.camera
    //   );
    //   directionalLightCameraHelper.visible = true;
    //   this.scene.add(directionalLightCameraHelper);
    // },

    //======= END LIGHTS =======//

    //======= START CAMERA AND CONTROL =======//

    setCamera() {
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.cameras.windowUser.width / this.cameras.windowUser.height,
        0.1,
        this.isMobile ? 500 : 2000
      );
      this.camera.position.set(
        this.cameras.defaultCameraPosition.camX,
        this.cameras.defaultCameraPosition.camY,
        this.cameras.defaultCameraPosition.camZ
      );

      this.scene.add(this.camera);
    },

    setControl() {
      this.controls = new OrbitControls(this.camera, this.container);
    },

    //======= END CAMERA AND CONTROL =======//

    //======= START RENDERER  =======//

    setRenderer() {
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.container,
        powerPreference: "high-performance",
        antialias: !this.isSafariOrMobile,
      });
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );

      this.setRenderSize();

      this.renderer.outputEncoding = THREE.sRGBEncoding;

      // shadow
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMapSoft = !this.isMobile;
      // https://stackoverflow.com/questions/15140832/what-are-the-possible-shadowmaptypes
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    },

    /*------------------------------
    Start Post Processing
    ------------------------------*/

    // setPostProcessing() {
    //   this.setEffectComposerRenderTarget();

    //   this.effectComposer.composer = new EffectComposer(
    //     this.renderer,
    //     this.effectComposer.renderTarget
    //   );
    //   this.effectComposer.composer.setPixelRatio(
    //     Math.min(window.devicePixelRatio, 2)
    //   );
    //   this.effectComposer.composer.setSize(
    //     this.cameras.windowUser.width,
    //     this.cameras.windowUser.height
    //   );
    //   this.effectComposer.composer.addPass(
    //     new RenderPass(this.scene, this.camera)
    //   );
    // },

    /*------------------------------
    End Post Processing
    ------------------------------*/

    //======= END RENDERER  =======//

    ////////////////////////////////
    //       END INIT SCENE AND RENDER
    ////////////////////////////////

    //======= START GAME LOOP =======//

    gameLoop() {
      // don't display the stats if on prod. Maybe this dev stuff should be handle differently
      // this.isDevEnv() ? this.helpers.stats.update() : null; // try to save a tiny extra

      const elapsedTime = this.clock.clock.getElapsedTime();
      this.clock.oldElapsedTime = elapsedTime;

      this.groupedRecudedFPSMethods(elapsedTime);

      // Render
      this.controls.update();

      this.renderer.render(this.scene, this.camera);

      // Call gameLoop again on the next frame
      this.isGamePaused ? null : this.nextAnimationFrame();
    },
    nextAnimationFrame() {
      this.animation = requestAnimationFrame(this.gameLoop);
    },

    groupedRecudedFPSMethods(elapsedTime) {
      this.reduced60FPS(elapsedTime);
      this.reduced10FPS(elapsedTime);
    },

    reduced60FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 60;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then60FPS;

      if (delta > interval) {
        this.particleSteps(0.05); // animate smokes and dust

        this.gltfAnimation.astronaut.animationMixer
          ? this.animationAstronaut()
          : null;

        this.flag.material.uniforms.uTime.value = this.clock.then60FPS * 2;

        // if rover is outside the perimeter reset it
        // this.physics.objectsToUpdate.length ? this.checkPositionRover() : null;
        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then60FPS = now - (delta % interval);
      }
    },
    reduced10FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 10;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then10FPS;

      if (delta > interval) {
        !this.gltfAnimation.astronaut.isRotationRunning &&
        this.gltfAnimation.astronaut.pivotPoint
          ? this.addParticles()
          : null;
        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then10FPS = now - (delta % interval);
      }
    },

    // To get the pixels, draw the image onto a canvas. From the canvas get the Pixel (R,G,B,A)
    // https://www.lukaszielinski.de/blog/posts/2014/11/07/webgl-creating-a-landscape-mesh-with-three-dot-js-using-a-png-heightmap/ // https://github.com/lukas2/threejs_landscape
    getTerrainPixelData(heightMapImg) {
      const sizeCanvas = 201;
      var canvas = document.createElement("canvas");

      canvas.width = sizeCanvas;
      canvas.height = sizeCanvas;
      canvas
        .getContext("2d")
        .drawImage(heightMapImg, 0, 0, sizeCanvas, sizeCanvas);

      var data = canvas
        .getContext("2d")
        .getImageData(0, 0, sizeCanvas, sizeCanvas).data;
      var normPixels = [];

      for (var i = 0, n = data.length; i < n; i += 4) {
        // get the average value of R, G and B.
        normPixels.push(
          ((data[i] + data[i + 1] + data[i + 2]) / 3 / 255) *
            this.physics.height
        );
      }
    },

    ////////////////////////////////
    //       START ADD MESHES TO SCENE
    ////////////////////////////////

    /*------------------------------
    Start Terrain
    ------------------------------*/

    addMeshesToScene() {
      const grassTexture = this.loadResponsiveTexture(
        "moon/textures/ground_grey_diff_128/ground_grey_diff_128__light.jpg",
        "moon/textures/ground_grey_diff_256/ground_grey_diff_256__light.jpg"
      );
      grassTexture.wrapS = grassTexture.wrapT = THREE.RepeatWrapping;

      const rockyTexture = this.loadResponsiveTexture(
        "moon/textures/ground_grey_diff_128/ground_grey_diff_128__dark.jpg",
        "moon/textures/ground_grey_diff_256/ground_grey_diff_256__dark.jpg"
      );
      rockyTexture.wrapS = rockyTexture.wrapT = THREE.RepeatWrapping;

      const snowyTexture = this.loadResponsiveTexture(
        "moon/textures/ground_grey_diff_128/ground_grey_diff_128.jpg",
        "moon/textures/ground_grey_diff_256/ground_grey_diff_256.jpg"
      );
      snowyTexture.wrapS = snowyTexture.wrapT = THREE.RepeatWrapping;

      const normalmap = this.loadResponsiveTexture(
        "moon/normal/ground_grey_nor_128.jpg",
        "moon/normal/ground_grey_nor_4k.jpg"
      );
      normalmap.wrapS = normalmap.wrapT = THREE.RepeatWrapping;

      const heightMapSizeMain = this.isMobile ? 64 : 128 * 2;

      this.addMeshToHeightMap(
        heightmap,
        normalmap,
        [400, 400, heightMapSizeMain, heightMapSizeMain],
        this.meshes.moon.repeatTexture,
        true,
        grassTexture,
        rockyTexture,
        snowyTexture,
        {
          position: [0, -3.7, 0],
          rotation: Math.PI * 0.5,
        }
      );
    },

    addMeshToHeightMap(
      selectedheigthMap,
      normalmap,
      size,
      textureRepeat,
      receiveShadow,
      grassTexture,
      rockyTexture,
      snowyTexture,
      primaryShader,
      secondaryShader,
      thirdaryShader,
      forthShader,
      fifthShader,
      sithShader,
      seventhShader,
      eigthShader
    ) {
      // https://stemkoski.github.io/Three.js/Shader-Heightmap-Textures.html
      const bumpTexture = this.loader.textureLoader.load(selectedheigthMap);
      bumpTexture.wrapS = bumpTexture.wrapT = THREE.RepeatWrapping;

      const moonGeometry = new THREE.PlaneBufferGeometry(
        size[0],
        size[1],
        size[2],
        size[3]
      );

      // https://github.com/mrdoob/three.js/issues/8016#issuecomment-254371295
      const customUniforms = {
        bumpTexture: { type: "t", value: bumpTexture },
        bumpScale: { type: "f", value: this.physics.height },
        opacity: { type: "f", value: 1.0 },
        grassTexture: { type: "t", value: grassTexture },
        rockyTexture: { type: "t", value: rockyTexture },
        snowyTexture: { type: "t", value: snowyTexture },
        uRepeat: {
          value: textureRepeat,
        },
        normalRepeat: {
          value: this.isMobile ? 0.5 : 15.0,
        },
        normalIntensity: {
          value: this.isMobile ? 0.3 : 0.6,
        },
        normalMap: {
          type: "t",
          value: normalmap,
        },
      };

      var shaderUniforms = THREE.UniformsUtils.clone(
        THREE.UniformsLib["lights"]
      );
      var shaderUniformsNormal = THREE.UniformsUtils.clone(
        THREE.UniformsLib["normalmap"]
      );
      const uniforms = Object.assign(
        shaderUniforms,
        shaderUniformsNormal,
        customUniforms
      );
      const moonMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: moonVertexShader,
        fragmentShader: this.isFragmentForSafariOnly(),
        lights: true,
      });
      // https://stackoverflow.com/questions/11071693/enabling-an-extension-on-a-three-js-shader
      moonMaterial.extensions.derivatives = true;

      const moon = new THREE.Mesh(moonGeometry, moonMaterial);
      moon.rotation.x = -Math.PI * 0.5;
      moon.rotation.z = primaryShader.rotation;
      moon.position.set(
        primaryShader.position[0],
        primaryShader.position[1],
        primaryShader.position[2]
      );

      moon.receiveShadow = receiveShadow;
      this.scene.add(moon);

      secondaryShader
        ? this.cloneShader(
            moon,
            secondaryShader.rotation,
            secondaryShader.position
          )
        : null;

      thirdaryShader
        ? this.cloneShader(
            moon,
            thirdaryShader.rotation,
            thirdaryShader.position
          )
        : null;
      forthShader
        ? this.cloneShader(moon, forthShader.rotation, forthShader.position)
        : null;
      fifthShader
        ? this.cloneShader(moon, fifthShader.rotation, fifthShader.position)
        : null;
      sithShader
        ? this.cloneShader(moon, sithShader.rotation, sithShader.position)
        : null;
      seventhShader
        ? this.cloneShader(moon, seventhShader.rotation, seventhShader.position)
        : null;
      eigthShader
        ? this.cloneShader(moon, eigthShader.rotation, eigthShader.position)
        : null;
    },
    cloneShader(shaderToClome, rotation, position) {
      const clonedShader = shaderToClome.clone();
      clonedShader.rotation.x = -Math.PI * 0.5;
      clonedShader.rotation.z = rotation;
      clonedShader.position.set(position[0], position[1], position[2]);

      clonedShader.receiveShadow = true;
      this.scene.add(clonedShader);

      this.updateMapProgress();
    },

    updateMapProgress() {
      this.loader.mapTotalProgress = this.loader.mapTotalProgress + 1;
    },

    isFragmentForSafariOnly() {
      // const isSafari = window.safari !== undefined;
      // I didnt find yet a solution to fix a bug on Safari so until I find something I created a separated fragement shader
      return this.isSafariOrMobile ? moonSafariFragment : moonFragmentShader;
    },
    /*------------------------------
    End Terrain
    ------------------------------*/

    ////////////////////////////////
    //       END ADD MESHES
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MODELS
    ////////////////////////////////

    loadModels() {
      this.textureLoader();
      this.setBaseLoader();

      this.loopGLTFLoader();
    },

    setBaseLoader() {
      // draco always before GLTF Loader
      this.setDraco();
      this.setLoader();
    },

    setDraco() {
      this.gltf.dracoLoader = new DRACOLoader();
      //  path to a folder containing WASM/JS decoding libraries.
      this.gltf.dracoLoader.setDecoderPath("/vendors/draco/"); // path needs be public
      this.gltf.dracoLoader.preload();
    },
    setLoader() {
      // set loader
      this.gltf.GLTFLoader = new GLTFLoader();
      this.gltf.GLTFLoader.setDRACOLoader(this.gltf.dracoLoader);
    },

    ////////////////////////////////
    //       START LOAD TEXTURE
    ////////////////////////////////

    textureLoader() {
      // Texture loader
      this.loader.textureLoader = new THREE.TextureLoader();
    },

    loadResponsiveTexture(mobileTexture, desktopTexture) {
      return this.isMobile
        ? this.requiredLoad(mobileTexture)
        : this.requiredLoad(desktopTexture);
    },
    requiredLoad(path) {
      return this.loader.textureLoader.load(this.requireFile(path));
    },
    requireFile(path) {
      return require(`@/assets/textures/${path}`);
    },
    ////////////////////////////////
    //       END LOAD TEXTURE
    ////////////////////////////////

    loopGLTFLoader() {
      this.gltf.GLFTList.forEach((model, index) => {
        // this.gltfLoader(model, index);
        this.isItemVisible(model.isMobile)
          ? this.gltfLoader(model, index)
          : null;
      });
    },

    isItemVisible(isItemMobile) {
      return (this.isMobile && isItemMobile) || !this.isMobile;
    },

    gltfLoader(model, index) {
      const childMaterial = this.setMaterial(model.material);

      this.gltf.GLTFLoader.load(
        `/three-assets/${model.path}`,
        (gltf) => {
          gltf.scene.traverse((child) => {
            child.material = childMaterial;
            if (child.isMesh) {
              child.castShadow = model.isCastShadow;
              child.receiveShadow = model.isEmitShadow;
              child.frustumCulled = false;
            }
            child.material.side = THREE.DoubleSide;
          });

          this.scene.add(gltf.scene);

          gltf.scene.scale.set(model.scale, model.scale, model.scale);
          gltf.scene.position.set(
            this.gltf.GLFTList[index].position[0],
            this.gltf.GLFTList[index].position[1],
            this.gltf.GLFTList[index].position[2]
          );
          gltf.scene.rotation.y = this.gltf.GLFTList[index].rotation;

          // only some models have an animation

          if (model.key === "astronaut") {
            this.gltfAnimation.astronaut.animationMixer = new THREE.AnimationMixer(
              gltf.scene
            );

            gltf.animations.forEach((clip) => {
              this.gltfAnimation.astronaut.animationMixer
                .clipAction(clip)
                .play();
            });

            this.gltfAnimation.astronaut.animationMixer.timeScale = 1;

            this.meshes.astronaut = gltf;

            const geometry = new THREE.BoxGeometry(1, 1, 1);
            const material = new THREE.MeshBasicMaterial({ color: 0x000000 });
            const cube = new THREE.Mesh(geometry, material);
            cube.position.y = 2;
            this.scene.add(cube);

            // Pivot point
            this.gltfAnimation.astronaut.pivotPoint = new THREE.Object3D();
            cube.add(this.gltfAnimation.astronaut.pivotPoint);

            this.gltfAnimation.astronaut.pivotPoint.rotation.y =
              this.gltfAnimation.astronaut.pivotPoint.rotation.y + 1;

            // Position from pivot point to sphere 2
            this.meshes.astronaut.scene.rotation.y = -0.05;
            this.meshes.astronaut.scene.position.set(10, -2, 0);

            // make the pivotpoint the sphere's parent.
            this.gltfAnimation.astronaut.pivotPoint.add(
              this.meshes.astronaut.scene
            );
            this.gltfAnimation.astronaut.pivotPoint.add(
              this.particles.fire.points
            );
          }
        },
        (xhr) => {
          this.GLTFProgressLoader(index, xhr.loaded / xhr.total);
        },
        undefined
      );
    },

    setMaterial(material) {
      const bakedTexture = this.loadResponsiveTexture(
        this.isItemHasResponsiveTexture(material, "texture"),
        material.large.texture
      );

      bakedTexture.flipY = false;
      bakedTexture.encoding = THREE.sRGBEncoding;

      const bakedMaterial = new THREE.MeshStandardMaterial();

      bakedMaterial.map = bakedTexture;

      // can be refacotred or not"
      if (material.normal) {
        const bakedNormal = this.loadResponsiveTexture(
          this.isItemHasResponsiveTexture(material, "normal"),
          material.large.normal
        );
        bakedNormal.flipY = false;
        bakedNormal.encoding = THREE.sRGBEncoding;
        bakedMaterial.normalMap = bakedNormal;
        bakedMaterial.normalScale = new THREE.Vector2(3, 3);
        return bakedMaterial;
      } else {
        return bakedMaterial;
      }
    },
    isItemHasResponsiveTexture(material, textureOrNormal) {
      return material.small
        ? material.small[textureOrNormal]
        : material.large[textureOrNormal];
    },

    animationAstronaut() {
      this.gltfAnimation.astronaut.pivotPoint &&
      this.meshes.astronaut &&
      this.gltfAnimation.astronaut.isRotationRunning
        ? (this.gltfAnimation.astronaut.pivotPoint.rotation.y -= 0.0002)
        : null;

      this.gltfAnimation.astronaut.isRotationRunning =
        this.gltfAnimation.astronaut.time > 0.011 &&
        this.gltfAnimation.astronaut.time < 0.031;

      this.gltfAnimation.astronaut.time < 0.036
        ? (this.gltfAnimation.astronaut.time =
            this.gltfAnimation.astronaut.time + 0.0001)
        : (this.gltfAnimation.astronaut.time = 0);

      this.gltfAnimation.astronaut.animationMixer.update(
        this.gltfAnimation.astronaut.time
      );
    },

    ////////////////////////////////
    //       END ADD MODELS
    ////////////////////////////////

    ////////////////////////////////
    //       START PARTICLES
    ////////////////////////////////

    //======= START CREATE PARTICLES =======//

    setParticleSystem() {
      /*------------------------------
      Start materials
      ------------------------------*/

      const smokeMaterial = new THREE.ShaderMaterial({
        uniforms: {
          diffuseTexture: {
            value: this.requiredLoad("smoke/smoke.png"),
          },
          pointMultiplier: {
            value:
              (window.innerHeight /
                (2.0 * Math.tan((0.5 * 60.0 * Math.PI) / 180.0))) *
              this.renderer.getPixelRatio(),
          },
        },
        vertexShader: fireVertexShader,
        fragmentShader: fireFragmentShader,
        blending: THREE.CustomBlending,
        blendEquation: THREE.AddEquation,
        blendSrc: THREE.OneFactor,
        blendDst: THREE.OneMinusSrcAlphaFactor,
        depthTest: true,
        depthWrite: false,
        transparent: true,
        vertexColors: true,
      });

      this.particles.fire.particles = [];

      /*------------------------------
      End materials
      ------------------------------*/

      /*------------------------------
      Start particule geometry
      ------------------------------*/

      this.particles.fire.geometry = new THREE.BufferGeometry();

      const defaultBufferGeometryValuesFire = this.setDefaultStartingParticles(
        this.particles.maxPoints.fire
      );
      // const defaultBufferGeometryValuesSmoke = this.setDefaultStartingParticles(
      //   this.particles.maxPoints.smoke
      // );

      this.setParticlesAttributes(
        defaultBufferGeometryValuesFire.position,
        defaultBufferGeometryValuesFire.particlePosition,
        defaultBufferGeometryValuesFire.size,
        defaultBufferGeometryValuesFire.colour,
        defaultBufferGeometryValuesFire.angle,
        defaultBufferGeometryValuesFire.blend,
        "fire",
        defaultBufferGeometryValuesFire.uv,
        defaultBufferGeometryValuesFire.normal,
        false
      );

      /*------------------------------
      End particule geometry
      ------------------------------*/

      this.particles.fire.points = new THREE.Mesh(
        this.particles.fire.geometry,
        smokeMaterial
      );

      this.scene.add(this.particles.fire.points);

      const splinesArray = [
        // START FIRE
        {
          particleName: "fire",
          splineName: "alphaSpline",
          pointsArray: [
            [0.0, 0.0],
            [0.2, 1.0],
            [0.5, 1.0],
            [1.0, 0.0],
          ],
          isColour: false,
        },
        {
          particleName: "fire",
          splineName: "colourSpline",
          pointsArray: [
            [0.0, new THREE.Color(0x4d4d4d)],
            [1.0, new THREE.Color(0x202020)],
          ],
          isColour: true,
        },
        {
          particleName: "fire",
          splineName: "sizeSpline",
          pointsArray: [
            [0.0, 0.8],
            [0.5, 1.0],
            [1.0, 0.8],
          ],
          isColour: false,
        },
        // END FIRE
      ];

      splinesArray.forEach((element) => {
        this.addParticulePointForSpline(
          element.particleName,
          element.splineName,
          element.pointsArray,
          element.isColour
        );
      });

      this.updateGeometry("fire");
    },

    setDefaultStartingParticles(maxPoints) {
      return {
        position: new Float32Array(maxPoints * 6),
        particlePosition: new Float32Array(maxPoints * 3 * 6),
        size: new Float32Array(maxPoints * 6),
        colour: new Float32Array(maxPoints * 4 * 6),
        angle: new Float32Array(maxPoints * 6),
        blend: new Float32Array(maxPoints * 6),
        uv: new Float32Array(maxPoints * 6 * 6),
        normal: new Float32Array(maxPoints * 6),
      };
    },

    /*------------------------------
    Start Add points
    ------------------------------*/
    addParticulePointForSpline(
      particuleName,
      splineName,
      arrayOfPoints,
      isColour
    ) {
      isColour
        ? (this.particles[particuleName][splineName] = new LinearSpline(
            (t, a, b) => {
              const c = a.clone();
              return c.lerp(b, t);
            }
          ))
        : (this.particles[particuleName][splineName] = new LinearSpline(
            (t, a, b) => {
              return a + t * (b - a);
            }
          ));

      arrayOfPoints.forEach((points) => {
        this.particles[particuleName][splineName].AddPoint(
          points[0],
          points[1]
        );
      });
    },

    /*------------------------------
    End Add points
    ------------------------------*/

    /*------------------------------
    Start create or udpate particules attributes
    ------------------------------*/
    setParticlesAttributes(
      worldPosition,
      particlePosition,
      sizes,
      colours,
      angles,
      blends,
      particuleName,
      uvs,
      normals,
      isNeedUpdate
    ) {
      const arrayAttributes = [
        { key: "position", vecLength: 3, att: worldPosition },
        { key: "particlePosition", vecLength: 3, att: particlePosition },
        {
          key: "size",
          vecLength: 1,
          att: sizes,
        },
        { key: "colour", vecLength: 4, att: colours },
        { key: "angle", vecLength: 1, att: angles },
        { key: "blend", vecLength: 1, att: blends },
        { key: "uv", vecLength: 3, att: uvs },
        { key: "normal", vecLength: 3, att: normals },
      ];

      arrayAttributes.forEach((element) => {
        isNeedUpdate
          ? (this.particles[particuleName].geometry.setAttribute(
              `${element.key}`,
              new THREE.Float32BufferAttribute(element.att, element.vecLength)
            ),
            (this.particles[particuleName].geometry.attributes[
              element.key
            ].needsUpdate = true),
            (this.particles[particuleName].points.frustumCulled = false),
            this.particles[particuleName].geometry.computeBoundingSphere())
          : this.particles[particuleName].geometry.setAttribute(
              `${element.key}`,
              new THREE.BufferAttribute(element.att, element.vecLength)
            );
      });
    },

    /*------------------------------
    End create or udpate particules attributes
    ------------------------------*/

    //======= END SET PARTICLES ATTRIBUTES =======//

    //======= START ADD PARTICLES =======//

    addParticles() {
      if (this.particles.fire.particles.length > this.particles.maxPoints.fire)
        return;
      const n = 1;
      //  fire
      for (let i = 0; i < n; i++) {
        this.particles.fire.particles.push(
          this.sharedParticuleGenerator(
            [10, -2, 0],
            (Math.random() * 0.5 + 0.5) * 1.0,
            (Math.random() * 0.75 + 0.25) * 15.0,
            Math.random() * 0.5 * Math.PI,
            [
              Math.random() * (0.1 - -0.1) + -0.1,
              0.1,
              Math.random() * (0.1 - -0.1) + -0.1,
            ],
            1.0,
            "",
            0.8
          )
        );
      }
    },

    /*------------------------------
    Start particule generator
    ------------------------------*/
    // generate dynamicly the partcile to add
    sharedParticuleGenerator(
      position,
      size,
      life,
      rotation,
      velocity,
      blend,
      splineName,
      originalSize
    ) {
      // https://stackoverflow.com/questions/34215682/wrong-location-of-texture-on-the-second-triangle-of-buffergeometry-square
      return {
        // position: new THREE.Vector3(position[0], position[1], position[2]),
        position: new THREE.Vector3(position[0], position[1], position[2]),
        worldPosition: new Float32Array([
          -originalSize,
          -originalSize,
          0.0,

          originalSize,
          -originalSize,
          0.0,

          originalSize,
          originalSize,
          0.0,

          originalSize,
          originalSize,
          0.0,

          -originalSize,
          originalSize,
          0.0,

          -originalSize,
          -originalSize,
          0.0,
        ]),
        size: size,
        colour: new THREE.Color(),
        alpha: 1.0,
        life: life,
        maxLife: life,
        rotation: rotation,
        velocity: new THREE.Vector3(velocity[0], velocity[1], velocity[2]),
        blend: blend,
        splineName: splineName,
        uvs: new Float32Array(),
      };
    },
    /*------------------------------
    End particule generator
    ------------------------------*/

    //======= END ADD PARTICLES =======//

    updatedValue(particules, index, axis) {
      // return particules[index] ? particules[index].position[axis] : 0;
      return particules[index] ? 0 : 0;
    },
    updateGeometry(particleName) {
      const worldPosition = [];
      const particlesPosition = [];
      const sizes = [];
      const colours = [];
      const angles = [];
      const blends = [];
      const uvs = [];
      const normals = [];

      for (let p of this.particles[particleName].particles) {
        worldPosition.push(
          p.worldPosition[0],
          p.worldPosition[1],
          p.worldPosition[2],

          p.worldPosition[3],
          p.worldPosition[4],
          p.worldPosition[5],

          p.worldPosition[6],
          p.worldPosition[7],
          p.worldPosition[8],

          p.worldPosition[9],
          p.worldPosition[10],
          p.worldPosition[11],

          p.worldPosition[12],
          p.worldPosition[13],
          p.worldPosition[14],

          p.worldPosition[15],
          p.worldPosition[16],
          p.worldPosition[17]
        );

        for (let i = 0; i < 6; i++) {
          particlesPosition.push(p.position.x, p.position.y, p.position.z);
          blends.push(p.blend);
          colours.push(p.colour.r, p.colour.g, p.colour.b, p.alpha);
          sizes.push(p.currentSize);
          angles.push(p.rotation);

          uvs.push(
            -1.0,
            -1.0,
            0.0,

            1.0,
            -1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            -1.0,
            1.0,
            0.0,

            -1.0,
            -1.0,
            0.0
          );

          normals.push(
            -1.0,
            -1.0,
            0.0,

            1.0,
            -1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            -1.0,
            1.0,
            0.0,

            -1.0,
            -1.0,
            0.0
          );
        }
      }
      this.setParticlesAttributes(
        worldPosition,
        particlesPosition,
        sizes,
        colours,
        angles,
        blends,
        particleName,
        uvs,
        normals,
        true
      );
    },

    particleSteps(timeElapsed) {
      // previously used the timeElapsed instead of hard coded value, but it looked really bad when FTS was higher
      this.updateParticles(timeElapsed, "fire");
      this.updateGeometry("fire");
    },
    updateParticles(timeElapsed, particleName) {
      for (let p of this.particles[particleName].particles) {
        p.life -= timeElapsed;
      }

      this.particles[particleName].particles = this.particles[
        particleName
      ].particles.filter((p) => {
        return p.life > 0.0;
      });

      for (let p of this.particles[particleName].particles) {
        const t = 1.0 - p.life / p.maxLife;
        p.rotation += timeElapsed * 0.5;

        p.position.add(p.velocity.clone().multiplyScalar(timeElapsed));

        this.splineSwitcher(p, t, particleName, p.splineName);

        const drag = p.velocity.clone();
        drag.multiplyScalar(timeElapsed * 0.1);
        drag.x =
          Math.sign(p.velocity.x) *
          Math.min(Math.abs(drag.x), Math.abs(p.velocity.x));
        drag.y =
          Math.sign(p.velocity.y) *
          Math.min(Math.abs(drag.y), Math.abs(p.velocity.y));
        drag.z =
          Math.sign(p.velocity.z) *
          Math.min(Math.abs(drag.z), Math.abs(p.velocity.z));
        p.velocity.sub(drag);
      }

      this.particles[particleName].particles.sort((a, b) => {
        const d1 = this.camera.position.distanceTo(a.position);
        const d2 = this.camera.position.distanceTo(b.position);
        if (d1 > d2) {
          return -1;
        }
        if (d1 < d2) {
          return 1;
        }
        return 0;
      });
    },

    //======= START UPDATE SPLINE =======//

    splineSwitcher(p, t, particleName, alphaSpline) {
      return (
        (p.alpha = this.particles[particleName][
          `alphaSpline${alphaSpline}`
        ].Get(t)),
        (p.currentSize =
          p.size *
          this.particles[particleName][`sizeSpline${alphaSpline}`].Get(t)),
        p.colour.copy(
          this.particles[particleName][`colourSpline${alphaSpline}`].Get(t)
        )
      );
    },

    //======= END UPDATE SPLINE =======//
    ////////////////////////////////
    //       END PARTICLES
    ////////////////////////////////

    ////////////////////////////////
    //       START PROGRESS LOADER
    ////////////////////////////////
    runProgressLoadingPage() {
      this.loader.intervalProgress = setInterval(() => {
        this.calculateSceneProgressLoader();
        this.$store.commit(
          "sharedTransition/UPDATE_PROGRESS",
          this.calculateCombinedProgress(this.lengthModelsToLoad)
        );

        this.isPageFullyLoaded(this.lengthModelsToLoad)
          ? (this.toggleIsSceneReadyLocally(true), this.startAnimation())
          : null;
      }, 100);
    },
    calculateCombinedProgress(responsiveTotalGLTFLength) {
      return this.loader.totalProgress / responsiveTotalGLTFLength;
    },
    isPageFullyLoaded(responsiveTotalGLTFLength) {
      return this.calculateCombinedProgress(responsiveTotalGLTFLength) >= 1;
    },

    startAnimation() {
      this.isTransitionLongEnough &&
      !this.isNavOpen &&
      this.isSceneReady && // if !debugging
      this.isSceneReadyLocally &&
      !this.loader.isSceneReady &&
      !this.gsapAnimation.intro.isAnimationEnded
        ? (this.resetLoaderManagerInterval(),
          this.toggleIntroTimeline(!this.isGamePaused))
        : // ,this.destroyUselessStates()
          null;
    },

    // destroyUselessStates() {
    //   if (this.isGamePlayDebugging) return;
    //   this.gltf.GLFTList = [];
    //   this.gltf.GLTFLoader = null;
    // },

    resetLoaderManagerInterval() {
      this.loader.intervalProgress
        ? (clearInterval(this.loader.intervalProgress),
          (this.loader.intervalProgress = null))
        : null;
    },

    calculateSceneProgressLoader() {
      this.gltf.GLFTList.forEach((model) => {
        model.progress !== undefined && !model.isLoaded
          ? this.updateTotalProgress(model.progress)
          : null;

        model.progress === 1 ? (model.isLoaded = true) : null;
      });
    },
    updateTotalProgress(progressToAdd) {
      this.loader.totalProgress = this.loader.totalProgress + progressToAdd;
    },
    GLTFProgressLoader(index, progress) {
      // update this gltf progress
      this.gltf.GLFTList[index].progress = progress;
    },

    ////////////////////////////////
    //       END PROGRESS LOADER
    ////////////////////////////////

    ////////////////////////////////
    //       START INTRO ANIMATION
    ////////////////////////////////

    startAnimationCamera() {
      this.runGame(true);
      this.camera.position.set(170, 14, 134);
      this.controls.target.x = 0;

      this.controls.target.y = 180;
      this.controls.target.z = 0;

      const rotationYDuration = this.isGamePlayDebugging ? 1 : 5;
      const endingDuration = this.isGamePlayDebugging ? 1 : 5;
      const extraDelay = this.isGamePlayDebugging ? 1 : 5;
      this.gsapAnimation.intro.timeline = gsap
        .timeline({
          paused: true,
          delay: 0,
          onComplete: () => {
            this.toggleStateAnimation("intro", "isAnimationEnded", true);
            this.emitCompletionToParent();

            this.initGalleryAnimation();
            this.initEndOfGallery();
          },
        })
        .to(
          this.controls.target,
          {
            y: 0,

            ease: "power1.out",
            duration: rotationYDuration,
          },
          "started"
        )
        .to(
          this.camera.position,
          {
            x: 15,

            z: 10,

            ease: "power1.out",
            duration: rotationYDuration + extraDelay,
            delay: 0,
          },
          "started"
        )
        .to(
          this.camera.position,
          {
            y: 6,

            ease: "power1.inOut",
            duration: rotationYDuration - 1,
            delay: 0,
          },
          `started+=${rotationYDuration + (extraDelay - extraDelay + 1)}`
        )

        .to(
          this.camera.position,
          {
            x: -7,
            y: 12,
            z: 20,
            ease: "power1.inOut",
            duration: endingDuration,
            delay: 0.5,
          },
          "ending"
        )
        .to(
          this.controls.target,
          {
            x: 0 - 2,
            y: 0,
            z: 0 + 2,
            ease: "power1.inOut",
            duration: endingDuration,
            delay: 0.5,
          },
          "ending"
        );
    },
    setCameraPositionPreAnimation() {
      this.camera.position.set(8, 15, 15);

      this.controls.target.set(0, 0, 0);
    },

    toggleStateAnimation(animation, stateName, bool) {
      this.gsapAnimation[animation][stateName] = bool;
    },

    toggleScrollGlobally(bool) {
      //  disable during the animation
      this.$store.commit("shared/TOGGLE_SCROLL_GLOBALLY", bool);
    },
    emitCompletionToParent() {
      this.$emit("introAnimationCompleted");
    },

    toggleIntroTimeline(bool) {
      this.loader.isSceneReady = true;
      // this is needed if user toggle play pause during the animation
      if (
        this.gsapAnimation.intro.timeline &&
        !this.gsapAnimation.intro.isAnimationEnded
      ) {
        bool
          ? this.gsapAnimation.intro.timeline.play()
          : this.gsapAnimation.intro.timeline.pause();
      }
    },

    runIntroTimelineFromNavOpen() {
      this.isTransitionLongEnough &&
      this.isSceneReady &&
      this.isSceneReadyLocally
        ? this.toggleIntroTimeline(true)
        : null;
    },

    ////////////////////////////////
    //       END INTRO ANIMATION
    ////////////////////////////////

    ////////////////////////////////
    //       START GALLERY ANIMATION
    ////////////////////////////////
    initGalleryAnimation() {
      // if (this.isMobile) return;
      this.gsapAnimation.gallery.timeline = gsap
        .timeline({ paused: true })
        .to(
          this.camera.position,
          {
            x: 0,
            y: 4,
            z: 7,
            ease: "power1.inOut",
            duration: this.isGamePlayDebugging ? 0.5 : 2,
          },
          "started"
        )
        .to(
          this.controls.target,
          {
            x: 0,
            y: 5,
            z: 0,
            ease: "power1.inOut",
            duration: this.isGamePlayDebugging ? 0.5 : 2,
          },
          "started"
        );
    },
    runGalleryAnimation(bool) {
      if (!this.gsapAnimation.gallery.timeline) return;
      bool
        ? this.gsapAnimation.gallery.timeline.play()
        : this.gsapAnimation.gallery.timeline.reverse();
    },
    ////////////////////////////////
    //       END GALLERY ANIMATION
    ////////////////////////////////

    ////////////////////////////////
    //       START END OF GALLERY ANIMATION
    ////////////////////////////////
    initEndOfGallery() {
      const endingDuration = this.isGamePlayDebugging ? 1 : 2;
      this.gsapAnimation.endGallery.timeline = gsap
        .timeline({
          repeatDelay: 0,
          paused: true,

          onComplete: () => {
            this.toggleEndGalleryIsStarted(true);
            setTimeout(() => {
              this.toggleScrollGlobally(false);
            }, 1000);
          },
          onReverseComplete: () => {
            setTimeout(() => {
              this.toggleScrollGlobally(false);
            }, 1000);
          },
        })
        .to(
          this.camera.position,
          {
            x: 2,
            y: 5,
            z: 10,
            ease: "power1.inOut",
            duration: endingDuration,
          },
          "enAnimation"
        )
        .to(
          this.controls.target,
          {
            x: 5,
            y: 0,
            z: 0,
            ease: "power1.inOut",
            duration: endingDuration,
            delay: 0,
          },
          "enAnimation"
        );
    },

    //======= START START ANIMATION =======//

    toggleEndGalleryTimeline() {
      this.toggleScrollGlobally(true);
      this.gsapAnimation.endGallery.isStarted
        ? this.resetEndOfGallery()
        : this.startEndOfGallery();
    },
    startEndOfGallery() {
      this.gsapAnimation.endGallery.timeline.play();
    },
    resetEndOfGallery() {
      this.toggleEndGalleryIsStarted(false);
      this.gsapAnimation.endGallery.timeline.reverse();
    },
    toggleEndGalleryIsStarted(bool) {
      this.gsapAnimation.endGallery.isStarted = bool;
      this.toggleProgressPage(bool);
    },
    toggleProgressPage(bool) {
      bool
        ? this.$store.commit(
            "sharedTransition/SET_NEW_TRANSITION",
            "exploration"
          )
        : this.$store.commit("sharedTransition/TOGGLE_TRANSITION", false);
    },

    //======= END START ANIMATION =======//
    ////////////////////////////////
    //       END END OF GALLERY ANIMATION
    ////////////////////////////////

    ////////////////////////////////
    //       START USER GOING TO NEXT PAGE
    ////////////////////////////////
    setGoToNextCameraTimeline() {
      this.toggleStateAnimation("goToNext", "isAnimationStarted", true);
      const endingDuration = this.isGamePlayDebugging ? 1 : 5;
      this.gsapAnimation.goToNext.timeline = gsap.timeline({}).to(
        this.controls.target,
        {
          x: 4,
          y: 25,
          z: 0,
          ease: "power1.inOut",
          duration: endingDuration,
        },
        "goToNext"
      );
    },
    ////////////////////////////////
    //       END USER GOING TO NEXT PAGE
    ////////////////////////////////

    ////////////////////////////////
    //       START FLAG
    ////////////////////////////////
    setFlag() {
      const flagTexture = this.requiredLoad("moon-details/flag.jpg");

      const flagGeometry = new THREE.PlaneGeometry(1, 0.75, 16, 16);
      // // Material
      this.flag.material = new THREE.ShaderMaterial({
        vertexShader: flagVertexShader,
        fragmentShader: flagFragmentShader,
        transparent: false,
        depthWrite: true,
        side: THREE.DoubleSide,
        uniforms: {
          uFrequency: { value: new THREE.Vector2(2000, 3) },
          uTime: { value: 0 },
          uColor: { value: new THREE.Color("orange") },
          uTexture: { value: flagTexture },
          speed: { value: 10 },
        },
      });

      const flagMesh = new THREE.Mesh(flagGeometry, this.flag.material);

      flagMesh.position.set(-3, 1.6, 5);

      this.scene.add(flagMesh);

      this.addMast();
    },
    addMast() {
      const mastGeometry = new THREE.BoxGeometry(0.04, 2, 0.04);
      const mastMaterial = new THREE.MeshBasicMaterial({
        color: 0x0c0c0c,
      });
      const mastMesh = new THREE.Mesh(mastGeometry, mastMaterial);

      mastMesh.position.set(-3.51, 1, 5);
      this.scene.add(mastMesh);
    },
    ////////////////////////////////
    //       END FLAG
    ////////////////////////////////

    ////////////////////////////////
    //       START DESTROY TIMELINE
    ////////////////////////////////

    killTimeLine() {
      this.timeLineDestroyer("intro");
      this.timeLineDestroyer("gallery");
      this.timeLineDestroyer("endGallery");
      this.timeLineDestroyer("goToNext");
    },

    timeLineDestroyer(name) {
      this.gsapAnimation[name].timeline
        ? (this.gsapAnimation[name].timeline.kill(),
          (this.gsapAnimation[name].timeline = null))
        : null;
    },
    destroyTimeout(interval) {
      interval ? (clearTimeout(interval), (interval = null)) : null;
    },

    ////////////////////////////////
    //       END DESTROY TIMELINE
    ////////////////////////////////

    ////////////////////////////////
    //       START RESIZE
    ////////////////////////////////
    cameraAndAnimationResize() {
      this.setInnerWidthDesktopState();

      if (this.gsapAnimation.endGallery.isStarted) {
        return this.restartEndGalleryAnimation();
      } else if (
        (this.loader.isSceneReady &&
          this.gsapAnimation.intro.isAnimationEnded) ||
        this.gsapAnimation.goToNext.isAnimationStarted
      ) {
        return this.upCameraPositionOnResize();
      } else {
        return this.restartIntroAnimation();
      }
    },

    restartEndGalleryAnimation() {
      this.upCameraPositionOnResize();
      this.startEndOfGallery();
    },

    upCameraPositionOnResize() {
      this.resetEndGalleryAnimation();
    },

    resetEndGalleryAnimation() {
      this.killTimeLine("endGallery", "cameraTimeLine");
      this.toggleEndGalleryIsStarted(false);
    },
    ////////////////////////////////
    //       END RESIZE
    ////////////////////////////////

    ////////////////////////////////
    //       START UTILS
    ////////////////////////////////
    isDesktop() {
      return window.innerWidth >= 640;
    },
    ////////////////////////////////
    //       END UTILS
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/config/vars.scss";
@import "@/assets/scss/config/mixins.scss";
@import "@/assets/scss/config/responsive.scss";

.scene {
  img {
    height: 100px;
    width: 100px;
  }
  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: -10;
  }
}
.dg.ac {
  z-index: 2 !important;
}
</style>
