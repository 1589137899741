export default {
  methods: {
    toggleDashboardBottom(key, bool) {
      switch (key) {
        case "reset":
          this.$store.commit("dashboardBottom/TOGGLE_RESET", bool);
          break;
        case "joystick":
          this.$store.commit("dashboardBottom/TOGGLE_JOYSTICK", bool);
          break;
        case "arrow":
          this.$store.commit("dashboardBottom/TOGGLE_ARROW_UP", bool);
          break;
        case "alti":
          this.$store.commit("dashboardBottom/TOGGLE_ALTIMETER", bool);
          break;

        default:
          this.$store.commit("dashboardBottom/RESET_ALL");
          break;
      }
    },
  },
};
