<template>
  <div class="shared-button-audio">
    <shared-button-circle
      :aria-label="$t('shared.audio.ariaLabel')"
      :is-white="false"
      :is-black="isBlack"
      icon-name="audio"
      :rotation="0"
      :is-disable="false"
      :is-hold-to-toggle="false"
      @clicked="toggleAudio"
    />
  </div>
</template>

<script>
import SharedButtonCircle from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircle";
export default {
  components: {
    SharedButtonCircle,
  },
  props: {
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {},
  methods: {
    toggleAudio() {
      this.$store.commit("sharedAudio/toggleMute");
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-audio {
  z-index: 99;
}
</style>
