<template>
  <div class="dashboard-warning-main-lines">
    <ul class="dashboard-warning-main-lines__list">
      <li
        v-for="index in 14"
        :key="index"
        class="dashboard-warning-main-lines__list__line"
      />
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.dashboard-warning-main-lines {
  display: flex;
  flex: 1;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &__list {
    --clip-path-lines: polygon(
      0% 0%,
      0% 100%,
      15% 100%,
      15% 15%,
      85% 15%,
      85% 85%,
      15% 85%,
      15% 100%,
      100% 100%,
      100% 0%
    );
    display: grid;
    grid-auto-flow: column;
    gap: 2rem;
    width: 100%;
    height: 100%;
    clip-path: var(--clip-path-lines);
    // @include teq-breakpoint($small) {
    //   --clip-path-lines: polygon(
    //     0% 0%,
    //     0% 100%,
    //     15% 100%,
    //     15% 15%,
    //     85% 15%,
    //     85% 85%,
    //     15% 85%,
    //     15% 100%,
    //     100% 100%,
    //     100% 0%
    //   );
    //   //   --clip-path-lines: polygon(
    //   //     0% 0%,
    //   //     0% 100%,
    //   //     25% 100%,
    //   //     25% 15%,
    //   //     75% 15%,
    //   //     75% 85%,
    //   //     25% 85%,
    //   //     25% 100%,
    //   //     100% 100%,
    //   //     100% 0%
    //   //   );
    // }
    // @include teq-breakpoint($large) {
    //   --clip-path-lines: polygon(
    //     0% 0%,
    //     0% 100%,
    //     15% 100%,
    //     15% 15%,
    //     85% 15%,
    //     85% 85%,
    //     15% 85%,
    //     15% 100%,
    //     100% 100%,
    //     100% 0%
    //   );
    // }
    &__line {
      @include transition(1s ease-in-out all);
      @include transform(translateY(-25%) translateX(-5rem) rotate(20deg));

      @include shadow(0px 0px 6px 0px rgba(255, 211, 105, 0.5));
      height: 150%;
      width: 2rem;
      background: $goldenrod;

      .dashboard-warning--visible & {
        @include transform(translateY(-25%) translateX(0%) rotate(20deg));
      }
    }
  }
}
</style>
