<template>
  <shared-modal>
    <dashboard-modal
      :is-animation-started="isAnimationStarted"
      :which-modal="whichModal"
      :scene-name="sceneName"
    />
  </shared-modal>
</template>

<script>
import pauseGameMixin from "@/mixins/pauseGameMixin";

import DashboardModal from "@/components/Dashboard/DashboardModal/DashboardModal";
import SharedModal from "@/components/Shared/SharedModal/SharedModal";
export default {
  mixins: [pauseGameMixin],
  components: {
    DashboardModal,
    SharedModal,
  },
  props: {
    whichModal: {
      type: String,
      required: false,
      default: null,
    },
    sceneName: {
      type: String,
      required: true,
      validator: (val) => ["liftOff", "landing", "exploration"].includes(val),
    },
  },
  data() {
    return {
      isAnimationStarted: false,
      modalTimeout: null,
    };
  },
  mounted() {
    this.displayModal();
  },
  beforeDestroy() {
    this.destroyModalTimeout();
  },
  methods: {
    displayModal() {
      this.modalTimeout = setTimeout(() => {
        this.toggleModal(true);
        this.destroyModalTimeout();
      }, 500);
    },
    toggleModal(bool) {
      this.isAnimationStarted = bool;
    },
    destroyModalTimeout() {
      this.modalTimeout
        ? (clearTimeout(this.modalTimeout), (this.modalTimeout = null))
        : null;
    },
  },
};
</script>
