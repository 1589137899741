<template>
  <section class="scene">
    <!-- <button
      v-if="isDevEnv"
      class="scene__debug-button"
      @click="mockPlayAudio()"
    >
      start
    </button> -->
    <video
      v-if="isMobile"
      ref="tvVideo"
      id="tvVideo"
      playsinline
      :muted="isMute"
    >
      <source src="@/assets/videos/leap-for-mankind-intro-compressed-3.mp4" />
    </video>
    <video v-else ref="tvVideo" id="tvVideo" playsinline>
      <source src="@/assets/videos/leap-for-mankind-intro-compressed-3.mp4" />
    </video>
    <canvas ref="scene__container" class="scene__container"></canvas>
  </section>
</template>

<script>
import { mapState } from "vuex";
import isDevMixin from "@/mixins/isDevMixin";
import sharedAudioMixin from "@/mixins/sharedAudioMixin";

import * as THREE from "three";

// Post Processing
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";

import { RGBShiftShader } from "three/examples/jsm/shaders/RGBShiftShader.js";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass.js";

// SHADERS
import postProcessingFragment from "@/shaders/gallery/postProcessingFragment.glsl";
import postProcessingVertex from "@/shaders/gallery/postProcessingVertex.glsl";

// STATS
import Stats from "stats.js";
import { GUI } from "@/vendors/dat.gui.module.js";

import _ from "lodash";

export default {
  mixins: [isDevMixin, sharedAudioMixin],
  data() {
    return {
      // base
      container: null,
      scene: null,
      camera: null,
      animation: undefined,

      isInitiating: false,

      effectComposer: {
        composer: null,
        renderTarget: null,

        customPass: {
          customPass: null,
        },
        filmPass: {
          filmPass: null,
        },
      },

      //  helper
      helpers: {
        stats: null,
        clock: null,
      },
      clock: {
        clock: null,
        then60FPS: 0,
        then10FPS: 0,
      },
      cameras: {
        defaultCameraPosition: {
          camX: 0,
          camY: 0,
          camZ: 3,
        },

        windowUser: {
          width: 0,
          height: 0,
        },
      },
      video: {
        video: null,
        imageContext: null,
        texture: null,
        fadeInOutInterval: null,
        volumeRate: 0.1,
        volume: 0,
        isReady: false,
        timeout: null,
      },
    };
  },
  mounted() {
    this.setWindowSize();
    this.$nextTick(() => {
      // Register an event listener when the Vue component is ready
      window.addEventListener("resize", this.onResize);
      this.isUserDeviceReady && !this.isInitiating ? this.init() : null;
    });
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animation);
    this.animation = undefined;
    window.removeEventListener("resize", this.onResize);
    this.removeEventListenerVideo();
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.userDevice.isMobile,
      isMute: (state) => state.sharedAudio.isMute,
      isUserDeviceReady: (state) => state.userDevice.isUserDeviceReady,
      isVideoPlaying: (state) => state.intro.isVideoPlaying,
    }),
  },
  watch: {
    isMute(bool) {
      this.fadeInOutAudio(!bool);
    },
    isVideoPlaying(bool) {
      bool ? this.playVideo() : this.fadeInOutAudio(false);
    },
    isUserDeviceReady(bool) {
      bool && !this.isInitiating ? this.init() : null;
    },
  },

  methods: {
    ////////////////////////////////
    //       START ON START METHODS
    ////////////////////////////////
    //   set window width
    setWindowSize() {
      this.cameras.windowUser.width = window.innerWidth;
      this.cameras.windowUser.height = window.innerHeight;
    },

    onResize() {
      // Update sizes
      this.setWindowSize();
      this.setResponsiveTV();

      // Update camera
      this.camera.aspect =
        this.cameras.windowUser.width / this.cameras.windowUser.height;
      this.camera.updateProjectionMatrix();

      // Update renderer
      this.setRenderSize();

      this.effectComposer.composer ? this.setSizePostProcessing() : null;

      this.setEffectComposerRenderTarget();
    },

    setRenderSize() {
      // Update renderer
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },

    //======= START SET RESPONSIVE CAMERA =======//

    setResponsiveTV() {
      window.innerWidth < 1600
        ? (this.updateResponsiveZoom(this.zoomIncrease()),
          this.updateTVPass(this.calculateAreaMulti()))
        : (this.updateResponsiveZoom(1), this.updateTVPass(0));
    },
    zoomIncrease() {
      return this.isMobile
        ? (window.innerWidth / 2100) * 1.0
        : (window.innerWidth / 2100) * 1.2;
    },
    updateResponsiveZoom(zoom) {
      this.camera.zoom = zoom;
    },
    updateTVPass(value) {
      this.effectComposer.customPass.customPass.uniforms.areaMultiplicator.value =
        1.5 + value;
      this.effectComposer.customPass.customPass.uniforms.power.value =
        7.0 - value;
    },
    calculateAreaMulti() {
      return 2100 / window.innerWidth / 10;
    },

    //======= END SET RESPONSIVE CAMERA =======//

    setEffectComposerRenderTarget() {
      this.effectComposer.renderTarget = new THREE.WebGLRenderTarget(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height,
        {
          minFilter: THREE.LinearFilter,
          magFilter: THREE.LinearFilter,
          format: THREE.RGBAFormat,
          encoding: THREE.sRGBEncoding,
        }
      );
    },

    ////////////////////////////////
    //       END ON START METHODS
    ////////////////////////////////

    ////////////////////////////////
    //       START INIT SCENE AND RENDER
    ////////////////////////////////

    init() {
      this.isInitiating = true;
      this.setBaseScene();

      this.setLight();

      /*------------------------------
      Start add meshes to the scenes
      ------------------------------*/
      this.addMeshesToScene();
      /*------------------------------
      End add meshes to the scenes
      ------------------------------*/

      this.setCamera();

      this.setRenderer();
      this.setPostProcessing();
      this.onResize(); // ensure tv is at the right size
      this.gameLoop();
    },

    ////////////////////////////////
    //       START SET POST PROCESSING
    ////////////////////////////////
    setPostProcessing() {
      this.effectComposer.composer = new EffectComposer(this.renderer);

      this.effectComposer.composer.addPass(
        new RenderPass(this.scene, this.camera)
      );
      this.setSizePostProcessing();
      // add passes
      this.customPass();
      this.setFilmPass();
    },
    setSizePostProcessing() {
      this.effectComposer.composer.setPixelRatio(
        Math.min(window.devicePixelRatio, 2)
      );
      this.effectComposer.composer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );
    },

    //======= START ADD POST PROCESSING PASSES =======//

    customPass() {
      //custom shader pass
      const myEffect = {
        uniforms: {
          tDiffuse: { value: null },
          scrollSpeed: { value: 0.0 },
          areaMultiplicator: { value: 1.5 },
          power: { value: 7.0 },
          areaY: { value: 0.5 },
        },
        vertexShader: postProcessingVertex,
        fragmentShader: postProcessingFragment,
      };

      this.effectComposer.customPass.customPass = new ShaderPass(myEffect);
      this.effectComposer.customPass.customPass.renderToScreen = true;

      this.effectComposer.composer.addPass(
        this.effectComposer.customPass.customPass
      );
    },

    setFilmPass() {
      this.effectComposer.RGBShiftPass = new ShaderPass(RGBShiftShader);
      this.effectComposer.composer.addPass(this.effectComposer.RGBShiftPass);
      this.effectComposer.RGBShiftPass.enabled = true;
      this.effectComposer.RGBShiftPass.uniforms.amount.value = 0.003;
      this.effectComposer.RGBShiftPass.uniforms.angle.value = 1.2;

      this.effectComposer.filmPass.filmPass = new FilmPass(
        0.6,
        0.6,
        256,
        false
      );

      this.effectComposer.filmPass.filmPass.renderToScreen = true;
      this.effectComposer.composer.addPass(
        this.effectComposer.filmPass.filmPass
      );
      this.effectComposer.filmPass.filmPass.enabled = true;
    },

    //======= END ADD POST PROCESSING PASSES =======//

    ////////////////////////////////
    //       END SET POST PROCESSING
    ////////////////////////////////

    //======= START GAME LOOP =======//

    gameLoop() {
      // don't display the stats if on prod. Maybe this dev stuff should be handle differently
      this.isDevEnv() ? this.helpers.stats.update() : "";

      const elapsedTime = this.clock.clock.getElapsedTime();

      this.reduced60FPS(elapsedTime);
      this.reduced10FPS(elapsedTime);

      // this.renderer.render(this.scene, this.camera);
      this.effectComposer.composer.render();

      // Call gameLoop again on the next frame
      this.animation = requestAnimationFrame(this.gameLoop);
    },
    reduced60FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 60;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then60FPS;

      if (delta > interval) {
        // this.video.video.readyState === this.video.video.HAVE_ENOUGH_DATA to this.video.video.HAVE_ENOUGH_DATA to 4 instead of ready state to fix a bug on ios safari
        this.video.video.HAVE_ENOUGH_DATA >= 4 ? this.updateVideo() : null;

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then60FPS = now - (delta % interval);
      }
    },
    // isVideoReady(){
    //     this.video.video.HAVE_ENOUGH_DATA
    // },

    updateVideo() {
      this.video.imageContext.drawImage(this.video.video, 0, 0);
      this.video.texture ? (this.video.texture.needsUpdate = true) : null;
      // console.log(this.video.video.currentTime);
      // console.log(this.video.video.duration);
      !this.video.isReady ? this.videoLoaded() : null;
    },

    reduced10FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 10;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then10FPS;

      if (delta > interval) {
        this.progressionVideo(
          this.video.video.currentTime / this.video.video.duration
        );

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then10FPS = now - (delta % interval);
      }
    },

    //======= END GAME LOOP =======//

    //======= START BASE THREEJS =======//

    setBaseScene() {
      // set container
      this.container = this.$refs.scene__container;

      // create scene
      this.scene = new THREE.Scene();

      this.isDevEnv() ? this.setHelpers() : "";

      this.setClock();
    },

    setHelpers() {
      // stats
      this.helpers.stats = new Stats();
      document.body.appendChild(this.helpers.stats.dom);

      // axes helpers
      const axesHelper = new THREE.AxesHelper(5);
      // x y z
      this.scene.add(axesHelper);

      // set gui globaly
      this.setGUI();
    },
    setGUI() {
      this.helpers.gui = new GUI();
    },
    setClock() {
      this.clock.clock = new THREE.Clock();
    },

    //======= END BASE THREEJS =======//

    //======= START LIGHTS =======//

    setLight() {
      const ambientLight = new THREE.AmbientLight(0xffffff, 1);
      this.scene.add(ambientLight);
    },

    //======= END LIGHTS =======//

    //======= START CAMERA AND CONTROL =======//

    setCamera() {
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.cameras.windowUser.width / this.cameras.windowUser.height,
        0.1,
        100
      );
      this.camera.position.set(
        this.cameras.defaultCameraPosition.camX,
        this.cameras.defaultCameraPosition.camY,
        this.cameras.defaultCameraPosition.camZ
      );

      this.camera.lookAt(new THREE.Vector3(0, 0, 0));

      this.scene.add(this.camera);
    },

    //======= END CAMERA AND CONTROL =======//

    //======= START RENDERER  =======//

    setRenderer() {
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.container,
        powerPreference: "high-performance",
      });
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );

      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },

    //======= END RENDERER  =======//

    ////////////////////////////////
    //       END INIT SCENE AND RENDER
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MESHES TO SCENE
    ////////////////////////////////
    addMeshesToScene() {
      this.setVideo();
    },
    ////////////////////////////////
    //       END ADD MESHES TO SCENE
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD CUBE
    ////////////////////////////////

    setVideo() {
      this.video.video = this.$refs.tvVideo;

      this.video.video.addEventListener("ended", this.videoEnded, false);

      const videoImage = document.createElement("canvas");
      videoImage.width = 776;
      videoImage.height = 576;

      this.video.imageContext = videoImage.getContext("2d");
      // background color if no video present
      this.video.imageContext.fillStyle = "#000000";
      this.video.imageContext.fillRect(
        0,
        0,
        videoImage.width,
        videoImage.height
      );

      this.video.texture = new THREE.Texture(videoImage);
      this.video.texture.minFilter = THREE.LinearFilter;
      this.video.texture.magFilter = THREE.LinearFilter;

      const movieMaterial = new THREE.MeshBasicMaterial({
        map: this.video.texture,
        side: THREE.DoubleSide,
      });
      // the geometry on which the movie will be displayed;
      // 		movie image will be scaled to fit these dimensions.
      const movieGeometry = new THREE.PlaneGeometry(240 / 10, 140 / 10, 4, 4);
      //   var movieGeometry = new THREE.BoxGeometry(1, 1, 1);
      const movieScreen = new THREE.Mesh(movieGeometry, movieMaterial);
      const movieScreenPosition = this.isMobile ? -6 : -10;
      movieScreen.position.set(0, 0, movieScreenPosition);
      this.scene.add(movieScreen);
    },

    playVideo() {
      this.video.video.play();
      this.video.video.volume = 0;
    },

    videoLoaded() {
      // run it once
      this.video.isReady = true;
      // used for the loader

      this.$store.dispatch("intro/UPDATE_INTRO_LOAD", 1);
    },

    ////////////////////////////////
    //       END ADD CUBE
    ////////////////////////////////

    ////////////////////////////////
    //       START VIDEO ENDED
    ////////////////////////////////
    videoEnded(e) {
      // allow audio globaly (simply by set intro.isVislbe to false)
      this.$store.dispatch("intro/TOGGLE_VIDEO", false);
      this.video.timeout = setTimeout(() => {
        this.$store.dispatch("intro/HIDE_INTRO", false);
        this.updateAudioHome();
        this.destroyTimeout(this.video.timeout);
      }, 1000);
      // this this video
      // trigger global audio if needed
    },
    updateAudioHome() {
      this.updateMusicTrack("Home", "set");
    },

    progressionVideo(progress) {
      progress > 0 && this.isVideoPlaying
        ? this.$store.dispatch("intro/PROGRESS_VIDEO", progress)
        : null;
    },
    ////////////////////////////////
    //       END VIDEO ENDED
    ////////////////////////////////

    ////////////////////////////////
    //       START AUDIO
    ////////////////////////////////
    fadeInOutAudio(bool) {
      // if audio already debouncing so stop it now and the next fade will update it
      this.clearIntervalFadeInOut();

      this.video.fadeInOutInterval = setInterval(() => {
        this.debouncedFunction(bool);
      }, 50);
    },

    debouncedFunction: _.throttle(function (bool) {
      this.updateVolume(bool);
    }, 40),

    updateVolume(bool) {
      bool ? this.increaseVolume() : this.decreaseVolume();
    },
    increaseVolume() {
      this.video.volume < 0.99
        ? ((this.video.volume = this.video.volume + this.video.volumeRate),
          this.updateAudioVideo())
        : this.clearIntervalFadeInOut();
    },
    decreaseVolume() {
      this.video.volume > 0.1
        ? ((this.video.volume = this.video.volume - this.video.volumeRate),
          this.updateAudioVideo())
        : (this.video.volume = 0);
    },
    clearIntervalFadeInOut() {
      this.video.fadeInOutInterval
        ? clearInterval(this.video.fadeInOutInterval)
        : null;
    },
    updateAudioVideo() {
      this.video.video.volume = this.video.volume;
    },

    ////////////////////////////////
    //       END AUDIO
    ////////////////////////////////

    removeEventListenerVideo() {
      this.video.video.removeEventListener("ended", this.videoEnded, false);
      // this.video.video.removeEventListener("canplay", this.videoLoaded);
    },
    destroyTimeout(methodName) {
      methodName ? (clearTimeout(methodName), (methodName = null)) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.scene {
  height: 100vh;
  width: 100vw;
  z-index: 9;
  &__debug-button {
    position: fixed;
    top: 1px;
    left: 50%;
    z-index: 99;
  }
  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 2;
    pointer-events: none;
  }
}
video {
  display: none;
  z-index: -99; // fix issue on safari
}
</style>
