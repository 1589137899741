<template>
  <div id="app" class="app" :class="{ 'app--mobile': isMobile }">
    <navigation />
    <transition name="fade-progress">
      <shared-progress v-if="isPageTransitionVisible" />
    </transition>
    <router-view />
    <svgIcons />

    <shared-audio-manager />
  </div>
</template>
<script>
import { mapState } from "vuex";

import detectDeviceMixin from "@/mixins/detectDeviceMixin.js";
import sharedAudioMixin from "@/mixins/sharedAudioMixin";
import sharedFXAudioMixin from "@/mixins/sharedFXAudioMixin";

import Navigation from "@/components/Navigation/Navigation";
import SharedAudioManager from "@/components/Shared/SharedAudio/SharedAudioManager";
import SharedProgress from "@/components/Shared/SharedProgress/SharedProgress";
import svgIcons from "@/assets/svg/svgIcons";
export default {
  mixins: [detectDeviceMixin, sharedAudioMixin, sharedFXAudioMixin],
  components: {
    Navigation,
    SharedAudioManager,
    SharedProgress,
    svgIcons,
  },
  computed: {
    ...mapState({
      isPageTransitionVisible: (state) =>
        state.sharedTransition.isPageTransitionVisible,
      isMobile: (state) => state.userDevice.isMobile,
    }),
  },
  watch: {
    $route() {
      this.isScrollablePage();
      this.disableIntroIfUserNotLandingFromHome();
      this.$nextTick(() => {
        this.updateAudio("update");
      });
    },
  },
  mounted() {
    this.isScrollablePage();
    this.disableIntroIfUserNotLandingFromHome();

    this.setUserDevice();
    this.$nextTick(() => {
      this.enableBodyToScroll();
      this.updateAudio("set");
      window.addEventListener("resize", this.onResize);
    });
  },

  methods: {
    updateAudio(mutationType) {
      this.updateMusicTrack(this.$route.name, mutationType);

      this.isMobile ? null : this.preloadFXOnPageLoad(this.$route.name);
    },
    disableIntroIfUserNotLandingFromHome() {
      this.$route.name != "Home" ? this.hideIntroPage() : null;
    },
    hideIntroPage() {
      // hide intro page in case user didn't land on intro

      this.$store.dispatch("intro/HIDE_INTRO");
      this.$store.dispatch("sharedTransition/DISABLE_INTRO");
    },

    ////////////////////////////////
    //       START ENABLE SCROLL
    ////////////////////////////////

    enableBodyToScroll() {
      this.isMobile
        ? document.body.classList.add("is-mobile")
        : document.body.classList.remove("is-mobile");
    },

    isScrollablePage() {
      this.$route.name === "Privacy" || this.$route.name === "Credits"
        ? this.toggleScrollPage(true)
        : this.toggleScrollPage(false);
    },
    toggleScrollPage(bool) {
      const $html = document.documentElement;
      // Browser and OS classes
      bool
        ? $html.classList.add("is-scroll")
        : $html.classList.remove("is-scroll");
    },
    ////////////////////////////////
    //       END ENABLE SCROLL
    ////////////////////////////////

    onResize() {
      this.enableBodyToScroll();
    },
  },
};
</script>
<style lang="scss">
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  overflow: scroll;
  height: 100%;
}

#nav {
  padding: 30px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: $goldenrod;
    }
  }
}
</style>
