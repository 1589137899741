<template>
  <div class="shared-layout-title">
    <VueTextTransition tag="h1" name="fade" :show="isVisible" :interval="40">{{
      title
    }}</VueTextTransition>
  </div>
</template>

<script>
import VueTextTransition from "vue-text-transition";
export default {
  components: {
    VueTextTransition,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-layout-title {
  --shared-layout-margin-top: 20vh;
  --shared-layout-margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: var(--shared-layout-margin-top);
  margin-bottom: var(--shared-layout-margin-bottom);
  overflow: hidden;
  color: $gallery;
  &::v-deep h1 {
    text-align: center;
  }
}
</style>
