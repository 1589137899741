import Vue from "vue";
import Vuex from "vuex";

import dashboardAlerts from "./modules/dashboardAlerts";
import dashboardBottom from "./modules/dashboardBottom";
import dashboardModal from "./modules/dashboardModal";
import dashboardTutorial from "./modules/dashboardTutorial";
import gallery from "./modules/gallery";
import intro from "./modules/intro";
import navigation from "./modules/navigation";
import shared from "./modules/shared";
import sharedAudio from "./modules/sharedAudio";
import sharedGamePlay from "./modules/sharedGamePlay";
import sharedTransition from "./modules/sharedTransition";
import spaceExploration from "./modules/spaceExploration";
import userDevice from "./modules/userDevice";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dashboardAlerts,
    dashboardBottom,
    dashboardModal,
    dashboardTutorial,
    gallery,
    intro,
    navigation,
    shared,
    sharedAudio,
    sharedGamePlay,
    sharedTransition,
    spaceExploration,
    userDevice,
  },
});
