<template>
  <div
    class="shared-icons-audio"
    :class="{
      'shared-icons-audio--visible': isAnimationStarted,
      'shared-icons-audio--mute': isMute,
    }"
  >
    <div class="shared-icons-audio__icon">
      <svg
        id="wave"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        fill="none"
        viewBox="0 0 456.7 39.9"
        style="enable-background: new 0 0 456.7 39.9"
        xml:space="preserve"
      >
        <path
          d="M4.2,33.2c0.1-0.1,7-6.9,15.9-13.8C27.7,13.7,38.7,6,47.5,6c7.5,0,14,6.6,20.3,12.9l0.4,0.4
	c6.8,6.9,14.6,14.6,24.6,14.6c9.9,0,17.7-7.8,24.5-14.6l0.5-0.5C124,12.5,130.5,6,137.9,6c7.5,0,13.9,6.5,20.2,12.9l0.4,0.4
	c6.8,6.9,14.6,14.6,24.5,14.6c10,0,17.8-7.8,24.6-14.6l0.5-0.5C214.4,12.5,220.9,6,228.4,6c7.5,0,14,6.5,20.2,12.9l0.4,0.4
	c6.8,6.9,14.5,14.6,24.5,14.6c9.9,0,17.7-7.8,24.5-14.6l0.3-0.3c6.3-6.4,12.9-13,20.5-13c7.5,0,14.1,6.6,20.4,13l0.3,0.3
    c6.8,6.9,14.6,14.6,24.5,14.6c9.9,0,17.6-7.8,24.5-14.6l0.2-0.2C395.1,12.6,401.6,6,409.2,6c8.7,0,19.8,7.7,27.3,13.4
	c8.9,6.8,15.9,13.7,16,13.8"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import gtagMixin from "@/mixins/gtagMixin.js";
export default {
  mixins: [gtagMixin],
  components: {},
  computed: {
    ...mapState({
      isMute: (state) => state.sharedAudio.isMute,
    }),
  },
  watch: {
    isMute(bool) {
      this.emitMuteToGA(bool);
    },
  },
  props: {
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    emitMuteToGA(bool) {
      this.emitGtag(
        `Audio_${this.humanizeBoolean(bool)}_${this.$route.name}`,
        "Audio",
        "Click"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
// https://stackoverflow.com/questions/44446074/continuous-wave-like-animation-from-wave-line-svg
.shared-icons-audio {
  --opacity-icon: 0;
  --play-state: running;
  --shadow-size: 2px;
  --wave-scale: 0.8;
  --wave-width: 5;
  @include transition(0.5s ease opacity);
  transition-delay: 2s;
  opacity: var(--opacity-icon);

  &--visible {
    --opacity-icon: 1;
  }

  &--mute {
    --shadow-size: 3px;
    --wave-scale: 0.3;
    --wave-width: 10;
  }
  &__icon {
    @include transition(0.5s ease all);
    @include transform(scale(1, var(--wave-scale)));
    width: 30px;
    overflow: hidden;

    svg {
      --color-line: var(--color-gallery);
      @include transition(0.5s ease all);
      @include filterShadow(0px 0px var(--shadow-size) var(--color-line));

      animation: wave 1s linear infinite;

      position: relative;
      width: 200px;
      left: -50px;

      fill: none !important;
      stroke: var(--color-line);
      stroke-width: var(--wave-width);
      stroke-miterlimit: 10;
      .circular-button-icon--black & {
        --color-line: var(--color-ebony);
      }
    }
    @-moz-document url-prefix() {
      svg {
        @include transition(none!important);
      }
    }
  }
}
@keyframes wave {
  0% {
    left: -80px;
  }
  100% {
    left: -40px;
  }
}
</style>
