<template>
  <li class="shared-bottom-control-left">
    <div class="shared-bottom-control-left__inner">
      <shared-button-circle
        :is-white="false"
        :is-black="false"
        icon-name="reset"
        :rotation="0"
        :aria-label="$t('shared.restart.ariaLabel')"
        @clicked="clicked"
      />
    </div>
  </li>
</template>

<script>
import pauseGameMixin from "@/mixins/pauseGameMixin";
import SharedButtonCircle from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircle";
export default {
  mixins: [pauseGameMixin],
  components: {
    SharedButtonCircle,
  },
  methods: {
    clicked() {
      this.toggleGamePause(true);
      // this.$store.commit("sharedGamePlay/TOGGLE_GAME_RESET", true); // debug
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-bottom-control-left {
  grid-column: 1/2;

  &__inner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
  }
}
</style>
