<template>
  <div class="gallery-frame" :class="{ 'gallery-frame--visible': isVisible }">
    <shared-button-corner :is-animation-started="isVisible" />
    <gallery-close />
    <div class="gallery-frame__img">
      <img
        v-if="selectedImgId"
        v-lazy="getImgGalleryUrl(parent, 'full', selectedImgId)"
        :alt="$t(`gallery.${parent}.${selectedImgId}.info`)"
      />
    </div>
  </div>
</template>

<script>
import sharedMixin from "@/mixins/sharedMixin.js";

import GalleryClose from "@/components/Gallery/GalleryClose";
import SharedButtonCorner from "@/components/Shared/SharedButton/SharedButtonCorner/SharedButtonCorner";
export default {
  mixins: [sharedMixin],
  components: {
    GalleryClose,
    SharedButtonCorner,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedImgId: {
      type: String,
      required: false,
      default: null,
    },
    parent: {
      type: String,
      required: false,
      default: "space",
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-frame {
  $transition-gallery-frame: 0.3s ease box-shadow 0.3s, 0.3s ease transform;
  --opacity-lines: 0;
  --clip-path: 50% 0% 50% 0%;
  --image-translate: 10rem;
  @include lines-warning(var(--opacity-lines));
  @include transition($transition-gallery-frame);
  padding: 5px;
  display: flex;
  flex-direction: column;

  &--visible {
    --opacity-lines: 0.1;
    --scale: 1;
    --clip-path: 0% 0% 0% 0%;
    --image-translate: 0rem;
  }

  &__img {
    $transition-gallery-frame-img: 1s cubic-bezier(0.85, 0, 0.15, 1) clip-path
        0.5s,
      1s cubic-bezier(0.85, 0, 0.15, 1) transform 0.5s;
    @include transition($transition-gallery-frame-img);
    @include transform(translateY(var(--image-translate)));
    @include lazy-load-image;
    display: flex;
    opacity: 1;
    max-height: var(--max-height-gallery);

    clip-path: inset(var(--clip-path));

    img {
      display: flex;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
}
</style>
