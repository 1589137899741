<template>
  <section class="scene" :class="{ 'scene--dev': isDev }">
    <dashboard
      v-if="physics.objectsToUpdate.length"
      :vehicule-position="physics.objectsToUpdate[0].body.position"
      :perimeters="perimeters.alert"
      :is-landed-outside="alerts.landingMissed"
      :is-paused="this.isGamePaused"
      scene-name="landing"
      :total-altitude="200"
      :floor-position-adjustment="5"
      :position-adjustment="1"
    />

    <dashboard-bottom
      scene-name="landing"
      @keyPressed="keyPressed"
      @keyUnPressed="keyUnPressed"
    />
    <compass />
    <canvas ref="scene__container" class="scene__container"></canvas>
  </section>
</template>

<script>
import { mapState } from "vuex";

// GSAP
import { gsap } from "gsap";

import compassMixin from "@/mixins/compassMixin";
import gtagMixin from "@/mixins/gtagMixin.js";
import isDevMixin from "@/mixins/isDevMixin";
import resetGamePlayStoreMixin from "@/mixins/resetGamePlayStoreMixin";
import sharedFXAudioMixin from "@/mixins/sharedFXAudioMixin";
import toggleDashboardBottomMixin from "@/mixins/toggleDashboardBottomMixin";
import transitionMixin from "@/mixins/transitionMixin";
import tutorialMixin from "@/mixins/tutorialMixin";

// Three
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { RGBShiftShader } from "three/examples/jsm/shaders/RGBShiftShader.js";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass.js";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";

// vendors
import * as CANNON from "cannon-es";
import CannonDebugRenderer from "@/vendors/CannonDebugRenderer.js";
import LinearSpline from "@/vendors/LinearSpline.js";
import JoyStick from "@/vendors/toon3d.js";

// STATS
import Stats from "stats.js";
import { GUI } from "@/vendors/dat.gui.module.js";

// shaders
import moonVertexShader from "@/shaders/moon/vertex.glsl";
import moonFragmentShader from "@/shaders/moon/fragment.glsl";
import moonSafariFragment from "@/shaders/moon/safariFragment.glsl";

import fireVertexShader from "@/shaders/fire/vertex.glsl";
import fireFragmentShader from "@/shaders/fire/fragment.glsl";

// heigthmap
import heightmap from "@/assets/textures/moon/heightmap/center.jpg";
import heightmapTop from "@/assets/textures/moon/heightmap/north.jpg";
import heightmapWest from "@/assets/textures/moon/heightmap/west.jpg";
import heightmapNorthWest from "@/assets/textures/moon/heightmap/northwest.jpg";
import heightmapSurrounding from "@/assets/textures/moon/heightmap/surrounding.jpg";

// components
import Compass from "@/components/Compass/Compass";
import Dashboard from "@/components/Dashboard/Dashboard";
import DashboardBottom from "@/components/Dashboard/DashboardBottom/DashboardBottom";

export default {
  mixins: [
    compassMixin,
    gtagMixin,
    isDevMixin,
    sharedFXAudioMixin,
    resetGamePlayStoreMixin,
    toggleDashboardBottomMixin,
    transitionMixin,
    tutorialMixin,
  ],
  components: {
    Compass,
    Dashboard,
    DashboardBottom,
  },
  data() {
    return {
      // base
      container: null,
      scene: null,
      composer: null,
      camera: null,
      controls: null,
      animation: undefined,
      isGamePausedOnce: false,

      softParticles: {
        renderTarget: null,
        ratio: 0,
        uDepthFade: 0.01,
      },

      effectComposer: {
        effectComposer: null,
        renderTarger: null,
        glitchPass: {
          GlitchPass: null,
          isGlitchPassVisible: false,
        },
        RGBShiftPass: null,
        filmPass: {
          filmPass: null,
        },
        glitchResetTimeOut: null,
      },

      initiate: {
        isInitStarted: false,
        isInitiating: false,
        timeout: null,
        timeoutGameloop: null,
        timeoutMounted: null,
      },

      gameIsResetTimeout: null,

      //  helper
      helpers: {
        stats: null,
        clock: null,
      },
      clock: {
        clock: null,
        oldElapsedTime: 0,
        then: 0,
        thenReducedFPS: 0,
        then60FPS: 0,
      },
      lights: {
        directionalLight: null,
      },
      cameras: {
        defaultCameraPosition: {
          camX: 258,
          camY: 253,
          camZ: 258,
        },

        windowUser: {
          width: 0,
          height: 0,
        },
      },

      perimeters: {
        alert: {
          // left + right keys
          x: {
            min: -450,
            max: 450,
          },
          // top + bottom keys
          z: {
            min: -450,
            max: 450,
          },

          y: {
            min: 50,
            max: 95,
          },
        },
        reset: {
          // left + right keys
          x: {
            min: -550,
            max: 550,
          },
          // top + bottom keys
          z: {
            min: -550,
            max: 550,
          },

          y: {
            min: 20,
            max: 110,
          },
        },
        landingZone: {
          // left + right keys
          x: {
            min: -200,
            max: 200,
          },
          // top + bottom keys
          z: {
            min: -200,
            max: 200,
          },
        },
      },

      // fire exhaust perimeters
      particles: {
        maxPoints: {
          fire: 100,
          smoke: 100,
        },
        fire: {
          particles: [],
          points: undefined,
          geometry: null,
          alphaSpline: null,
          colourSpline: null,
          sizeSpline: null,
          // smoke
          alphaSplineS: null,
          colourSplineS: null,
          sizeSplineS: null,
        },
        smoke: {
          positionY: 0,

          particles: [],
          points: undefined,
          geometry: undefined,
          alphaSpline: null,
          colourSpline: null,
          sizeSpline: null,
          alphaSplineS: null,
          colourSplineS: null,
          sizeSplineS: null,
        },

        boosterParticlesData: {
          ArrowLeft: [
            // front right
            {
              position: [1.5, 0.93, 1.18],
              velocity: [1, 0, 0],
            },
            // back right
            {
              position: [1.5, 0.93, -1.18],
              velocity: [1, 0, 0],
            },
          ],
          ArrowRight: [
            // front left
            {
              position: [-1.6, 0.85, 1.2],
              velocity: [-1, 0, 0],
            },
            // back left
            {
              position: [-1.6, 0.93, -1.18],
              velocity: [-1, 0, 0],
            },
          ],
          ArrowUp: [
            // front front left
            {
              position: [-1.25, 0.93, 1.5],
              velocity: [0, 0, 1],
            },
            // front front right
            {
              position: [1.2, 0.93, 1.5],
              velocity: [0, 0, 1],
            },
          ],
          ArrowDown: [
            // back back left
            {
              position: [-1.25, 0.93, -1.5],
              velocity: [0, 0, -1],
            },
            // back back right
            {
              position: [1.2, 0.93, -1.5],
              velocity: [0, 0, -1],
            },
          ],
        },
      },

      // loader GLTF
      gltf: {
        GLTFLoader: null,
        GLFTList: [
          {
            key: "LM",

            path: "LM/lunar-module--draco.glb",
            position: [0, 0, 0],
            scale: [0.4, 0.4, 0.4],
            progress: 0,
            loaded: false,
            isCastShadow: true,
            isAnimated: false,
          },
          {
            key: "GLTFTarget",

            path: "moon/border/border.glb",
            position: [30, 2.27, 2],
            scale: [1, 1, 1],
            progress: 0,
            loaded: false,
            isCastShadow: false,
            isAnimated: false,
          },
        ],
      },
      // loader: {

      // },
      loader: {
        textureLoader: null,
        intervalProgress: null,
        timeOutStartGame: null,
        totalProgress: 0,
        mapTotalProgress: 0,
      },
      //   meshes
      meshes: {
        moon: {
          repeatTexture: 5.0,
          vertex: [],
          data: [],
        },
      },

      //   physics
      physics: {
        world: null,
        helper: null,
        cannonDebugRenderer: null,
        isPaused: false,
        height: 10.0,
        // startPosition: { x: 30, y: 30, z: 0 }, // debug
        // startForce: [0, 0, 0], // debug
        startPosition: { x: -430, y: 81, z: 50 },
        startForce: [15, 6, 0],
        materials: {
          defaultMaterial: null,
        },
        objectsToUpdate: [],
        boostPower: {
          space: [0, 0.25, 0],
          ArrowLeft: [-0.25, 0, 0],
          ArrowRight: [0.25, 0, 0],
          ArrowUp: [0, 0, -0.25],
          ArrowDown: [0, 0, 0.25],
          isSpaceKeyPressed: false,
        },
        heightMapMatrixMoon: [],
        joystick: {
          controler: null,
          forward: 0,
          turn: 0,
        },
      },
      alerts: {
        timeoutModal: null,
        landingMissed: false,
      },

      gsapAnimation: {
        exitScene: {
          isRunning: false,
          timeout: null,
          animationRunning: false,
          timeline: null,
        },
        intro: {
          timeline: null,
          isAnimationRunning: true,
        },
      },

      tutorials: {
        isResetStarted: false,
      },
      compass: {
        point: {
          position: new THREE.Vector3(30, 0, 2),
          element: document.querySelector(".compass"),
        },
        size: 35,
      },
    };
  },

  computed: {
    ...mapState({
      isMobile: (state) => state.userDevice.isMobile,
      isGamePaused: (state) => state.sharedGamePlay.isGamePaused,
      isGamePlayDebug: (state) => state.sharedGamePlay.isGamePlayDebug,
      isGameControlDisabled: (state) =>
        state.sharedGamePlay.isGameControlDisabled,
      isGameToReset: (state) => state.sharedGamePlay.isGameToReset,
      isIntroAnimationVisible: (state) =>
        state.sharedGamePlay.isIntroAnimationVisible,
      isTransitionLongEnough: (state) =>
        state.sharedTransition.isTransitionLongEnough,

      isUserDeviceReady: (state) => state.userDevice.isUserDeviceReady,
      userBrowser: (state) => state.userDevice.browser,
    }),
    isUserLanded() {
      return this.gsapAnimation.exitScene.isRunning;
    },

    isGamePlayDebugging() {
      return this.isDevEnv() && this.isGamePlayDebug;
    },
    isSafari() {
      return this.userBrowser.includes("safari") || this.isMobile;
    },
    isCompassInvisibleOverwritten() {
      return (
        !this.gsapAnimation.intro.isAnimationRunning &&
        !this.tutorials.isResetStarted &&
        !this.gsapAnimation.exitScene.animationRunning
      );
    },
    lengthMapToLoad() {
      return this.isMobile ? 5 : 12;
    },
  },

  watch: {
    isGameToReset(bool) {
      bool ? this.resetGame() : this.displayTutorialAfterReset();
    },
    isUserDeviceReady(bool) {
      bool && !this.initiate.isInitiating ? this.delayInit() : null;
    },
    isTransitionLongEnough(bool) {
      bool && this.isPageFullyLoaded() ? this.gameIsReady() : null;
    },
    isGamePaused(bool) {
      bool
        ? (this.toggleBoosterAudio(false),
          this.toggleBoostUp(false),
          this.toggleIntroTimeline(!bool),
          this.toggleIsGamePausedOnce(true))
        : this.toggleIntroTimeline(!bool),
        this.isGamePausedOnce &&
        this.calculateCombinedProgress(this.lengthMapToLoad)
          ? this.nextAnimationFrame()
          : null;
    },
  },

  mounted() {
    this.delayMountedMethods();
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animation);
    this.animation = undefined;
    // remove all event listenenr
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("keydown", this.keyPressed);
    window.removeEventListener("keyup", this.keyUnPressed);
    this.physics.objectsToUpdate[0].body.removeEventListener(
      "collide",
      this.sprayMoonDustOnTouch
    );

    this.gsapTimelineDestroyer();
    this.destroyerAllTimeout();

    // reset global variables
    this.resetGlobalGamePlayMixin();
    this.clearDisplayAndHideTimeOut();
    // reset timeout
    this.destroyTimeout(this.effectComposer.glitchResetTimeOut);
    this.destroyTimeout(this.initiate.timeoutMounted);
    this.destroyTimeout(this.alerts.timeoutModal);
    this.destroyTimeout(this.gameIsResetTimeout);
  },
  methods: {
    ////////////////////////////////
    //       START ON START METHODS
    ////////////////////////////////

    delayMountedMethods() {
      this.initiate.timeoutMounted = setTimeout(() => {
        this.methodsOnMount();
        this.destroyTimeout(this.initiate.timeoutMounted);
      }, 300);
    },

    methodsOnMount() {
      this.setWindowSize();
      this.toggleResetLocally(false);

      this.isUserDeviceReady && !this.initiate.isInitiating
        ? this.delayInit()
        : null;
      // Register an event listener when the Vue component is ready
      window.addEventListener("resize", this.onResize);
      window.addEventListener("keydown", this.keyPressed);
      window.addEventListener("keyup", this.keyUnPressed);
    },

    /*------------------------------
      Start Reset on mount
    ------------------------------*/
    resetGlobalGamePlayMixin() {
      this.resetGamePlayStore();
    },
    // gameLoop is pause when game pause, so ensure that gameLoop don't run twince on mount
    toggleIsGamePausedOnce(bool) {
      this.isGamePausedOnce = bool;
    },

    /*------------------------------
      End Reset on mount
    ------------------------------*/

    //   set window width
    setWindowSize() {
      this.cameras.windowUser.width = window.innerWidth;
      this.cameras.windowUser.height = window.innerHeight;
    },

    onResize() {
      // Update sizes
      this.setWindowSize();

      // Update camera
      this.camera ? this.updateCameraOnResize() : null;

      this.renderer ? this.setRenderSize() : null;

      this.effectComposer.composer
        ? (this.setSizePostProcessing(), this.setEffectComposerRenderTarget())
        : null;

      // add joystick if needed
      this.setJoyStick();

      this.setCompassSize();
    },

    updateCameraOnResize() {
      this.camera.aspect =
        this.cameras.windowUser.width / this.cameras.windowUser.height;
      this.camera.updateProjectionMatrix();
    },

    setRenderSize() {
      // Update renderer
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );

      this.softParticles.ratio = Math.min(window.devicePixelRatio, 2);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },

    setEffectComposerRenderTarget() {
      this.effectComposer.renderTarget = new THREE.WebGLRenderTarget(
        window.innerWidth,
        window.innerHeight,
        {
          minFilter: THREE.LinearFilter,
          magFilter: THREE.LinearFilter,
          format: THREE.RGBAFormat,
          encoding: THREE.sRGBEncoding,
        }
      );
    },

    setCompassSize() {
      this.compass.size = window.innerWidth >= 1080 ? 35 : 23;
    },

    ////////////////////////////////
    //       END ON START METHODS
    ////////////////////////////////

    ////////////////////////////////
    //       START INIT SCENE AND RENDER
    ////////////////////////////////
    delayInit() {
      this.initiate.isInitiating = true;
      this.initiate.timeout = setTimeout(
        () => {
          this.init();
          this.destroyTimeout(this.initiate.timeout);
        },
        this.isMobile ? 2000 : 300
      );
    },
    // update the number of smoke

    init() {
      if (this.initiate.isInitStarted) return;
      this.initiate.isInitStarted = true;
      // ensure that user have enough time to read the tutorial
      this.startTimerTransitionIsLongEnough();

      this.setBaseScene();

      this.setLight();

      /*------------------------------
      Start add meshes to the scenes
      ------------------------------*/
      this.addPhysics();
      this.loadModels();

      /*------------------------------
      End add meshes to the scenes
      ------------------------------*/

      /*------------------------------
      Start Particles
      ------------------------------*/
      this.setParticleSystem();
      /*------------------------------
      End Particles
      ------------------------------*/

      /*------------------------------
      Start add Joytick
      ------------------------------*/
      this.setJoyStick();
      /*------------------------------
      End add Joytick
      ------------------------------*/

      /*------------------------------
      Start Run Progress Loading
      ------------------------------*/
      this.runProgressLoadingPage();
      /*------------------------------
      End Run Progress Loading
      ------------------------------*/

      this.setCamera();
      this.setControl();

      this.setRenderer();
      this.setPostProcessing();
      this.setRenderTargetMethod();

      // skip animation in debug mode
      this.isGamePlayDebugging ? this.runDebuggedGameMode() : null;

      this.initiate.isInitiating = false;

      // set compass size
      this.setCompassSize();

      this.gameLoop();
    },

    //======= START BASE THREEJS =======//

    setBaseScene() {
      // set container
      this.container = this.$refs.scene__container;

      // create scene
      this.scene = new THREE.Scene();

      // this.scene.background = new THREE.Color(0x000000);

      this.isDevEnv() ? this.setHelpers() : "";

      this.setClock();
    },

    setHelpers() {
      // stats
      this.helpers.stats = new Stats();
      document.body.appendChild(this.helpers.stats.dom);

      // axes helpers
      // const axesHelper = new THREE.AxesHelper(5);
      // // x y z
      // this.scene.add(axesHelper);

      // set gui globaly
      this.setGUI();
    },
    setGUI() {
      this.helpers.gui = new GUI();
      const debugObject = {};

      debugObject.pushUp = () => {
        this.applyForce(
          this.physics.boostPower.space[0],
          this.physics.boostPower.space[1],
          this.physics.boostPower.space[2]
        );
      };
      debugObject.pushRight = () => {
        this.applyForce(
          this.physics.boostPower.ArrowRight[0],
          this.physics.boostPower.ArrowRight[1],
          this.physics.boostPower.ArrowRight[2]
        );
      };
      debugObject.pushLeft = () => {
        this.applyForce(
          this.physics.boostPower.ArrowLeft[0],
          this.physics.boostPower.ArrowLeft[1],
          this.physics.boostPower.ArrowLeft[2]
        );
      };
      debugObject.pushDown = () => {
        this.applyForce(
          this.physics.boostPower.ArrowRight[0],
          this.physics.boostPower.ArrowRight[1],
          this.physics.boostPower.ArrowRight[2]
        );
      };
      debugObject.pushTop = () => {
        this.applyForce(
          this.physics.boostPower.ArrowRight[0],
          this.physics.boostPower.ArrowRight[1],
          this.physics.boostPower.ArrowRight[2]
        );
      };

      debugObject.reset = () => {
        this.resetGame();
      };

      this.helpers.gui.add(debugObject, "pushUp");
      this.helpers.gui.add(debugObject, "pushLeft");
      this.helpers.gui.add(debugObject, "pushRight");
      this.helpers.gui.add(debugObject, "pushDown");
      this.helpers.gui.add(debugObject, "pushTop");
      this.helpers.gui.add(debugObject, "reset");
    },
    setClock() {
      this.clock.clock = new THREE.Clock();
    },

    //======= END BASE THREEJS =======//

    //======= START LIGHTS =======//

    setLight() {
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      this.scene.add(ambientLight);

      this.lights.directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      this.lights.directionalLight.position.set(-0, 120, -0.5);
      this.lights.directionalLight.castShadow = true;
      let d = 5;
      this.lights.directionalLight.shadow.camera.left = -d;
      this.lights.directionalLight.shadow.camera.right = d;
      this.lights.directionalLight.shadow.camera.top = d;
      this.lights.directionalLight.shadow.camera.bottom = -d;

      this.lights.directionalLight.shadow.camera.near = 0;
      this.lights.directionalLight.shadow.camera.far = 150;

      this.lights.directionalLight.shadow.mapSize.x = this.lights.directionalLight.shadow.mapSize.y = this
        .isMobile
        ? 64
        : 256;

      this.lights.directionalLight.target.position.set(0, 0, 0);
      this.scene.add(this.lights.directionalLight.target);

      this.scene.add(this.lights.directionalLight);

      // this.isDevEnv() ? this.directioLightHelper() : null;
    },
    directioLightHelper() {
      const directionalLightHelper = new THREE.DirectionalLightHelper(
        this.lights.directionalLight,
        0.1
      );
      this.scene.add(directionalLightHelper);
      const directionalLightCameraHelper = new THREE.CameraHelper(
        this.lights.directionalLight.shadow.camera
      );
      directionalLightCameraHelper.visible = true;
      this.scene.add(directionalLightCameraHelper);
    },

    /*------------------------------
    Start lights follow Vehicule
    ------------------------------*/
    updatePositionLight() {
      this.lights.directionalLight.position.set(
        this.physics.objectsToUpdate[0].body.position.x,

        this.physics.objectsToUpdate[0].body.position.y + 10,
        this.physics.objectsToUpdate[0].body.position.z
      );
      this.lights.directionalLight.target.position.set(
        this.physics.objectsToUpdate[0].body.position.x,
        0,
        this.physics.objectsToUpdate[0].body.position.z
      );
    },
    /*------------------------------
    End lights follow Vehicule
    ------------------------------*/

    //======= END LIGHTS =======//

    //======= START CAMERA AND CONTROL =======//

    setCamera() {
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.cameras.windowUser.width / this.cameras.windowUser.height,
        0.1,
        this.isMobile ? 500 : 2000
      );
      this.camera.position.set(
        this.cameras.defaultCameraPosition.camX,
        this.cameras.defaultCameraPosition.camY,
        this.cameras.defaultCameraPosition.camZ
      );

      // this.camera.lookAt(new THREE.Vector3(0, 0, 0));

      this.scene.add(this.camera);
    },

    setControl() {
      this.controls = new OrbitControls(this.camera, this.container);
      this.controls.enableDamping = false;

      this.controls.target.x = 0;
      this.controls.target.y = 0;
      this.controls.target.z = 0;
    },

    //======= END CAMERA AND CONTROL =======//

    //======= START RENDERER  =======//

    setRenderer() {
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.container,
        powerPreference: "high-performance",
        antialias: !this.isSafari,
      });
      this.setRenderSize();

      this.renderer.outputEncoding = THREE.sRGBEncoding;

      // shadow
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMapSoft = true;
      // https://stackoverflow.com/questions/15140832/what-are-the-possible-shadowmaptypes
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    },

    setRenderTargetMethod() {
      this.softParticles.renderTarget = new THREE.WebGLRenderTarget(1, 1);
      this.softParticles.renderTarget.texture.format = THREE.RGBAFormat;
      this.softParticles.renderTarget.texture.minFilter = THREE.NearestFilter;
      this.softParticles.renderTarget.texture.magFilter = THREE.NearestFilter;
      this.softParticles.renderTarget.texture.generateMipmaps = false;
      this.softParticles.renderTarget.stencilBuffer = false;
      this.softParticles.renderTarget.depthBuffer = true;
      this.softParticles.renderTarget.depthTexture = new THREE.DepthTexture();
      this.softParticles.renderTarget.depthTexture.type =
        THREE.UnsignedShortType;
      this.softParticles.renderTarget.depthTexture.format = THREE.DepthFormat;

      this.softParticles.renderTarget.setSize(
        Math.floor(window.innerWidth * this.softParticles.ratio),
        Math.floor(window.innerHeight * this.softParticles.ratio)
      );
    },

    /*------------------------------
    Start Post Processing
    ------------------------------*/

    setPostProcessing() {
      this.setEffectComposerRenderTarget();

      this.effectComposer.composer = new EffectComposer(
        this.renderer,
        this.effectComposer.renderTarget
      );
      this.setSizePostProcessing();

      this.effectComposer.composer.addPass(
        new RenderPass(this.scene, this.camera)
      );

      this.setGlitchPass();
      this.setFilmPass();
    },

    setSizePostProcessing() {
      this.effectComposer.composer.setPixelRatio(
        Math.min(window.devicePixelRatio, 2)
      );
      this.effectComposer.composer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );
    },

    // set Glitch Pass
    setFilmPass() {
      this.effectComposer.RGBShiftPass = new ShaderPass(RGBShiftShader);
      this.effectComposer.composer.addPass(this.effectComposer.RGBShiftPass);
      this.effectComposer.RGBShiftPass.enabled = false;

      this.effectComposer.filmPass.filmPass = new FilmPass(
        0.2,
        0.6,
        256,
        false
      );

      this.effectComposer.filmPass.filmPass.renderToScreen = true;
      this.effectComposer.composer.addPass(
        this.effectComposer.filmPass.filmPass
      );

      this.effectComposer.filmPass.filmPass.enabled = false;
    },
    setGlitchPass() {
      this.effectComposer.glitchPass.glitchPass = new GlitchPass(64);
      this.effectComposer.glitchPass.glitchPass.renderToScreen = true;
      this.effectComposer.composer.addPass(
        this.effectComposer.glitchPass.glitchPass
      );
      this.effectComposer.glitchPass.glitchPass.enabled = false;
    },

    /*------------------------------
    End Post Processing
    ------------------------------*/

    //======= END RENDERER  =======//

    ////////////////////////////////
    //       END INIT SCENE AND RENDER
    ////////////////////////////////

    //======= START GAME LOOP =======//

    gameLoop() {
      // don't display the stats if on prod. Maybe this dev stuff should be handle differently
      this.isDevEnv() ? this.helpers.stats.update() : null;
      // this.isDevEnv() ? this.physics.cannonDebugRenderer.update() : null;

      const elapsedTime = this.clock.clock.getElapsedTime();
      const deltaTime = elapsedTime - this.clock.oldElapsedTime;
      this.clock.oldElapsedTime = elapsedTime;

      this.gsapAnimation.intro.isAnimationRunning || this.alerts.timeoutModal
        ? null
        : this.animateSmokeAndPhysic(deltaTime);

      for (const object of this.physics.objectsToUpdate) {
        object.mesh.position.copy(object.body.position);
        object.mesh.quaternion.copy(object.body.quaternion);
      }

      // move camera
      !this.isIntroAnimationVisible &&
      this.physics.objectsToUpdate.length &&
      !this.gsapAnimation.exitScene.animationRunning
        ? this.followVehicule()
        : null;

      // add smoke on landing

      this.physics.objectsToUpdate.length && this.meshes.moon.vertex.length
        ? (this.addSmokeOnLanding(elapsedTime),
          this.groupedRecudedFPSMethods(elapsedTime))
        : null;

      // Render
      this.controls.update();
      // this.renderer.render(this.scene, this.camera);
      this.effectComposer.composer.render();

      // Call gameLoop again on the next frame
      this.isGamePaused ? null : this.nextAnimationFrame();
    },
    nextAnimationFrame() {
      this.animation = requestAnimationFrame(this.gameLoop);
    },

    animateSmokeAndPhysic(deltaTime) {
      this.physics.world.step(1 / 60, deltaTime, 3);
    },

    groupedRecudedFPSMethods(elapsedTime) {
      this.reducedFPSMethods(elapsedTime);
      this.reduced60FPS(elapsedTime);
    },

    reduced60FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 60;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then60FPS;

      if (delta > interval) {
        this.particleSteps(0.05); // animate smokes and dust

        // move fire point below the rocket
        // not used on mobile, but the array should be empty so no need for more
        this.particles.fire.points
          ? this.placeFirePoinstBelowTheRocket()
          : null;

        // update position light to update shadow position
        this.updatePositionLight();
        this.updateCompass(
          this.compass.point,
          this.camera,
          this.physics.objectsToUpdate[0].body.position,
          this.compass.size,
          this.isCompassInvisibleOverwritten
        );

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then60FPS = now - (delta % interval);
      }
    },

    reducedFPSMethods(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 15;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.thenReducedFPS;

      if (delta > interval) {
        this.isUserLanded || this.isGameControlDisabled || this.isGamePaused
          ? null
          : this.manageJoystickBooster();

        // if rover is outside the perimeter reset it
        this.checkPositionRocket();

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.thenReducedFPS = now - (delta % interval);
      }
    },

    manageJoystickBooster() {
      this.applyForce(
        this.physics.joystick.turn / 2,
        this.isSpaceKeyPressed(),
        -this.physics.joystick.forward / 2
      );

      this.addBoosterFireEngine(
        this.physics.joystick.forward,
        -this.physics.joystick.turn
      );
    },

    /*------------------------------
   Start Soft Particles Uniforms updates
   ------------------------------*/
    togglePoints(bool) {
      this.particles.fire.points.material.visible = bool;
      this.particles.smoke.points.material.visible = bool;
    },

    updatePointsUniforms(key) {
      this.particles[
        key
      ].points.material.uniforms.uDepthTexture.value = this.softParticles.renderTarget.depthTexture;
      this.particles[
        key
      ].points.material.uniforms.uCameraNear.value = this.camera.near;
      this.particles[
        key
      ].points.material.uniforms.uCameraFar.value = this.camera.far;
      this.particles[
        key
      ].points.material.uniforms.uResolution.value = new THREE.Vector2(
        window.innerWidth * this.softParticles.ratio,
        window.innerHeight * this.softParticles.ratio
      );
    },
    /*------------------------------
   End Soft Particles Uniforms updates
   ------------------------------*/

    //======= END GAME LOOP =======//

    addSmokeOnLanding(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 5;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then;

      if (delta > interval) {
        // save performance by only doing that only 10 times per second
        this.togglePoints(false);

        this.renderer.setRenderTarget(this.softParticles.renderTarget);
        this.renderer.render(this.scene, this.camera);
        this.renderer.setRenderTarget(null);

        this.togglePoints(true);
        this.updatePointsUniforms("fire");
        !this.isMobile
          ? (this.setAxisYSmoke(), this.updatePointsUniforms("smoke"))
          : null;

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then = now - (delta % interval);
      }
    },
    setAxisYSmoke() {
      return this.isLMOutsideLandingZone()
        ? this.updatePositionDustY(10)
        : this.findCoodinateLMvsTerrain();
    },
    isLMOutsideLandingZone() {
      return (
        this.physics.objectsToUpdate[0].body.position.x >= 200 ||
        this.physics.objectsToUpdate[0].body.position.x <= -200 ||
        this.physics.objectsToUpdate[0].body.position.z >= 200 ||
        this.physics.objectsToUpdate[0].body.position.z <= -200
      );
    },
    findCoodinateLMvsTerrain() {
      var i = 0,
        len = this.meshes.moon.vertex.length;
      while (i < len) {
        // your code
        Math.abs(
          this.meshes.moon.vertex[i].x -
            this.physics.objectsToUpdate[0].body.position.x
        ) < 3 &&
        Math.abs(
          this.meshes.moon.vertex[i].z -
            this.physics.objectsToUpdate[0].body.position.z
        ) < 3
          ? this.updatePositionDustY(this.meshes.moon.vertex[i].y)
          : // this.debugUpdatePositionDustY(
            //   this.meshes.moon.vertex[i].y,
            //   this.meshes.moon.vertex[i].x,
            //   this.meshes.moon.vertex[i].z
            // ) // debug
            null;

        i++;
      }
    },
    updatePositionDustY(yAxis) {
      return (this.particles.smoke.positionY = yAxis);
    },

    ////////////////////////////////
    //       START SMOKE WHERE LM IS LANDING
    ////////////////////////////////
    convertVertexToWorld() {
      // this can be improve. I create a plane for to loop all vertex so then place the smokeY.
      // This plane have same amount of vertex than the heightmap used for physics floor
      const tempFloor = new THREE.Mesh(
        new THREE.PlaneGeometry(400, 400, 100, 100),
        new THREE.MeshStandardMaterial({
          wireframe: false,
        })
      );
      // same as the
      tempFloor.rotation.x = -Math.PI * 0.5;
      tempFloor.rotation.z = Math.PI * 0.5;

      this.scene.add(tempFloor);

      // for simplify, loop everything ever
      tempFloor.updateMatrixWorld();

      const position = tempFloor.geometry.attributes.position;
      const vector = new THREE.Vector3();
      this.meshes.moon.vertex = [];
      for (let i = 0, l = position.count; i < l; i++) {
        vector.fromBufferAttribute(position, i);
        vector.applyMatrix4(tempFloor.matrixWorld);
        this.meshes.moon.vertex.push({
          x: Math.round(vector.x),
          y: this.physics.heightMapMatrixMoon[i],
          z: Math.round(vector.z),
        });
        if (i + 1 === position.count) {
          // clear up the plane and the heightmap value because there is no use anymore starting this code line
          this.scene.remove(tempFloor);
          tempFloor.geometry.dispose();
          tempFloor.material.dispose();
          this.physics.heightMapMatrixMoon = [];
        }
      }
    },
    ////////////////////////////////
    //       END SMOKE WHERE LM IS LANDING
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD PHYSICS
    ////////////////////////////////
    addPhysics() {
      this.setPhysicsWorld();
      this.setPhysicFLoor();
    },

    applyForce(x, y, z) {
      this.physics.objectsToUpdate[0].body.applyLocalImpulse(
        new CANNON.Vec3(x, y, z),
        new CANNON.Vec3(0, 0, 0)
      );
    },

    //======= START SET PHYSICS WORLD =======//

    setPhysicsWorld() {
      // world
      this.physics.world = new CANNON.World();
      this.physics.world.broadphase = new CANNON.SAPBroadphase(
        this.physics.world
      );
      this.physics.world.allowSleep = true;
      this.physics.world.gravity.set(0, -1.62, 0); // moon

      // material
      this.physics.materials.defaultMaterial = new CANNON.Material("default");

      const defaultContactMaterial = new CANNON.ContactMaterial(
        this.physics.materials.defaultMaterial,
        this.physics.materials.defaultMaterial,
        {
          friction: 0.3,
          restitution: 0.2,
        }
      );

      this.physics.world.addContactMaterial(defaultContactMaterial);
      this.physics.world.defaultContactMaterial = defaultContactMaterial;

      this.physics.cannonDebugRenderer = new CannonDebugRenderer(
        this.scene,
        this.physics.world
      );
    },

    //======= END SET PHYSICS WORLD =======//

    //======= START PHYSIC FLOOR =======//

    setPhysicFLoor() {
      const heigtMapImage = new Image();
      heigtMapImage.src = heightmap;

      // load the image first to avoid: Uncaught TypeError: Failed to execute 'drawImage' on
      heigtMapImage.onload = () => {
        // image  has been loaded
        this.getTerrainPixelData(heigtMapImage);
      };
    },

    // To get the pixels, draw the image onto a canvas. From the canvas get the Pixel (R,G,B,A)
    // https://www.lukaszielinski.de/blog/posts/2014/11/07/webgl-creating-a-landscape-mesh-with-three-dot-js-using-a-png-heightmap/ // https://github.com/lukas2/threejs_landscape
    getTerrainPixelData(heightMapImg) {
      const sizeCanvas = 101;

      const canvas = document.createElement("canvas");

      canvas.width = sizeCanvas;
      canvas.height = sizeCanvas;
      canvas
        .getContext("2d")
        .drawImage(heightMapImg, 0, 0, sizeCanvas, sizeCanvas);

      const data = canvas
        .getContext("2d")
        .getImageData(0, 0, sizeCanvas, sizeCanvas).data;
      const normPixels = [];

      for (var i = 0, n = data.length; i < n; i += 4) {
        // get the average value of R, G and B.
        normPixels.push(
          ((data[i] + data[i + 1] + data[i + 2]) / 3 / 255) *
            this.physics.height
        );
      }

      // Heightfield need an array in a matrix, size
      this.physics.heightMapMatrixMoon = normPixels;
      this.addHeightMapToPhysic(sizeCanvas);
    },
    listToMatrix(list, elementsPerSubArray) {
      var matrix = [],
        i,
        k;
      for (i = 0, k = -1; i < list.length; i++) {
        if (i % elementsPerSubArray === 0) {
          k++;
          matrix[k] = [];
        }
        matrix[k].push(list[i]);
      }
      return matrix;
    },
    addHeightMapToPhysic(sizeCanvas) {
      var body = new CANNON.Body({ mass: 0 });
      body.material = this.physics.materials.groundMaterial;

      var shape = new CANNON.Heightfield(
        this.listToMatrix(this.physics.heightMapMatrixMoon, sizeCanvas),
        { elementSize: 4 }
      );
      var quat = new CANNON.Quaternion(-0.5, 0, 0, 0.5);
      quat.normalize();
      body.addShape(shape, new CANNON.Vec3(), quat);
      body.position.set(-200, 0, 200);
      this.physics.world.addBody(body);

      // add meshes there
      this.addMeshesToScene();
    },

    //======= END PHYSIC FLOOR =======//

    //======= START PHYSICS GENERATOR =======//

    LMphysicGenerator(width, height, depth, mesh) {
      // Cannon.js body
      const shape = new CANNON.Box(
        new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5)
      );

      const body = new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(this.physics.startPosition),
        shape: shape,
        material: this.physics.materials.defaultMaterial,
      });
      body.position.copy(this.physics.startPosition);

      this.physics.world.addBody(body);

      var feet_sides = new CANNON.Box(new CANNON.Vec3(0.8, 1.2, 0.5));
      var feet = new CANNON.Box(new CANNON.Vec3(0.5, 1.2, 0.8));
      // sides left right
      body.addShape(
        feet_sides,
        new CANNON.Vec3(-2.6, -1.565, 0),
        new CANNON.Quaternion()
      );
      body.addShape(
        feet_sides,
        new CANNON.Vec3(2.6, -1.565, 0),
        new CANNON.Quaternion()
      );
      // front
      body.addShape(
        feet,
        new CANNON.Vec3(0, -1.565, 2.6),
        new CANNON.Quaternion()
      );
      //back
      body.addShape(
        feet,
        new CANNON.Vec3(0, -1.565, -2.6),
        new CANNON.Quaternion()
      );

      // Save in objects
      this.physics.objectsToUpdate.push({
        mesh: mesh,
        body: body,
      });

      this.applyForce(
        this.physics.startForce[0],
        this.physics.startForce[1],
        this.physics.startForce[2]
      );

      body.addEventListener("collide", this.sprayMoonDustOnTouch);
      // ensure that the LM has been loaded. The animation is trigger later.
      this.initAnimatedCamera();
    },

    //======= END PHYSICS GENERATOR =======//

    sprayMoonDustOnTouch(collision) {
      const impactStrength = collision.contact.getImpactVelocityAlongNormal();
      impactStrength > 1.5
        ? (this.addLandingTouchDust(),
          !this.gsapAnimation.exitScene.isRunning
            ? this.stopAndPlaySoundEffect("touchDown", "fx")
            : null)
        : null;

      !this.gsapAnimation.exitScene.isRunning
        ? this.isUserLandedProperly()
        : null;
    },

    ////////////////////////////////
    //       END ADD PHYSICS
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MESHES TO SCENE
    ////////////////////////////////

    /*------------------------------
    Start Terrain
    ------------------------------*/

    addMeshesToScene() {
      const grassTexture = this.loadResponsiveTexture(
        "moon/textures/ground_grey_diff_128/ground_grey_diff_128__light.jpg",
        "moon/textures/ground_grey_diff_512/ground_grey_diff_512__light.jpg"
      );
      grassTexture.wrapS = grassTexture.wrapT = THREE.RepeatWrapping;

      const rockyTexture = this.loadResponsiveTexture(
        "moon/textures/ground_grey_diff_128/ground_grey_diff_128__dark.jpg",
        "moon/textures/ground_grey_diff_512/ground_grey_diff_512__dark.jpg"
      );
      rockyTexture.wrapS = rockyTexture.wrapT = THREE.RepeatWrapping;

      const snowyTexture = this.loadResponsiveTexture(
        "moon/textures/ground_grey_diff_128/ground_grey_diff_128.jpg",
        "moon/textures/ground_grey_diff_512/ground_grey_diff_512.jpg"
      );
      snowyTexture.wrapS = snowyTexture.wrapT = THREE.RepeatWrapping;

      const normalmap = this.loadResponsiveTexture(
        "moon/normal/ground_grey_nor_128.jpg",
        "moon/normal/ground_grey_nor_4k.jpg"
      );
      normalmap.wrapS = normalmap.wrapT = THREE.RepeatWrapping;

      const heightMapSize = this.isMobile ? 8 : 16;
      const heightMapSizeMain = this.isMobile ? 32 : 128;

      this.addMeshToHeightMap(
        heightmap,
        normalmap,
        [400, 400, heightMapSizeMain, heightMapSizeMain],
        this.meshes.moon.repeatTexture,
        true,
        grassTexture,
        rockyTexture,
        snowyTexture,
        {
          id: "center",
          position: [0, 0, 0],
          rotation: Math.PI * 0.5,
        }
      );
      // west + north east + south west
      this.addMeshToHeightMap(
        heightmapWest,
        normalmap,
        [400, 400, heightMapSize, heightMapSize],
        this.meshes.moon.repeatTexture,
        true,
        grassTexture,
        rockyTexture,
        snowyTexture,
        {
          position: [-399.5, 0, 0],
          rotation: Math.PI * 0.5,
        },
        {
          position: [399.5, 0, -399.5],
          rotation: Math.PI * 0.5,
        },
        {
          position: [399.5, 0, 399.5],
          rotation: Math.PI * 0.5,
        }
      );

      // north West + Est + South West
      this.addMeshToHeightMap(
        heightmapNorthWest,
        normalmap,
        [400, 400, heightMapSize, heightMapSize],
        this.meshes.moon.repeatTexture,
        true,
        grassTexture,
        rockyTexture,
        snowyTexture,
        {
          position: [-399.5, 0, -399.5],
          rotation: Math.PI * 0.5,
        },
        {
          position: [399.5, 0, 0],
          rotation: Math.PI * 0.5,
        },
        {
          position: [-399.5, 0, 399.5],
          rotation: Math.PI * 0.5,
        }
      );

      // noth + south
      this.addMeshToHeightMap(
        heightmapTop,
        normalmap,
        [400, 400, heightMapSize, heightMapSize],
        this.meshes.moon.repeatTexture,
        true,
        grassTexture,
        rockyTexture,
        snowyTexture,
        {
          position: [0, 0, -399.5],
          rotation: Math.PI * 0.5,
        },
        // south
        {
          position: [0, 0, 399.5],
          rotation: Math.PI * 0.5,
        }
      );

      // surrounding
      if (!this.isMobile) {
        this.addMeshToHeightMap(
          heightmapSurrounding,
          normalmap,
          [400 * 3, 400 * 3, 4, 4],
          this.meshes.moon.repeatTexture * 2,
          false,
          grassTexture,
          rockyTexture,
          snowyTexture,
          // west
          {
            position: [-400 * 3 + 0.5, 0, 0],
            rotation: Math.PI * 0.5,
          },
          // north west
          {
            position: [-400 * 3 + 0.5, 0, -400 * 3],
            rotation: Math.PI * 0.5,
          },
          // north
          {
            position: [0, 0, -400 * 3 + 1],
            rotation: Math.PI * 0.5,
          },
          // north east
          {
            position: [400 * 3 - 0.5, 0, -400 * 3 + 0.5],
            rotation: Math.PI * 0.5,
          },
          // east
          {
            position: [400 * 3 - 0.5, 0, 0],
            rotation: Math.PI * 0.5,
          },
          // south east
          {
            position: [400 * 3 - 0.5, 0, 400 * 3],
            rotation: Math.PI * 0.5,
          },
          // south
          {
            position: [0, 0, 400 * 3 - 1],
            rotation: Math.PI * 0.5,
          },
          // south west
          {
            position: [-400 * 3, 0, 400 * 3 - 1],
            rotation: Math.PI * 0.5,
          }
        );
      }

      this.convertVertexToWorld();
    },
    addMeshToHeightMap(
      selectedheigthMap,
      normalmap,
      size,
      textureRepeat,
      receiveShadow,
      grassTexture,
      rockyTexture,
      snowyTexture,
      primaryShader,
      secondaryShader,
      thirdaryShader,
      forthShader,
      fifthShader,
      sithShader,
      seventhShader,
      eigthShader
    ) {
      // https://stemkoski.github.io/Three.js/Shader-Heightmap-Textures.html
      const bumpTexture = this.loader.textureLoader.load(selectedheigthMap);
      bumpTexture.wrapS = bumpTexture.wrapT = THREE.RepeatWrapping;

      const moonGeometry = new THREE.PlaneBufferGeometry(
        size[0],
        size[1],
        size[2],
        size[3]
      );

      // https://github.com/mrdoob/three.js/issues/8016#issuecomment-254371295
      const customUniforms = {
        bumpTexture: { type: "t", value: bumpTexture },
        bumpScale: { type: "f", value: this.physics.height },
        opacity: { type: "f", value: 1.0 },
        grassTexture: { type: "t", value: grassTexture },
        rockyTexture: { type: "t", value: rockyTexture },
        snowyTexture: { type: "t", value: snowyTexture },
        uRepeat: {
          value: textureRepeat,
        },
        normalRepeat: {
          value: this.isMobile ? 0.5 : 3.0,
        },
        normalIntensity: {
          value: this.isMobile ? 0.3 : 0.6,
        },
        normalMap: {
          type: "t",
          value: normalmap,
        },
      };

      var shaderUniforms = THREE.UniformsUtils.clone(
        THREE.UniformsLib["lights"]
      );
      var shaderUniformsNormal = THREE.UniformsUtils.clone(
        THREE.UniformsLib["normalmap"]
      );
      const uniforms = Object.assign(
        shaderUniforms,
        shaderUniformsNormal,
        customUniforms
      );

      const moonMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: moonVertexShader,
        fragmentShader: this.isFragmentForSafariOnly(),
        lights: true,
      });
      // https://stackoverflow.com/questions/11071693/enabling-an-extension-on-a-three-js-shader
      moonMaterial.extensions.derivatives = true;

      const moon = new THREE.Mesh(moonGeometry, moonMaterial);
      moon.rotation.x = -Math.PI * 0.5;
      moon.rotation.z = primaryShader.rotation;
      moon.position.set(
        primaryShader.position[0],
        primaryShader.position[1],
        primaryShader.position[2]
      );

      moon.receiveShadow = receiveShadow;
      this.scene.add(moon);

      secondaryShader
        ? this.cloneShader(
            moon,
            secondaryShader.rotation,
            secondaryShader.position
          )
        : null;

      thirdaryShader
        ? this.cloneShader(
            moon,
            thirdaryShader.rotation,
            thirdaryShader.position
          )
        : null;
      forthShader
        ? this.cloneShader(moon, forthShader.rotation, forthShader.position)
        : null;
      fifthShader
        ? this.cloneShader(moon, fifthShader.rotation, fifthShader.position)
        : null;
      sithShader
        ? this.cloneShader(moon, sithShader.rotation, sithShader.position)
        : null;
      seventhShader
        ? this.cloneShader(moon, seventhShader.rotation, seventhShader.position)
        : null;
      eigthShader
        ? this.cloneShader(moon, eigthShader.rotation, eigthShader.position)
        : null;
    },
    cloneShader(shaderToClome, rotation, position) {
      const clonedShader = shaderToClome.clone();
      clonedShader.rotation.x = -Math.PI * 0.5;
      clonedShader.rotation.z = rotation;
      clonedShader.position.set(position[0], position[1], position[2]);

      clonedShader.receiveShadow = true;
      this.scene.add(clonedShader);

      this.updateMapProgress();
    },
    updateMapProgress() {
      this.loader.mapTotalProgress = this.loader.mapTotalProgress + 1;
    },

    isFragmentForSafariOnly() {
      // I didnt find yet a solution to fix a bug on Safari so until I find something I created a separated fragement shader
      return this.isSafari ? moonSafariFragment : moonFragmentShader;
    },

    /*------------------------------
    End Terrain
    ------------------------------*/

    // addFloor() {
    //   const floor = new THREE.Mesh(
    //     new THREE.PlaneGeometry(100, 100, 16, 16),
    //     new THREE.MeshStandardMaterial({
    //       color: "#777777",
    //       metalness: 0.3,
    //       roughness: 0.4,
    //       // wireframe: true,

    //       //   envMap: environmentMapTexture,
    //     })
    //   );
    //   floor.receiveShadow = true;
    //   floor.rotation.x = -Math.PI * 0.5;
    //   this.scene.add(floor);
    //   floor.position.y = 1;
    //   floor.updateMatrixWorld();
    //   this.convertVertexToWorld(floor);
    // },

    // addCube() {
    //   const boxGeometry1 = new THREE.BoxGeometry(2, 2, 2);
    //   const boxMaterial1 = new THREE.MeshStandardMaterial({
    //     metalness: 0.3,
    //     roughness: 0.4,
    //     wireframe: true,
    //   });
    //   const position = { x: 0, y: 0.5, z: 0 };
    //   const width = 1;
    //   const height = 1;
    //   const depth = 1;
    //   this.meshes.cube.mesh = new THREE.Mesh(boxGeometry1, boxMaterial1);
    //   this.meshes.cube.mesh.scale.set(width, height, depth);
    //   this.meshes.cube.mesh.castShadow = true;
    //   this.meshes.cube.mesh.position.copy(position);
    //   this.scene.add(this.meshes.cube.mesh);
    // },

    addBorders() {
      this.addBorder();
    },

    addBorder() {
      const geometry = new THREE.PlaneGeometry(15, 15);
      const material = new THREE.MeshBasicMaterial({
        color: 0xffff00,
        side: THREE.DoubleSide,
      });
      const plane = new THREE.Mesh(geometry, material);

      plane.rotation.x = -Math.PI * 0.5;

      plane.position.set(35, 3, 0);

      this.scene.add(plane);
    },

    ////////////////////////////////
    //       END ADD MESHES
    ////////////////////////////////

    ////////////////////////////////
    //       START MOVE CAMERA
    ////////////////////////////////
    followVehicule() {
      this.camera.position.set(
        this.physics.objectsToUpdate[0].body.position.x - 5,
        this.maxCameraY(
          this.physics.objectsToUpdate[0].body.position.y * 1.1 + 6
        ),
        this.physics.objectsToUpdate[0].body.position.z + 5
      );

      this.controls.target.x = this.physics.objectsToUpdate[0].body.position.x;
      this.controls.target.y = this.physics.objectsToUpdate[0].body.position.y;
      this.controls.target.z = this.physics.objectsToUpdate[0].body.position.z;
    },
    maxCameraY(cameraPostion) {
      return cameraPostion >= 100 ? 100 : cameraPostion;
    },
    cameraPositionY(positionCamera) {
      return positionCamera <= 50 ? positionCamera : 50;
    },

    ////////////////////////////////
    //       END MOVE CAMERA
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MODELS
    ////////////////////////////////
    loadModels() {
      this.setBaseLoader();
      this.textureLoader();
      this.loopGLTFLoader();
    },
    setBaseLoader() {
      // set loader
      this.setDraco();
      this.setLoader();
    },
    setDraco() {
      this.gltf.dracoLoader = new DRACOLoader();
      //  path to a folder containing WASM/JS decoding libraries.
      this.gltf.dracoLoader.setDecoderPath("/vendors/draco/"); // path needs be public
      this.gltf.dracoLoader.preload();
    },
    setLoader() {
      // set loader
      this.gltf.GLTFLoader = new GLTFLoader();
      this.gltf.GLTFLoader.setDRACOLoader(this.gltf.dracoLoader);
    },

    ////////////////////////////////
    //       START LOAD TEXTURE
    ////////////////////////////////

    textureLoader() {
      // Texture loader
      this.loader.textureLoader = new THREE.TextureLoader();
    },

    loadResponsiveTexture(mobileTexture, desktopTexture) {
      return this.isMobile
        ? this.requiredLoad(mobileTexture)
        : this.requiredLoad(desktopTexture);
    },
    requiredLoad(path) {
      return this.loader.textureLoader.load(this.requireFile(path));
    },
    requireFile(path) {
      return require(`@/assets/textures/${path}`);
    },
    ////////////////////////////////
    //       END LOAD TEXTURE
    ////////////////////////////////

    loopGLTFLoader() {
      this.gltf.GLFTList.forEach((model, index) => {
        this.gltfLoader(model, index);
      });
    },

    gltfLoader(model, index) {
      const childMaterial = this.setMaterial(model.key);

      this.gltf.GLTFLoader.load(
        `/three-assets/${model.path}`,
        (gltf) => {
          model.isAnimated ? (this[model.key] = gltf.scene) : null;

          gltf.scene.traverse((child) => {
            child.material = childMaterial;
            if (child.isMesh) {
              child.castShadow = model.isCastShadow;
              child.receiveShadow = false;
            }
          });

          this.scene.add(gltf.scene);

          gltf.scene.scale.set(model.scale[0], model.scale[1], model.scale[2]);
          gltf.scene.position.set(
            model.position[0],
            model.position[1],
            model.position[2]
          );
          model.key === "LM" ? this.setPhysicsToLM(gltf.scene) : null;
        },
        (xhr) => {
          this.GLTFProgressLoader(index, xhr.loaded / xhr.total);
        },
        undefined
      );
    },

    //======= START SET MATERIALS GLTF =======//

    setMaterial(key) {
      return key === "LM" ? this.setLMMaterial() : this.setArrowMaterial();
    },
    setLMMaterial() {
      const bakedTexture = this.loadResponsiveTexture(
        "LM/bake__3__512.jpg",
        "LM/bake__3.jpg"
      );
      const bakedNormal = this.loadResponsiveTexture(
        "LM/bake__3--normals__512.jpg",
        "LM/bake__3--normals.jpg"
      );
      bakedTexture.flipY = false;
      bakedTexture.encoding = THREE.sRGBEncoding;
      bakedNormal.flipY = false;
      bakedNormal.encoding = THREE.sRGBEncoding;

      const bakedMaterial = new THREE.MeshStandardMaterial();

      bakedMaterial.map = bakedTexture;
      bakedMaterial.normalMap = bakedNormal;
      bakedMaterial.normalScale = new THREE.Vector2(3, 3);
      return bakedMaterial;
    },
    setArrowMaterial() {
      return new THREE.MeshBasicMaterial({
        color: 0xff9e0f,
        opacity: 0.3,
        transparent: true,
      });
    },

    //======= END SET MATERIALS GLTF =======//

    setPhysicsToLM(scene) {
      const width = 3;
      const height = 4.2;
      const depth = 3;
      this.LMphysicGenerator(width, height, depth, scene);
    },

    ////////////////////////////////
    //       END ADD MODELS
    ////////////////////////////////

    ////////////////////////////////
    //       START PARTICLES
    ////////////////////////////////

    setParticleSystem() {
      const particlesUDepthFade = this.isMobile
        ? 0.0
        : this.softParticles.uDepthFade;
      const uniforms = {
        diffuseTexture: {
          value: this.requiredLoad("fire/fire.png"),
        },
        pointMultiplier: {
          value:
            window.innerHeight /
            (2.0 * Math.tan((0.5 * 60.0 * Math.PI) / 180.0)),
        },
        uCameraNear: {
          value: 0,
        },
        uCameraFar: {
          value: 0,
        },
        uDepthFade: {
          value: particlesUDepthFade,
        },
        uResolution: {
          value: new THREE.Vector2(),
        },
        uDepthTexture: {
          value: null,
        },
        uEnableFade: {
          value: 1.0,
        },
        uSpriteGrid: {
          value: new THREE.Vector2(4, 4),
        },
      };

      const fireMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: fireVertexShader,
        fragmentShader: fireFragmentShader,
        blending: THREE.CustomBlending,
        blendEquation: THREE.AddEquation,
        blendSrc: THREE.OneFactor,
        blendDst: THREE.OneMinusSrcAlphaFactor,
        depthTest: true,
        depthWrite: false,
        transparent: true,
        vertexColors: true,
      });

      const uniformsSmoke = {
        diffuseTexture: {
          value: this.requiredLoad("smoke/smoke.png"),
        },
        pointMultiplier: {
          value:
            window.innerHeight /
            (2.0 * Math.tan((0.5 * 60.0 * Math.PI) / 180.0)),
        },
        uCameraNear: {
          value: 0,
        },
        uCameraFar: {
          value: 0,
        },
        uDepthFade: {
          value: particlesUDepthFade,
        },
        uResolution: {
          value: new THREE.Vector2(),
        },
        uDepthTexture: {
          value: null,
        },
        uEnableFade: {
          value: 0.0,
        },
        uSpriteGrid: {
          value: new THREE.Vector2(4, 4),
        },
      };

      const smokeMaterial = new THREE.ShaderMaterial({
        uniforms: uniformsSmoke,
        vertexShader: fireVertexShader,
        fragmentShader: fireFragmentShader,
        blending: THREE.CustomBlending,
        blendEquation: THREE.AddEquation,
        blendSrc: THREE.OneFactor,
        blendDst: THREE.OneMinusSrcAlphaFactor,
        depthTest: true,
        depthWrite: false,
        transparent: true,
        vertexColors: true,
      });

      this.particles.fire.particles = [];
      this.particles.smoke.particles = [];

      /*------------------------------
      Start particule geometry
      ------------------------------*/
      this.particles.fire.geometry = new THREE.BufferGeometry();
      this.particles.smoke.geometry = new THREE.BufferGeometry();
      // this.setParticlesAttributes([], [], [], [], [], "fire", false);
      // this.setParticlesAttributes([], [], [], [], [], "smoke", false);

      const defaultBufferGeometryValuesFire = this.setDefaultStartingParticles(
        this.particles.maxPoints.fire
      );
      const defaultBufferGeometryValuesSmoke = this.setDefaultStartingParticles(
        this.particles.maxPoints.smoke
      );

      this.setParticlesAttributes(
        defaultBufferGeometryValuesFire.position,
        defaultBufferGeometryValuesFire.particlePosition,
        defaultBufferGeometryValuesFire.size,
        defaultBufferGeometryValuesFire.colour,
        defaultBufferGeometryValuesFire.angle,
        defaultBufferGeometryValuesFire.blend,
        "fire",
        defaultBufferGeometryValuesFire.uv,
        defaultBufferGeometryValuesFire.normal,
        false
      );
      this.setParticlesAttributes(
        defaultBufferGeometryValuesSmoke.position,
        defaultBufferGeometryValuesSmoke.particlePosition,
        defaultBufferGeometryValuesSmoke.size,
        defaultBufferGeometryValuesSmoke.colour,
        defaultBufferGeometryValuesSmoke.angle,
        defaultBufferGeometryValuesSmoke.blend,
        "smoke",
        defaultBufferGeometryValuesSmoke.uv,
        defaultBufferGeometryValuesSmoke.normal,
        false
      );
      /*------------------------------
      End particule geometry
      ------------------------------*/

      this.particles.fire.points = new THREE.Mesh(
        this.particles.fire.geometry,
        fireMaterial
      );
      this.particles.smoke.points = new THREE.Mesh(
        this.particles.smoke.geometry,
        smokeMaterial
      );

      this.scene.add(this.particles.fire.points);
      this.scene.add(this.particles.smoke.points);

      const splinesArray = [
        // START FIRE
        {
          particleName: "fire",
          splineName: "alphaSpline",
          pointsArray: [
            [0.0, 0.0],
            [0.1, 1.0],
            [0.6, 1.0],
            [1.0, 0.0],
          ],
          isColor: false,
        },
        {
          particleName: "fire",
          splineName: "colourSpline",
          pointsArray: [
            [0.0, new THREE.Color(0xffff80)],
            [1.0, new THREE.Color(0xff8080)],
          ],
          isColor: true,
        },
        {
          particleName: "fire",
          splineName: "sizeSpline",
          pointsArray: [
            [0.0, 2.0],
            [0.5, 3.0],
            [1.0, 2.0],
          ],
          isColor: false,
        },
        // END FIRE
        // START SMOKE FIRE

        {
          particleName: "fire",
          splineName: "alphaSplineS",
          pointsArray: [
            [0.0, 0.0],
            [0.1, 1.0],
            [0.5, 1.0],
            [1.0, 0.0],
          ],
          isColor: false,
        },
        {
          particleName: "fire",
          splineName: "colourSplineS",
          pointsArray: [
            [0.0, new THREE.Color(0x202020)],
            [1.0, new THREE.Color(0x000000)],
          ],
          isColor: true,
        },
        {
          particleName: "fire",
          splineName: "sizeSplineS",
          pointsArray: [
            [0.0, 2.0],
            [0.5, 3.0],
            [1.0, 3.0],
          ],
          isColor: false,
        },
        // END SMOKE FIRE

        // DUST
        {
          particleName: "smoke",
          splineName: "alphaSpline",
          pointsArray: [
            [0.0, 0.0],
            [0.1, 1.0],
            [0.6, 1.0],
            [1.0, 0.0],
          ],
          isColor: false,
        },
        {
          particleName: "smoke",
          splineName: "colourSpline",
          pointsArray: [
            [0.0, new THREE.Color(0x4d4d4d)],
            [1.0, new THREE.Color(0x202020)],
          ],
          isColor: true,
        },
        {
          particleName: "smoke",
          splineName: "sizeSpline",
          pointsArray: [
            [0.0, 3.0],
            [0.5, 6.0],
            [1.0, 7.0],
          ],
          isColor: false,
        },
      ];

      splinesArray.forEach((element) => {
        this.addParticulePointForSpline(
          element.particleName,
          element.splineName,
          element.pointsArray,
          element.isColor
        );
      });

      this.updateGeometry("fire");
      this.updateGeometry("smoke");
    },

    setDefaultStartingParticles(maxPoints) {
      return {
        position: new Float32Array(maxPoints * 6),
        particlePosition: new Float32Array(maxPoints * 3 * 6),
        size: new Float32Array(maxPoints * 6),
        colour: new Float32Array(maxPoints * 4 * 6),
        angle: new Float32Array(maxPoints * 6),
        blend: new Float32Array(maxPoints * 6),
        uv: new Float32Array(maxPoints * 6 * 6),
        normal: new Float32Array(maxPoints * 6),
      };
    },

    /*------------------------------
    Start Add points
    ------------------------------*/
    addParticulePointForSpline(
      particuleName,
      splineName,
      arrayOfPoints,
      isColour
    ) {
      isColour
        ? (this.particles[particuleName][splineName] = new LinearSpline(
            (t, a, b) => {
              const c = a.clone();
              return c.lerp(b, t);
            }
          ))
        : (this.particles[particuleName][splineName] = new LinearSpline(
            (t, a, b) => {
              return a + t * (b - a);
            }
          ));

      arrayOfPoints.forEach((points) => {
        this.particles[particuleName][splineName].AddPoint(
          points[0],
          points[1]
        );
      });
    },

    /*------------------------------
    End Add points
    ------------------------------*/

    /*------------------------------
    Start create or udpate particules attributes
    ------------------------------*/
    setParticlesAttributes(
      worldPosition,
      particlePosition,
      sizes,
      colours,
      angles,
      blends,
      particuleName,
      uvs,
      normals,
      isNeedUpdate
    ) {
      const arrayAttributes = [
        { key: "position", vecLength: 3, att: worldPosition },
        { key: "particlePosition", vecLength: 3, att: particlePosition },
        {
          key: "size",
          vecLength: 1,
          att: sizes,
        },
        { key: "colour", vecLength: 4, att: colours },
        { key: "angle", vecLength: 1, att: angles },
        { key: "blend", vecLength: 1, att: blends },
        { key: "uv", vecLength: 3, att: uvs },
        { key: "normal", vecLength: 3, att: normals },
      ];

      arrayAttributes.forEach((element) => {
        isNeedUpdate
          ? (this.particles[particuleName].geometry.setAttribute(
              `${element.key}`,
              new THREE.Float32BufferAttribute(element.att, element.vecLength)
            ),
            (this.particles[particuleName].geometry.attributes[
              element.key
            ].needsUpdate = true),
            (this.particles[particuleName].points.frustumCulled = false),
            this.particles[particuleName].geometry.computeBoundingSphere())
          : this.particles[particuleName].geometry.setAttribute(
              `${element.key}`,
              new THREE.BufferAttribute(element.att, element.vecLength)
            );
      });
    },

    /*------------------------------
    End create or udpate particules attributes
    ------------------------------*/

    //======= END SET PARTICLES ATTRIBUTES =======//

    //======= START ADD PARTICLES =======//
    addSideBoosterParticles(arrowKey) {
      if (this.particles.fire.particles.length > this.particles.maxPoints.fire)
        return;
      const n = 2;
      // there is always 2 sides boosters
      for (let i = 0; i < n; i++) {
        this.particles.fire.particles.push(
          this.sharedParticuleGenerator(
            this.particles.boosterParticlesData[arrowKey][0].position,
            (Math.random() * 0.5 + 0.5) / 3,
            (Math.random() * 0.75 + 0.25) * 1.0,
            Math.random() * 2.0 * Math.PI,
            this.particles.boosterParticlesData[arrowKey][0].velocity,
            0.2,
            "",
            1.0
          ),
          this.sharedParticuleGenerator(
            this.particles.boosterParticlesData[arrowKey][1].position,
            (Math.random() * 0.5 + 0.5) / 3,
            (Math.random() * 0.75 + 0.25) * 1.0,
            Math.random() * 2.0 * Math.PI,
            this.particles.boosterParticlesData[arrowKey][1].velocity,
            0.2,
            "",
            1.0
          )
        );
      }
    },
    addBoosterParticles() {
      if (this.particles.fire.particles.length > this.particles.maxPoints.fire)
        return;
      const n = 3;
      // fire
      for (let i = 0; i < n; i++) {
        this.particles.fire.particles.push(
          this.sharedParticuleGenerator(
            [0, Math.random() * 2 - 4, 0],
            (Math.random() * 0.5 + 0.1) * 2.0,
            (Math.random() * 0.75 + 0.25) * 1.0,
            Math.random() * 2.0 * Math.PI,
            [0, -1, 0],
            0.2,
            "",
            1.0
          )
        );
      }
      // smoke
      for (let i = 0; i < n * 3; i++) {
        this.particles.fire.particles.push(
          this.sharedParticuleGenerator(
            [
              (Math.random() * 2 - 1) * 0.5,
              (Math.random() * 2 - 6) * 0.5,
              (Math.random() * 2 - 1) * 0.5,
            ],
            (Math.random() * 0.5 + 0.1) * 1.0,
            (Math.random() * 0.75 + 0.25) * 1.0,
            Math.random() * 2.0 * Math.PI,
            [0, -1.5, 0],
            1.0,
            "S",
            1.0
          )
        );
      }
    },

    addLandingDust() {
      if (
        this.isMobile ||
        this.particles.smoke.particles.length > this.particles.maxPoints.smoke
      )
        return;
      const n = 15;
      for (let i = 0; i < n; i++) {
        this.particles.smoke.particles.push(
          this.sharedParticuleGenerator(
            [
              this.physics.objectsToUpdate[0].body.position.x +
                Math.random() * 0.5 * 1.0,
              this.particles.smoke.positionY + 1 + Math.random() * 0.5,
              this.physics.objectsToUpdate[0].body.position.z +
                Math.random() * 0.5 * 2.5,
            ],
            (Math.random() * 0.5 + 0.5) * 1.0,
            (Math.random() * 0.75 + 0.25) * 10.0,
            Math.random() * 2.0 * Math.PI,
            [
              Math.random() * (0.8 - -0.8) + -0.8,
              0.5,
              Math.random() * (0.8 - -0.8) + -0.8,
            ],
            1.0,
            "",
            1.0
          )
        );
      }
    },
    addLandingTouchDust() {
      if (
        this.isMobile ||
        this.particles.smoke.particles.length > this.particles.maxPoints.smoke
      )
        return;
      const n = 2;
      for (let i = 0; i < n; i++) {
        this.particles.smoke.particles.push(
          this.sharedParticuleGenerator(
            [
              this.physics.objectsToUpdate[0].body.position.x -
                2 +
                Math.random() * 0.5 * 1.0,
              this.particles.smoke.positionY,
              this.physics.objectsToUpdate[0].body.position.z +
                Math.random() * 0.5 * 2.5,
            ],
            (Math.random() * 0.5 + 0.5) * 1.0,
            (Math.random() * 0.75 + 0.25) * 10.0,
            Math.random() * 2.0 * Math.PI,
            [
              Math.random() * (0.8 - -0.8) + -0.8,
              0.5,
              Math.random() * (0.8 - -0.8) + -0.8,
            ],
            1.0,
            "",
            1.0
          ),
          this.sharedParticuleGenerator(
            [
              this.physics.objectsToUpdate[0].body.position.x +
                2 +
                Math.random() * 0.5 * 1.0,
              this.particles.smoke.positionY,
              this.physics.objectsToUpdate[0].body.position.z +
                Math.random() * 0.5 * 2.5,
            ],
            (Math.random() * 0.5 + 0.5) * 1.0,
            (Math.random() * 0.75 + 0.25) * 10.0,
            Math.random() * 2.0 * Math.PI,
            [
              Math.random() * (0.8 - -0.8) + -0.8,
              0.5,
              Math.random() * (0.8 - -0.8) + -0.8,
            ],
            1.0,
            "",
            1.0
          ),
          this.sharedParticuleGenerator(
            [
              this.physics.objectsToUpdate[0].body.position.x +
                Math.random() * 0.5 * 1.0,
              this.particles.smoke.positionY,
              this.physics.objectsToUpdate[0].body.position.z +
                2 +
                Math.random() * 0.5 * 2.5,
            ],
            (Math.random() * 0.5 + 0.5) * 1.0,
            (Math.random() * 0.75 + 0.25) * 10.0,
            Math.random() * 2.0 * Math.PI,
            [
              Math.random() * (0.8 - -0.8) + -0.8,
              0.5,
              Math.random() * (0.8 - -0.8) + -0.8,
            ],
            1.0,
            "",
            1.0
          ),
          this.sharedParticuleGenerator(
            [
              this.physics.objectsToUpdate[0].body.position.x +
                Math.random() * 0.5 * 1.0,
              this.particles.smoke.positionY,
              this.physics.objectsToUpdate[0].body.position.z -
                2 +
                Math.random() * 0.5 * 2.5,
            ],
            (Math.random() * 0.5 + 0.5) * 1.0,
            (Math.random() * 0.75 + 0.25) * 10.0,
            Math.random() * 2.0 * Math.PI,
            [
              Math.random() * (0.8 - -0.8) + -0.8,
              0.5,
              Math.random() * (0.8 - -0.8) + -0.8,
            ],
            1.0,
            "",
            1.0
          )
        );
      }
    },

    /*------------------------------
    Start particule generator
    ------------------------------*/
    // generate dynamicly the partcile to add
    sharedParticuleGenerator(
      position,
      size,
      life,
      rotation,
      velocity,
      blend,
      splineName,
      originalSize
    ) {
      // https://stackoverflow.com/questions/34215682/wrong-location-of-texture-on-the-second-triangle-of-buffergeometry-square
      return {
        // position: new THREE.Vector3(position[0], position[1], position[2]),
        position: new THREE.Vector3(position[0], position[1], position[2]),
        worldPosition: new Float32Array([
          -originalSize,
          -originalSize,
          0.0,

          originalSize,
          -originalSize,
          0.0,

          originalSize,
          originalSize,
          0.0,

          originalSize,
          originalSize,
          0.0,

          -originalSize,
          originalSize,
          0.0,

          -originalSize,
          -originalSize,
          0.0,
        ]),
        size: size,
        colour: new THREE.Color(),
        alpha: 1.0,
        life: life,
        maxLife: life,
        rotation: rotation,
        velocity: new THREE.Vector3(velocity[0], velocity[1], velocity[2]),
        blend: blend,
        splineName: splineName,
        uvs: new Float32Array(),
      };
    },
    /*------------------------------
    End particule generator
    ------------------------------*/

    //======= END ADD PARTICLES =======//

    updateGeometry(particleName) {
      const worldPosition = [];
      const particlesPosition = [];
      const sizes = [];
      const colours = [];
      const angles = [];
      const blends = [];
      const uvs = [];
      const normals = [];

      for (let p of this.particles[particleName].particles) {
        worldPosition.push(
          p.worldPosition[0],
          p.worldPosition[1],
          p.worldPosition[2],

          p.worldPosition[3],
          p.worldPosition[4],
          p.worldPosition[5],

          p.worldPosition[6],
          p.worldPosition[7],
          p.worldPosition[8],

          p.worldPosition[9],
          p.worldPosition[10],
          p.worldPosition[11],

          p.worldPosition[12],
          p.worldPosition[13],
          p.worldPosition[14],

          p.worldPosition[15],
          p.worldPosition[16],
          p.worldPosition[17]
        );

        for (let i = 0; i < 6; i++) {
          particlesPosition.push(p.position.x, p.position.y, p.position.z);
          blends.push(p.blend);
          colours.push(p.colour.r, p.colour.g, p.colour.b, p.alpha);
          sizes.push(p.currentSize);
          angles.push(p.rotation);

          uvs.push(
            -1.0,
            -1.0,
            0.0,

            1.0,
            -1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            -1.0,
            1.0,
            0.0,

            -1.0,
            -1.0,
            0.0
          );

          normals.push(
            -1.0,
            -1.0,
            0.0,

            1.0,
            -1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            1.0,
            1.0,
            0.0,

            -1.0,
            1.0,
            0.0,

            -1.0,
            -1.0,
            0.0
          );
        }
      }
      this.setParticlesAttributes(
        worldPosition,
        particlesPosition,
        sizes,
        colours,
        angles,
        blends,
        particleName,
        uvs,
        normals,
        true
      );
    },

    //======= START UPDATE PARTICULES EACH FRAMES =======//

    particleSteps(timeElapsed) {
      this.updateParticuleManager("fire", timeElapsed);
      this.updateParticuleManager("smoke", timeElapsed);
    },
    updateParticuleManager(name, timeElapsed) {
      this.updateParticles(timeElapsed, name);
      this.updateGeometry(name);
    },
    updateParticles(timeElapsed, particleName) {
      for (let p of this.particles[particleName].particles) {
        p.life -= timeElapsed;
      }

      this.particles[particleName].particles = this.particles[
        particleName
      ].particles.filter((p) => {
        return p.life > 0.0;
      });

      for (let p of this.particles[particleName].particles) {
        const t = 1.0 - p.life / p.maxLife;
        p.rotation += timeElapsed * 0.5;

        p.position.add(p.velocity.clone().multiplyScalar(timeElapsed));

        this.splineSwitcher(p, t, particleName, p.splineName);

        const drag = p.velocity.clone();
        drag.multiplyScalar(timeElapsed * 0.1);
        drag.x =
          Math.sign(p.velocity.x) *
          Math.min(Math.abs(drag.x), Math.abs(p.velocity.x));

        drag.y =
          Math.sign(p.velocity.y) *
          Math.min(Math.abs(drag.y), Math.abs(p.velocity.y));
        drag.z =
          Math.sign(p.velocity.z) *
          Math.min(Math.abs(drag.z), Math.abs(p.velocity.z));
        p.velocity.sub(drag);
      }

      this.particles[particleName].particles.sort((a, b) => {
        const d1 = this.camera.position.distanceTo(a.position);
        const d2 = this.camera.position.distanceTo(b.position);
        if (d1 > d2) {
          return -1;
        }
        if (d1 < d2) {
          return 1;
        }
        return 0;
      });
    },

    /*------------------------------
    Start START UPDATE SPLINE
    ------------------------------*/
    splineSwitcher(p, t, particleName, alphaSpline) {
      return (
        (p.alpha = this.particles[particleName][
          `alphaSpline${alphaSpline}`
        ].Get(t)),
        (p.currentSize =
          p.size *
          this.particles[particleName][`sizeSpline${alphaSpline}`].Get(t)),
        p.colour.copy(
          this.particles[particleName][`colourSpline${alphaSpline}`].Get(t)
        )
      );
    },
    /*------------------------------
    End START UPDATE SPLINE
    ------------------------------*/

    //======= END UPDATE PARTICULES EACH FRAMES =======//

    ////////////////////////////////
    //       END PARTICLES
    ////////////////////////////////

    ////////////////////////////////
    //       START MOVE FIRE WITH THE ROCKET
    ////////////////////////////////
    placeFirePoinstBelowTheRocket() {
      this.particles.fire.points.position.copy(
        this.physics.objectsToUpdate[0].body.position
      );
      this.particles.fire.points.quaternion.copy(
        this.physics.objectsToUpdate[0].body.quaternion
      );
    },
    ////////////////////////////////
    //       END MOVE FIRE WITH THE ROCKET
    ////////////////////////////////

    ////////////////////////////////
    //       START MOVE ROCKET
    ////////////////////////////////
    keyPressed(keyPressed) {
      if (this.isUserLanded || this.isGameControlDisabled || this.isGamePaused)
        return;
      this.particles.boosterParticlesData[keyPressed.code]
        ? this.addSideBoosterParticles(keyPressed.code)
        : null;

      switch (keyPressed.code) {
        case "Space":
          this.toggleBoostUp(true);
          this.isLMCreateMoonDust();
          this.toggleBoosterAudio(true);

          this.toggleLandingMissed(false);

          this.applyForce(
            this.physics.boostPower.space[0],
            this.isSpaceKeyPressed(),
            this.physics.boostPower.space[2]
          );
          break;
        case "ArrowLeft":
          this.toggleBoosterAudio(true);
          this.applyForce(
            this.physics.boostPower.ArrowLeft[0],
            this.isSpaceKeyPressed(),
            this.physics.boostPower.ArrowLeft[2]
          );
          break;
        case "ArrowRight":
          this.toggleBoosterAudio(true);
          this.applyForce(
            this.physics.boostPower.ArrowRight[0],
            this.isSpaceKeyPressed(),
            this.physics.boostPower.ArrowRight[2]
          );
          break;
        case "ArrowUp":
          this.toggleBoosterAudio(true);
          this.applyForce(
            this.physics.boostPower.ArrowUp[0],
            this.isSpaceKeyPressed(),
            this.physics.boostPower.ArrowUp[2]
          );
          break;
        case "ArrowDown":
          this.toggleBoosterAudio(true);
          this.applyForce(
            this.physics.boostPower.ArrowDown[0],
            this.isSpaceKeyPressed(),
            this.physics.boostPower.ArrowDown[2]
          );
          break;

        default:
          // this.toggleBoosterAudio(true);
          // this.applyForce(
          //   this.physics.boostPower.ArrowDown[0],
          //   this.isSpaceKeyPressed(),
          //   this.physics.boostPower.ArrowDown[2]
          // );
          break;
      }
    },
    toggleBoosterAudio(bool) {
      this.playPauseFX("rocket", "engine", bool);
    },
    isSpaceKeyPressed() {
      if (this.isUserLanded) return;
      // allowing user to go up while pressing other key
      this.physics.boostPower.isSpaceKeyPressed
        ? this.addBoosterParticles()
        : null;
      return this.physics.boostPower.isSpaceKeyPressed
        ? //  &&
          //   this.physics.objectsToUpdate[0].body.position.y <= 80
          this.physics.objectsToUpdate[0].body.position.y <= 90
          ? 0.35
          : 0.01
        : 0;
    },

    isLMCreateMoonDust() {
      Math.abs(
        this.particles.smoke.positionY -
          this.physics.objectsToUpdate[0].body.position.y
      ) <= 10
        ? this.addLandingDust()
        : null;
    },

    //======= START USER HIT SPACE + OTHER KEY AT THE SAME TIME =======//

    keyUnPressed(keyUnPressed) {
      if (this.isGameControlDisabled || this.gsapAnimation.exitScene.isRunning)
        return;
      this.pauseAudioAndBooster(keyUnPressed);
    },
    pauseAudioAndBooster(keyUnPressed) {
      this.toggleBoosterAudio(false);
      keyUnPressed.code === "Space" ? this.toggleBoostUp(false) : null;
    },
    toggleBoostUp(bool) {
      return (this.physics.boostPower.isSpaceKeyPressed = bool);
    },

    //======= END USER HIT SPACE + OTHER KEY AT THE SAME TIME =======//

    //======= START JOYSTICK =======//

    /*------------------------------
    Start Add joystick if user on mobile
    ------------------------------*/
    setJoyStick() {
      this.isJoystickVisibile() ? this.addJoystick() : null;
    },
    isJoystickVisibile() {
      return window.innerWidth <= 640 && !this.physics.joystick.controler;
    },

    addJoystick() {
      this.physics.joystick.controler = new JoyStick({
        game: this,
        onMove: this.userMoveWithJoyStick,
      });
    },
    /*------------------------------
    End Add joystick if user on mobile
    ------------------------------*/

    /*------------------------------
    Start Drive with the joystick
    ------------------------------*/
    userMoveWithJoyStick(forward, turn) {
      this.isGameControlDisabled || this.isUserLanded
        ? null
        : (this.updateJoystickStateDirection("forward", forward),
          this.updateJoystickStateDirection("turn", turn));

      this.playPauseFX("rocket", "engine", forward != 0);
    },
    updateJoystickStateDirection(direction, value) {
      this.physics.joystick[direction] = value;
    },

    addBoosterFireEngine(forward, turn) {
      this.isEngineFireVisible(forward)
        ? this.addSideBoosterParticles(
            this.joystickToKeyConvertor(forward, "ArrowUp", "ArrowDown")
          )
        : null;

      this.isEngineFireVisible(turn)
        ? this.addSideBoosterParticles(
            this.joystickToKeyConvertor(turn, "ArrowLeft", "ArrowRight")
          )
        : null;
    },
    joystickToKeyConvertor(value, key1, key2) {
      // is foward so key up and down
      return value > 0 ? key1 : key2;
    },
    isEngineFireVisible(value) {
      // avoid useless fire with the joystick (like forward with a tiny turn 0.001 would trigger a full engive turn )
      return Math.abs(value) >= 0.2;
    },

    /*------------------------------
    End Drive with the joystick
    ------------------------------*/

    //======= END JOYSTICK =======//

    ////////////////////////////////
    //       END MOVE ROCKET
    ////////////////////////////////

    ////////////////////////////////
    //       START RESET POSTION ROCKET
    ////////////////////////////////
    checkPositionRocket() {
      const isRocketOuside = this.isRocketFarOutside();
      const isRocketOusideAndTooLow = this.isRocketOutsideLandingZoneAndTooLow();
      const isRocketTooHigh = this.isRocketFlyingTooHigh();

      isRocketOuside || isRocketOusideAndTooLow || isRocketTooHigh
        ? (this.startResetGlitch(),
          this.displayModalWithDelay(
            isRocketOuside,
            isRocketOusideAndTooLow,
            isRocketTooHigh
          ))
        : this.alertManagement();
    },
    alertManagement() {
      this.isAlertRocketFarOutsideVisibile() ||
      this.isAlertRocketOutsideLandingZoneAndTooLowVisibile() ||
      this.isAlertRocketFlyingTooHighVisibile()
        ? this.startGlitch()
        : this.removeGlitch();
    },
    isRocketFarOutside() {
      // the rocket is simply to far away
      return (
        this.physics.objectsToUpdate[0].mesh.position.x <=
          this.perimeters.reset.x.min ||
        this.physics.objectsToUpdate[0].mesh.position.x >=
          this.perimeters.reset.x.max ||
        this.physics.objectsToUpdate[0].mesh.position.z <=
          this.perimeters.reset.z.min ||
        this.physics.objectsToUpdate[0].mesh.position.z >=
          this.perimeters.reset.z.max
      );
    },
    isRocketOutsideLandingZoneAndTooLow() {
      // rocket can fly outside the landing zone but it needs to be higher (beacause cannot land there)
      return (
        (this.physics.objectsToUpdate[0].mesh.position.x <=
          this.perimeters.landingZone.x.min &&
          this.physics.objectsToUpdate[0].mesh.position.y <=
            this.perimeters.reset.y.min) ||
        (this.physics.objectsToUpdate[0].mesh.position.x >=
          this.perimeters.landingZone.x.max &&
          this.physics.objectsToUpdate[0].mesh.position.y <=
            this.perimeters.reset.y.min) ||
        (this.physics.objectsToUpdate[0].mesh.position.z <=
          this.perimeters.landingZone.z.min &&
          this.physics.objectsToUpdate[0].mesh.position.y <=
            this.perimeters.reset.y.min) ||
        (this.physics.objectsToUpdate[0].mesh.position.z >=
          this.perimeters.landingZone.z.max &&
          this.physics.objectsToUpdate[0].mesh.position.y <=
            this.perimeters.reset.y.min)
      );
    },

    isRocketFlyingTooHigh() {
      return (
        this.physics.objectsToUpdate[0].mesh.position.y >=
        this.perimeters.reset.y.max
      );
    },

    /*------------------------------
    Start Alert
    ------------------------------*/
    isAlertRocketFarOutsideVisibile() {
      // the rocket is simply to far away
      return (
        this.physics.objectsToUpdate[0].mesh.position.x <=
          this.perimeters.alert.x.min ||
        this.physics.objectsToUpdate[0].mesh.position.x >=
          this.perimeters.alert.x.max ||
        this.physics.objectsToUpdate[0].mesh.position.z <=
          this.perimeters.alert.z.min ||
        this.physics.objectsToUpdate[0].mesh.position.z >=
          this.perimeters.alert.z.max
      );
    },
    isAlertRocketOutsideLandingZoneAndTooLowVisibile() {
      // rocket can fly outside the landing zone but it needs to be higher (beacause cannot land there)
      return (
        (this.physics.objectsToUpdate[0].mesh.position.x <=
          this.perimeters.landingZone.x.min &&
          this.physics.objectsToUpdate[0].mesh.position.y <=
            this.perimeters.alert.y.min) ||
        (this.physics.objectsToUpdate[0].mesh.position.x >=
          this.perimeters.landingZone.x.max &&
          this.physics.objectsToUpdate[0].mesh.position.y <=
            this.perimeters.alert.y.min) ||
        (this.physics.objectsToUpdate[0].mesh.position.z <=
          this.perimeters.landingZone.z.min &&
          this.physics.objectsToUpdate[0].mesh.position.y <=
            this.perimeters.alert.y.min) ||
        (this.physics.objectsToUpdate[0].mesh.position.z >=
          this.perimeters.landingZone.z.max &&
          this.physics.objectsToUpdate[0].mesh.position.y <=
            this.perimeters.alert.y.min)
      );
    },

    isAlertRocketFlyingTooHighVisibile() {
      return (
        this.physics.objectsToUpdate[0].mesh.position.y >=
        this.perimeters.alert.y.max
      );
    },
    /*------------------------------
    End Alert
    ------------------------------*/

    ////////////////////////////////
    //       END RESET POSTION ROCKET
    ////////////////////////////////

    ////////////////////////////////
    //       START RESET
    ////////////////////////////////

    //======= START DISPLAY RESTART/PAUSE MODAL =======//

    displayModalWithDelay(
      isRocketOustide,
      isRocketOusideAndTooLow,
      isRocketTooHigh
    ) {
      if (!this.alerts.timeoutModal) {
        this.alerts.timeoutModal = setTimeout(() => {
          this.displayDashboardModal(
            isRocketOustide,
            isRocketOusideAndTooLow,
            isRocketTooHigh
          );

          this.destroyTimeout(this.alerts.timeoutModal);
        }, 2000);
      }
    },

    displayDashboardModal(
      isRocketOuside,
      isRocketOusideAndTooLow,
      isRocketTooHigh
    ) {
      if (isRocketOuside) {
        this.displayDashboardModalGlobally("outside");
      } else if (isRocketOusideAndTooLow) {
        this.displayDashboardModalGlobally("low");
      } else if (isRocketTooHigh) {
        this.displayDashboardModalGlobally("high");
      }
    },
    displayDashboardModalGlobally(val) {
      this.$store.commit("dashboardModal/OPEN_MODAL", val);
    },

    //======= END DISPLAY RESTART/PAUSE MODAL =======//

    //======= START RESET GAME =======//

    resetGame() {
      this.gameIsResetTimeout = setTimeout(() => {
        // this.destroyTimeout(this.alerts.timeoutModal);
        this.alerts.timeoutModal = null;
        this.resetVehicule();
        // reset locally (used only to reset the tutorial);
        this.toggleResetLocally(true);

        // reset missed landing
        this.toggleLandingMissed(false);

        // reset modal pause
        this.toggleGamePauseGlobally(false);

        // reset composer
        this.resetEffectComposer();

        // reset dust and smoke
        this.resetDust();

        // reset tutorial (reset)
        this.resetTutorial();

        this.emitGtag(`Reset_Landing`, "Reset", "Click");
      }, 500);
    },

    displayTutorialAfterReset() {
      this.tutorials.isResetStarted
        ? (this.displayTutorial(), this.toggleResetLocally(true))
        : null;
    },

    toggleResetLocally(bool) {
      this.tutorials.isResetStarted = bool;
    },

    resetTutorial() {
      this.displayJoystickAndAlimeters(false);
      this.clearDisplayAndHideTimeOut();
      this.hideTutorials();
    },

    resetEffectComposer() {
      this.updateGlitch(false);
      this.effectComposer.filmPass.filmPass.uniforms.grayscale.value = false;
    },

    resetDust() {
      this.particles.fire.particles = [];
      this.particles.smoke.particles = [];
    },

    resetVehicule() {
      // resetPosition
      // https://github.com/schteppe/cannon.js/issues/215

      this.physics.objectsToUpdate[0].body.position.x = this.physics.startPosition.x;
      this.physics.objectsToUpdate[0].body.position.y = this.physics.startPosition.y;
      this.physics.objectsToUpdate[0].body.position.z = this.physics.startPosition.z;

      // orientation
      this.physics.objectsToUpdate[0].body.quaternion.set(0, 0, 0, 1);
      this.physics.objectsToUpdate[0].body.initQuaternion.set(0, 0, 0, 1);
      this.physics.objectsToUpdate[0].body.previousQuaternion.set(0, 0, 0, 1);
      this.physics.objectsToUpdate[0].body.interpolatedQuaternion.set(
        0,
        0,
        0,
        1
      );

      // Velocity
      this.physics.objectsToUpdate[0].body.velocity.setZero();
      this.physics.objectsToUpdate[0].body.initVelocity.setZero();
      this.physics.objectsToUpdate[0].body.angularVelocity.setZero();
      this.physics.objectsToUpdate[0].body.initAngularVelocity.setZero();

      // Force
      this.physics.objectsToUpdate[0].body.force.setZero();
      this.physics.objectsToUpdate[0].body.torque.setZero();

      // Sleep state reset
      this.physics.objectsToUpdate[0].body.sleepState = 0;
      this.physics.objectsToUpdate[0].body.timeLastSleepy = 0;
      this.physics.objectsToUpdate[0].body._wakeUpAfterNarrowphase = false;

      this.applyForce(
        this.physics.startForce[0],
        this.physics.startForce[1],
        this.physics.startForce[2]
      );
    },

    //======= END RESET GAME =======//

    ////////////////////////////////
    //       END RESET
    ////////////////////////////////

    ////////////////////////////////
    //       START POST PROCESSING GLITCHPASS
    ////////////////////////////////

    // when the user is outside autorized area
    startGlitch() {
      if (this.isGamePaused) return;

      this.effectComposer.glitchPass.isGlitchPassVisible
        ? (this.effectComposer.glitchPass.glitchPass.goWild = false)
        : this.updateGlitch(true);
    },
    updateGlitch(bool) {
      this.effectComposer.glitchPass.glitchPass.enabled = bool;
      this.effectComposer.glitchPass.isGlitchPassVisible = bool;
      this.effectComposer.glitchPass.glitchPass.goWild = bool;
      this.effectComposer.filmPass.filmPass.enabled = bool;
      this.effectComposer.RGBShiftPass.enabled = bool;
    },
    removeGlitch() {
      if (!this.effectComposer.glitchPass.isGlitchPassVisible) return;
      this.effectComposer.glitchPass.isGlitchPassVisible = false;
      this.effectComposer.glitchPass.glitchPass.goWild = true;
      this.effectComposer.glitchResetTimeOut = setTimeout(() => {
        clearTimeout(this.effectComposer.glitchResetTimeOut);
        this.effectComposer.glitchResetTimeOut = null;
        this.updateGlitch(false);
      }, 100);
    },

    startResetGlitch() {
      if (this.effectComposer.glitchResetTimeOut) return;

      this.updateGlitch(true);
      this.effectComposer.glitchResetTimeOut = setTimeout(() => {
        this.resetGlitchEnd();
        this.alerts.timeoutModal ? this.toggleGamePauseGlobally(true) : null;
      }, 700);
    },
    toggleGamePauseGlobally(bool) {
      this.$store.commit("sharedGamePlay/TOGGLE_GAME_PAUSED", bool);
    },

    resetGlitchEnd() {
      clearTimeout(this.effectComposer.glitchResetTimeOut);
      this.effectComposer.glitchPass.glitchPass.goWild = false;
      this.effectComposer.glitchPass.glitchPass.enabled = false;
      this.effectComposer.filmPass.filmPass.uniforms.grayscale.value = true;
    },

    ////////////////////////////////
    //       END POST PROCESSING GLITCHPASS
    ////////////////////////////////

    ////////////////////////////////
    //       START DISPLAY TUTORIAL
    ////////////////////////////////
    displayTutorial() {
      // MOCK > should be display after the end of the animation
      this.displayJoystickAndAlimeters(true);
      const landingTutorial = {
        isCombinedTutorial: true,
        copy: "arrows",
      };
      this.displayThenHideATutorial(15000, landingTutorial);
    },
    displayJoystickAndAlimeters(bool) {
      this.toggleDashboardBottom("arrow", bool);
      this.toggleDashboardBottom("joystick", bool);
      this.toggleDashboardBottom("alti", bool);
      this.toggleDashboardBottom("reset", bool);
    },
    ////////////////////////////////
    //       END DISPLAY TUTORIAL
    ////////////////////////////////

    ////////////////////////////////
    //       START PROGRESS LOADER
    ////////////////////////////////
    runProgressLoadingPage() {
      this.loader.intervalProgress = setInterval(() => {
        this.calculateSceneProgressLoader(this.lengthMapToLoad);
        this.$store.commit(
          "sharedTransition/UPDATE_PROGRESS",
          this.calculateCombinedProgress(this.lengthMapToLoad)
        );
        this.isPageFullyLoaded(this.lengthMapToLoad)
          ? this.gameIsReady()
          : null;
      }, 50);
    },
    calculateCombinedProgress(totalMapProgress) {
      const progress =
        (this.loader.totalProgress / this.gltf.GLFTList.length +
          this.loader.mapTotalProgress / totalMapProgress) /
        2;
      return progress > 1 ? 1 : progress;
    },
    isPageFullyLoaded(totalMapProgress) {
      return (
        this.loader.totalProgress + this.loader.mapTotalProgress >=
        this.gltf.GLFTList.length + totalMapProgress
      );
    },

    gameIsReady() {
      this.isTransitionLongEnough
        ? (this.destroyInterval(this.loader.intervalProgress),
          this.toggleIntroTimeline(!this.isGamePaused))
        : // ,this.destroyUselessStates()
          null;
    },

    // destroyUselessStates() {
    //   if (this.isGamePlayDebugging) return;
    //   this.gltf.GLFTList = [];
    //   this.gltf.GLTFLoader = null;
    // },

    calculateSceneProgressLoader() {
      this.gltf.GLFTList.forEach((model) => {
        model.progress !== undefined && !model.isLoaded
          ? this.updateTotalProgress(model.progress)
          : null;

        model.progress === 1 ? (model.isLoaded = true) : null;
      });
    },
    updateTotalProgress(progressToAdd) {
      this.loader.totalProgress = this.loader.totalProgress + progressToAdd;
    },
    GLTFProgressLoader(index, progress) {
      // update this gltf progress
      this.gltf.GLFTList[index].progress = progress;
    },

    ////////////////////////////////
    //       END PROGRESS LOADER
    ////////////////////////////////

    ////////////////////////////////
    //       START INTRO ANIMATION
    ////////////////////////////////
    initAnimatedCamera() {
      this.isIntroAnimationVisible && !this.isGamePlayDebugging
        ? this.runIntroCameraAnimation()
        : null;
    },
    runIntroCameraAnimation() {
      this.camera.position.x =
        this.physics.objectsToUpdate[0].body.position.x + 6;
      this.camera.position.y =
        this.physics.objectsToUpdate[0].body.position.y - 2;
      this.camera.position.z =
        this.physics.objectsToUpdate[0].body.position.z + 12;

      this.controls.target.x = this.physics.objectsToUpdate[0].body.position.x;
      this.controls.target.y =
        this.physics.objectsToUpdate[0].body.position.y + 20;
      this.controls.target.z =
        this.physics.objectsToUpdate[0].body.position.z + 5;

      this.gsapAnimation.intro.timeline = gsap
        .timeline({
          paused: true,
          delay: 0,
          onUpdate: () => {
            this.addSideBoosterParticles("ArrowRight");
          },
          onComplete: () => {
            // show the labels
            // at the end of the animation, set to follow
            // run mocked methods until the prgress page is ready
            this.displayTutorial();
            this.$store.commit("sharedGamePlay/TOGGLE_INTRO_ANIMATION", false);
            this.gsapAnimation.intro.isAnimationRunning = false;

            // radio 2
            this.stopAndPlaySoundEffect("landing30s", "fx");

            // Destroy this timeline since it's now useless
            this.$nextTick(() => {
              this.destroyTimeline("intro");
            });
          },
        })
        .to(
          this.physics.objectsToUpdate[0].body.position,
          {
            duration: 7 + 0,
            x: this.physics.objectsToUpdate[0].body.position.x + 50,
            ease: "none",
          },
          "animationStarted"
        )

        .to(
          this.controls.target,
          {
            duration: 4 + 0,
            x: this.physics.objectsToUpdate[0].body.position.x + 30,
            y: this.physics.objectsToUpdate[0].body.position.y - 3,
            z: this.physics.objectsToUpdate[0].body.position.z - 5,

            ease: "power1.inOut",
          },
          "animationStarted"
        )
        .to(
          this.camera.position,
          {
            duration: 5 + 0,
            x: this.physics.objectsToUpdate[0].body.position.x + 22,
            y: this.physics.objectsToUpdate[0].body.position.y + 2,
            z: this.physics.objectsToUpdate[0].body.position.z + 4,
            ease: "power1.inOut",
          },
          "animationStarted"
        )
        .add(() => {
          // radio 1
          this.stopAndPlaySoundEffect("landing50s", "fx");
        }, "animationStarted+=3")
        .to(
          this.camera.position,
          {
            duration: 3,
            x: this.physics.objectsToUpdate[0].body.position.x + 50 - 5,
            y: this.maxCameraY(
              this.physics.objectsToUpdate[0].body.position.y * 1.1 + 6
            ),
            z: this.physics.objectsToUpdate[0].body.position.z + 5,
            ease: "power1.inOut",
          },
          "-=3"
        )
        .to(
          this.controls.target,
          {
            duration: 3,
            x: this.physics.objectsToUpdate[0].body.position.x + 50,
            y: this.physics.objectsToUpdate[0].body.position.y,
            z: this.physics.objectsToUpdate[0].body.position.z,
            ease: "power1.inOut",
          },
          "-=6"
        );
    },
    toggleIntroTimeline(bool) {
      // this is needed if user toggle play pause during the animation
      if (this.gsapAnimation.intro.timeline && !this.isGamePlayDebugging) {
        bool
          ? this.gsapAnimation.intro.timeline.play()
          : this.gsapAnimation.intro.timeline.pause();
      }
    },

    runDebuggedGameMode() {
      this.$store.commit("sharedGamePlay/TOGGLE_INTRO_ANIMATION", false);
      this.displayTutorial();
      this.gsapAnimation.intro.isAnimationRunning = false;
    },

    ////////////////////////////////
    //       END INTRO ANIMATION
    ////////////////////////////////

    ////////////////////////////////
    //       START USER LANDED
    ////////////////////////////////

    //======= START CHECK USER LANDED =======//

    isUserLandedProperly() {
      // is position is within the landing site
      // if user is not on landing site so display message you need to land on landing zone
      this.isUserOutSideLandingPad()
        ? this.toggleLandingMissed(true)
        : this.startCompletedMission();
    },
    isUserOutSideLandingPad() {
      // ORIGINAL VALUES. 30 is the center of the square, which is 9m width
      // const minLandingX = 30 - 9;
      // const maxLandingX = 30 + 9;
      // const minLandingZ = 6 - 9;
      // const maxLandingZ = 6 + 9;
      const minLandingX = 21;
      const maxLandingX = 38;
      const minLandingZ = -6;
      const maxLandingZ = 10;
      return (
        this.physics.objectsToUpdate[0].body.position.x < minLandingX ||
        this.physics.objectsToUpdate[0].body.position.x > maxLandingX ||
        this.physics.objectsToUpdate[0].body.position.z < minLandingZ ||
        this.physics.objectsToUpdate[0].body.position.z > maxLandingZ
      );
    },
    startCompletedMission() {
      // toggle user landed
      this.toggleUserLanded(true);
      // disable engine
      // disable all control
      this.pauseAudioAndBooster("space");
      // remove landing missed
      this.toggleLandingMissed(false);
      this.initExitAnimation();
      // trigger message eagle has landed
      this.gsapAnimation.exitScene.timeout = setTimeout(() => {
        this.gsapAnimation.exitScene.timeline.play();
        this.gsapAnimation.exitScene.animationRunning = true;
        this.destroyTimeout(this.gsapAnimation.exitScene.timeout);
      }, 2000);
      // play out animation
    },
    toggleUserLanded(bool) {
      this.gsapAnimation.exitScene.isRunning = bool;
    },

    toggleLandingMissed(bool) {
      this.alerts.landingMissed !== bool
        ? (this.alerts.landingMissed = bool)
        : null;
    },

    //======= END CHECK USER LANDED =======//

    //======= START ANIMATE EXIT =======//

    initExitAnimation() {
      this.gsapAnimation.exitScene.timeline = gsap.timeline({
        paused: true,
        onStart: () => {
          this.pauseAudioAndBooster("space");
          this.stopAndPlaySoundEffect("landingEagleHasLanded", "fx");
        },
      });

      this.gsapAnimation.exitScene.timeline
        .to(
          this.camera.position,
          {
            y: 5,
            x: this.camera.position.x + 3,
            z: this.camera.position.z + 1,
            ease: "power1.inOut",
            duration: 3,
          },
          "rotateCamera"
        )
        .to(
          this.controls.target,
          {
            z: this.controls.target.z - 3,
            ease: "power1.inOut",
            duration: 3,
          },
          "rotateCamera"
        )
        .to(
          this.controls.target,
          {
            y: this.controls.target.y + 100,
            ease: "power4.in",
            duration: 5,
          },
          "rotateCamera+=1"
        )
        .add(() => {
          this.$store.commit(
            "sharedTransition/SET_SUCCESS_MISSION",
            "firstSteps"
          );
        }, "-=1")
        .add(() => {
          this.$store.commit("sharedGamePlay/TOGGLE_GAME_PAUSED", true);
        });
    },

    //======= END ANIMATE EXIT =======//

    ////////////////////////////////
    //       END USER LANDED
    ////////////////////////////////

    ////////////////////////////////
    //       START DESTROY TIMLINES
    ////////////////////////////////
    gsapTimelineDestroyer() {
      this.destroyTimeline("intro");
      this.destroyTimeline("exitScene");
    },
    destroyTimeline(name) {
      this.gsapAnimation[name].timeline
        ? (this.gsapAnimation[name].timeline.kill(),
          (this.gsapAnimation[name].timeline = null))
        : null;
    },

    destroyerAllTimeout() {
      this.destroyTimeout(this.gsapAnimation.timeout);
      this.destroyTimeout(this.initiate.timeout);
    },
    destroyInterval(interval) {
      interval ? (clearInterval(interval), (interval = null)) : null;
    },
    destroyTimeout(timeout) {
      timeout ? (clearTimeout(timeout), (timeout = null)) : null;
    },
    ////////////////////////////////
    //       END DESTROY TIMLINES
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/config/vars.scss";
@import "@/assets/scss/config/mixins.scss";
@import "@/assets/scss/config/responsive.scss";
.scene {
  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 0;
    pointer-events: none;

    .scene--dev & {
      pointer-events: auto;
    }
  }
}
</style>
