<template>
  <div
    class="shared-icons-reset"
    :class="{ 'shared-icons-reset--visible': isAnimationStarted }"
  >
    <div class="shared-icons-reset__icon">
      <shared-icons name="reset" class="shared-icons-reset__icon" />
      <!-- :class="`shared-icons-reset--rotate-${rotation}`" -->
    </div>
  </div>
</template>

<script>
import SharedIcons from "@/components/Shared/SharedIcons";
export default {
  components: {
    SharedIcons,
  },
  props: {
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-icons-reset {
  @include transition(0.5s ease all);
  @include transform(rotate(180deg));
  transition-delay: 2s;
  opacity: 0;
  &--visible {
    @include transform(rotate(0deg));
    opacity: 1;
  }
  &__icon {
    @include transition(0.5s ease all);
    @include transform(rotate(0deg));
    .circular-button-icon--hover & {
      @include transform(rotate(-180deg));
    }
  }
  &::v-deep svg {
    fill: var(--color-gallery);
    @include transform(scale(1.1));
    .circular-button-icon--black & {
      fill: var(--color-ebony);
    }
  }
}
</style>
