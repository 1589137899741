<template>
  <li class="shared-bottom-control-right">
    <div class="shared-bottom-control-right__inner">
      <transition name="slide-tutorial-mobile">
        <shared-bottom-control-tutorial
          v-if="isArrowTutorialVisible"
          :is-centered="false"
          :copy="copyTutorial()"
        />
      </transition>
      <shared-button-circle
        :is-white="false"
        :is-black="false"
        icon-name="arrow"
        :rotation="270"
        :is-disable="false"
        :is-hold-to-toggle="true"
        :aria-label="$t('shared.goUp.ariaLabel')"
        @mousedown.native="clicked(true)"
        @mouseup.native="clicked(false)"
        @touchstart.native="clicked(true)"
        @touchend.native="clicked(false)"
      />
    </div>
  </li>
</template>

<script>
import { mapState } from "vuex";
import SharedBottomControlTutorial from "@/components/Shared/SharedBottomControl/SharedBottomControlTutorial";
import SharedButtonCircle from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircle";
export default {
  components: {
    SharedBottomControlTutorial,
    SharedButtonCircle,
  },

  props: {
    sceneName: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      liftOffButton: null,
      isArrowTutorialVisible: false,
    };
  },

  mounted() {
    this.removeDefaultTouchBehavior();
    this.runTutorialVisibility();
  },
  beforeDestroy() {
    this.destroyEventListener();
  },
  computed: {
    ...mapState({
      isTutorialVisible: (state) => state.dashboardTutorial.isTutorialVisible,
      pathCopyTutorial: (state) => state.dashboardTutorial.pathCopyTutorial,
    }),
  },
  watch: {
    isTutorialVisible(bool) {
      this.runTutorialVisibility();
    },
  },

  methods: {
    runTutorialVisibility() {
      this.isTutorialVisible && this.pathCopyTutorial === "space"
        ? this.delayVisiblityTutorial()
        : this.toggleVisiblityTutorial(false);
    },
    removeDefaultTouchBehavior() {
      this.liftOffButton = document.getElementsByClassName(
        "shared-bottom-control-right__inner"
      )[0];

      this.liftOffButton.addEventListener("touchstart", (evt) => {
        this.handleTouches(evt);
      });
    },
    handleTouches(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    },
    destroyEventListener() {
      this.liftOffButton.removeEventListener("touchstart", (evt) => {
        this.handleTouches(evt);
      });
    },

    clicked(bool) {
      const keyPressed = {
        code: "Space",
      };
      return bool
        ? this.$emit("keyPressed", keyPressed)
        : this.$emit("keyUnPressed", keyPressed);
    },

    ////////////////////////////////
    //       START TUTORIALS
    ////////////////////////////////

    delayVisiblityTutorial() {
      // so anumation of the button is done before the copy show up

      setTimeout(() => {
        this.toggleVisiblityTutorial(true);
      }, 1000);
    },
    toggleVisiblityTutorial(bool) {
      this.isArrowTutorialVisible = bool;
    },
    copyTutorial() {
      return this.$t(`shared.tutorial.${this.sceneName}.space.mobile`);
    },
    ////////////////////////////////
    //       END TUTORIALS
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.shared-bottom-control-right {
  --joystick-opacity: 0;
  --joystick-pointer-events: none;
  --joystick-index: -999;

  display: flex;
  justify-content: flex-end;
  grid-column: 3/4;
  // @include teq-breakpoint($medium) {
  opacity: var(--joystick-opacity);
  pointer-events: var(--joystick-pointer-events);
  z-index: var(--joystick-index);
  // }
  .app--mobile & {
    --joystick-opacity: 1;
    --joystick-pointer-events: auto;
    --joystick-index: 1;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    -webkit-user-drag: none;
    -webkit-user-modify: none;
    -webkit-highlight: none;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}
</style>
