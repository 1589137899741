<template>
  <div
    class="shared-bottom-control-tutorial"
    :class="{ 'shared-bottom-control-tutorial--center': isCentered }"
  >
    <p v-html="copy" />
  </div>
</template>

<script>
export default {
  props: {
    isCentered: {
      type: Boolean,
      required: false,
      default: true,
    },
    copy: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-bottom-control-tutorial {
  --alignement-text: right;

  position: absolute;
  color: $gallery;

  top: 0px;
  text-align: var(--alignement-text);

  margin-bottom: $small-margin;

  display: flex;
  justify-content: center;
  &--center {
    --alignement-text: center;

    margin-left: -$medium-margin * 1.2;
    width: calc(100% + 2.5rem * 2);

    p {
      max-width: 30rem;
      @include transform(translateY(-100%));
    }
  }
  .shared-bottom-control-center--right & {
    right: 0px;
  }
  p {
    max-width: 30rem;
    .shared-bottom-control-center--right & {
      @include transform(translateY(-100%));
    }
    &::v-deep span {
      white-space: nowrap;
    }
  }
}
</style>
