<template>
  <ul class="navigation-main-left">
    <!-- menu btn -->
    <li class="navigation-main-left__menu">
      <shared-button-menu v-if="isVisible" :is-black="isBlack" />
    </li>
    <li class="navigation-main-left__bottom">
      <transition name="button-video">
        <shared-button-video v-if="isVideoPlaying" />
      </transition>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
import SharedButtonMenu from "@/components/Shared/SharedButton/SharedButtonMenu/SharedButtonMenu";
import SharedButtonVideo from "@/components/Shared/SharedButton/SharedButtonVideo/SharedButtonVideo";
export default {
  components: {
    SharedButtonMenu,
    SharedButtonVideo,
  },

  props: {
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isVideoPlaying: (state) => state.intro.isVideoPlaying,
    }),
  },
};
</script>

<style lang="scss" scoped>
.navigation-main-left {
  display: flex;
  flex: 0;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;
  &__menu {
    pointer-events: auto;
  }
  &__bottom {
    pointer-events: auto;
  }
}
</style>
