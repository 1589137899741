<template>
  <ul
    class="shared-button-corner"
    :class="{ 'shared-button-corner--ready': isAnimationStarted }"
  >
    <shared-button-corner-svg :is-animation-started="isAnimationStarted" />
    <shared-button-corner-svg :is-animation-started="isAnimationStarted" />
    <shared-button-corner-svg
      v-if="!isModal"
      :is-animation-started="isAnimationStarted"
    />
    <shared-button-corner-svg
      v-if="!isModal"
      :is-animation-started="isAnimationStarted"
    />
  </ul>
</template>

<script>
import SharedButtonCornerSvg from "@/components/Shared/SharedButton/SharedButtonCorner/SharedButtonCornerSvg";
export default {
  components: {
    SharedButtonCornerSvg,
  },
  props: {
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
    isModal: { type: Boolean, required: false, default: false },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-corner {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  li {
    @include transition(0.3s ease all);
    position: absolute;
    opacity: 1;

    &:nth-child(4) {
      top: 0px;
      left: 0px;
    }
    &:nth-child(3) {
      @include rotate(90deg);
      top: 0px;
      right: 0px;
    }
    &:nth-child(2) {
      @include rotate(-90deg);
      bottom: 0px;
      left: 0px;
    }
    &:nth-child(1) {
      @include rotate(180deg);
      bottom: 0px;
      right: 0px;
    }
  }
}
</style>
