<template>
  <ul class="navigation-toggle-main-side-list-links">
    <li v-for="(item, index) in list" :key="index">
      <navigation-toggle-side-list-link
        :item="item"
        :is-active="isPageActive(item)"
        @clicked="clicked"
      />
    </li>
  </ul>
</template>

<script>
import NavigationToggleSideListLink from "@/components/Navigation/navigationToggle/NavigationToggleMain/NavigationToggleMainSide/NavigationToggleMainSideList/NavigationToggleSideListLink";
export default {
  components: {
    NavigationToggleSideListLink,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    navigation: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    isPageActive(item) {
      //   is current URL is include this
      return this.$route.name.toLowerCase() === item;
    },
    clicked(val) {
      val === "contact" ? this.openEmail() : this.openPage(val);
    },
    openEmail() {
      location.href = "mailto:yay@aurelienvigne.com";
    },
    openPage(val) {
      this.$store.commit(
        "sharedTransition/SET_NEW_PROGRESS",
        this.navigation[val].key
      );
      this.toggleNavigation();
      const redirectTimeOut = setTimeout(() => {
        // go to next page
        this.pageRedirection(this.navigation[val].url);
        clearTimeout(redirectTimeOut);
      }, 800);
      // close navigation
    },

    toggleNavigation() {
      this.$store.commit("navigation/TOGGLE_NAVIGATION");
    },
    pageRedirection(pageName) {
      this.$router.push(pageName);
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-toggle-main-side-list-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  li {
    @include teq-breakpoint($medium) {
      /* 2.5 / 2 */
      padding: 1.25rem 0px 1.25rem 0px;
    }
  }
}
</style>
