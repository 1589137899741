<template>
  <div
    class="shared-joystick shared-joystick--bold"
    :class="{
      'shared-joystick--ready': isAnimationStarted,
    }"
  >
    <div class="shared-joystick__inner">
      <div class="shared-joystick__inner__thumb">
        <shared-joystick-thumb />
      </div>

      <shared-joystick-svg />

      <div class="shared-joystick__inner__circle" />
    </div>
  </div>
</template>

<script>
import SharedJoystickSvg from "@/components/Shared/SharedJoystick/SharedJoystickSvg";
import SharedJoystickThumb from "@/components/Shared/SharedJoystick/SharedJoystickThumb";
export default {
  components: {
    SharedJoystickSvg,
    SharedJoystickThumb,
  },
  data() {
    return {
      isAnimationStarted: false,
      timeout: null,
    };
  },

  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    isVisible() {
      this.delayAnimation();
    },
  },
  methods: {
    delayAnimation() {
      this.timeout = setTimeout(() => {
        this.isAnimationStarted = this.isVisible;
        this.timeoutDestroyer();
      }, 100);
    },
    timeoutDestroyer() {
      this.timeout ? (clearTimeout(this.timeout), (this.timeout = null)) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-joystick {
  position: absolute;

  width: 130px;
  height: 130px;

  z-index: 2;
  border-radius: 50%;

  bottom: 0;

  pointer-events: none;
  touch-action: none;

  &--ready {
    pointer-events: auto;
  }
  .shared-bottom-control-center--right & {
    right: 0;
  }
  @include teq-breakpoint($medium) {
    z-index: -2;
  }

  &__inner {
    display: flex;
    position: relative;
    height: 100%;

    &__thumb {
      @include transition(1s ease all);
      @include transition-delay(2.5s);
      opacity: 0;

      .shared-joystick--ready & {
        opacity: 1;
      }
    }

    &__circle {
      @include transition(0.5s ease all);
      @include transition-delay(2s);
      @include radius(50%);

      width: 100%;
      height: 100%;
      position: absolute;

      bottom: 0;
      .shared-joystick--ready & {
        @include shadow($yellow-shadow-outline);
      }

      &:before {
        @include transition(0.5s ease all);
        @include transition-delay(2s);
        content: " ";
        @include radius(50%);

        width: 100%;
        height: 100%;
        position: absolute;

        .shared-joystick--ready & {
          @include shadow($yellow-shadow-inset);
        }
      }
    }
    @include buttonAnimation;
  }
}
</style>
