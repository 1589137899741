import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      progressToDisplay: (state) => state.sharedTransition.progressToDisplay,
      isPageTransitionVisible: (state) =>
        state.sharedTransition.isPageTransitionVisible,
      isSceneExiting: (state) => state.sharedTransition.isSceneExiting,
    }),
  },
  methods: {
    isWhite() {
      return (this.isPageTransitionVisible || this.isSceneExiting) &&
        // (this.progressToDisplay === "liftOff" ||
        this.progressToDisplay === "space"
        ? true
        : false;
    },
  },
};
