// initial state
const state = () => ({
  // display alert panel
  isModalVisible: false,
  // set modal message (pause || too low || too high || outside limit || no booster )
  whichModal: null,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  ////////////////////////////////
  //       START MANAGE MODAL
  ////////////////////////////////
  OPEN_MODAL(state, val) {
    // set which modal to display
    state.isModalVisible = true;
    // open modal
    state.whichModal = val;
  },
  RESET_MODAL(state) {
    state.isModalVisible = false;
    state.whichModal = null;
  },
  ////////////////////////////////
  //       END MANAGE MODAL
  ////////////////////////////////
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
