<template>
  <li class="navigation-main-center-list">
    <!-- button -->
    <div class="navigation-main-center-list__button">
      <shared-button-nav
        :is-active="isPageActive(item.routeName)"
        :button="item"
        :is-buttons-visible="isButtonsVisible"
        :aria-label="item.routeName"
      />
    </div>
    <ul
      class="navigation-main-center-list__circles"
      v-if="item.circle.length && isCircleVisible"
    >
      <navigation-main-center-list-circle
        v-for="(circle, index) in item.circle"
        :key="index"
        :is-visible="isCircleVisible"
        :size="circle"
        :customId="`${item.key}-${index}`"
      />
    </ul>
  </li>
</template>

<script>
import SharedButtonNav from "@/components/Shared/SharedButton/SharedButtonNav/SharedButtonNav";
import NavigationMainCenterListCircle from "@/components/Navigation/NavigationMain/NavigationMainCenter/NavigationMainCenterList/NavigationMainCenterListCircle";
export default {
  components: {
    SharedButtonNav,
    NavigationMainCenterListCircle,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isCircleVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    isButtonsVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    isPageActive(routeName) {
      //   is current URL is include this
      return this.$route.name === routeName;
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-main-center-list {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: flex-end;
  @include teq-breakpoint($medium) {
    justify-content: flex-end;
    align-content: center;
    align-items: center;
  }
  &__button {
    /* @include transform(translate(0px, 30px)); */
    @include teq-breakpoint($medium) {
      /* @include transform(translate(0px, 0px)); */
    }
  }
  &__circles {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
}
</style>
