<template>
  <div
    class="navigation-toggle-main-side-list"
    :class="{ 'navigation-toggle-main-side-list--left': isLeft }"
  >
    <navigation-toggle-main-side-list-links
      v-if="list.mobile"
      class="navigation-toggle-main-side-list__mobile"
      :list="list.mobile"
      :navigation="navigation"
    />
    <navigation-toggle-main-side-list-links
      class="navigation-toggle-main-side-list__desktop"
      :list="list.desktop"
      :navigation="navigation"
    />
  </div>
</template>

<script>
import NavigationToggleMainSideListLinks from "@/components/Navigation/navigationToggle/NavigationToggleMain/NavigationToggleMainSide/NavigationToggleMainSideList/NavigationToggleMainSideListLinks";
export default {
  components: {
    NavigationToggleMainSideListLinks,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
    isLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      navigation: {
        credits: {
          key: "credits",
          url: "/credits",
          routeName: "Credits",
        },
        privacy: {
          key: "privacy",
          url: "/privacy",
          routeName: "Privacy",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.navigation-toggle-main-side-list {
  display: none;
  flex: 1;
  @include teq-breakpoint($medium) {
    display: flex;
  }
  &--left {
    display: flex;
    flex: 1;
  }

  &__mobile {
    display: flex;
    flex-direction: row;
    @include teq-breakpoint($medium) {
      display: none;
    }
  }
  &__desktop {
    display: none;
    flex-direction: column;
    @include teq-breakpoint($medium) {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
