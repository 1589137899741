<template>
  <div class="shared-progress-tutorial">
    <div class="shared-progress-tutorial__inner">
      <!-- arrow ony -->
      <tutorial-combined-keys
        v-if="isArrowVisible"
        :is-black="isBlack"
        :is-mobile="isMobile"
        :copy="$t(`shared.tutorial.${progressToDisplay}.arrows`)"
        :icons="this.whichIconsToDisplay()"
        :is-copy-desktop-only="false"
      />

      <!-- combined -->
      <tutorial-combined
        v-else
        :is-black="isBlack"
        :is-mobile="isMobile"
        :copy="$t(`shared.tutorial.${progressToDisplay}`)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TutorialCombined from "@/components/Tutorial/TutorialCombined/TutorialCombined";
import TutorialCombinedKeys from "@/components/Tutorial/TutorialCombined/TutorialCombinedKeys";
export default {
  components: {
    TutorialCombined,
    TutorialCombinedKeys,
  },
  props: {
    isBlack: { type: Boolean, require: false, default: false },
  },
  computed: {
    ...mapState({
      progressToDisplay: (state) => state.sharedTransition.progressToDisplay,
      isMobile: (state) => state.userDevice.isMobile,
    }),
    isArrowVisible() {
      return (
        this.progressToDisplay === "exploration" ||
        this.progressToDisplay === "liftOff"
      );
    },
  },

  data() {
    return {
      isExploration: false,
    };
  },
  methods: {
    whichIconsToDisplay() {
      return this.progressToDisplay === "exploration"
        ? ["joystick", "arrows"]
        : ["arrowUp", "space"];
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-progress-tutorial {
  position: absolute;
  bottom: $xl-margin;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
</style>
