<template>
  <div class="navigation-toggle-blend"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.navigation-toggle-blend {
  @include radius(50%);
  @include transform(scale(0));

  transform-origin: center;
  position: fixed;
  --size-circle: calc((100vw + 100vh) * 1.5); // pytoagores

  --position-menu-btn: calc(1.5rem + 65px / 2);
  --position-circle: calc(
    (0px - (var(--size-circle)) / 2) + var(--position-menu-btn)
  );

  top: var(--position-circle);
  left: var(--position-circle);
  background-color: var(--color-ebony);
  width: var(--size-circle);
  height: var(--size-circle); // pytoagores
  z-index: 10;

  @include teq-breakpoint($small) {
    --position-menu-btn: calc(4rem + 65px / 2);
  }

  .navigation-toggle--open & {
    @include transform(scale(1));
  }
}
</style>
