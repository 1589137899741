<template>
  <li
    class="tutorial-combined-space"
    :class="{ 'tutorial-combined-space--desktop': !isMobile }"
  >
    <div
      class="tutorial-combined-space__list tutorial-combined-space__list--mobile"
    >
      <tutorial-block
        :copy="copyToDisplay('mobile', copy.mobile)"
        :icons="icons[0]"
        :is-black="isBlack"
      />
    </div>
    <div
      class="tutorial-combined-space__list tutorial-combined-space__list--laptop"
    >
      <tutorial-block
        :copy="copyToDisplay('desktop', copy.desktop)"
        :icons="icons[1]"
        :is-black="isBlack"
      />
    </div>
    <div
      class="tutorial-combined-space__list tutorial-combined-space__list--desktop"
    >
      <tutorial-block
        :copy="copy.desktop"
        :icons="icons[1]"
        :is-black="isBlack"
      />
    </div>
  </li>
</template>

<script>
import TutorialBlock from "@/components/Tutorial/TutorialBlock/TutorialBlock";
export default {
  components: {
    TutorialBlock,
  },
  props: {
    copy: {
      type: Object,
      required: true,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    icons: {
      type: Array,
      required: false,
      default: () => ["arrowUp", "arrows"],
    },
    isCopyDesktopOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    isMobile: { type: Boolean, required: false, default: false },
  },
  methods: {
    copyToDisplay(size, copy) {
      return (size === "mobile" || size === "desktop") && this.isCopyDesktopOnly
        ? null
        : copy;
    },
  },
};
</script>

<style lang="scss" scoped>
.tutorial-combined-space {
  display: flex;
  &__list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    &--mobile {
      display: flex;
      .tutorial-combined-space--desktop & {
        display: none;
      }
    }
    &--laptop {
      display: none;
      .tutorial-combined-space--desktop & {
        display: flex;
        @include teq-breakpoint($large) {
          display: none;
        }
      }
    }
    &--desktop {
      display: none;
      .tutorial-combined-space--desktop & {
        @include teq-breakpoint($large) {
          display: flex;
        }
      }
    }
  }
}
</style>
