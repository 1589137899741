<template>
  <div
    :class="{
      'shared-button-animation--white': isWhite,
      'shared-button-animation--black': isBlack,
      'shared-button-animation--ready': isAnimationStarted,
      'shared-button-animation--circle': isCircle,
      'shared-button-animation--hover': isHover,
      'shared-button-animation--circle-visible': isCircleShadowVisible,
    }"
    class="shared-button-animation"
  >
    <shared-button-svg-circle v-if="isCircle" />
    <shared-button-svg-square v-else />

    <div class="shared-button-animation__circle" v-if="isCircle" />
  </div>
</template>

<script>
import SharedButtonSvgCircle from "@/components/Shared/SharedButton/SharedButtonSvg/SharedButtonSvgCircle";
import SharedButtonSvgSquare from "@/components/Shared/SharedButton/SharedButtonSvg/SharedButtonSvgSquare";
export default {
  components: {
    SharedButtonSvgCircle,
    SharedButtonSvgSquare,
  },
  props: {
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCircle: {
      type: Boolean,
      required: false,
      default: true,
    },
    isHover: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      timeoutAnimationCircle: null,
      isCircleShadowVisible: false,
    };
  },
  watch: {
    isAnimationStarted(bool) {
      this.isShadowVisible(false);
      bool ? this.triggerAnimationCircle() : null;
    },
  },
  beforeDestroy() {
    this.isShadowVisible(false);
    this.timeoutAnimationCircle ? this.clearTimeOut() : null;
  },
  mounted() {
    this.isShadowVisible(false);
    this.isShadowVisibleOnMount();
  },
  methods: {
    isShadowVisibleOnMount() {
      this.isAnimationStarted ? this.triggerAnimationCircle() : null;
    },
    triggerAnimationCircle() {
      this.isShadowVisible(false);
      this.timeoutAnimationCircle = setTimeout(() => {
        this.isShadowVisible(true);
        this.clearTimeOut();
      }, 1500);
    },
    isShadowVisible(bool) {
      this.isCircleShadowVisible = bool;
    },
    clearTimeOut() {
      clearTimeout(this.timeoutAnimationCircle);
      this.timeoutAnimationCircle = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-animation {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 60px;
  &__circle {
    @include transition(0.5s);
    /* @include transition-delay(2s); */
    @include radius(50%);

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    .shared-button-animation--circle-visible.shared-button-animation--circle & {
      @include shadow($yellow-shadow-outline);
    }
    .shared-button-animation--circle-visible.shared-button-animation--circle.shared-button-animation--hover
      & {
      @include shadow($yellow-shadow-outline--large);
    }

    .shared-button-animation--white.shared-button-animation--circle-visible & {
      @include shadow($white-shadow-outline);
    }
    .shared-button-animation--white.shared-button-animation--circle-visible.shared-button-animation--hover
      & {
      @include shadow($white-shadow-outline--large);
    }
    &:before {
      @include transition(0.5s);
      /* @include transition-delay(2s); */
      content: " ";
      @include radius(50%);

      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      .shared-button-animation--circle-visible.shared-button-animation--circle
        & {
        @include shadow($yellow-shadow-inset);
      }
      /* .shared-button-animation--circle-visible.shared-button-animation--circle.shared-button-animation--hover
        & {
        @include shadow($yellow-shadow-inset--large);
      } */
      .shared-button-animation--white.shared-button-animation--circle-visible
        & {
        @include shadow($white-shadow-inset);
      }
      /* .shared-button-animation--white.shared-button-animation--circle-visible.shared-button-animation--hover
        & {
        @include shadow($white-shadow-inset--large);
      } */
    }
  }
  @include buttonAnimation;
}
</style>
