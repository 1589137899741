<template>
  <section
    class="scene"
    :class="{ 'scene--debug': isDebug && this.isGamePlayDebugging }"
    :style="`--vh: ${customHeightScreen}px`"
  >
    <ul
      class="scene__gallery"
      :class="{
        'scene__gallery--scrolling': gallery.animation.scroll.isUserScrolling,
        'scene__gallery--mobile': isMobile,
      }"
    >
      <li class="scene__gallery__list"></li>
      <li
        v-for="(item, index) in images"
        :key="index"
        :ref="item.ref"
        class="scene__gallery__list"
      >
        <button
          :data-attribute="
            $t(`gallery.data-attributes`, {
              title: $t(`gallery.${parent}.${item.id}.info`),
            })
          "
          class="scene__gallery__list__item"
          @click="openGallery(item.id)"
        >
          <img
            class="scene__gallery__list__item__img"
            :src="getImgGalleryUrl(parent, responsivePathImg, item.img)"
            :alt="$t(`gallery.${parent}.${item.id}.info`)"
          />
        </button>
      </li>
      <li class="scene__gallery__list"></li>
    </ul>
    <transition name="gallery-scroll">
      <shared-gallery-scroll v-if="isScrollArrowVisible" />
    </transition>
    <canvas ref="scene__container" class="scene__container"></canvas>
    <space-scene
      v-if="parent === 'space'"
      :gallery-progress="loader.loaderManager.textures.progress"
      :is-end-of-gallery="gallery.positionEndOfGallery.isScrolledToTheEnd"
      :is-gallery-animation-running="gsapAnimation.gallery.isRunning"
      @introAnimationCompleted="startScrollIntroAnimation"
    />
    <first-steps-scene
      v-else
      :gallery-progress="loader.loaderManager.textures.progress"
      :is-end-of-gallery="gallery.positionEndOfGallery.isScrolledToTheEnd"
      :is-gallery-animation-running="gsapAnimation.gallery.isRunning"
      @introAnimationCompleted="startScrollIntroAnimation"
    />
  </section>
</template>

<script>
import { mapState } from "vuex";
import isDevMixin from "@/mixins/isDevMixin";
import sharedMixin from "@/mixins/sharedMixin.js";
import transitionMixin from "@/mixins/transitionMixin.js";
import gtagMixin from "@/mixins/gtagMixin.js";

import { gsap } from "gsap";

import imagesLoaded from "imagesloaded";
import * as THREE from "three";

// Post Processing
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";

// STATS
import Stats from "stats.js";
// import { GUI } from "@/vendors/dat.gui.module.js";

// SHADERS
import fragment from "@/shaders/gallery/fragment.glsl";
import vertex from "@/shaders/gallery/vertex.glsl";

import postProcessingFragment from "@/shaders/gallery/postProcessingFragment.glsl";
import postProcessingVertex from "@/shaders/gallery/postProcessingVertex.glsl";

import tempImg from "@/assets/gallery/lazy/lazy.jpg";

import FirstStepsScene from "@/components/FirstSteps/FirstStepsScene";
import SpaceScene from "@/components/Space/SpaceScene";
import SharedGalleryScroll from "@/components/Shared/SharedGallery/SharedGalleryScroll";

export default {
  mixins: [isDevMixin, sharedMixin, transitionMixin, gtagMixin],
  components: {
    FirstStepsScene,
    SpaceScene,
    SharedGalleryScroll,
  },
  props: {
    images: {
      type: Array,
      required: false,
      default: () => [],
    },
    parent: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState({
      isGalleryOpen: (state) => state.gallery.isGalleryOpen,
      isGamePlayDebug: (state) => state.sharedGamePlay.isGamePlayDebug,

      isMobile: (state) => state.userDevice.isMobile,
      isNavOpen: (state) => state.navigation.isNavOpen,
      isScrollDisabled: (state) => state.shared.isScrollDisabled,
      isUserDeviceReady: (state) => state.userDevice.isUserDeviceReady,

      isPageTransitionVisible: (state) =>
        state.sharedTransition.isPageTransitionVisible,
    }),
    isGamePlayDebugging() {
      return this.isDevEnv() && this.isGamePlayDebug;
    },
    isScrollArrowVisible() {
      return (
        this.scrollArrow.isVisible &&
        (!this.gallery.isIntroAnimationEnded ||
          (this.gallery.animation.scroll.currentScrollX < 10 &&
            this.gallery.animation.scroll.currentScrollY < 501))
      );
    },
    customHeightScreen() {
      return this.isMobile
        ? this.cameras.windowUser.height + 100
        : this.cameras.windowUser.height;
    },

    responsivePathImg() {
      return this.isMobile ? "framed/small" : "framed";
    },
  },

  watch: {
    isScrollDisabled(bool) {
      bool ? this.addEventDisableScroll() : this.removeEventDisableScroll();
    },
    isUserDeviceReady(bool) {
      bool && !this.initiate.isInitiating ? this.init() : null;
    },
    isNavOpen(bool) {
      bool
        ? this.toggleIsGamePausedOnce(true)
        : this.isGamePausedOnce && !this.initiate.isInitiating
        ? this.nextAnimationFrame()
        : null;
    },
    isGalleryOpen(bool) {
      !bool && this.gsapAnimation.gallery.isRunning
        ? this.reverseGalleryAnimation()
        : null;
    },
  },
  data() {
    return {
      // base
      container: null,
      scene: null,
      camera: null,
      controls: null,
      animation: undefined,

      isGamePausedOnce: false,

      isDebug: false,

      initiate: {
        isInitStarted: false,
        isInitiating: false,
        timeout: null,
        timeoutGameloop: null,
        timeoutMounted: null,
      },

      // raycaster: {
      //   raycaster: null,
      //   mouse: null,
      // },

      gallery: {
        scrollContainer: null,
        isGalleryScrollable: false,
        isIntroAnimationEnded: false,
        animation: {
          scroll: {
            currentScroll: 0,
            currentScrollX: 0,
            currentScrollY: 0,
            currentScrollDisabledX: 0,
            currentScrollDisabledY: 0,

            scrollSpeed: 0.0,

            startPosition: {
              x: 0,
              y: 0,
            },

            isUserScrolling: false,
            scrollTimeOut: null,
            isUserScrollReseting: false,
            scrollSpeedMobile: {
              lastPos: 0,
              newPos: 0,
              delay: 0,
              delta: 50,
              scrollSpeed: 0,
            },
          },
        },

        images: {
          images: [],
          imageStore: [],
        },
        positionEndOfGallery: {
          x: 0,
          y: 0,
          isScrolledToTheEnd: false,
        },
      },

      effectComposer: {
        composer: null,
        renderTarget: null,

        customPass: {
          customPass: null,
        },
      },

      //  helper
      helpers: {
        stats: null,
      },
      clock: {
        clock: null,
        then60FPS: null,
      },

      loader: {
        textureLoader: null,
        loaderManager: {
          textures: { progress: 0 },
        },
      },

      material: null,
      materials: [],
      time: 0,
      cameras: {
        defaultCameraPosition: {
          camX: 0,
          camY: 0,
          camZ: 3,
        },

        windowUser: {
          width: 0,
          height: 0,
        },
        isDesktop: true,
        // innerWidthDesktop: 640,
      },
      gsapAnimation: {
        intro: {
          timeLine: null,
          startPosition: 500,
        },
        gallery: {
          timeLine: null,
          isRunning: false,
        },
        floating: {
          timeLine: null,
          timeLineImgs: [],
          isHovering: false,
        },
      },
      scrollArrow: {
        isVisible: false,
      },
    };
  },
  mounted() {
    this.delayMountedMethods();
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animation);
    this.animation = undefined;

    this.resetGalleryStore();
    // destroy event listener
    this.removeAllEventListenerScroll();
    this.destroyEventListener();
    this.destroyAllTimelines();
  },
  methods: {
    ////////////////////////////////
    //       START ON MOUNTED METHODS
    ////////////////////////////////

    delayMountedMethods() {
      this.initiate.timeoutMounted = setTimeout(() => {
        this.methodsOnMount();
        this.destroyTimeout(this.initiate.timeoutMounted);
      }, 300);
    },
    methodsOnMount() {
      this.resetGalleryStore();

      // disable scroll until scene is full ready
      this.toggleScrollGlobally(true);

      this.setWindowSize();

      // set current screen size for resize
      this.setIsDesktop();

      // make sure the gallery is scroll to the top after each load
      this.resetAllScroll(0);

      this.isUserDeviceReady && !this.initiate.isInitiating
        ? this.delayInit()
        : null;
      // Register an event listener when the Vue component is ready

      // init animation gallery
    },

    delayInit() {
      this.initiate.isInitiating = true;
      this.initiate.timeout = setTimeout(
        () => {
          this.init();
          window.addEventListener("resize", this.onResize);
          this.initGalleryAnimation();
          this.destroyTimeout(this.initiate.timeout);
        },
        this.isMobile ? 2000 : 300
      );
    },

    resetGalleryStore() {
      this.$store.dispatch("gallery/resetGallery");
    },

    //   set window width
    setWindowSize() {
      this.cameras.windowUser.width = window.innerWidth;

      this.cameras.windowUser.height = !this.isMobile
        ? window.innerHeight
        : window.screen.height;
    },
    setIsDesktop() {
      this.cameras.isDesktop = !this.isMobile;
    },

    // gameLoop is pause when game pause, so ensure that gameLoop don't run twince on mount
    toggleIsGamePausedOnce(bool) {
      this.isGamePausedOnce = bool;
    },

    onResize() {
      // mobile scroll always a pain
      this.isMobile ? this.resizeOnMobile() : this.groupedMethodsToResize();
    },
    resizeOnMobile() {
      // only resize mobile when width is different
      this.cameras.windowUser.width === window.innerWidth
        ? null
        : this.groupedMethodsToResize();
    },

    groupedMethodsToResize() {
      // Update sizes
      this.setWindowSize();

      // Update camera
      this.camera.aspect =
        this.cameras.windowUser.width / this.cameras.windowUser.height;
      this.camera.updateProjectionMatrix();

      this.gsapAnimation.gallery.isRunning ? this.resizeGalleryUI() : null;

      this.addEventOnScroll();

      this.resizeGallery();

      this.setRenderSize();

      this.effectComposer.composer ? this.setSizePostProcessing() : null;

      this.setEffectComposerRenderTarget();

      // this.scrollEvents();
      // this.resizeScrollIntroAnimation();
      // this.overWritescroll();
      this.$nextTick(() => {
        this.gsapAnimation.gallery.isRunning
          ? this.toggleGalleryAnimation(false)
          : null;
      });
    },

    setRenderSize() {
      // Update renderer
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },

    setEffectComposerRenderTarget() {
      this.effectComposer.renderTarget = new THREE.WebGLRenderTarget(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height,
        {
          minFilter: THREE.LinearFilter,
          magFilter: THREE.LinearFilter,
          format: THREE.RGBAFormat,
          encoding: THREE.sRGBEncoding,
        }
      );
    },

    resizeGalleryUI() {
      this.toggleScrollGlobally(false);
      // if gallery open
      // close modal
      this.$store.commit("gallery/UPDATE_SELECTED_IMG", null);
      this.$store.commit("gallery/TOGGLE_GALLERY", false);

      // reset position camera
      this.gsapAnimation.gallery.timeline.seek(0).pause();
    },

    ////////////////////////////////
    //       END ON MOUNTED METHODS
    ////////////////////////////////

    ////////////////////////////////
    //       START INIT SCENE AND RENDER
    ////////////////////////////////

    init() {
      if (this.initiate.isInitStarted) return;
      this.initiate.isInitStarted = true;
      this.startTimerTransitionIsLongEnough();
      //       // we don't need user to read the tutorial
      // this.toggleTransitionIsLongEnough(true);
      this.setBaseScene();

      this.setLight();

      // this.setRayCaster();

      /*------------------------------
      Start add meshes to the scenes
      ------------------------------*/
      this.textureLoader();
      this.addMeshesToScene();
      /*------------------------------
      End add meshes to the scenes
      ------------------------------*/

      this.setCamera();
    },

    //======= START GAME LOOP =======//

    gameLoop() {
      // console.log("this.material.uniforms", this.material.uniforms);
      // don't display the stats if on prod. Maybe this dev stuff should be handle differently
      // this.isDevEnv() ? this.helpers.stats.update() : ""; // try to save a tiny extra

      const elapsedTime = this.clock.clock.getElapsedTime();

      this.reduced60FPS(elapsedTime);

      // Render
      // this.controls.update();

      // this.renderer.render(this.scene, this.camera);
      this.effectComposer.composer.render();

      // Call gameLoop again on the next frame
      this.isNavOpen ? null : this.nextAnimationFrame();
    },
    nextAnimationFrame() {
      this.animation = requestAnimationFrame(this.gameLoop);
    },

    reduced60FPS(now) {
      // code from > https://gist.github.com/elundmark/38d3596a883521cb24f5
      // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
      const fps = 60;
      const interval = 1 / fps; // replaced
      const delta = now - this.clock.then60FPS;

      if (delta > interval) {
        this.time += this.setMaximumAndMinimumScrollSpeed(
          this.gallery.animation.scroll.scrollSpeed / 15
        );

        this.materials.forEach((m) => {
          m.uniforms.time.value = this.time;
        });

        this.gallery.animation.scroll.scrollSpeed > 0
          ? this.removeScrollSpeedOnStop()
          : null;

        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.
        this.clock.then60FPS = now - (delta % interval);
      }
    },
    /*------------------------------
    Start Scroll Speed Polish for the wave
    ------------------------------*/
    setMaximumAndMinimumScrollSpeed(scrollSpeed) {
      return scrollSpeed >= 0.2 ? 0.2 : this.setMinimumScrollSpeed(scrollSpeed);
    },
    setMinimumScrollSpeed(scrollSpeed) {
      return scrollSpeed < 0.04 ? 0.04 : scrollSpeed;
    },
    /*------------------------------
    End Scroll Speed Polish for the wave
    ------------------------------*/

    //======= END GAME LOOP =======//

    removeScrollSpeedOnStop() {
      (this.gallery.animation.scroll.scrollSpeed > 0.1 &&
        this.gallery.animation.scroll.isUserScrollReseting) ||
      !this.gallery.animation.scroll.isUserScrolling
        ? this.slowScrollSpeed()
        : this.resetScroll();
    },
    slowScrollSpeed() {
      this.gallery.animation.scroll.scrollSpeed =
        this.gallery.animation.scroll.scrollSpeed - this.scrollSpeedValues();
    },

    scrollSpeedValues() {
      return this.gallery.animation.scroll.scrollSpeed > 5 &&
        this.gallery.animation.scroll.isUserScrollReseting
        ? this.gallery.animation.scroll.scrollSpeed * 0.8
        : 0.01;
    },
    resetScroll() {
      this.gallery.animation.scroll.scrollSpeed < 1
        ? // ? ((this.gallery.animation.scroll.scrollSpeed = 0.25),
          (this.resetScrollWithGSAP(),
          (this.gallery.animation.scroll.isUserScrollReseting = false))
        : (this.gallery.animation.scroll.isUserScrollReseting = false);
    },
    isResetOccured() {
      this.gallery.animation.scroll.scrollSpeed > 0.25 &&
        this.gallery.animation.scroll.isUserScrollResetingWithGSAP;
    },
    resetScrollWithGSAP() {
      // this won't be trigger if lower than 0.25
      this.gallery.animation.scroll.scrollSpeed <= 0.25
        ? null
        : (this.gallery.animation.scroll.scrollSpeed =
            this.gallery.animation.scroll.scrollSpeed - 0.01);
    },

    //======= START BASE THREEJS =======//

    setBaseScene() {
      // set container
      this.container = this.$refs.scene__container;

      // create scene
      this.scene = new THREE.Scene();

      this.isDevEnv() ? this.setHelpers() : "";

      this.setClock();
    },

    setHelpers() {
      // stats
      this.helpers.stats = new Stats();
      document.body.appendChild(this.helpers.stats.dom);

      // axes helpers
      const axesHelper = new THREE.AxesHelper(5);
      // x y z
      this.scene.add(axesHelper);

      // set gui globaly
      // this.setGUI();
    },
    // setGUI() {
    //   this.helpers.gui = new GUI();
    // },
    setClock() {
      this.clock.clock = new THREE.Clock();
    },

    //======= END BASE THREEJS =======//

    //======= START SET RAYCASTER =======//

    // setRayCaster() {
    //   this.raycaster.raycaster = new THREE.Raycaster();
    //   this.raycaster.mouse = new THREE.Vector2();
    // },

    //======= END SET RAYCASTER =======//

    //======= START LIGHTS =======//

    setLight() {
      const ambientLight = new THREE.AmbientLight(0xffffff, 1);
      this.scene.add(ambientLight);
    },

    //======= END LIGHTS =======//

    //======= START CAMERA AND CONTROL =======//

    setCamera() {
      this.cameras.windowUser.width = window.innerWidth;
      this.cameras.windowUser.height = window.innerHeight;

      this.camera = new THREE.PerspectiveCamera(
        this.setFieldOfView(),
        this.cameras.windowUser.width / this.cameras.windowUser.height,
        10,
        2000
      );

      this.camera.position.z = 600;

      this.scene.add(this.camera);
    },
    setFieldOfView() {
      return (
        2 *
        Math.atan(this.cameras.windowUser.height / 2 / 600) *
        (180 / Math.PI)
      );
    },

    //======= END CAMERA AND CONTROL =======//

    //======= START RENDERER  =======//

    setRenderer() {
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.container,
        powerPreference: "high-performance",
        alpha: true,
      });
      this.renderer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );

      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      this.renderer.setClearColor(0xffffff, 0);
    },

    //======= END RENDERER  =======//

    ////////////////////////////////
    //       END INIT SCENE AND RENDER
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD MESHES TO SCENE
    ////////////////////////////////

    //======= START LOAD MANAGER =======//

    textureLoader() {
      const loadingManager = new THREE.LoadingManager();

      loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
        this.loader.loaderManager.textures.progress = itemsLoaded / itemsTotal;
      };
      loadingManager.onError = () => {
        console.log("texture loading error");
      };

      // Texture loader

      this.loader.textureLoader = new THREE.TextureLoader(loadingManager);
    },

    //======= END LOAD MANAGER =======//

    addMeshesToScene() {
      this.addImages();
    },
    ////////////////////////////////
    //       END ADD MESHES TO SCENE
    ////////////////////////////////

    ////////////////////////////////
    //       START SET POST PROCESSING
    ////////////////////////////////
    setPostProcessing() {
      this.effectComposer.composer = new EffectComposer(this.renderer);

      this.effectComposer.composer.addPass(
        new RenderPass(this.scene, this.camera)
      );
      this.setSizePostProcessing();
      // add passes
      this.customPass();
    },
    setSizePostProcessing() {
      this.effectComposer.composer.setPixelRatio(
        Math.min(window.devicePixelRatio, 2)
      );
      this.effectComposer.composer.setSize(
        this.cameras.windowUser.width,
        this.cameras.windowUser.height
      );
    },

    //======= START ADD POST PROCESSING PASSES =======//

    customPass() {
      //custom shader pass
      const myEffect = {
        uniforms: {
          tDiffuse: { value: null },
          scrollSpeed: { value: 0.0 },
          power: { value: 6.0 },
          areaMultiplicator: { value: 1.5 },
          areaY: { value: 0.4 },
        },
        vertexShader: postProcessingVertex,
        fragmentShader: postProcessingFragment,
      };

      this.effectComposer.customPass.customPass = new ShaderPass(myEffect);
      this.effectComposer.customPass.customPass.renderToScreen = true;

      this.effectComposer.composer.addPass(
        this.effectComposer.customPass.customPass
      );
    },

    //======= END ADD POST PROCESSING PASSES =======//

    ////////////////////////////////
    //       END SET POST PROCESSING
    ////////////////////////////////

    ////////////////////////////////
    //       START ADD STUFF
    ////////////////////////////////

    //======= START ADD IMAGES =======//

    addImages() {
      this.fetchImages();

      // Preload images
      const preloadImages = new Promise((resolve, reject) => {
        imagesLoaded(
          document.querySelectorAll("img"),
          { background: true },
          resolve
        );
      });

      let allDone = [preloadImages];
      Promise.all(allDone).then(() => {
        this.getImagesInfo();
        this.setPosition();
        // this.setMouseEventListener(); // leave it there if I want it later
        this.setRenderer();
        this.setPostProcessing();

        this.gameLoop();

        this.addEventOnScroll();

        this.setValuesPositionLastDiv();
        this.initiate.isInitiating = false;
      });
    },

    /*------------------------------
    Start Mouse Movement
    ------------------------------*/
    // leave it there if I want it later

    // setMouseEventListener() {
    //   window.addEventListener("mousemove", this.onMouseMove, false);
    // },
    // onMouseMove(event) {
    //   this.raycaster.mouse.x =
    //     (event.clientX / this.cameras.windowUser.width) * 2 - 1;
    //   this.raycaster.mouse.y =
    //     -(event.clientY / this.cameras.windowUser.height) * 2 + 1;

    //   this.rayCasterIntersects();
    // },
    // rayCasterIntersects() {
    //   // update the picking ray with the camera and mouse position
    //   this.raycaster.raycaster.setFromCamera(this.raycaster.mouse, this.camera);

    //   // calculate objects intersecting the picking ray
    //   const intersects = this.raycaster.raycaster.intersectObjects(
    //     this.scene.children
    //     // this.effectComposer.composer.passes[0].scene.children
    //   );

    //   if (intersects.length > 0) {
    //     let obj = intersects[0].object;
    //     obj.material.uniforms.hover.value = intersects[0].uv;
    //   }
    //   gsap.to(obj.material.uniforms.hover, {
    //     value: intersects[0].uv,
    //     duration: 1,
    //   });
    // },
    /*------------------------------
    End Mouse Movement
    ------------------------------*/

    /*------------------------------
    Start Get images
    ------------------------------*/
    fetchImages() {
      this.gallery.images.images = [...document.querySelectorAll("img")];
    },
    getImagesInfo() {
      this.createImageShader();
      this.loopAllImage();
    },

    // create new material for the images
    createImageShader() {
      this.material = new THREE.ShaderMaterial({
        side: THREE.DoubleSide,
        vertexShader: vertex,
        fragmentShader: fragment,
        // wireframe: true,
        uniforms: {
          scrollSpeed: { value: 0 },
          time: { value: 0 },
          uImage: { value: 0 },
          hover: { value: new THREE.Vector2(0.5, 0.5) },
          hoverState: { value: 0.0 },
          hoverState2: { value: 0.0 },
          oceanTexture: { value: this.loader.textureLoader.load(tempImg) },
        },
      });
    },

    // loop all images
    loopAllImage() {
      this.gallery.images.imagestore = this.gallery.images.images.map((img) => {
        let bounds = img.getBoundingClientRect();

        let geometry = new THREE.PlaneBufferGeometry(
          bounds.width,
          bounds.height,
          10,
          10
        );

        let texture = new THREE.Texture(img);
        // console.log("texture", texture);
        texture.generateMipmaps = false;
        texture.minFilter = THREE.LinearFilter;
        texture.needsUpdate = true;

        let material = this.material.clone();

        img.addEventListener("mouseenter", (evt) =>
          this.eventListenerHover(material)
        );
        img.addEventListener("mouseout", (evt) =>
          this.eventListenerUnHover(material)
        );

        this.materials.push(material);

        material.uniforms.uImage.value = texture;

        let mesh = new THREE.Mesh(geometry, material);

        this.scene.add(mesh);

        return {
          img: img,
          mesh: mesh,
          top: bounds.top,
          left: bounds.left,
          width: bounds.width,
          height: bounds.height,
          scale: 1,
        };
      });
    },

    //--- start Event listner hvoer the images ---//
    eventListenerHover(material) {
      gsap.to(material.uniforms.hoverState, {
        duration: 0.8,
        value: 1,
        ease: "power1.inOut",
      });
      gsap.to(material.uniforms.hoverState2, {
        duration: 2,
        value: 10.1,
        ease: "power1",
      });
    },
    eventListenerUnHover(material) {
      gsap.to(material.uniforms.hoverState, {
        duration: 0.8,
        value: 0,
        ease: "power1.inOut",
      });
      gsap.to(material.uniforms.hoverState2, {
        duration: 1.5,
        value: 0.0,
        ease: "power1",
      });
    },

    //--- end Event listner hvoer the images ---//

    /*------------------------------
    End Get images
    ------------------------------*/

    //======= END ADD IMAGES =======//

    ////////////////////////////////
    //       END ADD STUFF
    ////////////////////////////////

    ////////////////////////////////
    //       START SCROLL
    ////////////////////////////////

    //======= START SCROLL EVENTS =======//

    addEventOnScroll() {
      // Wheel allow horizontal scroll, otherwise scroll is needed on vertical scroll with mobile devices
      !this.isMobile ? this.addWheelListener() : this.addScrollListner();
    },
    addWheelListener() {
      this.removeAllEventListenerScroll();
      window.addEventListener("wheel", this.wheelEvents);
    },
    addScrollListner() {
      this.removeAllEventListenerScroll();
      window.addEventListener("scroll", this.scrollEvents);
    },

    wheelEvents(evt) {
      !this.isScrollDisabled
        ? (this.setWheelContainer(),
          this.calculateHorizontalScroll(evt, this.gallery.scrollContainer),
          this.updatePostionOnScroll())
        : null;
    },

    setWheelContainer() {
      // only needed on desktop because the horizontal scroll move the container instead of the all page
      !this.isMobile && !this.gallery.scrollContainer
        ? (this.gallery.scrollContainer = document.querySelector(
            ".scene__gallery"
          ))
        : null;
    },

    scrollEvents() {
      !this.isScrollDisabled
        ? (this.calculateVerticalScroll(),
          this.updatePostionOnScroll(),
          this.isUserScrolledToTheEndOfTheGallery("currentScrollY", "y"))
        : null;
    },

    /*------------------------------
    Start horizontal scroll
    ------------------------------*/
    calculateHorizontalScroll(evt, scrollContainer) {
      scrollContainer.scrollLeft += evt.deltaY;

      this.gallery.animation.scroll.currentScrollX = scrollContainer.scrollLeft;

      this.calculateWheelSpeed(evt.deltaY);

      this.isUserScrolledToTheEndOfTheGallery("currentScrollX", "x");
    },
    deltaScroll(newPositionY, currentPositionY) {
      return newPositionY - currentPositionY;
    },
    calculateWheelSpeed(deltaY) {
      return (this.gallery.animation.scroll.scrollSpeed +=
        (Math.abs(deltaY) - this.gallery.animation.scroll.scrollSpeed) * 0.2);
    },
    /*------------------------------
    End horizontal scroll
    ------------------------------*/

    /*------------------------------
    Start vertical scroll
    ------------------------------*/
    calculateVerticalScroll() {
      // calculate scroll speed (for mobile wheel delta won't do the job)
      this.calculateScrollSpeedForMobile();
      // scroll
      this.scrollVertically();
    },
    scrollVertically() {
      // this.gallery.animation.scroll.currentScrollY = window.scrollY;
      this.gallery.animation.scroll.currentScrollY += this.deltaScroll(
        window.scrollY,
        this.gallery.animation.scroll.currentScrollY
      );
    },

    calculateScrollSpeedForMobile() {
      this.clearScrollValues();

      this.gallery.animation.scroll.scrollSpeedMobile.newPos = window.scrollY;

      this.gallery.animation.scroll.scrollSpeedMobile.delta =
        this.gallery.animation.scroll.scrollSpeedMobile.newPos -
        this.gallery.animation.scroll.scrollSpeedMobile.lastPos;

      this.gallery.animation.scroll.scrollSpeedMobile.scrollSpeed = this.calculateWheelSpeed(
        Math.abs(this.gallery.animation.scroll.scrollSpeedMobile.delta)
      );

      this.gallery.animation.scroll.scrollSpeed = this.gallery.animation.scroll.scrollSpeedMobile.scrollSpeed;
    },

    clearScrollValues() {
      this.gallery.animation.scroll.scrollSpeedMobile.lastPos = this.gallery.animation.scroll.scrollSpeedMobile.newPos;
      this.gallery.animation.scroll.scrollSpeedMobile.delta = 0;
      this.gallery.animation.scroll.scrollSpeedMobile.scrollSpeed = 0;
    },

    /*------------------------------
    End vertical scroll
    ------------------------------*/

    //======= END SCROLL EVENTS =======//

    //======= START UPDATE IMG POSITIONS =======//

    updatePostionOnScroll() {
      this.setPosition();
      this.isUserStoppedScrolling();
    },
    setPosition() {
      this.gallery.images.imagestore.forEach((o) => {
        o.mesh.position.y =
          this.gallery.animation.scroll.currentScrollY -
          this.gallery.animation.scroll.startPosition.y -
          o.top +
          this.cameras.windowUser.height / 2 -
          (o.height * o.scale) / 2;
        o.mesh.position.x =
          o.left -
          this.cameras.windowUser.width / 2 +
          (o.width * o.scale) / 2 -
          (this.gallery.animation.scroll.currentScrollX -
            this.gallery.animation.scroll.startPosition.x);
      });
    },

    //======= END UPDATE IMG POSITIONS =======//

    //======= START USER STOP SCROLLING =======//

    isUserStoppedScrolling() {
      // if user scrolling
      this.setUserIsScrolling(true);
      this.setIsUserScrollReseting(false);
      // 1) reset timout
      this.cancelScrollTimeOut();
      // 2) add new timout
      this.setScrollTimeOut();
    },
    setUserIsScrolling(bool) {
      this.gallery.animation.scroll.isUserScrolling = bool;
    },
    setIsUserScrollReseting(bool) {
      this.gallery.animation.scroll.isUserScrollReseting = bool;
    },
    cancelScrollTimeOut() {
      this.gallery.animation.scroll.scrollTimeOut
        ? clearTimeout(this.gallery.animation.scroll.scrollTimeOut)
        : null;
    },
    setScrollTimeOut() {
      this.gallery.animation.scroll.scrollTimeOut = setTimeout(() => {
        this.cancelScrollTimeOut();

        this.setUserIsScrolling(false);

        this.setIsUserScrollReseting(true);
      }, 400);
    },

    //======= END USER STOP SCROLLING =======//

    //======= START RESIZE GALLERY =======//

    resizeGallery() {
      // if user resized from desktop to mobile so reset all scroll
      this.cameras.isDesktop !== !this.isMobile
        ? this.userToggleFromDesktopToMobile()
        : null;

      // adjust scroll start position on threejs
      this.overwriteStartPosition();
      // get new image size
      this.loopAllImagesForNewSize();

      // reset currentScrollY and X to 0 because threejs canvas don't move
      // update position each images
      this.setPosition();
      // update new position end
      this.setValuesPositionLastDiv();

      // scroll to the end if user scroll to the end
      this.gallery.positionEndOfGallery.isScrolledToTheEnd
        ? this.scrollUserToTheEnd()
        : null;
    },

    /*------------------------------
    Start reset all scrolls
    ------------------------------*/
    userToggleFromDesktopToMobile() {
      // update the isDesktop state
      this.setIsDesktop();
      // scroll to the top of the page
      // restart scroll intro animation
      this.gallery.animation.scroll.startPosition.x = 0;
      this.gallery.animation.scroll.currentScrollX = 0;

      this.startScrollIntroAnimation();
    },

    /*------------------------------
    End reset all scrolls
    ------------------------------*/
    /*------------------------------
    Start Resize on scroll
    ------------------------------*/
    overwriteStartPosition() {
      !this.isMobile && this.gallery.scrollContainer
        ? this.updateStartPositionDesktop(
            this.gallery.scrollContainer.scrollLeft
          )
        : this.updateStartPositionMobile(window.scrollY);
    },
    updateStartPositionDesktop(startPositionDesktop) {
      // udpate start position
      this.gallery.animation.scroll.startPosition.x = startPositionDesktop;
      // reset mobile position to avoid the gallery to be invisible on desktop
      this.gallery.animation.scroll.startPosition.y = 0;
    },
    updateStartPositionMobile(startPositionMobile) {
      // udpate start position
      this.gallery.animation.scroll.startPosition.y = startPositionMobile;
      // reset desktop position to avoid the gallery to be invisible on mobile
      this.gallery.animation.scroll.startPosition.x = 0;
    },

    resetAllScroll(xOrYPosition) {
      // reset all scroll states and scroll to the top/left

      this.resetScrollToZero(this.setPositionXY(xOrYPosition));
      this.resetCurrentScrollToZero();
      this.overwriteStartPosition();
    },

    /*------------------------------
     Start set the position
     ------------------------------*/
    setPositionXY(xOrYPosition) {
      return !xOrYPosition ? [0, 0] : this.setCustomPositionXY(xOrYPosition);
    },
    setCustomPositionXY(xOrYPosition) {
      // if user is on desktop, so return y = 0 and x = xOrYPosition
      // if user is on mobile, return y = xOrYPosition and x = 0
      return !this.isMobile ? [xOrYPosition, 0] : [0, xOrYPosition];
    },
    /*------------------------------
     End set the position
     ------------------------------*/

    /*------------------------------
    Start reset scroll on resize all Scroll
    ------------------------------*/
    resetScrollToZero(positionXY) {
      window.scrollTo(positionXY[0], positionXY[1]);
      this.gallery.scrollContainer
        ? (this.gallery.scrollContainer.scrollLeft = positionXY[0])
        : null;
    },
    resetCurrentScrollToZero() {
      this.gallery.animation.scroll.currentScrollX = 0;
      this.gallery.animation.scroll.currentScrollY = 0;
    },
    /*------------------------------
    End reset scroll  on resize all Scroll
    ------------------------------*/

    // User scrolled to the end
    scrollUserToTheEnd() {
      // kill the intro scroll timelines

      // scroll to the end
      this.overWritescrollToTheEnd(0);
    },
    overWritescrollToTheEnd(overwriteFinalPosition) {
      !this.isMobile
        ? this.overwriteHorizontalScrollToEnd()
        : this.overwriteVerticalScrollToEnd(overwriteFinalPosition);
      this.setPosition();
    },
    overwriteHorizontalScrollToEnd() {
      this.gallery.scrollContainer.scrollLeft = this.gallery.positionEndOfGallery.x;
      this.gallery.animation.scroll.currentScrollX = this.gallery.positionEndOfGallery.x;
    },
    overwriteVerticalScrollToEnd(overwriteFinalPosition) {
      window.scrollTo(
        0,
        this.gallery.positionEndOfGallery.y - overwriteFinalPosition
      );
      this.gallery.animation.scroll.currentScrollY =
        this.gallery.positionEndOfGallery.y - overwriteFinalPosition;
    },

    /*------------------------------
    End Resize on scroll
    ------------------------------*/

    /*------------------------------
    Start Reset image position
    ------------------------------*/
    // loop all image to update the size
    loopAllImagesForNewSize() {
      this.gallery.images.imagestore.forEach((img, index) => {
        let bounds = this.getImgBoundInfo(index);

        // rescale
        this.gallery.images.imagestore[index].mesh.scale.set(
          this.rescaleCalculator(bounds.width, img.width),
          this.rescaleCalculator(bounds.height, img.height),
          1
        );

        this.updateImgInfo(bounds, img.width, index);
      });
    },

    getImgBoundInfo(index) {
      return this.gallery.images.images[index].getBoundingClientRect();
    },
    rescaleCalculator(newValue, oldvalue) {
      return newValue / oldvalue;
    },

    updateImgInfo(bounds, imgWidh, index) {
      this.gallery.images.imagestore[index].top = bounds.top;
      this.gallery.images.imagestore[index].left = bounds.left;
      this.gallery.images.imagestore[index].scale = this.rescaleCalculator(
        bounds.width,
        imgWidh
      );
    },

    removeAllEventListenerScroll() {
      window.removeEventListener("wheel", this.wheelEvents);
      window.removeEventListener("scroll", this.scrollEvents);
    },

    /*------------------------------
    End Reset image position
    ------------------------------*/

    //======= END RESIZE GALLERY =======//

    //======= START DISABLE SCROLL =======//

    addEventDisableScroll() {
      this.gsapAnimation.gallery.isRunning
        ? this.disableScrollAtCurrentPosition()
        : this.disableScrollEitherTopOrEnd();
    },

    disableScrollAtCurrentPosition() {
      this.setCurrentScroll();
      window.addEventListener(
        "scroll",
        this.resetAllScroll(
          this.responsiveCurrentScrollPosition(
            this.gallery.animation.currentScrollY,
            this.gallery.animation.currentScrollX
          )
        )
      );
    },
    responsiveCurrentScrollPosition(currentScrollY, currentScrollX) {
      return this.isMobile ? currentScrollY : currentScrollX;
    },

    disableScrollEitherTopOrEnd() {
      this.gallery.isGalleryScrollable
        ? this.disableScrollToTheEnd()
        : this.disableScrollToTheTop();
    },

    setCurrentScroll() {
      // this is used later for when we enable back current scroll so the scroll is at the correct positon
      this.gallery.animation.scroll.currentScrollDisabledX = this.gallery.animation.scroll.currentScrollX;
      this.gallery.animation.scroll.currentScrollDisabledY = this.gallery.animation.scroll.currentScrollY;
    },

    /*------------------------------
    Start disable scroll when when the intro animation is running
    ------------------------------*/
    disableScrollToTheTop() {
      // reset scroll
      // please note: before I added the start position to fix a bug, but it seems that
      window.addEventListener("scroll", this.resetAllScroll(0));
    },

    /*------------------------------
    End disable scroll when when the intro animation is running
    ------------------------------*/
    disableScrollToTheEnd() {
      window.addEventListener("scroll", this.overWritescrollToTheEnd(0));
    },
    /*------------------------------
    Start disable scroll when user is at the end
    ------------------------------*/

    /*------------------------------
    End disable scroll when user is at the end
    ------------------------------*/
    removeEventDisableScroll() {
      this.gsapAnimation.gallery.isRunning
        ? this.removeDisableScrollOnCurrentPosition()
        : this.removeDisableSCrollEndOrTop();
    },
    removeDisableScrollOnCurrentPosition() {
      window.removeEventListener(
        "scroll",
        this.resetAllScroll(
          this.responsiveCurrentScrollPosition(
            this.gallery.animation.scroll.currentScrollDisabledY,
            this.gallery.animation.scroll.currentScrollDisabledX
          )
        )
      );

      this.gallery.animation.scroll.startPosition.x = 0;
      this.gallery.animation.scroll.startPosition.y = 0;
    },
    removeDisableSCrollEndOrTop() {
      this.gallery.isGalleryScrollable
        ? this.removeEventListenerScrollToTheEnd()
        : this.removeEventListnerDisableScrollToTheTop();
    },
    removeEventListnerDisableScrollToTheTop() {
      window.removeEventListener(
        "scroll",
        this.resetAllScroll(this.gsapAnimation.intro.startPosition)
      );

      this.gallery.animation.scroll.startPosition.x = 0;
      this.gallery.animation.scroll.startPosition.y = 0;
      // make sure
      this.toggleScrollableGallery(true);
    },
    removeEventListenerScrollToTheEnd() {
      this.gallery.positionEndOfGallery.isScrolledToTheEnd
        ? window.removeEventListener("scroll", this.overWritescrollToTheEnd(0))
        : window.removeEventListener("scroll", this.overWritescrollToTheEnd(2));
    },
    toggleScrollableGallery(bool) {
      // used to disable scroll on the intro animation vs the animation at the end
      this.gallery.isGalleryScrollable = bool;
    },

    //======= END DISABLE SCROLL =======//

    ////////////////////////////////
    //       END SCROLL
    ////////////////////////////////

    ////////////////////////////////
    //       START USER SCROLLED TO THE END OF THE GALLERY
    ////////////////////////////////

    //======= START CALCULATE LOCATION OF THE LAST DIV =======//

    setValuesPositionLastDiv() {
      const positionLastDiv = this.returnPositionLastDiv();

      const responsiveLastDivLength = window.innerWidth >= 1680 ? 2 : 1.5;

      // 700 and 400 refers to the css style. It's just enough to hide the picture on many screens
      // positionLastDiv start from the left of the screen. To ensure the total is correct so add start position which is used somewhere else too.
      this.gallery.positionEndOfGallery.x =
        // positionLastDiv.x + this.gallery.animation.scroll.startPosition.x + 700;
        positionLastDiv.x +
        this.gallery.animation.scroll.startPosition.x +
        window.innerWidth / responsiveLastDivLength;

      this.gallery.positionEndOfGallery.y =
        document.body.scrollHeight - (window.innerHeight + 200);
    },

    returnPositionLastDiv() {
      const lastSection = this.$refs.lastImg[0];

      return lastSection.getBoundingClientRect();
    },

    //======= END CALCULATE LOCATION OF THE LAST DIV =======//

    isUserScrolledToTheEndOfTheGallery(scrollDirection, axis) {
      const isUserAtTheEnd = this.compareScrollDistanceVersusEndOfGallery(
        scrollDirection,
        axis
      );

      // this.isUserAtTheEnd && this.this.gallery.positionEndOfGallery.isScrolledToTheEnd
      // console.log("isUserAtTheEnd", isUserAtTheEnd);
      this.gallery.positionEndOfGallery.isScrolledToTheEnd != isUserAtTheEnd
        ? this.triggerAnimationEndOfGallery(isUserAtTheEnd)
        : null;
    },

    triggerAnimationEndOfGallery(bool) {
      // update state value localy
      this.gallery.positionEndOfGallery.isScrolledToTheEnd = bool;
    },

    ////////////////////////////////
    //       END USER SCROLLED TO THE END OF THE GALLERY
    ////////////////////////////////

    compareScrollDistanceVersusEndOfGallery(scrollDirection, axis) {
      // compared scrolled distance vs the scroll necessary to go to the end
      return (
        this.gallery.animation.scroll[scrollDirection] >=
        this.gallery.positionEndOfGallery[axis] - 1
      );
    },

    ////////////////////////////////
    //       START INTRO ANIMATION
    ////////////////////////////////

    startScrollIntroAnimation() {
      // reset scroll position
      this.resetAllScroll(0);

      // mobile and desktion animation because the scroll direction is different
      !this.isMobile
        ? this.startDesktopScrollIntroAnimation()
        : this.startMobileScrollIntroAnimation();
    },

    // isDesktop() {
    //   // return window.innerWidth >= this.cameras.innerWidthDesktop;
    //   return !this.isMobile;
    // },

    startDesktopScrollIntroAnimation() {
      // make sure it's scroll to the top

      // set the container so user can horizontaly scroll
      this.setWheelContainer();
      const totalScrollToDurationDesktop = this.isGamePlayDebugging ? 0.5 : 3;

      this.gsapAnimation.intro.timeLine = gsap.timeline({
        onStart: () => {
          // disable scroll when the animation is running
          this.toggleScrollableGallery(false);
          this.toggleScrollGlobally(true);
        },
        onUpdate: () => {
          // move the img in threejs
          this.updatePostionOnScroll();
        },
        onComplete: () => {
          // run the floating animation
          this.floatingAnimation();
          // enable scroll back
          this.toggleScrollGlobally(false);

          this.gallery.isIntroAnimationEnded = true;
        },
      });

      this.gsapAnimation.intro.timeLine
        .to(this.gallery.animation.scroll, {
          currentScrollX: this.gsapAnimation.intro.startPosition,
          duration: totalScrollToDurationDesktop,
          ease: "power3.inOut",
        })
        .to(
          this.gallery.scrollContainer,
          {
            scrollLeft: this.gsapAnimation.intro.startPosition,
            duration: totalScrollToDurationDesktop,
            ease: "power3.inOut",
          },
          "<"
        )
        .add(() => {
          this.toggleScrollArrowVisible(true);
        }, `-=${totalScrollToDurationDesktop * 0.5}`);
    },
    startMobileScrollIntroAnimation() {
      const totalScrollToDurationMobile = this.isGamePlayDebugging ? 0.5 : 1;
      this.gsapAnimation.intro.timeLine = gsap.timeline({
        onStart: () => {
          // disable scroll when the animation is running
          this.toggleScrollableGallery(false);
          this.toggleScrollGlobally(true);
        },
        onUpdate: () => {
          // move the img in threejs
          this.updatePostionOnScroll();
        },
        onComplete: () => {
          // don't need to have an animation for the scroll to, only for threejs gallery
          window.scrollTo(0, this.gsapAnimation.intro.startPosition);
          // run the floating animation
          this.floatingAnimation();
          // enable scroll back
          this.toggleScrollGlobally(false);

          this.gallery.isIntroAnimationEnded = true;
        },
      });

      this.gsapAnimation.intro.timeLine
        .to(this.gallery.animation.scroll, {
          currentScrollY: this.gsapAnimation.intro.startPosition,
          duration: totalScrollToDurationMobile,
          ease: "power3.inOut",
        })
        .add(() => {
          this.toggleScrollArrowVisible(true);
        }, `-=${totalScrollToDurationMobile * 0.5}`);
    },
    toggleScrollArrowVisible(bool) {
      this.scrollArrow.isVisible = bool;
    },

    resizeScrollIntroAnimation() {
      this.gsapAnimation.intro.timeLine &&
      !this.gallery.positionEndOfGallery.isScrolledToTheEnd
        ? this.restartScrollIntroAnimation()
        : null;
    },
    restartScrollIntroAnimation() {
      this.destroyTimeline("intro");
      this.startScrollIntroAnimation();
    },

    toggleScrollGlobally(bool) {
      //  disable during the animation
      this.$store.commit("shared/TOGGLE_SCROLL_GLOBALLY", bool);
    },

    ////////////////////////////////
    //       END INTRO ANIMATION
    ////////////////////////////////

    ////////////////////////////////
    //       START FLOATING ANIMATION
    ////////////////////////////////

    floatingAnimation() {
      const durationAnimation = 5;
      const movementY = 20;
      const movementRotationY = 0.1;
      const movementRotationX = 0.05;
      this.gsapAnimation.floating.timeLine = gsap.timeline({
        repeatDelay: 0,
        repeat: -1,
        yoyo: true,
      });

      this.gsapAnimation.floating.timeLine.to(this.scene.position, {
        y: movementY,
        duration: durationAnimation,
        ease: "power1.inOut",
      });
      this.gsapAnimation.floating.timeLine.to(this.scene.position, {
        y: -movementY,
        duration: durationAnimation * 2,
        ease: "power1.inOut",
      });

      this.gallery.images.imagestore.forEach((o, index) => {
        this.gsapAnimation.floating.timeLineImgs[index] = gsap.timeline({
          repeatDelay: 0,
          repeat: -1,
          yoyo: true,
        });

        this.gsapAnimation.floating.timeLineImgs[index].to(o.mesh.rotation, {
          y: movementRotationY,
          z: -movementRotationX,
          duration: durationAnimation,
          ease: "power1.inOut",
        });
        this.gsapAnimation.floating.timeLineImgs[index].to(o.mesh.rotation, {
          y: -movementRotationY,
          z: movementRotationX,
          duration: durationAnimation,
          ease: "power1.inOut",
        });
      });
    },

    ////////////////////////////////
    //       END FLOATING ANIMATION
    ////////////////////////////////

    ////////////////////////////////
    //       START MANAGE GALLERY
    ////////////////////////////////

    //======= START INIT ANIMATION =======//

    initGalleryAnimation() {
      this.gsapAnimation.gallery.timeline = gsap.timeline({
        paused: true,
        onStart: () => {},
        onComplete: () => {
          this.$store.commit("gallery/TOGGLE_GALLERY", true);
        },
        onReverse: () => {},
        onReverseComplete: () => {
          // enable scroll back
          this.$store.commit("gallery/UPDATE_SELECTED_IMG", null);
        },
      });
      this.gsapAnimation.gallery.timeline.to(this.$refs.scene__container, {
        y: this.galleryAnimationDirection("0vh", this.whichGalleryDirection()),
        x: this.galleryAnimationDirection("100vw", "0vw"),
        ease: "power1.inOut",
        duration: this.isGamePlayDebugging ? 0.5 : 2,
      });
    },
    whichGalleryDirection() {
      return this.parent === "space" ? "100vh" : "-100vh";
    },
    galleryAnimationDirection(mobileValue, desktopValue) {
      return this.isMobile ? mobileValue : desktopValue;
    },

    //======= END INIT ANIMATION =======//

    //======= START PLAY OR REVERSE ANIMATION =======//

    openGallery(id) {
      // run animation background (gallery goes down elasctic and camera goes up?)
      this.toggleAnimation(true);
      this.toggleGalleryAnimation(true);
      // disable scroll
      this.toggleScrollGlobally(true);
      // set id picture in modal
      this.$store.commit("gallery/UPDATE_SELECTED_IMG", id);

      // emit to GA
      this.emitSelectedImgToGA(id);
    },

    toggleAnimation(bool) {
      bool
        ? this.gsapAnimation.gallery.timeline.play()
        : this.gsapAnimation.gallery.timeline.reverse();
    },
    toggleGalleryAnimation(bool) {
      this.gsapAnimation.gallery.isRunning = bool;
    },

    //--- start emit to GA ---//
    emitSelectedImgToGA(id) {
      const selectedImg = this.findImagePerId(id);
      selectedImg
        ? this.emitGtag(
            `Open_${this.parent}_${selectedImg.img}`,
            "Gallery",
            "Click"
          )
        : null;
    },
    findImagePerId(id) {
      return this.images.find((img) => img.id === id);
    },
    //--- end emit to GA ---//

    /*------------------------------
    Start Reverse animation
    ------------------------------*/
    reverseGalleryAnimation() {
      this.toggleScrollGlobally(false);
      this.$nextTick(() => {
        this.toggleAnimation(false);
        this.toggleGalleryAnimation(false);
      });
    },
    /*------------------------------
    End Reverse animation
    ------------------------------*/

    //======= END PLAY OR REVERSE ANIMATION =======//

    //======= START ON RESIZE GALLERY ANIMATION =======//

    resetGalleryAnimation() {},

    //======= END ON RESIZE GALLERY ANIMATION =======//

    ////////////////////////////////
    //       END MANAGE GALLERY
    ////////////////////////////////

    ////////////////////////////////
    //       START DESTROY
    ////////////////////////////////
    destroyEventListener() {
      window.removeEventListener("resize", this.onResize);
      // window.removeEventListener("mousemove", this.onMouseMove, false); // this is relaated to hover effect which was removed
      this.gallery.images.imagestore = this.gallery.images.images.map((img) => {
        img.removeEventListener("mouseenter", this.eventListenerHover);
        img.removeEventListener("mouseout", this.eventListenerUnHover);
      });
    },
    destroyAllTimelines() {
      this.destroyTimeline("intro");
      this.destroyTimeline("floating");
      this.destroyTimeline("gallery");
    },
    destroyTimeline(name) {
      this.gsapAnimation[name].timeline
        ? (this.gsapAnimation[name].timeline.kill(),
          (this.gsapAnimation[name].timeline = null))
        : null;
    },

    destroyTimeout(interval) {
      interval ? (clearTimeout(interval), (interval = null)) : null;
    },

    ////////////////////////////////
    //       END DESTROY
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.scene {
  --vh: 100vh;

  &__fixed {
    position: fixed;
    top: 30px;
    left: 50%;
    z-index: 9999;
    color: white;
  }

  &__gallery {
    // scrollbar-width: none;

    display: flex;
    z-index: 11;

    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    min-height: 100vh;

    &--mobile {
      overflow-y: scroll;
      overflow-x: hidden;
      flex-direction: column;
      min-height: calc(var(--vh));
    }

    &--scrolling {
      img {
        pointer-events: none;
      }
    }
    &__list {
      display: flex;
      width: 100vw;

      min-width: 100vw;
      min-height: 100vh;

      align-items: center;
      justify-content: flex-start;
      &:first-child {
        min-width: calc(100vw + 300px);
        min-height: 100vh;
      }
      &:last-child {
        min-width: 75vw;
        max-width: 75vw;
        min-height: 100%;
        max-height: 100vh;
        @include teq-breakpoint($large) {
          min-width: 50vw;
          max-width: 50vw;
        }
      }

      .scene__gallery--mobile & {
        align-items: flex-start;
        justify-content: center;
        &:first-child {
          min-height: calc(100vh + 200px);
        }
        &:last-child {
          min-height: calc(50vh + 30rem);
        }
      }

      &__item {
        @include removeButtonStyle;
        opacity: 0;
        cursor: pointer;
        padding: 1rem;

        // debug
        .scene--debug & {
          background: blue;
          opacity: 0.3;
          &:focus {
            background: green;
          }
        }
        &__img {
          /* https://stackoverflow.com/questions/55909163/is-there-a-way-to-scale-up-a-three-js-renderer-without-becoming-blurry */
          // image-rendering: pixelated;

          --max-width-img-gallery: 70vh;
          --max-height-img-gallery: 60rem;
          opacity: 0;
          aspect-ratio: 1/1;
          height: 100%;
          max-width: var(-max-width-img-gallery);
          max-height: var(--max-height-img-gallery);
          .scene--debug & {
            opacity: 1;
          }

          .scene__gallery--mobile & {
            --max-height-img-gallery: 80vw;
            --max-width-img-gallery: 80vw;

            @include teq-breakpoint($small) {
              width: 150%;
              left: -25%;
            }
          }
        }
      }
    }
  }

  &__container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    /* z-index: 2; */
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    /* https://stackoverflow.com/questions/55909163/is-there-a-way-to-scale-up-a-three-js-renderer-without-becoming-blurry */
    image-rendering: pixelated;

    .scene__gallery--mobile & {
      min-height: calc(var(--vh));
    }
  }
}
</style>
