<template>
  <div
    class="dashboard-modal-main"
    :class="[
      { 'dashboard-modal-main--visible': isAnimationStarted },
      `dashboard-modal-main--${whichModal}`,
    ]"
  >
    <!-- svg -->
    <shared-button-corner
      :is-animation-started="isAnimationStarted"
      :is-modal="true"
    />
    <!-- lines -->
    <ul class="dashboard-modal-main__content">
      <li class="dashboard-modal-main__content__button">
        <shared-button-label
          :label="$t('dashboard.modal.buttons.label.restart')"
          icon="reset"
          :is-animation-running="isAnimationStarted"
          @clicked="resetGame"
        />
      </li>
      <li
        v-if="whichModal === 'pause'"
        class="dashboard-modal-main__content__button"
      >
        <shared-button-label
          :label="$t('dashboard.modal.buttons.label.close')"
          icon="close"
          :is-animation-running="isAnimationStarted"
          @clicked="playGame"
        />
      </li>
    </ul>
    <!-- 1 or 2 buttons -->
  </div>
</template>

<script>
import pauseGameMixin from "@/mixins/pauseGameMixin";
import transitionMixin from "@/mixins/transitionMixin.js";
// import isDevMixin from "@/mixins/isDevMixin.js";

import SharedButtonLabel from "@/components/Shared/SharedButton/SharedButtonLabel/SharedButtonLabel";
import SharedButtonCorner from "@/components/Shared/SharedButton/SharedButtonCorner/SharedButtonCorner";
export default {
  mixins: [pauseGameMixin, transitionMixin],
  components: {
    SharedButtonLabel,
    SharedButtonCorner,
  },
  props: {
    whichModal: {
      type: String,
      required: false,
      default: "pause",
      // validator: (val) => ["restart", "pause", "crash", "boost"].includes(val),
    },
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
    sceneName: {
      type: String,
      required: true,
      validator: (val) => ["liftOff", "landing", "exploration"].includes(val),
    },
  },

  data() {
    return {
      mockProgress: {
        progress: 0, // used for reset
        interval: null,
        timeOut: null,
      },
    };
  },
  beforeDestroy() {
    this.resetInterval();
    this.resetTimeOut();
  },

  methods: {
    resetGame() {
      this.updateValueFakeReset(0);
      this.$store.commit("sharedTransition/SET_NEW_PROGRESS", this.sceneName);
      this.mockProgress.timeOut = setTimeout(() => {
        this.mockProgressStart();
        this.$store.commit("sharedGamePlay/TOGGLE_GAME_RESET", true);
      }, 100);
    },
    resetTimeOut() {
      this.mockProgress.timeOut
        ? (clearInterval(this.mockProgress.timeOut),
          (this.mockProgress.timeOut = null))
        : null;
    },

    mockProgressStart() {
      //reset mock progress
      this.mockProgress.interval = setInterval(() => {
        // run a interval
        this.updateMockProgressToStore(this.mockProgress.progress);
        this.updateValueFakeReset();
        // at the end of interval reset interval + moodal
        this.mockProgress.progress >= 1 ? this.endMockProgress() : null;
      }, 150);
    },

    updateValueFakeReset() {
      this.mockProgress.progress = this.mockProgress.progress + 0.07;
    },
    updateMockProgressToStore(value) {
      this.$store.commit("sharedTransition/UPDATE_PROGRESS", value);
    },

    endMockProgress() {
      this.updateMockProgressToStore(1);

      this.resetTimeOut();
      this.resetInterval();
      this.$store.commit("dashboardModal/RESET_MODAL");
      this.$store.commit("sharedGamePlay/TOGGLE_GAME_RESET", false);
      this.toggleTransitionIsLongEnough(true);
    },
    resetInterval() {
      this.mockProgress.interval
        ? (clearInterval(this.mockProgress.interval),
          (this.mockProgress.interval = null))
        : null;
    },

    playGame() {
      this.toggleGamePause(false);
    },

    // toggleTransitionIsLongEnough(bool) {
    //   this.$store.commit(
    //     "sharedTransition/TOGGLE_TRANSITION_ENOUGH_LONG",
    //     bool
    //   );
    // },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-modal-main {
  --opacity-lines: 0;
  --columns-main: 1fr;
  @include lines-warning(var(--opacity-lines));
  display: flex;
  flex: 1;
  padding: $medium-margin $x-small-margin;
  &--visible {
    --opacity-lines: 0.1;
  }
  &--pause {
    --columns-main: 1fr 1fr;
  }
  &__content {
    display: grid;
    grid-template-columns: var(--columns-main);
    width: 100%;
    justify-content: space-around;
    &__button {
      display: flex;
      justify-content: center;
      align-items: stretch;
    }
  }
}
</style>
