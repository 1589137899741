export default {
  methods: {
    resetGamePlayStore() {
      // reset modal end game
      this.$store.commit("dashboardModal/RESET_MODAL");

      // reset paused game
      this.$store.commit("sharedGamePlay/TOGGLE_GAME_PAUSED", false);
      // reset scene exit panel
      this.$store.commit("sharedGamePlay/TOGGLE_GAME_RESET", false);

      // reset mobile dashboard buttons
      this.$store.commit("dashboardBottom/RESET_ALL");

      this.$store.commit("dashboardTutorial/RESET_TUTORIAL");

      // stop FX
      this.$store.commit("sharedAudio/toggleAllSoundsEffectsPlayback", false);

      // reset scene exit panel
      this.$store.commit("sharedTransition/TOGGLE_EXIT_SCENE", false);
      this.$store.commit(
        "sharedTransition/TOGGLE_TRANSITION_ENOUGH_LONG",
        false
      );

      // reset intro animation
      this.$store.commit("sharedGamePlay/TOGGLE_INTRO_ANIMATION", true);

      // reset user is going to next page (used for gallery to landing and first step to buggy)
      this.$store.commit("gallery/TOGGLE_USER_GOING_NEXT_PAGE", false);

      // Reset FX
      this.$store.commit("sharedAudio/toggleStopSoundEffect", {
        whichAudio: "fx",
        bool: true,
      });

      ////////////////////////////////
      //       START DISABLE INTRO WHEN USER DOESNT LAND ON HOME OR WHEN USER RETURN TO HOME
      ////////////////////////////////
      // managed from router
      // hide intro page if user go to home page
      // this.$store.dispatch("intro/HIDE_INTRO");
      // hide intro page if user go to home page
      // this.$store.commit("sharedTransition/DISABLE_INTRO_TRANSITION");
      ////////////////////////////////
      //       END DISABLE INTRO WHEN USER DOESNT LAND ON HOME OR WHEN USER RETURN TO HOME
      ////////////////////////////////
    },
  },
};
