<template>
  <div class="shared-gallery-scroll">
    <div class="shared-gallery-scroll__inner">
      <shared-gallery-scroll-arrow />
    </div>
  </div>
</template>

<script>
import SharedGalleryScrollArrow from "@/components/Shared/SharedGallery/SharedGalleryScrollArrow";
export default {
  components: {
    SharedGalleryScrollArrow,
  },
};
</script>

<style lang="scss" scoped>
.shared-gallery-scroll {
  @include fullScreenDom;
  @include centeredPageLayout;
  &__inner {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
</style>
