<template>
  <shared-modal :is-wide="true">
    <ul class="gallery">
      <li>
        <gallery-frame
          :is-visible="isVisible"
          :parent="parent"
          :selected-img-id="selectedImgId"
        />
      </li>
      <gallery-copy
        :is-visible="isVisible"
        :parent="parent"
        :selected-img-id="selectedImgId"
      />
    </ul>
  </shared-modal>
</template>

<script>
import { mapState } from "vuex";

import GalleryCopy from "@/components/Gallery/GalleryCopy";
import GalleryFrame from "@/components/Gallery/GalleryFrame";
import SharedModal from "@/components/Shared/SharedModal/SharedModal";
export default {
  components: {
    GalleryCopy,
    GalleryFrame,
    SharedModal,
  },

  computed: {
    ...mapState({
      selectedImgId: (state) => state.gallery.selectedImgId,
    }),
  },
  props: {
    parent: {
      type: String,
      required: false,
      default: "space",
    },
  },

  data() {
    return {
      timeout: null,
      isVisible: false,
    };
  },
  mounted() {
    this.startAnimation();
  },
  beforeDestroy() {
    this.timeoutDestroyer();
  },

  methods: {
    startAnimation() {
      this.timeout = setTimeout(() => {
        this.toggleVisiblilty(true);
        this.timeoutDestroyer();
      }, 500);
    },
    timeoutDestroyer() {
      this.timeout ? (clearTimeout(this.timeout), (this.timeout = null)) : null;
    },
    toggleVisiblilty(bool) {
      this.isVisible = bool;
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  // --max-height-gallery: calc(100vh - 500px);
  // full height - (padding top bottom + size buttons + extra height for the button +some extra padding  )
  --max-height-gallery: calc(100vh - 8rem - 6.6rem - 6rem - 2.5rem);
  --gallery-max-width: 80rem;
  display: flex;
  flex-direction: column;
  max-height: var(--max-height-gallery);
  max-width: var(--gallery-max-width);

  li {
    display: flex;
  }
}
</style>
