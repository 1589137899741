<template>
  <section>
    <landing-scene />
  </section>
</template>

<script>
import LandingScene from "@/components/Landing/LandingScene";
export default {
  components: {
    LandingScene,
  },
  mounted() {
    this.updateProgressInformation();
  },
  methods: {
    updateProgressInformation() {
      this.$store.commit("sharedTransition/SET_NEW_PROGRESS", "landing");
    },
  },
};
</script>
