<template>
  <div
    class="navigation-toggle-main-side"
    :class="{
      'navigation-toggle-main-side--left': isLeft,
      'navigation-toggle-main-side--right': !isLeft,
    }"
  >
    <div class="navigation-toggle-main-side__inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-toggle-main-side {
  display: flex;
  width: 100%;
  position: relative;

  @include teq-breakpoint($medium) {
    /* width: calc(4rem * 2 + 75px); */
    width: 230px;
  }

  --width: 0%;
  .navigation-toggle--open & {
    --width: 100%;
  }
  &:before {
    content: " ";
    position: absolute;
    width: var(--width);
    height: 1px;
    background-color: $gallery-transparent;
    top: 0px;
    @include teq-breakpoint($medium) {
      left: 0px;
      height: var(--width);
      width: 1px;
    }
  }
  &--left {
    &:before {
      top: auto;
      bottom: 0px;
      @include teq-breakpoint($medium) {
        bottom: auto;
        top: 0px;
        left: auto;
        right: 0px;
        height: var(--width);
        width: 1px;
      }
    }
  }

  &__inner {
    @include responsiveLayoutPadding;
    @include transform(translate(0px, 10px));
    display: flex;
    flex: 1;
    opacity: 0;
    @include teq-breakpoint($medium) {
      @include transform(translate(10px, 0px));
      flex-direction: column;
      justify-content: center;
    }
    .navigation-toggle-main-side--left & {
      /* margin top + button + margin bottom */
      margin-top: calc(1.5rem + 60px + 2.5rem);

      @include teq-breakpoint($medium) {
        @include transform(translate(-10px, 0px));
        margin-top: 0px;
      }
    }

    /* debug */
    .navigation-toggle--open & {
      @include transform(translate(0px, 0px));
      opacity: 1;
    }
  }
}
</style>
