<template>
  <div
    class="circular-button-icon"
    :class="{
      'circular-button-icon--black': isBlack,
      'circular-button-icon--hover': isHover,
    }"
  >
    <div class="circular-button-icon__list">
      <!-- copy -->
      <shared-button-circle-copy
        v-if="copy"
        :copy="copy"
        :is-animation-started="isAnimationStarted"
      />

      <!-- list of icons -->

      <shared-button-circle-menu
        v-else-if="isComponentVisible('menu')"
        :is-black="isBlack"
        :is-active="isActive"
        :is-animation-started="isAnimationStarted"
      />
      <shared-icons-reset
        v-else-if="isComponentVisible('reset')"
        :is-active="isActive"
        :is-animation-started="isAnimationStarted"
      />
      <shared-icons-close
        v-else-if="isComponentVisible('close')"
        :is-active="isActive"
        :is-animation-started="isAnimationStarted"
      />
      <shared-icons-audio
        v-else-if="isComponentVisible('audio')"
        :is-active="isActive"
        :is-animation-started="isAnimationStarted"
      />
      <div
        v-else-if="!copy"
        class="circular-button-icon__list__static"
        :class="`circular-button-icon__list__static--rotate-${rotation}`"
      >
        <shared-icons
          :name="iconName"
          class="circular-button-icon__list__icon"
          :class="`circular-button-icon__list__icon--rotate-${rotation}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SharedButtonCircleCopy from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircleCopy";
import SharedButtonCircleMenu from "@/components/Shared/SharedButton/SharedButtonCircle/SharedButtonCircleMenu/SharedButtonCircleMenu";
import SharedIcons from "@/components/Shared/SharedIcons";
import SharedIconsAudio from "@/components/Shared/SharedIcons/SharedIconsAudio";
import SharedIconsClose from "@/components/Shared/SharedIcons/SharedIconsClose";
import SharedIconsReset from "@/components/Shared/SharedIcons/SharedIconsReset";
export default {
  components: {
    SharedButtonCircleCopy,
    SharedButtonCircleMenu,
    SharedIcons,
    SharedIconsAudio,
    SharedIconsClose,
    SharedIconsReset,
  },
  props: {
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconName: {
      type: String,
      required: true,
    },
    rotation: {
      type: Number,
      required: false,
      default: 0,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    copy: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isAnimatedDone: false,
      buttonTimeOut: null,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    isComponentVisible(icon) {
      return !this.copy && this.iconName === icon;
    },
  },
};
</script>

<style lang="scss" scoped>
.circular-button-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    &::v-deep svg {
      fill: var(--color-gallery);
      .circular-button-icon--black & {
        fill: var(--color-ebony);
      }
    }

    &__static {
      @include transition(0.5s);
      @include transform(scale(0));
      animation: 0.3s scaleAnimation ease-in-out forwards;
      animation-delay: 1.5s;
    }

    &__icon {
      &--rotate-90 {
        @include rotate(90deg);
      }
      &--rotate-180 {
        @include rotate(180deg);
      }
      &--rotate-270 {
        @include rotate(270deg);
      }
    }
  }
}
@keyframes scaleAnimation {
  to {
    @include transform(scale(1));
  }
}
</style>
