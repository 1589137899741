<template>
  <section>
    <lift-off-scene />
  </section>
</template>

<script>
import LiftOffScene from "@/components/LiftOff/LiftOffScene";
export default {
  components: {
    LiftOffScene,
  },
  mounted() {
    this.updateProgressInformation();
  },
  methods: {
    updateProgressInformation() {
      this.$store.commit("sharedTransition/SET_NEW_PROGRESS", "liftOff");
    },
  },
};
</script>
