<template>
  <div
    class="navigation-toggle-main-side-legal"
    :class="{ 'navigation-toggle-main-side-legal--mobile': isMobile }"
  >
    <!-- made on earth by -->
    <div
      class="navigation-toggle-main-side-legal__copy"
      :class="{
        'navigation-toggle-main-side-legal__copy--disabled': isSideBarButtonDisabled,
      }"
    >
      <p
        :disabled="isSideBarButtonDisabled"
        v-html="
          $t('navigation.legal.made', {
            author: `<a href='http://aurelienvigne.com' target='_blank' rel='noopener noreferrer'>${$t(
              'navigation.legal.by'
            )}</a>`,
          })
        "
      ></p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      isSideBarButtonDisabled: (state) =>
        state.navigation.isSideBarButtonDisabled,
    }),
  },
};
</script>

<style lang="scss" scoped>
.navigation-toggle-main-side-legal {
  display: flex;
  flex: 1;
  justify-content: center;
  &--mobile {
    @include teq-breakpoint($medium) {
      display: none;
    }
  }
  &__copy {
    display: inline-block;

    &--disabled {
      --cursor: none;
    }
    p {
      @include legal;
      text-align: center;
      color: var(--color-gallery);

      &::v-deep a {
        @include legalLink;
      }
    }
  }
}
</style>
