import Vue from "vue";
import VueRouter from "vue-router";

// import DesignSystem from "../views/DesignSystem.vue";
// import FirstStepsScene from "../components/FirstSteps/FirstStepsScene.vue";
import Credits from "../views/Credits.vue";
import Error404 from "../views/Error404.vue";
import Exploration from "../views/Exploration.vue";
import FirstSteps from "../views/FirstSteps.vue";
import Home from "../views/Home.vue";
import Landing from "../views/Landing.vue";
import LiftOff from "../views/LiftOff.vue";
import Privacy from "../views/Privacy.vue";
import Space from "../views/Space.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "Error",
    component: Error404,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/lift-off",
    name: "LiftOff",
    component: LiftOff,
  },
  {
    path: "/landing",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/moon-buggy",
    name: "Exploration",
    component: Exploration,
  },
  {
    path: "/space-travel",
    name: "Space",
    component: Space,
  },
  {
    path: "/first-steps",
    name: "FirstSteps",
    component: FirstSteps,
  },
  // {
  //   path: "/steps-debugger",
  //   name: "StepDebugger",
  //   component: FirstStepsScene,
  // },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/credits",
    name: "Credits",
    component: Credits,
  },

  // {
  //   path: "/Space-poc",
  //   name: "SpacePoc",
  //   component: SpacePoc,
  // },
  // {
  //   path: "/design-system",
  //   name: "DesignSystem",
  //   component: DesignSystem,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
