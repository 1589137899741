<template>
  <div
    refs="navigationToggle"
    class="navigation-toggle"
    :class="{ 'navigation-toggle--open': isOpenOverwrite }"
  >
    <navigation-toggle-main
      :is-circle-visible="animations.isCircleVisible"
      :is-buttons-visible="animations.isButtonsVisible"
      :is-nav-open="isNavOpen"
    />
    <navigation-toggle-blend />
  </div>
</template>

<script>
import { mapState } from "vuex";
import gtagMixin from "@/mixins/gtagMixin.js";
import pauseGameMixin from "@/mixins/pauseGameMixin";

import { gsap } from "gsap";

import NavigationToggleBlend from "@/components/Navigation/navigationToggle/NavigationToggleBlend";
import NavigationToggleMain from "@/components/Navigation/navigationToggle/NavigationToggleMain/NavigationToggleMain";

export default {
  mixins: [gtagMixin, pauseGameMixin],
  components: {
    NavigationToggleBlend,
    NavigationToggleMain,
  },
  computed: {
    ...mapState({
      isNavOpen: (state) => state.navigation.isNavOpen,
    }),
  },
  data() {
    return {
      navigationTimeline: null,
      navigationCloseTimeline: null,
      isOpenOverwrite: false, // debug
      animations: {
        isCircleVisible: false,
        isButtonsVisible: false,
      },
    };
  },

  created() {
    // window.addEventListener("resize", this.onResize);
  },
  mounted() {
    this.createdTimeline();
  },
  beforeDestroy() {
    this.navigationTimeline.kill();
  },

  destroyed() {
    // window.removeEventListener("resize", this.onResize);
  },

  watch: {
    isNavOpen(bool) {
      bool ? this.playTimeline() : this.reverseTimeline();
      this.emitToGA();
    },
  },

  methods: {
    createdTimeline() {
      const durationAnimation = 0.5;
      this.navigationTimeline = gsap.timeline({ paused: false });

      this.navigationTimeline
        .addLabel("show")

        // disable menu button to be clicked
        .add(() => {
          this.toggleMenuButtonDisable(true);
        })
        // pause the game
        .add(() => {
          this.toggleGamePause(true);
        })
        // scale the circle 0 to 1
        .to(".navigation-toggle-blend", {
          scale: 1,

          duration: durationAnimation,
          ease: "power3.inOut",
        })
        .to(".navigation-toggle-main", {
          zIndex: "12",
          opacity: 1,

          duration: 0,
          ease: "power3.inOut",
        })

        // start lines left and right
        .to(".navigation-toggle-main-side", {
          "--width": "100%",

          duration: durationAnimation,
          ease: "power1.inOut",
        })
        .to(
          ".navigation-main-center-lines__lines",
          {
            "--width": "200%",

            duration: durationAnimation * 1.2,
            ease: "power1.inOut",
          },
          "-=0.1"
        )

        // circle
        .add(() => {
          this.toggleCircles(true);
        })
        // buttons
        .add(() => {
          // able nav buttons to be selected
          this.toggleNavButtons(true);
        }, "+=1")

        .to(
          ".shared-button-nav__label",
          {
            duration: durationAnimation,
            // y: 0,
            opacity: 1,
            stagger: {
              each: 0.2,
              from: "start",
            },
            ease: "power1.out",
            overwrite: "all",
          },
          "+=1"
        )
        // start other buttons (credit/contact/cookies)
        .to(
          ".navigation-toggle-main-side .navigation-toggle-main-side__inner",
          {
            opacity: 1,
            y: 0,
            x: 0,

            duration: durationAnimation,
            ease: "power3.inOut",
          }
        )
        .to(
          ".navigation-toggle-main-center-legal__inner",
          {
            opacity: 1,
            y: 0,
            x: 0,

            duration: durationAnimation,
            ease: "power3.inOut",
          },
          `-=${durationAnimation}`
        )

        .add(() => {
          this.toggleSideBarsButtonDisable(false);
          this.toggleMenuButtonDisable(false);
        })
        // close nav

        .addPause()
        .addLabel("hide")

        // disable menu button to be clicked

        .to(".navigation-toggle-main", {
          opacity: 0,

          duration: 1,
          ease: "power3.inOut",
          onStart: () => {
            this.toggleMenuButtonDisable(true);
          },
        })
        .to(
          ".navigation-toggle-blend",
          {
            scale: 0,

            duration: durationAnimation,
            ease: "power3.inOut",
          },
          "-=0.5"
        )
        .add(() => {
          this.toggleCircles(false);
          this.toggleNavButtons(false);
          this.toggleMenuButtonDisable(false);
          this.toggleSideBarsButtonDisable(true);
          this.hideNav();

          // disable nav buttons to be selected
        });

      // clear style when animation is returned
      // on resize close the nav
    },

    //======= START CLOSE TIMELINE =======//

    //======= END CLOSE TIMELINE =======//

    //======= START TOGGLE TIMELINE =======//

    playTimeline() {
      this.navigationTimeline.play("show");
    },
    reverseTimeline() {
      this.navigationTimeline.play("hide");
    },

    //======= END TOGGLE TIMELINE =======//

    //======= START TOGGLE LINES AND BUTTON ANIMATION =======//

    toggleCircles(bool) {
      this.animations.isCircleVisible = bool;
    },
    toggleNavButtons(bool) {
      this.animations.isButtonsVisible = bool;
    },
    toggleSideBarsButtonDisable(bool) {
      this.$store.commit("navigation/TOGGLE_SIDEBARS_DISABLE", bool);
    },
    toggleMenuButtonDisable(bool) {
      this.$store.commit("navigation/TOGGLE_MENU_DISABLE", bool);
    },

    //======= END TOGGLE LINES AND BUTTON ANIMATION =======//

    //======= START HIDE NAV WHEN ANIMATION ENDED =======//

    hideNav() {
      this.$emit("hideNav");
    },

    //======= END HIDE NAV WHEN ANIMATION ENDED =======//

    //======= START EMIT TO GA =======//

    emitToGA() {
      const humanizeIsNavOpen = this.isNavOpen ? "OPEN" : "CLOSE";
      this.emitGtag(`Menu_${humanizeIsNavOpen}`, "Menu", "Click");
    },

    //======= END EMIT TO GA =======//
  },
};
</script>

<style lang="scss" scoped>
.navigation-toggle {
  &__inner {
    position: relative;
    display: flex;
    flex: 1;
  }
}
</style>
