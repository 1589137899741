<template>
  <div class="error">
    <error />
  </div>
</template>

<script>
import Error from "@/components/Error/Error.vue";
export default {
  metaInfo: {
    title: "We Have A Problem | The Leap For Mankind",
  },
  components: {
    Error,
  },
  mounted() {
    this.updateProgressInformation();
  },
  methods: {
    updateProgressInformation() {
      this.$store.commit("sharedTransition/SET_NEW_PROGRESS", "error");
    },
  },
};
</script>

<style></style>
