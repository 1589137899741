<template>
  <div class="rectangle-btn-base">
    <svg
      width="145"
      height="42"
      viewBox="0 0 145 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- square -->
      <clipPath id="rectangle-btn-base__square-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M144 1H1V41H144V1ZM0 0V42H145V0H0Z"
          fill="#E8E8F2"
        />
      </clipPath>
      <path
        clip-path="url(#rectangle-btn-base__square-path)"
        class="rectangle-btn-base__square-path"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M144 1H1V41H144V1ZM0 0V42H145V0H0Z"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
      />

      <!-- line -->
      <clipPath id="rectangle-btn-base__line-2-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#E8E8F2"
          d="M145 0.933322L1.12401 41.0667L0.748962 40.1396L144.625 0.00631576L145 0.933322Z"
        />
      </clipPath>
      <path
        clip-path="url(#rectangle-btn-base__line-2-path)"
        class="rectangle-btn-base__line-2-path"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
        d="M145 0.933322L1.12401 41.0667L0.748962 40.1396L144.625 0.00631576L145 0.933322Z"
      />

      <!-- line -->
      <clipPath id="rectangle-btn-base__line-1-path">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M143.876 41.0666L1.12401 0.933302L1.50159 0.00732422L144.254 40.1407L143.876 41.0666Z"
        />
      </clipPath>
      <path
        clip-path="url(#rectangle-btn-base__line-1-path)"
        class="rectangle-btn-base__line-1-path"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M143.876 41.0666L1.12401 0.933302L1.50159 0.00732422L144.254 40.1407L143.876 41.0666Z"
        fill="none"
        stroke-miterlimit="1"
        stroke-width="1"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scope>
.rectangle-btn-base {
  @include buttonAnimation;
  display: flex;
  flex-direction: column;
  position: relative;
}
</style>
