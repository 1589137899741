// initial state
const state = () => ({
  isDev: true,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  //   SET_SCENE_READY(state) {
  //     state.isSceneReady = true;
  //   },
  //   RESET_SCENE_READY(state) {
  //     state.isSceneReady = false;
  //   },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
