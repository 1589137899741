<template>
  <div class="shared-button-rectangle-main">
    <shared-button-rectangle-base
      :isAnimationStarted="isAnimationStarted"
      :is-white="isWhite"
      :is-black="isBlack"
    />
    <!-- <shared-button-circle-icon :icon-name="iconName" :is-black="isBlack" /> -->
    <shared-button-copy v-if="copy" :copy="copy" :is-black="isBlack" />
    <shared-button-corner :isAnimationStarted="isAnimationStarted" />
  </div>
</template>

<script>
import SharedButtonRectangleBase from "@/components/Shared/SharedButton/SharedButtonRectangle/SharedButtonRectangleBase";
import SharedButtonCopy from "@/components/Shared/SharedButton/SharedButtonRectangle/SharedButtonRectangleCopy";
import SharedButtonCorner from "@/components/Shared/SharedButton/SharedButtonCorner/SharedButtonCorner";
export default {
  components: {
    SharedButtonRectangleBase,
    SharedButtonCopy,
    SharedButtonCorner,
  },
  props: {
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
    copy: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-button-rectangle-main {
  position: relative;
}
</style>
