<template>
  <div class="intro">
    <ul class="intro__list">
      <!-- countdown -->

      <intro-count-down :progress="mockProgress.progress" />
      <!-- rice stadium -->
      <intro-location :is-visible="isLocationVisible" />
      <!-- start experience  -->
      <li class="intro__list__start">
        <shared-button-copy
          v-if="isButtonVisible"
          :is-white="false"
          :is-black="false"
          icon-name="arrow"
          :copy="$t('intro.buttons.start')"
          :is-reverse="false"
          unique-ref="intro"
          @clicked="enterWithAudio"
        />
      </li>
      <!-- without audio -->
      <li class="intro__list__mute">
        <button
          class="intro__list__mute__button"
          @click="enterWithoutAudio"
          @focusin="isUserHovering(true)"
          @focusout="isUserHovering(false)"
          :aria-label="$t('intro.buttons.mute')"
        >
          <span
            class="intro__list__mute__button__copy"
            :class="{
              'intro__list__mute__button__copy--focus': isUserFocusMuteButton,
            }"
          >
            {{ $t("intro.buttons.mute") }}</span
          >
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

import gtagMixin from "@/mixins/gtagMixin.js";

import { gsap } from "gsap";

import IntroCountDown from "@/components/Intro/IntroCountDown";
import IntroLocation from "@/components/Intro/IntroLocation";
import SharedButtonCopy from "@/components/Shared/SharedButton/SharedButtonCopy/SharedButtonCopy";
export default {
  mixins: [gtagMixin],
  components: {
    IntroCountDown,
    IntroLocation,
    SharedButtonCopy,
  },
  data() {
    return {
      timeline: null,
      width: 100,
      isButtonVisible: false,
      isLocationVisible: false,
      mockProgress: {
        interval: null,
        progress: 0,
        intervalRate: 100,
      },
      isUserFocusMuteButton: false,
    };
  },
  computed: {
    ...mapState({
      loaded: (state) => state.intro.loaded,
    }),
    isIntroVideoLoaded() {
      return this.loaded >= 1;
    },
  },
  watch: {
    loaded(val) {
      val >= 1 ? this.stopThenRunIntervalFaster() : null;
    },
  },
  mounted() {
    this.initAnimation();
    this.startMockProgress();
  },
  beforeDestroy() {
    this.resetInterval();
  },
  methods: {
    initAnimation() {
      this.timeline = gsap.timeline({ paused: true });
      this.timeline
        .to(".intro-count-down", {
          "--width-count-down": `77%`,

          ease: "power4.inOut",
          duration: 2,
        })
        .add(() => {
          this.isLocationVisible = true;
        }, "-=0.5")
        .to(".intro__list__start", {
          "--height-button": "6.6rem",

          ease: "power4.inOut",
          duration: 1,
          delay: 0.2,
        })
        .add(() => {
          this.isButtonVisible = true;
        }, "-=0.3")
        .to(".intro__list__mute", {
          opacity: 1,
          y: 0,
          ease: "power4.inOut",
          duration: 1,
          delay: 2.3,
        });
    },

    enterWithAudio() {
      this.goToVideo();
      this.toggleAudio();
      this.emitToGA(true);
    },
    toggleAudio() {
      this.$store.commit("sharedAudio/toggleMute");
    },
    enterWithoutAudio() {
      this.goToVideo();
      this.emitToGA(false);
    },
    goToVideo() {
      this.runIntroVideo();
      this.hideTransitionPage();
    },
    runIntroVideo() {
      this.$store.dispatch("intro/TOGGLE_VIDEO", true);
      this.$store.dispatch("sharedTransition/DISABLE_INTRO");
    },
    hideTransitionPage() {
      this.$store.commit("sharedTransition/TOGGLE_TRANSITION", false);
    },

    //======= START EMIT TO GA =======//

    emitToGA(bool) {
      const humanizeIsMute = bool ? "ON" : "OFF";
      this.emitGtag(`Enter_Audio_${humanizeIsMute}`, "Enter", "Click");
    },

    //======= END EMIT TO GA =======//

    ////////////////////////////////
    //       START MOCK PROGRESS
    ////////////////////////////////

    /*------------------------------
   Start Intervals
   ------------------------------*/
    startMockProgress() {
      this.runMockProgress(100);
    },
    runMockProgress(intervalRate) {
      this.mockProgress.interval = setInterval(() => {
        this.updateMockProgress();
        this.mockProgress.progress >= 100 ? this.endMockProgress() : null;
      }, intervalRate);
    },

    stopThenRunIntervalFaster() {
      // video loaded, so stop the current timeout then run it faster
      this.resetInterval();
      this.$nextTick(() => {
        this.runMockProgress(25);
      });
    },
    /*------------------------------
   End Intervals
   ------------------------------*/
    /*------------------------------
    Start Update value progress
    ------------------------------*/
    updateMockProgress() {
      (this.mockProgress.progress < 99 && !this.isIntroVideoLoaded) ||
      this.isIntroVideoLoaded
        ? this.updateMockProgressValue()
        : null;
    },

    updateMockProgressValue() {
      this.mockProgress.progress += 1;
    },
    /*------------------------------
    End Update value progress
    ------------------------------*/

    //======= START END PROGRESS =======//

    endMockProgress() {
      this.resetInterval();
      this.updateMockProgressToStore(1);
      this.timeline.play();
    },
    updateMockProgressToStore(value) {
      this.$store.commit("sharedTransition/UPDATE_PROGRESS", value);
    },
    resetInterval() {
      this.mockProgress.interval
        ? (clearInterval(this.mockProgress.interval),
          (this.mockProgress.interval = null))
        : null;
    },

    //======= END END PROGRESS =======//

    ////////////////////////////////
    //       END MOCK PROGRESS
    ////////////////////////////////

    ////////////////////////////////
    //       START MANAGE HOVER STATE
    ////////////////////////////////
    isUserHovering(bool) {
      this.toggleHover(bool);
    },
    toggleHover(bool) {
      this.isUserFocusMuteButton = bool;
    },

    ////////////////////////////////
    //       END MANAGE HOVER STATE
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.intro {
  display: flex;
  --height-button: 0%;
  &__list {
    display: flex;
    flex-direction: column;
    &__where {
      h2 {
        @include transform(translateY(100%));
        opacity: 0;
      }
    }
    &__start {
      display: flex;
      justify-content: center;
      height: var(--height-button);
      margin-top: $xl-margin;
    }
    &__mute {
      @include transform(translateY(100%));
      opacity: 0;
      margin-top: $small-margin;
      &__button {
        @include removeButtonStyle;
        &__copy {
          @include legalLink;
        }
      }
    }
    li {
      @include marginBetweenTitles;
      h2 {
        @include h3;
      }
    }
  }
}
</style>
