<template>
  <div
    class="tutorial-combined-copy"
    :class="{ 'tutorial-combined-copy--desktop': !isMobile }"
  >
    <p
      class="tutorial-combined-copy__copy tutorial-combined-copy__copy--mobile"
      :class="{ 'tutorial-combined-copy__copy--black': isBlack }"
      v-html="copy.combined.mobile"
    ></p>
    <p
      class="tutorial-combined-copy__copy tutorial-combined-copy__copy--desktop"
      :class="{ 'tutorial-combined-copy__copy--black': isBlack }"
    >
      {{ copy.combined.desktop }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    copy: {
      type: Object,
      required: true,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMobile: { type: Boolean, required: false, default: false },
  },
};
</script>

<style lang="scss" scoped>
.tutorial-combined-copy {
  @include transform(translateY(1rem));
  opacity: 0;
  animation: 0.3s moveUpAnimation ease-in-out forwards;
  animation-delay: 1.5s;

  display: flex;
  justify-content: center;

  @keyframes moveUpAnimation {
    to {
      @include transform(translateY(0rem));
      opacity: 1;
    }
  }

  $g: &;

  &__copy {
    max-width: 360px;
    text-align: center;
    color: var(--color-gallery);

    &--black {
      color: var(--color-ebony);
    }
    &--mobile {
      display: flex;
      .tutorial-combined--desktop & {
        display: none;
      }
    }
    &--desktop {
      display: none;
      .tutorial-combined--desktop & {
        display: flex;
        @include teq-breakpoint($large) {
          display: none;
        }
      }
    }
  }
}
</style>
