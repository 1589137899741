<template>
  <div
    class="tutorial-combined"
    :class="{ 'tutorial-combined--desktop': !isMobile }"
  >
    <tutorial-combined-copy
      :copy="copy"
      :is-black="isBlack"
      :is-mobile="isMobile"
    />
    <ul class="tutorial-combined__list">
      <!-- space -->

      <tutorial-combined-keys
        :is-black="isBlack"
        :copy="copy.space"
        :icons="['arrowUp', 'space']"
        :is-mobile="isMobile"
      />
      <!-- + -->
      <li class="tutorial-combined__list__item">
        <tutorial-combined-plus :is-black="isBlack" :is-mobile="isMobile" />
      </li>
      <!-- arrows -->
      <tutorial-combined-keys
        :is-black="isBlack"
        :copy="copy.arrows"
        :icons="['joystick', 'arrows']"
        :is-mobile="isMobile"
      />
    </ul>
  </div>
</template>

<script>
import TutorialCombinedCopy from "@/components/Tutorial/TutorialCombined/TutorialCombinedCopy";
import TutorialCombinedPlus from "@/components/Tutorial/TutorialCombined/TutorialCombinedPlus";
import TutorialCombinedKeys from "@/components/Tutorial/TutorialCombined/TutorialCombinedKeys";
export default {
  components: {
    TutorialCombinedCopy,
    TutorialCombinedPlus,
    TutorialCombinedKeys,
  },
  props: {
    copy: {
      type: Object,
      required: true,
    },

    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMobile: { type: Boolean, required: false, default: false },
  },
};
</script>

<style lang="scss" scoped>
.tutorial-combined {
  display: flex;
  flex-direction: column;
  &__copy {
    display: flex;
    justify-content: center;
    .tutorial-combined--desktop & {
      @include teq-breakpoint($large) {
        display: none;
      }
    }
    p {
      max-width: 360px;
      text-align: center;
      color: var(--color-gallery);
    }

    &__mobile {
      display: flex;
      // @include teq-breakpoint($medium) {
      .tutorial-combined--desktop & {
        display: none;
      }
      // }
    }
    &__laptop {
      display: none;
      // @include teq-breakpoint($medium) {
      .tutorial-combined--desktop & {
        display: flex;
      }
      // }
    }
  }
  &__list {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__item {
      &--mobile {
        display: flex;
        // @include teq-breakpoint($medium) {
        .tutorial-combined--desktop & {
          display: none;
        }
        // }
      }
      &--laptop {
        display: none;
        // @include teq-breakpoint($medium) {
        .tutorial-combined--desktop & {
          display: flex;
          @include teq-breakpoint($large) {
            display: none;
          }
        }
        // }
      }
      &--desktop {
        display: none;
        // @include teq-breakpoint($large) {
        .tutorial-combined--desktop & {
          display: flex;
        }
        // }
      }
    }
  }
}
</style>
