<template>
  <div
    class="dashboard-warning"
    :class="{ 'dashboard-warning--visible': isAnimationReady }"
  >
    <div class="dashboard-warning__inner">
      <!-- square with corners -->
      <!-- thin line -->
      <shared-button-corner :is-animation-started="isAnimationReady" />
      <dashboard-warning-main :copy="copy" />
    </div>
    <dashboard-warning-reset
      v-if="isResetVisible"
      :is-active="isAnimationStarted"
    />
  </div>
</template>

<script>
import DashboardWarningMain from "@/components/Dashboard/DashboardWarning/DashboardWarningMain";
import DashboardWarningReset from "@/components/Dashboard/DashboardWarning/DashboardWarningReset";
import SharedButtonCorner from "@/components/Shared/SharedButton/SharedButtonCorner/SharedButtonCorner";
export default {
  components: {
    SharedButtonCorner,
    DashboardWarningReset,
    DashboardWarningMain,
  },
  props: {
    copy: {
      type: Object,
      required: false,
      default: null,
    },
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAnimationDelay: {
      type: Boolean,
      required: false,
      default: false,
    },
    isResetVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isAnimationVisible: false,
    };
  },
  computed: {
    isAnimationReady() {
      return this.isAnimationDelay
        ? this.isAnimationVisible
        : this.isAnimationStarted;
    },
  },
  mounted() {
    this.delayAnimation();
  },
  beforeDestroy() {
    this.toggleAnimationVisible(false);
  },
  methods: {
    delayAnimation() {
      setTimeout(() => {
        this.toggleAnimationVisible(true);
      }, 100);
    },
    toggleAnimationVisible(bool) {
      this.isAnimationVisible = bool;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-warning {
  --opacity-lines: 0;
  @include lines-warning(var(--opacity-lines));
  width: 100%; //29rem;
  height: 13rem;
  min-width: 29rem;
  display: flex;
  flex: 1;
  &--visible {
    --opacity-lines: 0.1;
  }

  @include teq-breakpoint($small) {
    width: 45rem;
    height: 10rem;
  }
  @include teq-breakpoint($large) {
    width: 53rem;
    height: 17rem;
  }
  &__inner {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0.5rem;
  }
}

p {
  color: black;
}
</style>
