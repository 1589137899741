<template>
  <div
    class="tutorial-combined-plus"
    :class="{
      'tutorial-combined-plus--black': isBlack,
      'tutorial-combined-plus--desktop': !isMobile,
    }"
  >
    <div class="tutorial-combined-plus__symbol">
      <svg>
        <use xlink:href="#icon__plus" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMobile: { type: Boolean, required: false, default: false },
  },
};
</script>

<style lang="scss" scoped>
.tutorial-combined-plus {
  display: flex;
  align-items: flex-end;
  height: 100%;
  &__symbol {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100%;

    width: 42px;

    @include transform(scale(0));
    animation: 0.3s scaleAnimation ease-in-out forwards;
    animation-delay: 1.5s;

    .tutorial-combined-plus--desktop & {
      max-height: 42px;
      max-width: 42px;
    }
    &::v-deep svg {
      @include filterShadow(0px 0px 3px $gallery);
      width: 100%;
      height: 100%;
      max-width: 12px;
      max-height: 12px;
      fill: var(--color-gallery);
      .tutorial-combined-plus--black & {
        @include filterShadow(0px 0px 3px $wood-smoke);
        fill: var(--color-ebony);
      }
    }
  }
}
@keyframes scaleAnimation {
  to {
    @include transform(scale(1));
  }
}
</style>
