<template>
  <div class="Privacy">
    <shared-layout :heading="$t('pages.privacy.title')" parent="privacy"
      ><privacy-main
    /></shared-layout>
  </div>
</template>

<script>
import PrivacyMain from "@/components/Privacy/Privacy";
import SharedLayout from "@/components/Shared/SharedLayout/SharedLayout";
export default {
  metaInfo: {
    title: "Privacy Policy | The Leap For Mankind",
  },
  components: {
    PrivacyMain,
    SharedLayout,
  },
};
</script>
