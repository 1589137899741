import { mapState } from "vuex";
export default {
  data() {
    return {
      tracks: {
        rocket: "318688__limitsnap-creations__rocket-thrust-effect.mp3",
        // explosion: "399303__deleted-user-5405837__explosion-012.mp3",
        // explosion: "607049__aenhs__explosion.wav",
        // explosion: "80501__ggctuk__exp-obj-large05.wav",
        explosion: "80498__ggctuk__exp-obj-large01.mp3",
        touchDown:
          "touch-down-555136__f-m-audio__body-falling-on-carpeted-floor-1.mp3",
        car: "278188__debsound__rally-car-idle-loop-14.mp3",
        metal: "446132__justinvoke__collision-3.mp3",
        liftOffCountDown: "countdown.mp3",
        weHaveALiftOff: "weHaveALiftOff.mp3",
        liftOffRollProgram: "liftOffRollProgram.mp3",
        liftOffTMinus10s: "liftOffTMinus10s.mp3",
        liftOffTMinus30s: "liftOffTMinus30s.mp3",

        landingEagleHasLanded: "landingEagleHasLanded.mp3",
        landing50s: "landing50s.mp3",
        landing30s: "landing30s.mp3",
        doYouCopy: "exploration-do-you-copy.mp3",
        // reset:
        //   "touch-down-555136__f-m-audio__body-falling-on-carpeted-floor-1.wav",
      },
      timeoutAudioAfterStop: null,
    };
  },
  computed: {
    ...mapState({
      fx: (state) => state.sharedAudio.fx,
    }),
  },
  methods: {
    ////////////////////////////////
    //       START MANAGE UPDATES
    ////////////////////////////////
    playPauseFX(key, whichAudio, bool) {
      // if it's pause so only toggle play to pause
      !bool
        ? this.togglePlayFX(bool, whichAudio)
        : this.playFX(key, whichAudio, true);
    },

    togglePlayFX(bool, whichAudio) {
      // console.log("togglePlayFX");
      this.isTrackFXPlaying(whichAudio) !== bool
        ? this.$store.commit(this.setFXMutationName("playback"), {
            whichAudio: whichAudio,
            bool: bool,
          })
        : null;
    },
    playFX(key, whichAudio, isPlayable) {
      // if it's play so:
      // if track not set, so add new set and play
      // if track is set, so play
      // if track is set but isn't the right one, so change it and play
      !this.findCurrentFXTrack(whichAudio)
        ? this.updateFXTrack(key, "set", whichAudio)
        : this.FXTrackExist(key, whichAudio, isPlayable);
    },

    FXTrackExist(key, whichAudio, isPlayable) {
      this.findCurrentFXTrack(whichAudio) === `fx/${this.tracks[key]}`
        ? isPlayable
          ? this.togglePlayFX(true, whichAudio)
          : null
        : this.updateFXTrack(key, "update", whichAudio);
      // this.stopOrPlaySoundEffect(whichAudio, true));
    },

    ////////////////////////////////
    //       END MANAGE UPDATES
    ////////////////////////////////

    ////////////////////////////////
    //       START UPDATE STORE
    ////////////////////////////////

    updateFXTrack(key, mutationType, whichAudio) {
      let valueToMutate = {
        url: this.pathToMFXTrack(key),
        whichAudio: whichAudio,
      };
      switch (key) {
        case "rocket":
          this.$store.commit(
            this.setFXMutationName(mutationType),
            valueToMutate
          );

          break;
        case "explosion":
          this.$store.commit(
            this.setFXMutationName(mutationType),
            valueToMutate
          );

          break;
        case "engine":
          this.$store.commit(
            this.setFXMutationName(mutationType),
            valueToMutate
          );

          break;

        //   credits, legal, open nav
        default:
          this.$store.commit(
            this.setFXMutationName(mutationType),
            valueToMutate
          );
          break;
      }
    },
    setFXMutationName(mutationType) {
      switch (mutationType) {
        case "set":
          return "sharedAudio/updateCurrentSoundEffectTrack";
        case "update":
          return "sharedAudio/updateUpComingSoundEffectTrack";
        case "stop":
          return "sharedAudio/toggleStopSoundEffect";
        default:
          return "sharedAudio/toggleSoundEffectPlayback";
      }
    },
    pathToMFXTrack(key) {
      // return `${key}/${this.tracks[key]`
      return `fx/${this.tracks[key]}`; // debug
    },
    ////////////////////////////////
    //       END UPDATE STORE
    ////////////////////////////////

    ////////////////////////////////
    //       START STOP AND RUN FX
    ////////////////////////////////
    stopAndPlaySoundEffect(key, whichAudio) {
      this.stopSoundEffectAndPlay(key, whichAudio, true);
    },
    stopSoundEffectAndPlay(key, whichAudio, isPlayingRightAfter) {
      // stop sound effet
      this.stopOrPlaySoundEffect(whichAudio, true);

      isPlayingRightAfter
        ? this.playSoundEffectAfterAStop(key, whichAudio)
        : null;
    },
    stopOrPlaySoundEffect(whichAudio, bool) {
      this.$store.commit("sharedAudio/toggleStopSoundEffect", {
        whichAudio: whichAudio,
        bool: bool,
      });
    },
    playSoundEffectAfterAStop(key, whichAudio) {
      this.resetTimeoutAudioAfterStop();

      this.timeoutAudioAfterStop = setTimeout(() => {
        this.playPauseFX(key, whichAudio, true);
      }, 50);
    },
    resetTimeoutAudioAfterStop() {
      this.timeoutAudioAfterStop
        ? (clearTimeout(this.timeoutAudioAfterStop),
          (this.timeoutAudioAfterStop = null))
        : null;
    },

    ////////////////////////////////
    //       END STOP AND RUN FX
    ////////////////////////////////

    ////////////////////////////////
    //       START HELPERS
    ////////////////////////////////
    findCurrentFXTrack(whichAudio) {
      return this.fx[whichAudio].currentTrack;
    },
    isTrackFXPlaying(whichAudio) {
      // sharedAudio.fx.engine.isPlaying,
      return this.fx[whichAudio].isPlaying;
    },
    ////////////////////////////////
    //       END HELPERS
    ////////////////////////////////

    ////////////////////////////////
    //       START PRELOAD FX AUDIO ON PAGE MOUNT
    ////////////////////////////////
    preloadFXOnPageLoad(key) {
      switch (key) {
        case "LiftOff":
          this.playFX("rocket", "engine", false);
          this.playFX("explosion", "fx", false);
          this.$nextTick(() => {
            this.stopOrPlaySoundEffect("engine", true);
            this.stopOrPlaySoundEffect("fx", true);
          });
          break;
        case "Landing":
          this.playFX("rocket", "engine", false);
          this.playFX("touchDown", "fx", false);
          this.$nextTick(() => {
            this.stopOrPlaySoundEffect("engine", true);
            this.stopOrPlaySoundEffect("fx", true);
          });
          break;

        case "Exploration":
          this.playFX("car", "engine", false);
          this.playFX("touchDown", "fx", false);
          this.$nextTick(() => {
            this.stopOrPlaySoundEffect("engine", true);
            this.stopOrPlaySoundEffect("fx", true);
          });
          break;

        //   home, space, moon, credits, legal
        default:
          // potential button on click, navigation
          // this.stopSoundEffectAndPlay("rocket", "engine");
          break;
      }
    },

    ////////////////////////////////
    //       END PRELOAD FX AUDIO ON PAGE MOUNT
    ////////////////////////////////
  },
};
