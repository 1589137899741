<template>
  <div>
    <span>Total duration: {{ duration }} seconds</span>
    <span>Progress: {{ progress * 100 }}%-v: {{ volume }}</span>
    <button @click="togglePlayback">{{ playing ? "Pause" : "Play" }}</button>
    <button @click="stop">Stop</button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import VueHowler from "vue-howler";

export default {
  mixins: [VueHowler],
  props: {
    isPlaying: {
      type: Boolean,
      required: false,
      default: false,
    },

    isAudioSourceUpdated: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentTrack: {
      type: String,
      required: false,
      default: null,
    },
    upComingTrack: {
      type: String,
      required: false,
      default: null,
    },

    volumeInterval: {
      type: Number,
      required: false,
      default: 50,
    },
    isStopped: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxVolume: {
      type: Number,
      required: false,
      default: 1,
    },
    whichAudio: {
      type: String,
      required: false,
      default: "music",
    },
  },
  data() {
    return {
      fadeInOutInterval: null,
      volume: 0,
      volumeRate: 0.1,
      isPlayedOnce: false,
    };
  },
  computed: {
    ...mapState({
      isMute: (state) => state.sharedAudio.isMute,
      isIntroVisible: (state) => state.intro.isVisible,
    }),
    isAudioFX() {
      return this.whichAudio === "fx";
    },
    isPlayedMoreThanOnce() {
      return this.isPlayedOnce && this.isAudioFX;
    },
    isAudioUnPlayable() {
      return (
        this.isMute ||
        !this.isPlaying ||
        this.isIntroVisible ||
        this.isStopped ||
        this.isPlayedOnce
      );
    },
  },
  watch: {
    isPlaying(bool) {
      this.upComingTrack === this.currentTrack
        ? this.toggleIsPlayedOnce(false)
        : null;
      this.toggleAudio(bool);
    },
    volume() {
      this.updateVolumeHowler();
    },
    upComingTrack(val) {
      val !== this.currentTrack ? this.toggleAudio(false) : null;
    },
    isAudioSourceUpdated(bool) {
      if (!bool) return;
      this.$nextTick(() => {
        this.duration > 0 && bool
          ? (this.toggleIsPlayedOnce(false), this.toggleAudio(true))
          : null;
      });
    },
    duration(val) {
      val > 0 && this.isAudioSourceUpdated ? this.toggleAudio(true) : null;
    },
    isMute(bool) {
      if (bool) {
        this.toggleAudio(!bool);
      }
      !bool && this.isAudioUnPlayable ? null : this.toggleAudio(!bool);
    },
    isStopped(bool) {
      bool ? this.stop() : null;
    },
    isIntroVisible(bool) {
      !bool ? this.playAudio() : null;
    },
    // currentTrack() {
    //   this.toggleIsPlayedOnce(false);
    // },
  },
  methods: {
    ////////////////////////////////
    //       START TOGGLE AUDIO
    ////////////////////////////////
    toggleAudio(bool) {
      bool ? this.playAudio() : this.pauseAudio();
    },
    playAudio() {
      if (this.isAudioUnPlayable) return;
      this.debouncedPlay(true);
      this.toggleIsPlayedOnce(true);
    },

    toggleIsPlayedOnce(bool) {
      this.isAudioFX ? (this.isPlayedOnce = bool) : null;
    },
    stopOrPlaySoundEffect(whichAudio, bool) {
      this.$store.commit("sharedAudio/toggleStopSoundEffect", {
        whichAudio: whichAudio,
        bool: bool,
      });
    },

    debouncedPlay(bool) {
      this.volumeInterval === 0
        ? this.toggleAudioInstantly(bool)
        : this.fadeInOutAudio(bool);

      bool ? this.play() : null;
    },

    pauseAudio() {
      this.debouncedPlay(false);
    },
    ////////////////////////////////
    //       END TOGGLE AUDIO
    ////////////////////////////////

    ////////////////////////////////
    //       START TOGGLE AUDIO INSTANTLY
    ////////////////////////////////

    toggleAudioInstantly(bool) {
      // usefull for the FX
      this.volume = bool ? 1 : 0;
      bool ? null : this.audioFadeOutInDone();
    },

    ////////////////////////////////
    //       END TOGGLE AUDIO INSTANTLY
    ////////////////////////////////

    ////////////////////////////////ys
    //       START FADEIN AUDIO
    ////////////////////////////////
    fadeInOutAudio(bool) {
      // if audio already debouncing so stop it now and the next fade will update it
      this.clearIntervalFadeInOut();

      this.whichDebouncedFunction(bool);
    },

    whichDebouncedFunction(bool) {
      switch (this.volumeInterval) {
        case 100:
          this.fadeInOutInterval = setInterval(() => {
            this.debouncedFunctionSlow(bool);
          }, this.volumeInterval);
          break;
        case 30:
          this.fadeInOutInterval = setInterval(() => {
            this.debouncedFunctionFast(bool);
          }, this.volumeInterval);
          break;
        default:
          this.fadeInOutInterval = setInterval(() => {
            this.debouncedFunction(bool);
          }, this.volumeInterval);
          break;
      }
    },

    debouncedFunction: _.throttle(function (bool) {
      this.updateVolume(bool);
    }, 50),
    debouncedFunctionSlow: _.throttle(function (bool) {
      this.updateVolume(bool);
    }, 100),
    debouncedFunctionFast: _.throttle(function (bool) {
      this.updateVolume(bool);
    }, 10),

    updateVolume(bool) {
      bool ? this.increaseVolume() : this.decreaseVolume();
    },
    // increaseVolume() {
    //   this.volume < this.maxVolume
    //     ? ((this.volume = this.volume + this.volumeRate), this.play())
    //     : this.clearIntervalFadeInOut();
    // },
    increaseVolume() {
      this.volume < this.maxVolume
        ? ((this.volume = this.volume + this.volumeRate), this.forcePlayAudio())
        : this.clearIntervalFadeInOut();
    },
    forcePlayAudio() {
      this.whichAudio !== "music" &&
      (this.isMute || !this.isPlaying || this.isIntroVisible)
        ? null
        : this.play();
    },
    decreaseVolume() {
      this.volume > 0
        ? (this.volume = this.volume - this.volumeRate)
        : this.audioFadeOutInDone();
    },
    audioFadeOutInDone() {
      this.pause();
      this.clearIntervalFadeInOut();
      this.isTrackToUpdate()
        ? (this.updateCurrentTrack(), this.resetAudioSourceIsUpdated())
        : null;
    },

    clearIntervalFadeInOut() {
      this.fadeInOutInterval ? clearInterval(this.fadeInOutInterval) : null;
    },

    updateVolumeHowler() {
      this.setVolume(this.volume);
    },

    ////////////////////////////////
    //       END FADEIN AUDIO
    ////////////////////////////////

    ////////////////////////////////
    //       START SET TRACK
    ////////////////////////////////

    isTrackToUpdate() {
      return (
        this.currentTrack !== this.upComingTrack && this.upComingTrack !== null
      );
    },
    updateCurrentTrack() {
      this.whichAudio === "music"
        ? this.updateCurrentMusicTrack()
        : this.updateCurrenFXTrack();
    },
    updateCurrentMusicTrack() {
      this.$store.commit(
        "sharedAudio/updateCurrentMusicTrack",
        this.upComingTrack
      );
    },
    updateCurrenFXTrack() {
      const valuesToUpdate = {
        whichAudio: this.whichAudio,
        url: this.upComingTrack,
      };
      this.$store.commit(
        "sharedAudio/updateCurrentSoundEffectTrack",
        valuesToUpdate
      );
    },

    resetAudioSourceIsUpdated() {
      this.$emit("resetAudioSourceIsUpdated");
    },

    ////////////////////////////////
    //       END SET TRACK
    ////////////////////////////////
  },
};
</script>
