<template>
  <div
    class="shared-icons-close"
    :class="{ 'shared-icons-close--visible': isAnimationStarted }"
  >
    <!-- <div class="shared-icons-close__icon"> -->
    <ul class="shared-icons-close__icon">
      <li></li>
      <li></li>
    </ul>
    <!-- </div> -->
  </div>
</template>

<script>
// import SharedIcons from "@/components/Shared/SharedIcons";
export default {
  components: {
    // SharedIcons,
  },
  props: {
    isAnimationStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shared-icons-close {
  --rotation-lines-left: 0deg;
  --rotation-lines-right: 0deg;
  --opacity-lines: 0;
  @include transition(0.5s ease all);
  .circular-button-icon--hover & {
    @include transform(rotate(180deg));
  }

  &--visible {
    --rotation-lines-left: 45deg;
    --rotation-lines-right: -45deg;
    --opacity-lines: 1;
  }
  &__icon {
    display: flex;
    flex-direction: column;

    li {
      @include transition(0.5s ease all);
      @include shadow(0px 0px 10px 0px rgba(238, 238, 238, 1));
      transition-delay: 2s;
      width: 1.5rem;
      height: 0.2rem;
      background: var(--color-gallery);
      opacity: var(--opacity-lines);
      .circular-button-icon--black & {
        @include shadow(0px 0px 10px 0px rgba(14, 14, 15, 1));
        background: var(--color-ebony);
      }

      &:nth-child(1) {
        @include transform(
          rotate(var(--rotation-lines-left)) translate(0.05rem, 0.1rem)
        );
      }
      &:nth-child(2) {
        @include transform(
          rotate(var(--rotation-lines-right)) translate(0.05rem, -0.1rem)
        );
      }
    }
  }
}
</style>
