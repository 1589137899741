<template>
  <div class="home-page-main">
    <!-- copy -->
    <home-page-main-copy :titles="titles" />
    <!-- button -->
    <div class="home-page-main__cta">
      <shared-button-copy
        v-if="button.isVisible"
        :is-white="false"
        :is-black="false"
        icon-name="arrow"
        :copy="$t('pages.home.main.cta')"
        :is-reverse="false"
        unique-ref="progress"
        @clicked="clicked"
      />
    </div>
  </div>
</template>

<script>
import resetGamePlayStoreMixin from "@/mixins/resetGamePlayStoreMixin";
import SharedButtonCopy from "@/components/Shared/SharedButton/SharedButtonCopy/SharedButtonCopy";
import HomePageMainCopy from "@/components/HomePage/HomePageMain/HomePageMainCopy";
export default {
  mixins: [resetGamePlayStoreMixin],
  components: {
    HomePageMainCopy,
    SharedButtonCopy,
  },
  data() {
    return {
      button: {
        timeout: null,
        isVisible: false,
      },
      isVisible: false,
      titles: {
        top: {
          copy: this.$t("pages.home.main.title.the"),
          headerNumber: "h2",
        },
        bottom: {
          copy: this.$t("pages.home.main.title.leap"),
          headerNumber: "h1",
        },
      },
    };
  },
  mounted() {
    this.displayButton();
  },
  beforeDestroy() {
    this.timeoutDestroyer();
    this.resetGamePlayStore();
  },
  methods: {
    displayButton() {
      this.button.timeout = setTimeout(() => {
        this.button.isVisible = true;
      }, 2000);
    },
    timeoutDestroyer() {
      this.button.timeout
        ? (clearTimeout(this.button.timeout), (this.button.timeout = null))
        : null;
    },
    ////////////////////////////////
    //       START GO TO NEXT PAGE
    ////////////////////////////////
    clicked() {
      // open loading page
      this.$store.commit("sharedTransition/SET_NEW_PROGRESS", "liftOff");
      const redirectTimeOut = setTimeout(() => {
        // go to next page
        this.pageRedirection("/lift-off");
        clearTimeout(redirectTimeOut);
      }, 1000);
    },
    pageRedirection(pageName) {
      this.$router.push(pageName);
    },
    ////////////////////////////////
    //       END GO TO NEXT PAGE
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.home-page-main {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__cta {
    margin-top: $medium-margin;

    height: 6.6rem;
    @include teq-breakpoint($large) {
      margin-top: $xl-margin;
    }
  }
}
</style>
