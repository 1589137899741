// initial state
const state = () => ({
  // isGalleryAnimationRunning: false,
  isGalleryOpen: false,
  isUserGoingToNextPage: false,
  selectedImgId: null,
});

// getters
const getters = {};

// actions
const actions = {
  // openGallery(store, { imageId }) {
  //   store.commit("UPDATE_SELECTED_IMG", imageId);
  //   // store.commit("UPDATE_GALLERY_ANIMATION", true);

  //   // set new image
  //   store.commit("TOGGLE_GALLERY", true);
  // },
  closeGallery(store) {
    store.commit("TOGGLE_GALLERY", false);
  },
  resetGallery(store) {
    store.commit("UPDATE_SELECTED_IMG", null);
    store.commit("TOGGLE_GALLERY", false);
  },
};

// mutations
const mutations = {
  // UPDATE_GALLERY_ANIMATION(state, bool) {
  //   state.isGalleryAnimationRunning = bool;
  // },
  TOGGLE_GALLERY(state, bool) {
    state.isGalleryOpen = bool;
  },
  TOGGLE_USER_GOING_NEXT_PAGE(state, bool) {
    state.isUserGoingToNextPage = bool;
  },
  UPDATE_SELECTED_IMG(state, id) {
    state.selectedImgId = id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
