<template>
  <div class="navigation-main">
    <!-- left column -->
    <navigation-main-left :is-visible="!isIntroVisible" :is-black="isWhite()" />
    <!-- right colum -->
    <navigation-main-right :is-visible="isAudioVisible" :is-black="isWhite()" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import navigationMixin from "@/mixins/navigationMixin.js";

import NavigationMainLeft from "@/components/Navigation/NavigationMain/NavigationMainLeft/NavigationMainLeft";
import NavigationMainRight from "@/components/Navigation/NavigationMain/NavigationMainRight/NavigationMainRight";
export default {
  mixins: [navigationMixin],
  components: {
    NavigationMainLeft,
    NavigationMainRight,
  },
  computed: {
    ...mapState({
      isIntroVisible: (state) => state.intro.isVisible,
      isPageTransitionVisible: (state) =>
        state.sharedTransition.isPageTransitionVisible,
    }),
    isAudioVisible() {
      return (
        !this.isIntroVisible ||
        (this.isIntroVisible && !this.isPageTransitionVisible)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-main {
  @include responsiveLayoutPadding;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 90;

  pointer-events: none;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
