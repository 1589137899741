// initial state
const state = () => ({
  isVisible: true,
  isVideoPlaying: false,
  progress: 0,
  loaded: 0,
});

// getters
const getters = {};

// actions
const actions = {
  HIDE_INTRO(store) {
    store.commit("HIDE_INTRO");
  },
  TOGGLE_VIDEO(store, bool) {
    store.commit("TOGGLE_VIDEO", bool);
  },
  PROGRESS_VIDEO(store, progress) {
    store.commit("PROGRESS_VIDEO", progress);
  },
  UPDATE_INTRO_LOAD(store, load) {
    store.commit("UPDATE_INTRO_LOAD", load);
  },
};

// mutations
const mutations = {
  HIDE_INTRO(state) {
    state.isVisible = false;
  },
  TOGGLE_VIDEO(state, bool) {
    state.isVideoPlaying = bool;
  },
  PROGRESS_VIDEO(state, progress) {
    state.progress = progress;
  },
  UPDATE_INTRO_LOAD(state, load) {
    state.loaded = load;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
