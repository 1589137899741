<template>
  <div class="shared-button-rectangle">
    <shared-button-rectangle-main
      :isAnimationStarted="isAnimationStarted"
      :is-white="isWhite"
      :is-black="isBlack"
      :copy="copy"
    />
  </div>
</template>

<script>
import SharedButtonRectangleMain from "@/components/Shared/SharedButton/SharedButtonRectangle/SharedButtonRectangleMain";
export default {
  components: {
    SharedButtonRectangleMain,
  },
  props: {
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBlack: {
      type: Boolean,
      required: false,
      default: false,
    },
    copy: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      timeOut: {
        timeOut: null,
        time: 500,
      },
      isAnimationStarted: false,
    };
  },

  mounted() {
    this.startAnimation();
  },
  beforeDestroy() {
    this.clearTimeOut();
  },
  methods: {
    ////////////////////////////////
    //       START ANIMATION
    ////////////////////////////////
    startAnimation() {
      this.timeOut.timeOut = setTimeout(() => {
        this.toggleAnimation(true);
        this.clearTimeOut();
      }, this.timeOut.time);
    },
    toggleAnimation(bool) {
      this.isAnimationStarted = bool;
    },
    clearTimeOut() {
      clearTimeout(this.timeOut.timeOut);
    },
    ////////////////////////////////
    //       END ANIMATION
    ////////////////////////////////
  },
};
</script>

<style lang="scss" scoped>
.shared-button-rectangle {
  @include removeButtonStyle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 3px; */
  position: relative;
}
</style>
