<template>
  <div class="home-page">
    <!-- background video -->
    <home-page-background v-if="!isIntroVisible" />
    <!-- inner -->
    <home-page-main v-if="isHomeCopyVisible" class="home-page__inner" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import HomePageBackground from "@/components/HomePage/HomePageBackground";
import HomePageMain from "@/components/HomePage/HomePageMain/HomePageMain";

export default {
  components: {
    HomePageBackground,
    HomePageMain,
  },
  data() {
    return {
      isHomeCopyVisible: false,
      timeoutCopy: null,
    };
  },
  computed: {
    ...mapState({
      isIntroVisible: (state) => state.intro.isVisible,
    }),
  },
  beforeDestroy() {
    this.timeoutDestroyer();
  },

  watch: {
    isIntroVisible(bool) {
      !bool ? (this.isHomeCopyVisible = true) : null;
    },
  },

  mounted() {
    this.updateProgressInformation();
    this.isIntroVisible ? null : this.timeoutHomeCopyVisiblity();
  },
  methods: {
    timeoutHomeCopyVisiblity() {
      this.timeoutCopy = setTimeout(() => {
        this.isHomeCopyVisible = true;
        this.timeoutDestroyer();
      }, 1000);
    },
    updateProgressInformation() {
      this.$store.commit("sharedTransition/SET_NEW_PROGRESS", "home");
    },
    timeoutDestroyer() {
      this.timeoutCopy
        ? (clearTimeout(this.timeoutCopy), (this.timeoutCopy = null))
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  @include centeredPageLayout;
}
</style>
