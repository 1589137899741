import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      isMobile: (state) => state.userDevice.isMobile,
    }),
  },
  methods: {
    updateMusicTrack(key, mutationType) {
      if (this.isMobile) return;
      const compressedPath = "compressed/";
      const desktopPath = "desktop/";
      switch (key) {
        case "LiftOff":
          this.submitToStore(
            mutationType,
            compressedPath,
            "john-tasoulas-voyager1.mp3"
          );
          break;
        case "Space":
          this.submitToStore(
            mutationType,
            desktopPath,
            "lesion-x-a-journey-through-the-universe-1.mp3"
          );
          break;
        case "Landing":
          this.submitToStore(
            mutationType,
            compressedPath,
            "alex-productions-epic-cinematic-gaming-cyberpunk-reset.mp3"
          );

          break;
        case "FirstSteps":
          this.submitToStore(
            mutationType,
            compressedPath,
            "keys-of-moon-sleepless-city.mp3"
          );
          break;
        case "Exploration":
          this.submitToStore(
            mutationType,
            compressedPath,
            "jiglr-cyberpunk.mp3"
          );
          break;

        //   credits, legal, open nav
        default:
          this.submitToStore(
            mutationType,
            desktopPath,
            "scott-buckley-passage-of-time.mp3"
          );
          break;
      }
    },
    submitToStore(mutationType, responsivePath, track) {
      this.$store.commit(
        this.setMutationName(mutationType),
        this.pathToMusicTrack(responsivePath, track)
      );
    },
    setMutationName(mutationType) {
      switch (mutationType) {
        case "set":
          return "sharedAudio/updateCurrentMusicTrack";
        case "update":
          return "sharedAudio/updateUpComingMusicTrack";
        default:
          return "sharedAudio/updateCurrentMusicTrack";
      }
    },
    pathToMusicTrack(path, audioTrack) {
      return `/music/${path}${audioTrack}`; // debug
    },
  },
};
