// initial state
const state = () => ({
  isNavOpen: false,
  isMenuButtonDisabled: false,
  isSideBarButtonDisabled: true,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  TOGGLE_NAVIGATION(state) {
    state.isNavOpen = !state.isNavOpen;
  },
  UPDATE_NAVIGATION(state, bool) {
    state.isNavOpen = bool;
  },
  TOGGLE_MENU_DISABLE(state, bool) {
    state.isMenuButtonDisabled = bool;
  },
  TOGGLE_SIDEBARS_DISABLE(state, bool) {
    state.isSideBarButtonDisabled = bool;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
