<template>
  <div class="home">
    <!-- <three-scene /> -->
    <lift-off />
  </div>
</template>

<script>
// @ is an alias to /src

// import ThreeScene from "@/components/ThreeScene.vue";
import LiftOff from "@/components/LiftOff/LiftOff";

export default {
  metaInfo: {
    title: "Lift Off | The Leap For Mankind",
  },
  name: "Home",
  components: {
    LiftOff,
    // ThreeScene,
  },
};
</script>
