import { mapState } from "vuex";
export default {
  data() {
    return {
      timeoutTransitionIsLongEnough: null,
    };
  },
  computed: {
    ...mapState({
      isDev: (state) => state.shared.isDev,
    }),
  },

  beforeDestroy() {
    this.timeoutDestroyer();
  },
  methods: {
    startTimerTransitionIsLongEnough() {
      this.toggleTransitionIsLongEnough(false);
      this.timeoutTransitionIsLongEnough = setTimeout(() => {
        this.toggleTransitionIsLongEnough(true);
        this.timeoutDestroyer();
      }, this.customTransitionLength());
    },
    customTransitionLength() {
      return this.isDev && this.isGamePlayDebug ? 100 : 2000;
    },
    timeoutDestroyer() {
      this.timeoutTransitionIsLongEnough
        ? (clearTimeout(this.timeoutTransitionIsLongEnough),
          (this.timeoutTransitionIsLongEnough = null))
        : null;
    },

    toggleTransitionIsLongEnough(bool) {
      this.$store.commit(
        "sharedTransition/TOGGLE_TRANSITION_ENOUGH_LONG",
        bool
      );
    },

    resetTransitionIsLongEnough() {
      this.toggleTransitionIsLongEnough(false);
    },
  },
};
